import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { ApiExtraService, ApiExtraUnit, ApiHotel, ApiHotelRoom } from '@ibe/api';
import { ApiAccommodation } from '../../../api/model';
import Image from '@/components/image/Image';
import { API_ITEM_SERVICE_CODE } from '@/Util/globals';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';
import HotelRooms from '@/components/hotelSelection/HotelRooms';
import { default404Image } from '@ibe/components';
import TruncateContent from '@/components/truncateContent/TruncateContent';
import { Props } from '@/types/cms/magnolia';
import HotelTeaserRightSide from '@/components/hotelSelection/HotelTeaserRightSide';

const HotelTeaser: FC<{
  hotel: ApiHotel;
  allHotels: ApiHotel[];
  totalNumberOfSelectables: number;
  componentId: string;
  checkoutStore: CheckoutStore;
  selectedHotel?: ApiHotel;
  setSelectedAccommodation: Dispatch<SetStateAction<ApiAccommodation | undefined>>;
  serviceType: API_ITEM_SERVICE_CODE;
  selectExternalHotel?: (hotel: ApiHotel, roomUnitRateId?: string) => void;
  pageProps?: Props;
  isExtension?: boolean;
}> = observer(function HotelTeaser({
  hotel,
  allHotels,
  setSelectedAccommodation,
  componentId,
  checkoutStore,
  totalNumberOfSelectables,
  selectedHotel,
  serviceType,
  selectExternalHotel,
  pageProps,
  isExtension
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const isActive = selectedHotel?.id === hotel.id || selectedHotel?.code === hotel.code;
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const accommodation = useMemo(() => {
    return hotel.metaInfo?.contentByMetaType?.HotelContent?.content?.[hotel.cmsCode] as
      | ApiAccommodation
      | undefined;
  }, [hotel]);

  const allRooms = useMemo((): ApiHotelRoom[] => {
    return (
      (serviceType === API_ITEM_SERVICE_CODE.CRUISE
        ? allHotels.reduce((total: ApiHotel[], current: ApiHotel) => {
            return [...total, ...current.rooms.map(room => ({ ...current, rooms: [room] }))];
          }, [])
        : allHotels
      ).flatMap(singleHotel => singleHotel.rooms) || []
    ).sort((a, b) => {
      const priceA = a?.roomrates?.[0]?.totalPrice;
      const priceB = b?.roomrates?.[0]?.totalPrice;
      if (priceA.finalPrice === priceB.finalPrice) {
        return (a.description || a.name || '').localeCompare(b.description || b.name || '');
      } else {
        return (priceA.finalPrice || 0) - (priceB.finalPrice || 0);
      }
    });
  }, [allHotels]);

  const allExtraUnits = useMemo((): ApiExtraUnit[] => {
    return allHotels.flatMap(
      singleHotel => ((singleHotel as unknown) as ApiExtraService).extraUnits
    );
  }, [allHotels]);

  const cmsRooms = useMemo(() => {
    return allHotels
      .flatMap(singleHotel => singleHotel?.rooms || [])
      .map(singleRoom => {
        return accommodation?.rooms?.find(room => room.cmsCode === singleRoom.cmsCode);
      });
  }, [allHotels]);

  const onTruncate = (isTruncated: boolean): void => {
    setIsTruncated(isTruncated);
  };

  const handleSelect = async (): Promise<void> => {
    if (!!selectExternalHotel) {
      selectExternalHotel(hotel);
    } else {
      await checkoutStore.selectItemsInCart(componentId, [allHotels[0].rooms[0].roomrates[0].id]);
    }
  };

  return (
    <div className="checkout-teaser__container">
      <div
        className={classNames('checkout-teaser', {
          'checkout-teaser--active': isActive && totalNumberOfSelectables > 1,
          'checkout-teaser--active-single': isActive && totalNumberOfSelectables === 1
        })}
      >
        <div className="checkout-teaser__left">
          {!!accommodation?.images && accommodation.images.length > 0 ? (
            <Image
              src={accommodation.images[0].imageLink || ''}
              alt={accommodation.images[0].title || accommodation.images[0].caption || ''}
              width={accommodation.images[0].width}
              height={accommodation.images[0].height}
              serverSideContext={pageProps?.albConfig.context}
            />
          ) : (
            <Image
              src={default404Image}
              alt="default image"
              width={100}
              height={100}
              serverSideContext={pageProps?.albConfig.context}
            />
          )}
        </div>
        <div className="checkout-teaser__center">
          <h4>{hotel.name}</h4>
          {!!accommodation?.shortDescription && (
            <div>
              <TruncateContent onTruncate={onTruncate}>
                {accommodation.shortDescription}
              </TruncateContent>
            </div>
          )}
          {!!accommodation && accommodation?.accommodationType !== 'landService' && isTruncated && (
            <div
              className="checkout-teaser__read-more"
              onClick={(): void => setSelectedAccommodation(accommodation)}
            >
              {t(Keys.readMore)}
            </div>
          )}
        </div>
        <HotelTeaserRightSide
          hotel={hotel}
          selectedHotel={selectedHotel}
          allRooms={allRooms}
          allExtraUnits={allExtraUnits}
          cmsRooms={cmsRooms}
          totalNumberOfSelectables={totalNumberOfSelectables}
          handleSelect={handleSelect}
          isActive={isActive}
          serviceType={serviceType}
          componentId={componentId}
          checkoutStore={checkoutStore}
          pageProps={pageProps}
          selectExternalHotel={selectExternalHotel}
          isExtension={isExtension}
        />
      </div>
      {allRooms.length > 1 && (
        <Collapse isOpen={isActive}>
          <HotelRooms
            allRooms={allRooms}
            allExtraUnits={allExtraUnits}
            cmsRooms={cmsRooms}
            selectedHotel={selectedHotel}
            componentId={componentId}
            checkoutStore={checkoutStore}
            hotel={hotel}
            selectExternalHotel={selectExternalHotel}
            pageProps={pageProps}
          />
        </Collapse>
      )}
    </div>
  );
});

export default HotelTeaser;
