import React, { ReactElement, useId, MouseEvent } from 'react';
import classNames from 'classnames';

export const Close = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
    <circle cx="26" cy="26" r="26" fill="white" />
    <rect
      x="16.7148"
      y="34.0859"
      width="24.5664"
      height="1.29297"
      transform="rotate(-45 16.7148 34.0859)"
      fill="black"
    />
    <rect
      x="17.6289"
      y="16.7148"
      width="24.5664"
      height="1.29297"
      transform="rotate(45 17.6289 16.7148)"
      fill="black"
    />
  </svg>
);

export const Next = ({ className }: { className?: string }): ReactElement => (
  <svg
    className={classNames('svgs__next', className || '')}
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
  >
    <g id="Group 348">
      <g id="Group 74">
        <circle id="Ellipse 34" cx="26" cy="26" r="26" fill="#7FF1C5" />
      </g>
      <path id="Vector 1" d="M23 35L33 27L23 19" stroke="black" strokeWidth="2" />
    </g>
  </svg>
);

export const Previous = (): ReactElement => (
  <svg
    className="svgs__previous"
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
  >
    <g id="Group 349">
      <g id="Group 74">
        <circle
          id="Ellipse 34"
          cx="26"
          cy="26"
          r="25.5"
          transform="rotate(-180 26 26)"
          stroke="#7FF1C5"
          fill="none"
        />
      </g>
      <path id="Vector 1" d="M29 17L19 25L29 33" stroke="#7FF1C5" strokeWidth="2" fill="none" />
    </g>
  </svg>
);

export const EyeIcon = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
    <g id="Group 61">
      <circle cx="27" cy="27" r="27" />
      <path
        id="Vector"
        d="M39.9407 26.4561C39.9018 26.3688 38.9499 24.3043 36.8242 22.2252C34.8518 20.2984 31.463 18 26.5 18C21.537 18 18.1482 20.2984 16.1758 22.2252C14.0501 24.3043 13.0982 26.3655 13.0593 26.4561C13.0202 26.542 13 26.6351 13 26.7291C13 26.8232 13.0202 26.9162 13.0593 27.0022C13.0982 27.0883 14.0501 29.1528 16.1758 31.2319C18.1482 33.1588 21.537 35.456 26.5 35.456C31.463 35.456 34.8518 33.1588 36.8242 31.2319C38.9499 29.1528 39.9018 27.0917 39.9407 27.0022C39.9798 26.9162 40 26.8232 40 26.7291C40 26.6351 39.9798 26.542 39.9407 26.4561ZM26.5 34.1132C22.9099 34.1132 19.7751 32.8354 17.1815 30.3166C16.0942 29.2597 15.1742 28.0503 14.4517 26.728C15.174 25.406 16.094 24.1968 17.1815 23.1406C19.7751 20.6206 22.9099 19.3428 26.5 19.3428C30.0901 19.3428 33.2249 20.6206 35.8185 23.1406C36.906 24.1968 37.826 25.406 38.5483 26.728C37.8195 28.0943 34.1653 34.1132 26.5 34.1132ZM26.5 21.5807C25.4591 21.5807 24.4416 21.8826 23.5762 22.4482C22.7107 23.0138 22.0362 23.8177 21.6378 24.7582C21.2395 25.6988 21.1353 26.7337 21.3383 27.7322C21.5414 28.7307 22.0426 29.6478 22.7787 30.3677C23.5147 31.0875 24.4524 31.5778 25.4733 31.7764C26.4942 31.975 27.5523 31.8731 28.514 31.4835C29.4756 31.0939 30.2976 30.4341 30.8758 29.5877C31.4541 28.7412 31.7628 27.746 31.7628 26.728C31.761 25.3634 31.2059 24.0552 30.2193 23.0903C29.2328 22.1254 27.8952 21.5825 26.5 21.5807ZM26.5 30.5325C25.7307 30.5325 24.9786 30.3094 24.3389 29.8913C23.6992 29.4733 23.2006 28.8791 22.9062 28.1839C22.6118 27.4887 22.5348 26.7238 22.6849 25.9858C22.835 25.2478 23.2054 24.5699 23.7494 24.0378C24.2935 23.5057 24.9866 23.1434 25.7411 22.9966C26.4957 22.8498 27.2778 22.9251 27.9886 23.2131C28.6994 23.501 29.3069 23.9887 29.7343 24.6143C30.1617 25.24 30.3899 25.9755 30.3899 26.728C30.3899 27.737 29.9801 28.7047 29.2506 29.4182C28.5211 30.1317 27.5317 30.5325 26.5 30.5325Z"
      />
    </g>
  </svg>
);
export const MapClose = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
    <circle cx="26" cy="26" r="26" fill="#082E33" />
    <rect
      x="16.7148"
      y="34.0859"
      width="24.5664"
      height="1.29297"
      transform="rotate(-45 16.7148 34.0859)"
      fill="white"
    />
    <rect
      x="17.6289"
      y="16.7148"
      width="24.5664"
      height="1.29297"
      transform="rotate(45 17.6289 16.7148)"
      fill="white"
    />
  </svg>
);

export const CircleCaretDown = ({
  text,
  textStartOffset
}: {
  text?: string;
  textStartOffset?: number;
}): ReactElement => {
  const randomId = useId();

  return (
    <div className="circle-caret-down">
      {!!text && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
        >
          <g id="Group 1014">
            <g id="Group 75">
              <path
                id={randomId}
                stroke="white"
                opacity="0"
                d={`  
                M 0, 30
                a 30,30 0 1,1 60,0
                30,30 0 1,1 -60,0
              `}
              />
              <text className="circle-caret-down__text-path" fill="white">
                <textPath
                  href={`#${randomId}`}
                  fill="white"
                  textLength={Math.floor(Math.PI * 2.15 * text.length)}
                  startOffset={textStartOffset || 0}
                >
                  {text}
                </textPath>
              </text>
            </g>
          </g>
        </svg>
      )}
      <div
        style={{
          borderRadius: '50%',
          overflow: 'hidden',
          padding: '0.0625rem 0 0 0.0625rem'
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
        >
          <g id="Group 1015">
            <g id="Group 76">
              <circle
                cx="24"
                cy="24"
                r="24"
                transform="rotate(-90 23.8649 23.8656)"
                stroke="white"
              />
            </g>
            <path
              id="Vector 1"
              d="M17 19.7998L24 28.1998L31 19.7998"
              stroke="white"
              strokeWidth="2.2"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export const Play = (): ReactElement => {
  return (
    <svg
      className="svgs__play"
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27" cy="27" r="27" transform="rotate(-180 27 27)" fill="#7FF1C5" />
      <path d="M21 16V38L37.7327 27L21 16Z" fill="#274E54" />
    </svg>
  );
};
export const ZoomIn = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 26 26" fill="none">
      <path
        d="M13.0395 25.4414C19.8047 25.4414 25.2889 19.9571 25.2889 13.192C25.2889 6.42677 19.8047 0.942505 13.0395 0.942505C6.27431 0.942505 0.790039 6.42677 0.790039 13.192C0.790039 19.9571 6.27431 25.4414 13.0395 25.4414Z"
        fill="#082E33"
        stroke="white"
        strokeWidth="0.964215"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2117 13.4647H8.41211M13.3119 18.3645V8.56494"
        stroke="white"
        strokeWidth="0.964215"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ZoomOut = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 27 26" fill="none">
      <path
        d="M13.5385 25.4414C20.3037 25.4414 25.788 19.9571 25.788 13.192C25.788 6.42677 20.3037 0.942505 13.5385 0.942505C6.77333 0.942505 1.28906 6.42677 1.28906 13.192C1.28906 19.9571 6.77333 25.4414 13.5385 25.4414Z"
        fill="#082E33"
        stroke="white"
        strokeWidth="0.964215"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4529 13.1874H8.65332"
        stroke="white"
        strokeWidth="0.964215"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export type StarMode = 'empty' | 'half' | 'full';
export const RatingStar = ({ mode = 'full' }: { mode: StarMode }): ReactElement => {
  return (
    <svg
      className={`rating-star ${
        mode === 'empty'
          ? 'rating-star--empty'
          : mode === 'half'
          ? 'rating-star--half'
          : 'rating-star--full'
      }`}
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.85176 2.31369C8.04881 1.82643 8.73868 1.82643 8.93572 2.31369L10.6411 6.53093C10.7251 6.73859 10.9203 6.88001 11.1438 6.89506L15.7022 7.202C16.2291 7.23747 16.4426 7.89801 16.0362 8.23521L12.5363 11.1391C12.3627 11.2831 12.2875 11.514 12.3428 11.7326L13.4567 16.1293C13.586 16.6397 13.0275 17.0476 12.5807 16.769L8.70303 14.3515C8.51373 14.2335 8.27376 14.2335 8.08445 14.3515L4.20681 16.769C3.75999 17.0476 3.2015 16.6397 3.33081 16.1293L4.44464 11.7326C4.50001 11.514 4.42475 11.2831 4.25122 11.1391L0.75125 8.23521C0.344844 7.89801 0.558387 7.23747 1.08527 7.202L5.64368 6.89506C5.86717 6.88001 6.06241 6.73859 6.14638 6.53093L7.85176 2.31369Z"
        fill="#fff"
      />
      <path
        d="M8.33338 1.94824C8.13273 1.96837 7.94133 2.08918 7.85176 2.31067L6.14638 6.52792C6.06241 6.73557 5.86717 6.877 5.64368 6.89204L1.08527 7.19898C0.558387 7.23446 0.344844 7.895 0.75125 8.23219L4.25122 11.1361C4.42475 11.2801 4.50001 11.511 4.44464 11.7296L3.33081 16.1263C3.2015 16.6367 3.75999 17.0445 4.20681 16.766L8.08445 14.3485C8.16134 14.3005 8.24658 14.2721 8.33338 14.2631V1.94824Z"
        fill="#"
      />
    </svg>
  );
};

export const Layer = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26" fill="none">
      <g id="Group 1025">
        <path
          id="Vector"
          d="M21.9787 0.865603H15.3198C15.1128 0.865451 14.9142 0.947245 14.7673 1.09311L1.45501 14.4022C1.1636 14.695 1 15.0913 1 15.5045C1 15.9176 1.1636 16.314 1.45501 16.6068L7.79266 22.9444C8.0856 23.2359 8.48202 23.3995 8.89525 23.3995C9.30848 23.3995 9.7049 23.2359 9.99784 22.9444L23.3064 9.64081C23.4522 9.49393 23.534 9.29529 23.5339 9.08829V2.42564C23.5349 2.2209 23.4954 2.01799 23.4176 1.82858C23.3399 1.63918 23.2254 1.46702 23.0809 1.32202C22.9363 1.17702 22.7645 1.06205 22.5754 0.983717C22.3862 0.905385 22.1834 0.865243 21.9787 0.865603Z"
          stroke="black"
          strokeWidth="1.34818"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M19.2021 6.93391C18.8593 6.93391 18.5242 6.83225 18.2391 6.64178C17.9541 6.45132 17.7319 6.1806 17.6007 5.86387C17.4695 5.54714 17.4352 5.19861 17.5021 4.86237C17.5689 4.52613 17.734 4.21727 17.9764 3.97486C18.2189 3.73244 18.5277 3.56735 18.864 3.50047C19.2002 3.43359 19.5487 3.46791 19.8655 3.59911C20.1822 3.7303 20.4529 3.95248 20.6434 4.23753C20.8338 4.52258 20.9355 4.85771 20.9355 5.20054C20.9355 5.66026 20.7529 6.10115 20.4278 6.42622C20.1027 6.75129 19.6618 6.93391 19.2021 6.93391Z"
          fill="black"
        />
        <path
          id="Vector_3"
          d="M12.5898 25.1343L26.7818 10.9423C26.8524 10.8712 26.9079 10.7865 26.9452 10.6935C26.9824 10.6004 27.0005 10.5009 26.9985 10.4007V3.46716"
          stroke="black"
          strokeWidth="1.34818"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const Tips = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9.99219C1 8.04219 5.701 5.49219 11.5 5.49219C17.299 5.49219 22 8.04219 22 9.99219V14.4922C22 16.4422 17.299 18.9922 11.5 18.9922C5.701 18.9922 1 16.4422 1 14.4922V9.99219Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 14.4682C17.299 14.4682 22 12.0592 22 9.98919C22 7.91769 17.299 5.49219 11.5 5.49219C5.701 5.49219 1 7.91769 1 9.98919C1 12.0592 5.701 14.4682 11.5 14.4682Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 5.50781C1 3.55781 5.701 1.00781 11.5 1.00781C17.299 1.00781 22 3.55781 22 5.50781V10.0078C22 11.9578 17.299 14.5078 11.5 14.5078C5.701 14.5078 1 11.9578 1 10.0078V5.50781Z"
        fill="#E4F3F8"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 9.98381C17.299 9.98381 22 7.57481 22 5.50481C22 3.43331 17.299 1.00781 11.5 1.00781C5.701 1.00781 1 3.43331 1 5.50481C1 7.57481 5.701 9.98381 11.5 9.98381Z"
        fill="#E4F3F8"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Bus = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="25" viewBox="0 0 23 25" fill="none">
      <g id="Group 1030">
        <path
          id="Vector"
          d="M19.1816 4.82263H2.81816C1.81402 4.82263 1 5.63665 1 6.64079V13.9134C1 14.9176 1.81402 15.7316 2.81816 15.7316H19.1816C20.1858 15.7316 20.9998 14.9176 20.9998 13.9134V6.64079C20.9998 5.63665 20.1858 4.82263 19.1816 4.82263Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M19.1816 15.7289H2.81816C1.81402 15.7289 1 16.5429 1 17.547V21.1834C1 22.1875 1.81402 23.0015 2.81816 23.0015H19.1816C20.1858 23.0015 20.9998 22.1875 20.9998 21.1834V17.547C20.9998 16.5429 20.1858 15.7289 19.1816 15.7289Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M19.1816 4.81833H2.81816C2.33637 4.81698 1.8747 4.62499 1.53402 4.28431C1.19334 3.94363 1.00135 3.48196 1 3.00017C1.00135 2.51838 1.19334 2.0567 1.53402 1.71602C1.8747 1.37535 2.33637 1.18336 2.81816 1.18201H19.1816C19.6634 1.18336 20.1251 1.37535 20.4658 1.71602C20.8064 2.0567 20.9984 2.51838 20.9998 3.00017C20.9984 3.48196 20.8064 3.94363 20.4658 4.28431C20.1251 4.62499 19.6634 4.81698 19.1816 4.81833ZM4.63632 22.9999V24.2499C4.63632 24.4006 4.57646 24.5451 4.46991 24.6517C4.36335 24.7582 4.21884 24.8181 4.06815 24.8181H2.47726C2.32657 24.8181 2.18205 24.7582 2.0755 24.6517C1.96894 24.5451 1.90908 24.4006 1.90908 24.2499V22.9999H4.63632ZM20.0907 22.9999V24.2499C20.0907 24.4006 20.0308 24.5451 19.9243 24.6517C19.8177 24.7582 19.6732 24.8181 19.5225 24.8181H17.9316C17.7809 24.8181 17.6364 24.7582 17.5299 24.6517C17.4233 24.5451 17.3634 24.4006 17.3634 24.2499V22.9999H20.0907Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M17.3622 20.2736C17.8643 20.2736 18.2713 19.8666 18.2713 19.3645C18.2713 18.8625 17.8643 18.4554 17.3622 18.4554C16.8601 18.4554 16.4531 18.8625 16.4531 19.3645C16.4531 19.8666 16.8601 20.2736 17.3622 20.2736Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M4.63564 20.2736C5.13771 20.2736 5.54472 19.8666 5.54472 19.3645C5.54472 18.8625 5.13771 18.4554 4.63564 18.4554C4.13357 18.4554 3.72656 18.8625 3.72656 19.3645C3.72656 19.8666 4.13357 20.2736 4.63564 20.2736Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const DrinkingWater = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="34" viewBox="0 0 29 34" fill="none">
      <g id="Layer_1" clipPath="url(#clip0_4993_3692)">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.30275 0.0432129C7.93903 0.0432129 11.292 5.99124 11.292 8.74659C11.292 11.5019 9.0581 13.7363 6.30275 13.7363C3.54741 13.7363 1.31301 11.503 1.31301 8.74659C1.31301 5.99017 4.66595 0.0432129 6.30275 0.0432129ZM27.2634 29.7485C28.4575 29.3975 28.9908 31.2138 27.7967 31.5643L19.7772 33.9167C19.6882 33.9429 19.5987 33.9552 19.5103 33.9552L8.8598 33.9574C6.83013 33.9574 5.61941 31.7133 6.12374 29.9099H4.44406C2.25093 29.9099 1.12007 27.5506 1.87308 25.6935C-0.71022 24.8193 -0.546753 20.8281 1.95348 20.044C1.14311 17.9881 2.39403 15.87 4.67131 15.87H17.6002C18.3173 15.87 17.8429 15.252 17.7052 15.1582C16.2651 14.186 14.5045 12.8247 13.201 11.6611C10.9806 9.68183 13.0429 5.97463 16.6204 7.41528C19.2697 8.48183 23.1216 10.4595 23.5182 10.6637C23.6012 10.7028 23.68 10.7543 23.7518 10.8186L28.6703 15.2381C29.5948 16.0704 28.3273 17.4778 27.4028 16.6455L22.5744 12.3069C21.9082 11.965 18.3548 10.1524 15.9162 9.17107C14.6122 8.64637 13.6207 9.49853 14.4605 10.2457C15.7131 11.3637 17.3847 12.6564 18.7648 13.5879C20.5179 14.7739 20.356 17.7667 17.9142 17.7667H4.67131C3.12722 17.7667 3.41128 19.9175 4.51588 19.9175H13.3999C14.647 19.9175 14.647 21.8142 13.3999 21.8142H2.80511C1.69997 21.8142 1.41699 23.965 2.96107 23.965H13.3999C14.647 23.965 14.647 25.8623 13.3999 25.8623H4.60056C3.05862 25.8623 3.33678 28.0131 4.44406 28.0131H15.0388C16.2865 28.0131 16.2865 29.9099 15.0388 29.9099H8.75743C7.31892 29.9099 7.90687 32.0601 8.8598 32.0601H19.3822L27.2634 29.7485Z"
          fill="black"
          stroke="white"
          strokeWidth="0.0133989"
          strokeMiterlimit="10"
        />
        <path
          className="drinking-water"
          id="Vector_2"
          d="M6.30469 5.37463V11.5574"
          stroke="white"
          strokeWidth="1.07191"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          className="drinking-water"
          id="Vector_3"
          d="M9.39372 8.46594H3.21094"
          stroke="white"
          strokeWidth="1.07191"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4993_3692">
          <rect width="29" height="33.927" fill="white" transform="translate(0 0.036499)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CheckboxCheck = (): ReactElement => {
  return (
    <svg
      className="icon-checkbox-check"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="15" height="15" fill="#082E33" />
      <rect x="0.5" y="0.5" width="15" height="15" stroke="#082E33" />
      <path
        d="M11.1605 5.57397L11.1597 5.57463L6.79302 9.31722L4.84044 7.64372C4.8404 7.64369 4.84036 7.64366 4.84032 7.64362C4.78374 7.59478 4.70676 7.56731 4.62645 7.56731C4.54613 7.56731 4.46913 7.59479 4.41255 7.64365C4.41252 7.64367 4.41249 7.6437 4.41246 7.64372L4.08915 7.92082C4.08911 7.92086 4.08907 7.92089 4.08903 7.92093C4.03204 7.96942 4 8.0354 4 8.10422C4 8.17306 4.03206 8.23906 4.08907 8.28755C4.08909 8.28758 4.08912 8.2876 4.08915 8.28763L6.58133 10.4236C6.58138 10.4236 6.58143 10.4236 6.58147 10.4237C6.63805 10.4725 6.71503 10.5 6.79533 10.5C6.87563 10.5 6.9526 10.4725 7.00918 10.4237C7.00922 10.4236 7.00927 10.4236 7.00932 10.4236L11.9108 6.22267C11.9108 6.22265 11.9109 6.22264 11.9109 6.22263C11.9679 6.17413 12 6.10812 12 6.03926C12 5.9705 11.968 5.90457 11.9111 5.85609C11.911 5.85601 11.911 5.85594 11.9108 5.85586L11.5877 5.57493V5.57492L11.5866 5.57397C11.4678 5.47534 11.2792 5.47534 11.1605 5.57397Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="1"
      />
    </svg>
  );
};

export const ExclamationMarkCircle = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30">
      <g clip-path="url(#clip0_2249_1012)">
        <path
          className="exclamation-circle"
          d="M14.5 29.3272C22.5081 29.3272 29 22.8353 29 14.8272C29 6.81905 22.5081 0.327179 14.5 0.327179C6.49187 0.327179 0 6.81905 0 14.8272C0 22.8353 6.49187 29.3272 14.5 29.3272Z"
        />
        <path
          className="exclamation"
          d="M11.9512 13.2901C13.2142 13.1599 14.3145 12.893 15.252 12.4893C15.4147 12.5544 15.5286 12.639 15.5938 12.7432C15.5938 16.1221 15.6198 18.5375 15.6719 19.9893C15.6784 20.1586 15.7272 20.3051 15.8184 20.4288C15.916 20.546 16.0527 20.6371 16.2285 20.7022C16.4043 20.7673 16.5508 20.8161 16.668 20.8487C16.7852 20.8747 16.9382 20.9008 17.127 20.9268C17.166 21.0049 17.1855 21.0863 17.1855 21.171C17.1855 21.2556 17.166 21.3272 17.127 21.3858C16.2676 21.3467 15.4342 21.3272 14.627 21.3272C13.8327 21.3272 13.0059 21.3467 12.1465 21.3858C12.1204 21.2816 12.1074 21.21 12.1074 21.171C12.1074 21.1384 12.1204 21.057 12.1465 20.9268C12.3613 20.9008 12.5371 20.8747 12.6738 20.8487C12.8105 20.8161 12.9798 20.7673 13.1816 20.7022C13.3835 20.6371 13.5365 20.546 13.6406 20.4288C13.7448 20.3051 13.8001 20.1586 13.8066 19.9893C13.8327 19.3448 13.8457 18.5635 13.8457 17.6456C13.8457 17.3396 13.8424 16.8187 13.8359 16.0831C13.8294 15.3474 13.8262 14.7029 13.8262 14.1495C13.4551 13.9216 12.8301 13.7881 11.9512 13.7491C11.9251 13.6449 11.9121 13.5635 11.9121 13.5049C11.9121 13.4659 11.9251 13.3943 11.9512 13.2901ZM13.4844 9.20804C13.4844 8.87601 13.6016 8.5928 13.8359 8.35843C14.0768 8.12406 14.36 8.00687 14.6855 8.00687C15.0176 8.00687 15.3008 8.12406 15.5352 8.35843C15.7695 8.5928 15.8867 8.87601 15.8867 9.20804C15.8867 9.53356 15.7695 9.81676 15.5352 10.0576C15.3008 10.292 15.0176 10.4092 14.6855 10.4092C14.36 10.4092 14.0768 10.292 13.8359 10.0576C13.6016 9.81676 13.4844 9.53356 13.4844 9.20804Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2249_1012">
          <rect width="29" height="29" fill="white" transform="translate(0 0.327179)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CrossSmall = (): ReactElement => {
  return (
    <svg
      className="icon-cross-small"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.0012207"
        y="18.2283"
        width="24.5664"
        height="1.29297"
        transform="rotate(-45 0.0012207 18.2283)"
        fill="currentColor"
      />
      <rect
        x="0.914307"
        y="0.857422"
        width="24.5664"
        height="1.29297"
        transform="rotate(45 0.914307 0.857422)"
        fill="currentColor"
      />
    </svg>
  );
};

export const CircleHeart = ({
  className,
  onClick
}: {
  className?: string;
  onClick?: (e: MouseEvent<HTMLOrSVGElement>) => void;
}): ReactElement => {
  return (
    <svg
      className={className || ''}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <circle cx="15" cy="15" r="15" fill="#082E33" />
      <g clipPath="url(#clip0_28_7315)">
        <path
          d="M15.0138 21.6601C14.7617 21.9228 14.3583 21.9228 14.1062 21.6601L8.11842 15.455C6.95868 14.3041 6.66874 12.5526 7.39988 11.0889C8.55963 8.77451 11.6733 8.28661 13.5137 10.1131L14.56 11.164L15.6189 10.1131C17.4594 8.28661 20.5604 8.78702 21.7202 11.1014C22.4513 12.5526 22.1614 14.3166 21.0016 15.4675L15.0138 21.6726V21.6601Z"
          stroke="#7FF1C5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_28_7315">
          <rect
            width="17.1429"
            height="17.1429"
            fill="white"
            transform="translate(6.42773 6.42871)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Information = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="none"
        d="M15.2729 0.163574C7.2617 0.163574 0.772949 6.65232 0.772949 14.6636C0.772949 22.6748 7.2617 29.1636 15.2729 29.1636C23.2842 29.1636 29.7729 22.6748 29.7729 14.6636C29.7729 6.65232 23.2842 0.163574 15.2729 0.163574ZM17.0854 5.60107V9.22607H13.4604V5.60107H17.0854ZM11.6479 21.9136V23.7261H18.8979V21.9136H17.0854V11.0386H11.6479V12.8511H13.4604V21.9136H11.6479Z"
      />
    </svg>
  );
};

export const CircleCheckMark = (): ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Check icon">
        <rect x="0.00195312" y="0.0161133" width="24" height="24" rx="12" fill="#BDE0F4" />
        <path
          id="Vector"
          d="M9.24735 17.0161L4.00195 12.276L5.3133 11.091L9.24735 14.6461L17.6906 7.01611L19.002 8.20114L9.24735 17.0161Z"
          fill="#082E33"
        />
      </g>
    </svg>
  );
};
