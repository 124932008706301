import { EditableAreaOrPageChild, MagnoliaAreaNode, MagnoliaNode } from '@/types/cms/magnolia';
import { InputType } from 'reactstrap/types/lib/Input';

type InputData = {};

export type Field = {
  title: string;
  validation: string[];
  readonly: boolean;
  mandatory: boolean;
  disabled: boolean;
  data: InputData;
  controlName: string;
  edits?: MagnoliaAreaNode & { [key: number]: FormField };
  autofocus: boolean;
  autocomplete: boolean;
  type?: 'checkbox' | 'radio' | 'select';
  metadata: MagnoliaNode;
  fieldWidth?: string;
} & MagnoliaNode &
  EditableAreaOrPageChild;

export type InputField = {
  inputType?: InputType;
  rows: number;
  minimum?: number;
  maximum?: number;
  step?: number;
  editLength?: 'form-item-s' | 'form-item-m' | 'form-item-l';
  maxLength?: string;
  placeholder?: string;
  pattern?: string;
  patternDescription?: string;
  subtitle?: string;
} & Field;

export type SelectField = {
  type?: 'checkbox' | 'radio' | 'select';
  labels?: string;
  horizontal?: boolean;
  default?: string;
} & Field;

export type HiddenField = {
  value?: string;
} & Field;

export type SubmitButton = {
  buttonText?: string;
  conditionList: unknown[];
} & Field;

export type FormField = InputField | SelectField | HiddenField;

export type FieldSet = MagnoliaAreaNode & {
  fields: MagnoliaAreaNode & { [key: number]: FormField };
};

export type FieldSets = MagnoliaAreaNode & {
  [key: number]: FieldSet;
};

export const isInputField = (item: FormField): item is InputField => {
  return (
    item.hasOwnProperty('inputType') ||
    item?.metadata?.['mgnl:template'] === 'form:components/formGroupEditItem' ||
    item?.['mgnl:template'] === 'form:components/formGroupEditItem' ||
    item?.metadata?.['mgnl:template'] === 'form:components/form:components/formHidden' ||
    item?.['mgnl:template'] === 'form:components/form:components/formHidden'
  );
};

export const isSelectField = (item: FormField): item is SelectField => {
  return item.hasOwnProperty('type');
};

export const HONEYPOT_FIELD_NAME = 'passwordField';
