import {
  meetsAirportConditions,
  meetsCountryConditions,
  meetsDateConditions
} from '@/components/Search/searchReducer/helpers';
import {
  ApiTravelTypeComponent,
  Payload,
  PayloadType,
  ReducerStateItem
} from '@/components/Search/searchReducer/types';
import {
  ApiProductsCacheData,
  ApiProductsCacheResponseCountry,
  ApiProductsCacheResponseTravelTypes
} from '@ibe/api';

export const getTravelTypesState = (
  continentItems: [string, ReducerStateItem][],
  dateItems: [string, ReducerStateItem][],
  travelTypeItems: [string, ReducerStateItem][],
  airportItems: [string, ReducerStateItem][],
  apiTravelTypes: ApiTravelTypeComponent[],
  filtersAreEmpty: boolean,
  payloadType: PayloadType,
  payload: Payload,
  countries?: ApiProductsCacheResponseCountry[],
  travelTypes?: ApiProductsCacheResponseTravelTypes[],
  products?: ApiProductsCacheData[]
): ApiTravelTypeComponent[] => {
  if (
    (payloadType !== 'travelType' &&
      meetsCountryConditions(continentItems, countries) &&
      meetsAirportConditions(airportItems, products) &&
      meetsDateConditions(dateItems, products)) ||
    filtersAreEmpty
  ) {
    for (let travelType of travelTypes || []) {
      const travelTypeIndex = apiTravelTypes.findIndex(
        apiTravelType => apiTravelType.id === travelType.id
      );
      if (travelTypeIndex < 0) {
        apiTravelTypes = [
          ...apiTravelTypes,
          { id: travelType.id || '', name: travelType.name || '', products: 1 }
        ];
      } else {
        const foundTravelType = {
          ...apiTravelTypes[travelTypeIndex],
          products: apiTravelTypes[travelTypeIndex].products + 1
        };
        apiTravelTypes.splice(travelTypeIndex, 1, foundTravelType);
      }
    }
  }
  if (
    payloadType === 'travelType' &&
    !travelTypeItems.find(([travelTypeItemId]) => travelTypeItemId === payload.id)
  ) {
    const apiTravelTypesIndex = apiTravelTypes.findIndex(
      apiTravelType => apiTravelType.id === payload.id && apiTravelType.products === 0
    );
    if (apiTravelTypesIndex >= 0) {
      apiTravelTypes.splice(apiTravelTypesIndex, 1);
    }
  }

  return apiTravelTypes;
};
