'use client';

import React, { FC, useMemo } from 'react';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import { EditableAreaOrPageChild, MagnoliaNode } from '@/types/cms/magnolia';
import GridContainer from '@/Layouts/GridContainer';

interface Props extends EditableAreaOrPageChild {
  headerText?: string;
  accordionArea: MagnoliaNode & {
    [key: number]: MagnoliaNode & { accordionBody: MagnoliaNode };
  };
}

const Accordion: FC<Props> = ({ headerText, accordionArea }): JSX.Element => {
  return (
    <GridContainer className="accordion">
      {!!headerText ? <div className="accordion__title">{headerText}</div> : <></>}
      {!!accordionArea ? <EditableArea content={accordionArea} /> : <></>}
    </GridContainer>
  );
};

export default Accordion;
