import React, { JSX } from 'react';
import Keys from '@/Translations/generated/da/country.json.keys';
import Intro from '@/components/intro/Intro';
import { useTranslation } from '@/app/i18n';
import { THEMES } from '@/Util/globals';
import ImageGallery from '@/components/imageGallery/ImageGallery';
import { ApiCountry } from '../../../api/model';
import sanitize from '@/Util/sanitize';
import { Props } from '@/types/cms/magnolia';

const CountryImages = async ({
  country,
  language,
  pathname,
  pageProps
}: {
  country?: ApiCountry;
  language: string;
  pathname: string;
  pageProps?: Props;
}): Promise<JSX.Element> => {
  const {
    name,
    introTitleImages,
    introTextImages,
    themeIntroMedia,
    themeImageGallery,
    imageGallery
  } = country || {};
  const { t } = await useTranslation(language, 'country', {
    translationsVersion: pageProps?.translationsVersion
  });

  return (
    <>
      <Intro
        headline={introTitleImages ? introTitleImages : `${t(Keys.headlineImages)} ${name}`}
        pageProps={pageProps}
        pathname={pathname}
        singleColumn={
          <div>
            {!!introTextImages && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(introTextImages, true)
                }}
              />
            )}
          </div>
        }
        theme={themeIntroMedia || THEMES.blue}
      />
      {!!imageGallery && (
        <ImageGallery
          theme={themeImageGallery || THEMES.darkGreen}
          images={imageGallery}
          pageProps={pageProps}
        />
      )}
    </>
  );
};

export default CountryImages;
