import React, { PropsWithChildren, ReactElement } from 'react';
import { THEMES } from '@/Util/globals';
import { Close } from '@/Theme/SVG/Svgs';
import { Modal, ModalBody } from 'reactstrap';

const IsoModal = ({
  toggle,
  isOpen,
  onClose,
  theme,
  children,
  className
}: PropsWithChildren<{
  toggle: () => void;
  isOpen: boolean;
  onClose: () => void;
  theme?: string;
  className?: string;
}>): ReactElement => {
  return (
    <Modal
      className={`iso-modal ${className || ''} ${theme || THEMES.darkGreen}`}
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalBody className={`iso-modal__body ${theme || THEMES.darkGreen}`}>
        <div className="iso-modal__close__container">
          <div onClick={onClose} className="iso-modal__close__button">
            <Close />
          </div>
        </div>
        <div className="iso-modal__content">{children}</div>
      </ModalBody>
    </Modal>
  );
};

export default IsoModal;
