import AccommodationContent from '@/components/accommodations/AccommodationContent';
import React, { ReactElement } from 'react';
import { ApiAccommodation } from '../../../api/model';
import GridContainer from '@/Layouts/GridContainer';
import { Props as PageProps } from '@/types/cms/magnolia';

type Props = {
  accommodations: ApiAccommodation[];
  theme: string;
  pageProps?: PageProps;
};
const SingleHotel = (props: Props): ReactElement => {
  const { accommodations, theme, pageProps } = props;

  return (
    <>
      {accommodations.map((accommodation: ApiAccommodation, idx: number) => (
        <div key={accommodation.id || accommodation.name || idx} className="accommodation">
          <GridContainer>
            <div className="accommodation__box">
              <h2 className="accommodation__title">{accommodation.name}</h2>
              <AccommodationContent
                accommodation={accommodation}
                className={theme}
                pageProps={pageProps}
              />
            </div>
          </GridContainer>
        </div>
      ))}
    </>
  );
};

export default SingleHotel;
