import { EventType } from '@/Tracking/types';

export default class PageView {
  event: EventType;
  pageType: string;
  worldPart?: string;
  country?: string;
  city?: string;
  theme?: string;
  airport?: string;
  price?: number;
  currency?: string;
  roundTripCode?: string;
  roundTripName?: string;
  duration?: number;
}
