import React, { FC, JSX, useMemo, useState } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import Excursion from '@/components/checkoutExcursions/Excursion';
import { ApiAccommodation, ApiAccommodationFromJSON, ApiExcursion } from '../../../api/model';
import AccommodationContent from '@/components/accommodations/AccommodationContent';
import IsoModal from '@/components/IsoModal';
import { THEMES } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

const ExcursionPackages: FC<{ checkoutStore: CheckoutStore; pageProps?: Props }> = observer(
  function ExcursionPackages({ checkoutStore, pageProps }): JSX.Element {
    const [selectedExcursionPackage, setSelectedExcursionPackage] = useState<
      ApiExcursion | undefined
    >(undefined);

    const transformedSelectedExcursionPackage = useMemo((): ApiAccommodation | undefined => {
      return !!selectedExcursionPackage
        ? ApiAccommodationFromJSON({
            id: selectedExcursionPackage.id,
            name: selectedExcursionPackage.name,
            images: selectedExcursionPackage.images,
            shortDescription: selectedExcursionPackage.description,
            rooms: []
          })
        : undefined;
    }, [selectedExcursionPackage]);

    return (
      <>
        <div className="excursion-packages">
          {checkoutStore.excursionPackages.map(excursionPackage =>
            !!excursionPackage.selectableItems[0] ? (
              <Excursion
                key={excursionPackage.id}
                excursion={excursionPackage.selectableItems[0]}
                isSelected={excursionPackage.selectedItems?.length > 0}
                checkoutStore={checkoutStore}
                setSelectedExcursion={setSelectedExcursionPackage}
                componentId={excursionPackage.id}
                pageProps={pageProps}
              />
            ) : (
              <></>
            )
          )}
        </div>
        <IsoModal
          className="accommodation__modal"
          toggle={(): void => setSelectedExcursionPackage(undefined)}
          isOpen={!!selectedExcursionPackage}
          onClose={(): void => setSelectedExcursionPackage(undefined)}
          theme={THEMES.blue}
        >
          <div className="accommodation__modal__container">
            <AccommodationContent
              accommodation={transformedSelectedExcursionPackage}
              simpleMode
              pageProps={pageProps}
            />
          </div>
        </IsoModal>
      </>
    );
  }
);

export default ExcursionPackages;
