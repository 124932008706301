import { Dispatch, SetStateAction } from 'react';
import { MagnoliaSite, Props } from '@/types/cms/magnolia';
import { ApiTextSearchRequestFromJSON, ApiTextSearchResponse } from '@ibe/api';
import { useApi } from '@/Hooks/useApi';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { EventType, SearchCategory } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { logger } from '@/Util/globals';

dayjs.extend(utc);

export const useRequests = (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setTextSearch: Dispatch<SetStateAction<Required<ApiTextSearchResponse>>>,
  pageProps?: Props,
  siteConfig?: MagnoliaSite
): {
  getTextSearchResults: (value: string) => Promise<void>;
} => {
  const api = useApi(pageProps?.isAuthor);
  const config = useConfig();
  const locale = useCurrentLanguage();

  const getTextSearchResults = async (value: string): Promise<void> => {
    if (value.length < 1 || !siteConfig) return Promise.resolve(undefined);
    setIsLoading(true);
    broadcastEvent(EventType.SEARCH, config, locale, {
      search: { searchCategory: SearchCategory.TOUR },
      data: { pageProps }
    });
    try {
      const res: ApiTextSearchResponse = await api.searchText(
        ApiTextSearchRequestFromJSON({ input: value })
      );
      if (!!res) {
        setTextSearch({ products: res.products || [] });
      }
    } catch (err) {
      logger('error')('Unable to fetch text search data: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getTextSearchResults
  };
};
