/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiAccommodation,
        ApiAccommodationFromJSON,
        ApiAccommodationFromJSONTyped,
        ApiAccommodationToJSON,
        ApiCity,
        ApiCityFromJSON,
        ApiCityFromJSONTyped,
        ApiCityToJSON,
        ApiContinent,
        ApiContinentFromJSON,
        ApiContinentFromJSONTyped,
        ApiContinentToJSON,
        ApiCountry,
        ApiCountryFromJSON,
        ApiCountryFromJSONTyped,
        ApiCountryToJSON,
        ApiDepartureDateItem,
        ApiDepartureDateItemFromJSON,
        ApiDepartureDateItemFromJSONTyped,
        ApiDepartureDateItemToJSON,
        ApiExcursion,
        ApiExcursionFromJSON,
        ApiExcursionFromJSONTyped,
        ApiExcursionToJSON,
        ApiExtension,
        ApiExtensionFromJSON,
        ApiExtensionFromJSONTyped,
        ApiExtensionToJSON,
        ApiFactSheet,
        ApiFactSheetFromJSON,
        ApiFactSheetFromJSONTyped,
        ApiFactSheetToJSON,
        ApiFooterContent,
        ApiFooterContentFromJSON,
        ApiFooterContentFromJSONTyped,
        ApiFooterContentToJSON,
        ApiHighlight,
        ApiHighlightFromJSON,
        ApiHighlightFromJSONTyped,
        ApiHighlightToJSON,
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
        ApiInformationGroup,
        ApiInformationGroupFromJSON,
        ApiInformationGroupFromJSONTyped,
        ApiInformationGroupToJSON,
        ApiItinerary,
        ApiItineraryFromJSON,
        ApiItineraryFromJSONTyped,
        ApiItineraryToJSON,
        ApiMetaInformation,
        ApiMetaInformationFromJSON,
        ApiMetaInformationFromJSONTyped,
        ApiMetaInformationToJSON,
        ApiPriceInformation,
        ApiPriceInformationFromJSON,
        ApiPriceInformationFromJSONTyped,
        ApiPriceInformationToJSON,
        ApiProductInformation,
        ApiProductInformationFromJSON,
        ApiProductInformationFromJSONTyped,
        ApiProductInformationToJSON,
        ApiProductStateType,
        ApiProductStateTypeFromJSON,
        ApiProductStateTypeFromJSONTyped,
        ApiProductStateTypeToJSON,
        ApiTravelType,
        ApiTravelTypeFromJSON,
        ApiTravelTypeFromJSONTyped,
        ApiTravelTypeToJSON,
        ApiVideo,
        ApiVideoFromJSON,
        ApiVideoFromJSONTyped,
        ApiVideoToJSON,
        ApiVideoContent,
        ApiVideoContentFromJSON,
        ApiVideoContentFromJSONTyped,
        ApiVideoContentToJSON,
    } from './';

/**
* 
* @export
* @interface ApiProduct
*/
export interface ApiProduct {
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    name: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    reviewsCode?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    market: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    sortOrder: string;
    /**
    * 
    * @type {ApiProductStateType}
    * @memberof ApiProduct
    */
    productState?: ApiProductStateType;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    displayUrl: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    productTitle: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    tagline?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    salesPriority: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    productPageBasePath: string;
    /**
    * 
    * @type {boolean}
    * @memberof ApiProduct
    */
    newProduct?: boolean;
    /**
    * 
    * @type {Array<ApiTravelType>}
    * @memberof ApiProduct
    */
    travelTypes?: Array<ApiTravelType>;
    /**
    * 
    * @type {Array<ApiContinent>}
    * @memberof ApiProduct
    */
    continents?: Array<ApiContinent>;
    /**
    * 
    * @type {Array<ApiCountry>}
    * @memberof ApiProduct
    */
    countries?: Array<ApiCountry>;
    /**
    * 
    * @type {Array<ApiCity>}
    * @memberof ApiProduct
    */
    cities?: Array<ApiCity>;
    /**
    * 
    * @type {Array<ApiProduct>}
    * @memberof ApiProduct
    */
    variants?: Array<ApiProduct>;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    content?: string;
    /**
    * 
    * @type {ApiVideo}
    * @memberof ApiProduct
    */
    heroBackgroundVideo?: ApiVideo;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiProduct
    */
    heroBackgroundImage?: ApiImage;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiProduct
    */
    teaserImageLarge: ApiImage;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiProduct
    */
    teaserImageMedium: ApiImage;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiProduct
    */
    pdfCoverImage?: ApiImage;
    /**
    * 
    * @type {Array<ApiImage>}
    * @memberof ApiProduct
    */
    imageGallery?: Array<ApiImage>;
    /**
    * 
    * @type {Array<ApiVideoContent>}
    * @memberof ApiProduct
    */
    productVideos?: Array<ApiVideoContent>;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    introductionTitle: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    tripDescription: string;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiProduct
    */
    map: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    aspectRatio?: string;
    /**
    * 
    * @type {Array<ApiHighlight>}
    * @memberof ApiProduct
    */
    highlights?: Array<ApiHighlight>;
    /**
    * 
    * @type {ApiFactSheet}
    * @memberof ApiProduct
    */
    factSheet?: ApiFactSheet;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    hotelIntroduction?: string;
    /**
    * 
    * @type {Array<ApiAccommodation>}
    * @memberof ApiProduct
    */
    singleAccommodations?: Array<ApiAccommodation>;
    /**
    * 
    * @type {Array<ApiAccommodation>}
    * @memberof ApiProduct
    */
    multipleAccommodations?: Array<ApiAccommodation>;
    /**
    * 
    * @type {Array<ApiItinerary>}
    * @memberof ApiProduct
    */
    itinerary?: Array<ApiItinerary>;
    /**
    * 
    * @type {ApiProductInformation}
    * @memberof ApiProduct
    */
    productInformation?: ApiProductInformation;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    childPrice?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    roomUpgrade?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    priceInfoExcursions?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    priceIncludes?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    priceDoesNotInclude?: string;
    /**
    * 
    * @type {Array<ApiPriceInformation>}
    * @memberof ApiProduct
    */
    priceInformation?: Array<ApiPriceInformation>;
    /**
    * 
    * @type {boolean}
    * @memberof ApiProduct
    */
    visum?: boolean;
    /**
    * 
    * @type {boolean}
    * @memberof ApiProduct
    */
    vaccination?: boolean;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    currency?: string;
    /**
    * 
    * @type {Array<ApiInformationGroup>}
    * @memberof ApiProduct
    */
    informationGroup?: Array<ApiInformationGroup>;
    /**
    * 
    * @type {Array<ApiExcursion>}
    * @memberof ApiProduct
    */
    excursions?: Array<ApiExcursion>;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    overviewHeadline?: string;
    /**
    * 
    * @type {Array<ApiExtension>}
    * @memberof ApiProduct
    */
    extensions?: Array<ApiExtension>;
    /**
    * 
    * @type {ApiTravelType}
    * @memberof ApiProduct
    */
    relatedTravelType?: ApiTravelType;
    /**
    * 
    * @type {ApiCountry}
    * @memberof ApiProduct
    */
    relatedCountry?: ApiCountry;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeIntroduction?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeHighlights?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themePriceInformation?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeImageGallery?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeTravelInformation?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeExtensionTeaser?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeItinerary?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeMultipleHotels?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeSingleHotel?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeExcursions?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeProductTeasers?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeAccommodation?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeContactInformation?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeContentArea?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeHotel?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeReviews?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiProduct
    */
    themeDatesAndPrices?: string;
    /**
    * 
    * @type {ApiFooterContent}
    * @memberof ApiProduct
    */
    footerTopLeft?: ApiFooterContent;
    /**
    * 
    * @type {ApiFooterContent}
    * @memberof ApiProduct
    */
    footerTopRight?: ApiFooterContent;
    /**
    * 
    * @type {boolean}
    * @memberof ApiProduct
    */
    isManualTour?: boolean;
    /**
    * 
    * @type {Array<ApiDepartureDateItem>}
    * @memberof ApiProduct
    */
    departureDateItems?: Array<ApiDepartureDateItem>;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiProduct
    */
    metaInformation?: ApiMetaInformation;
}

export function ApiProductFromJSON(json: any): ApiProduct {
return ApiProductFromJSONTyped(json, false);
}

export function ApiProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProduct {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'name': json['name'],
                    'reviewsCode': json['reviewsCode'],
                    'market': json['market'],
                    'sortOrder': json['sortOrder'],
                            'productState': ApiProductStateTypeFromJSON(json['productState']),
                    'displayUrl': json['displayUrl'],
                    'productTitle': json['productTitle'],
                    'tagline': json['tagline'],
                    'salesPriority': json['salesPriority'],
                    'productPageBasePath': json['productPageBasePath'],
                    'newProduct': json['newProduct'],
                'travelTypes': ((json['travelTypes'] as Array<any>).map(ApiTravelTypeFromJSON)),
                'continents': ((json['continents'] as Array<any>).map(ApiContinentFromJSON)),
                'countries': ((json['countries'] as Array<any>).map(ApiCountryFromJSON)),
                'cities': ((json['cities'] as Array<any>).map(ApiCityFromJSON)),
                'variants': ((json['variants'] as Array<any>).map(ApiProductFromJSON)),
                    'content': json['content'],
                            'heroBackgroundVideo': ApiVideoFromJSON(json['heroBackgroundVideo']),
                            'heroBackgroundImage': ApiImageFromJSON(json['heroBackgroundImage']),
                            'teaserImageLarge': ApiImageFromJSON(json['teaserImageLarge']),
                            'teaserImageMedium': ApiImageFromJSON(json['teaserImageMedium']),
                            'pdfCoverImage': ApiImageFromJSON(json['pdfCoverImage']),
                'imageGallery': ((json['imageGallery'] as Array<any>).map(ApiImageFromJSON)),
                'productVideos': ((json['productVideos'] as Array<any>).map(ApiVideoContentFromJSON)),
                    'introductionTitle': json['introductionTitle'],
                    'tripDescription': json['tripDescription'],
                            'map': ApiImageFromJSON(json['map']),
                    'aspectRatio': json['aspectRatio'],
                'highlights': ((json['highlights'] as Array<any>).map(ApiHighlightFromJSON)),
                            'factSheet': ApiFactSheetFromJSON(json['factSheet']),
                    'hotelIntroduction': json['hotelIntroduction'],
                'singleAccommodations': ((json['singleAccommodations'] as Array<any>).map(ApiAccommodationFromJSON)),
                'multipleAccommodations': ((json['multipleAccommodations'] as Array<any>).map(ApiAccommodationFromJSON)),
                'itinerary': ((json['itinerary'] as Array<any>).map(ApiItineraryFromJSON)),
                            'productInformation': ApiProductInformationFromJSON(json['productInformation']),
                    'childPrice': json['childPrice'],
                    'roomUpgrade': json['roomUpgrade'],
                    'priceInfoExcursions': json['priceInfoExcursions'],
                    'priceIncludes': json['priceIncludes'],
                    'priceDoesNotInclude': json['priceDoesNotInclude'],
                'priceInformation': ((json['priceInformation'] as Array<any>).map(ApiPriceInformationFromJSON)),
                    'visum': json['visum'],
                    'vaccination': json['vaccination'],
                    'currency': json['currency'],
                'informationGroup': ((json['informationGroup'] as Array<any>).map(ApiInformationGroupFromJSON)),
                'excursions': ((json['excursions'] as Array<any>).map(ApiExcursionFromJSON)),
                    'overviewHeadline': json['overviewHeadline'],
                'extensions': ((json['extensions'] as Array<any>).map(ApiExtensionFromJSON)),
                            'relatedTravelType': ApiTravelTypeFromJSON(json['relatedTravelType']),
                            'relatedCountry': ApiCountryFromJSON(json['relatedCountry']),
                    'themeIntroduction': json['themeIntroduction'],
                    'themeHighlights': json['themeHighlights'],
                    'themePriceInformation': json['themePriceInformation'],
                    'themeImageGallery': json['themeImageGallery'],
                    'themeTravelInformation': json['themeTravelInformation'],
                    'themeExtensionTeaser': json['themeExtensionTeaser'],
                    'themeItinerary': json['themeItinerary'],
                    'themeMultipleHotels': json['themeMultipleHotels'],
                    'themeSingleHotel': json['themeSingleHotel'],
                    'themeExcursions': json['themeExcursions'],
                    'themeProductTeasers': json['themeProductTeasers'],
                    'themeAccommodation': json['themeAccommodation'],
                    'themeContactInformation': json['themeContactInformation'],
                    'themeContentArea': json['themeContentArea'],
                    'themeHotel': json['themeHotel'],
                    'themeReviews': json['themeReviews'],
                    'themeDatesAndPrices': json['themeDatesAndPrices'],
                            'footerTopLeft': ApiFooterContentFromJSON(json['footerTopLeft']),
                            'footerTopRight': ApiFooterContentFromJSON(json['footerTopRight']),
                    'isManualTour': json['isManualTour'],
                'departureDateItems': ((json['departureDateItems'] as Array<any>).map(ApiDepartureDateItemFromJSON)),
                            'metaInformation': ApiMetaInformationFromJSON(json['metaInformation']),
        };
    }

    export function ApiProductToJSON(value?: ApiProduct | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'name': value.name,
                'reviewsCode': value.reviewsCode,
                'market': value.market,
                'sortOrder': value.sortOrder,
                            'productState': ApiProductStateTypeToJSON(value.productState),
                'displayUrl': value.displayUrl,
                'productTitle': value.productTitle,
                'tagline': value.tagline,
                'salesPriority': value.salesPriority,
                'productPageBasePath': value.productPageBasePath,
                'newProduct': value.newProduct,
                    'travelTypes': ((value.travelTypes as Array<any>).map(ApiTravelTypeToJSON)),
                    'continents': ((value.continents as Array<any>).map(ApiContinentToJSON)),
                    'countries': ((value.countries as Array<any>).map(ApiCountryToJSON)),
                    'cities': ((value.cities as Array<any>).map(ApiCityToJSON)),
                    'variants': ((value.variants as Array<any>).map(ApiProductToJSON)),
                'content': value.content,
                            'heroBackgroundVideo': ApiVideoToJSON(value.heroBackgroundVideo),
                            'heroBackgroundImage': ApiImageToJSON(value.heroBackgroundImage),
                            'teaserImageLarge': ApiImageToJSON(value.teaserImageLarge),
                            'teaserImageMedium': ApiImageToJSON(value.teaserImageMedium),
                            'pdfCoverImage': ApiImageToJSON(value.pdfCoverImage),
                    'imageGallery': ((value.imageGallery as Array<any>).map(ApiImageToJSON)),
                    'productVideos': ((value.productVideos as Array<any>).map(ApiVideoContentToJSON)),
                'introductionTitle': value.introductionTitle,
                'tripDescription': value.tripDescription,
                            'map': ApiImageToJSON(value.map),
                'aspectRatio': value.aspectRatio,
                    'highlights': ((value.highlights as Array<any>).map(ApiHighlightToJSON)),
                            'factSheet': ApiFactSheetToJSON(value.factSheet),
                'hotelIntroduction': value.hotelIntroduction,
                    'singleAccommodations': ((value.singleAccommodations as Array<any>).map(ApiAccommodationToJSON)),
                    'multipleAccommodations': ((value.multipleAccommodations as Array<any>).map(ApiAccommodationToJSON)),
                    'itinerary': ((value.itinerary as Array<any>).map(ApiItineraryToJSON)),
                            'productInformation': ApiProductInformationToJSON(value.productInformation),
                'childPrice': value.childPrice,
                'roomUpgrade': value.roomUpgrade,
                'priceInfoExcursions': value.priceInfoExcursions,
                'priceIncludes': value.priceIncludes,
                'priceDoesNotInclude': value.priceDoesNotInclude,
                    'priceInformation': ((value.priceInformation as Array<any>).map(ApiPriceInformationToJSON)),
                'visum': value.visum,
                'vaccination': value.vaccination,
                'currency': value.currency,
                    'informationGroup': ((value.informationGroup as Array<any>).map(ApiInformationGroupToJSON)),
                    'excursions': ((value.excursions as Array<any>).map(ApiExcursionToJSON)),
                'overviewHeadline': value.overviewHeadline,
                    'extensions': ((value.extensions as Array<any>).map(ApiExtensionToJSON)),
                            'relatedTravelType': ApiTravelTypeToJSON(value.relatedTravelType),
                            'relatedCountry': ApiCountryToJSON(value.relatedCountry),
                'themeIntroduction': value.themeIntroduction,
                'themeHighlights': value.themeHighlights,
                'themePriceInformation': value.themePriceInformation,
                'themeImageGallery': value.themeImageGallery,
                'themeTravelInformation': value.themeTravelInformation,
                'themeExtensionTeaser': value.themeExtensionTeaser,
                'themeItinerary': value.themeItinerary,
                'themeMultipleHotels': value.themeMultipleHotels,
                'themeSingleHotel': value.themeSingleHotel,
                'themeExcursions': value.themeExcursions,
                'themeProductTeasers': value.themeProductTeasers,
                'themeAccommodation': value.themeAccommodation,
                'themeContactInformation': value.themeContactInformation,
                'themeContentArea': value.themeContentArea,
                'themeHotel': value.themeHotel,
                'themeReviews': value.themeReviews,
                'themeDatesAndPrices': value.themeDatesAndPrices,
                            'footerTopLeft': ApiFooterContentToJSON(value.footerTopLeft),
                            'footerTopRight': ApiFooterContentToJSON(value.footerTopRight),
                'isManualTour': value.isManualTour,
                    'departureDateItems': ((value.departureDateItems as Array<any>).map(ApiDepartureDateItemToJSON)),
                            'metaInformation': ApiMetaInformationToJSON(value.metaInformation),
    };
    }



    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

