/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
    } from './';

/**
* 
* @export
* @interface ApiExcursion
*/
export interface ApiExcursion {
    /**
    * 
    * @type {string}
    * @memberof ApiExcursion
    */
    id?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiExcursion
    */
    name?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiExcursion
    */
    cmsCode?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiExcursion
    */
    description?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiExcursion
    */
    price?: string;
    /**
    * 
    * @type {Array<ApiImage>}
    * @memberof ApiExcursion
    */
    images?: Array<ApiImage>;
}

export function ApiExcursionFromJSON(json: any): ApiExcursion {
return ApiExcursionFromJSONTyped(json, false);
}

export function ApiExcursionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiExcursion {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'id': json['id'],
                    'name': json['name'],
                    'cmsCode': json['cmsCode'],
                    'description': json['description'],
                    'price': json['price'],
                'images': ((json['images'] as Array<any>).map(ApiImageFromJSON)),
        };
    }

    export function ApiExcursionToJSON(value?: ApiExcursion | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'id': value.id,
                'name': value.name,
                'cmsCode': value.cmsCode,
                'description': value.description,
                'price': value.price,
                    'images': ((value.images as Array<any>).map(ApiImageToJSON)),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

