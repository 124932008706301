'use client';

import React, { ReactElement } from 'react';
import Image from '@/components/image/Image';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AspectRatio } from '@/Util/imageLoader';
import { ApiImage } from '../../../api/model';
import { Props as PageProps } from '@/types/cms/magnolia';

type Props = {
  map: ApiImage | undefined;
  theme: string;
  aspectRatio?: AspectRatio;
  pageProps?: PageProps;
};

const Map = (props: Props): ReactElement => {
  const { map, aspectRatio, pageProps } = props;

  return (
    <div className="map">
      {!!map && (
        <>
          <div className="map__wrapper">
            <div className="map__image__wrapper">
              <>
                <Image
                  className="map__image"
                  src={map.imageLink || ''}
                  alt={map.title || ''}
                  width={1280}
                  height={1280}
                  aspectRatio={aspectRatio}
                  serverSideContext={pageProps?.albConfig.context}
                />
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Map;
