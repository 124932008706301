import React, { ReactElement } from 'react';
import { RatingStar, StarMode } from '@/Theme/SVG/Svgs';

const getStarMode = (index: number, rating: number): StarMode => {
  if (index <= rating) {
    return 'full';
  } else if (index > rating && index - rating < 0.5 && index - rating > 0) {
    return 'half';
  } else {
    return 'empty';
  }
};

const StarRating = ({
  rating,
  maxRating,
  className
}: {
  rating: number;
  maxRating: number;
  className?: string;
}): ReactElement => {
  return (
    <div className={`d-xs-flex ${className || ''}`}>
      {maxRating > 0 &&
        [...Array(maxRating).keys()].map(key => (
          <RatingStar mode={getStarMode(key + 1, rating)} key={key} />
        ))}
    </div>
  );
};

export default StarRating;
