'use client';

import React, { JSX, PropsWithChildren, useContext, useEffect } from 'react';
import { TemplateAnnotations } from '@magnolia/template-annotations';
import { EditorContext, EditorContextHelper } from '@magnolia/react-editor';
import EditableComment from '../EditableComponent/EditableComment';
import { constants, EditorContextType } from '../componentHelper';

interface Props {
  content: Record<string, any>;
  parentTemplateId?: string;
}

const EditableAreaClient = ({
  content,
  parentTemplateId,
  children
}: PropsWithChildren<Props>): JSX.Element => {
  const context = useContext<EditorContextType>(EditorContext);

  useEffect(() => {
    if (!context) {
      throw new Error('EditableArea component must be wrapped inside EditablePage component.');
    }
  }, []);

  const getParentTemplateId = (): string => {
    const { content: contextContent } = context;

    return parentTemplateId || contextContent[constants.TEMPLATE_ID_PROP];
  };

  const getAnnotation = (): string => {
    if (!content) {
      return '';
    }
    const pageTemplateId = getParentTemplateId();
    const { templateDefinitions, templateAnnotations } = context;
    if (templateDefinitions && templateDefinitions[pageTemplateId]) {
      return TemplateAnnotations.getAreaCommentString(content, templateDefinitions[pageTemplateId]);
    }
    if (templateAnnotations && templateAnnotations[content?.['@path']]) {
      return templateAnnotations[content?.['@path']];
    }
    return '';
  };

  const onReady = (): void => {
    EditorContextHelper.refresh();
  };

  if (!content) {
    return <></>;
  }

  return (
    <>
      <EditableComment annotation={getAnnotation()} callback={onReady} />
      {children}
      <EditableComment annotation={constants.CLOSED_AREA_COMMENT} />
    </>
  );
};

export default EditableAreaClient;
