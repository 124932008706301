'use client';

import React, { ReactElement, RefObject, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import useScrollSlide from '@/Hooks/useScrollSlide';
import { Next, Previous } from '@/Theme/SVG/Svgs';

const ITEM_WIDTH_PERCENTAGE = 0.78;
const ITEM_PADDING = 20; // 1.25rem -> productTeasers.scss: .product-teasers__inner > div:not(:first-child) margin-left
const ITEM_WIDTH_PERCENTAGE_MD = 0.4;
const ITEM_PADDING_MD = 28; // 1.75rem
const ITEM_INDEX_TOLERANCE = 0.02;

const getItemWidth = (
  isDesktop: boolean,
  containerRect: DOMRect | null,
  reverted?: boolean,
  lastElement?: boolean,
  withPadding?: boolean
): number => {
  return (
    (containerRect?.width || 0) *
      (reverted
        ? 1 - (isDesktop ? ITEM_WIDTH_PERCENTAGE_MD : ITEM_WIDTH_PERCENTAGE)
        : isDesktop
        ? ITEM_WIDTH_PERCENTAGE_MD
        : ITEM_WIDTH_PERCENTAGE) -
    (!lastElement && !withPadding ? (isDesktop ? ITEM_PADDING_MD : ITEM_PADDING) : 0)
  );
};

let timer: ReturnType<typeof setTimeout> | null = null;

const TeaserCarousel = <T extends { [key: string]: any; code?: string; id?: string }>({
  items,
  indexerProp,
  renderItem,
  innerItemClass
}: {
  items: T[];
  renderItem: (item: T) => ReactElement;
  indexerProp?: string;
  innerItemClass?: string;
}): JSX.Element => {
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const [outerContainerMeasures, setOuterContainerMeasures] = useState<
    [DOMRect, DOMRect | undefined] | null
  >(null);

  useEffect(() => {
    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const scroll = useCallback(
    (toLeft: boolean, outerContainer: RefObject<HTMLDivElement>): void => {
      if (!outerContainerMeasures?.[0] || !outerContainer?.current) return;
      const currentItemWidth = getItemWidth(
        isDesktop,
        outerContainerMeasures[0],
        false,
        false,
        true
      );
      const itemIndex = outerContainer.current.scrollLeft / currentItemWidth;
      const isOnImageBorder = itemIndex % 1 === 0;
      const itemIndexRounded = Math.floor(itemIndex);
      const scrollToPosition = toLeft
        ? currentItemWidth * (isOnImageBorder ? itemIndex - 1 : itemIndexRounded)
        : currentItemWidth *
          (itemIndexRounded +
            (Math.ceil(itemIndex) - itemIndex > 0 &&
            Math.ceil(itemIndex) - itemIndex < ITEM_INDEX_TOLERANCE
              ? 2
              : 1));

      outerContainer.current.scrollTo({
        left: scrollToPosition,
        behavior: 'smooth'
      });
    },
    [outerContainerMeasures, isDesktop]
  );

  const { outerContainer, Buttons } = useScrollSlide(
    (outerContainer): void => {
      const firstImageContainer = outerContainer?.current?.querySelector(
        innerItemClass || '.product-teaser__img-container'
      );
      const setMeasures = (): void => {
        if (!!outerContainer?.current && !!firstImageContainer) {
          setOuterContainerMeasures([
            outerContainer.current.getBoundingClientRect(),
            firstImageContainer.getBoundingClientRect()
          ]);
        }
      };
      setMeasures();
      timer = setTimeout(setMeasures, 500);
    },
    scroll,
    [
      {
        class: 'navBtn--prev',
        style: { top: `${(outerContainerMeasures?.[1]?.height || 0) / 2}px` },
        icon: <Previous />
      },
      {
        class: 'navBtn--next',
        style: { top: `${(outerContainerMeasures?.[1]?.height || 0) / 2}px` },
        icon: <Next />
      }
    ]
  );

  return (
    <div className="position-relative">
      <div ref={outerContainer} className="product-teasers__outer">
        <div className="product-teasers__inner">
          {items.map((item, idx) => (
            <div
              key={item[indexerProp || ''] || item.code || item.id || idx}
              className={classNames('product-teaser__container', {
                'product-teaser__container--single': item.length === 1
              })}
              style={{
                width: !!outerContainerMeasures?.[0].width
                  ? `${getItemWidth(isDesktop, outerContainerMeasures[0])}px`
                  : '100%'
              }}
            >
              {renderItem(item)}
            </div>
          ))}
        </div>
      </div>
      {Buttons}
    </div>
  );
};

export default TeaserCarousel;
