'use client';

import React, { FC, JSX, useEffect, useMemo, useRef, useState } from 'react';
import ProgressBar, { ProgressBarItem } from '@/components/ProgressBar/ProgressBar';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facConfirm, facExtras, facPath, facPersons } from '@/Theme/SVG/Icons';
import CustomSpinner from '@/components/CustomSpinner/CustomSpinner';
import CheckoutFooter from '@/components/checkout/CheckoutFooter';
import { MagnoliaSite, Props } from '@/types/cms/magnolia';
import { ApiAirlineLogo } from '../../../api/model';
import { useMediaQuery, useWindow } from '@ibe/components';
import { useApi } from '@/Hooks/useApi';
import { useRouter } from 'next/navigation';
import isClient, {
  API_ITEM_SERVICE_CODE,
  getProductPageBasePath,
  logger,
  MEDIAQUERY_DEFAULTS,
  VARIANT_QUERY_PROP
} from '@/Util/globals';
import CheckoutStore, {
  CHECKOUT_STORAGE_KEY,
  CheckoutStorage
} from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import ErrorMessage from 'components/checkout/ErrorMessage';
import { ExternalLinks } from '@/components/checkout/TermsAndConditions';
import { FormRefs } from '@/components/checkout/ParticipantsForm';
import CheckoutStepsContainer from '@/components/checkout/CheckoutStepsContainer';
import { stepCreator } from '@/templates/checkout/stepCreator';
import { DisabledPaymentOptions } from '@/components/checkout/payment/PaymentContent';
import { ApiBookingState } from '@ibe/api';
import { useCheckoutContext } from '@/templates/checkout/CheckoutContextProvider';
import { SessionStoragePersistence } from '@ibe/services';
import { BookingIdAfterInvoiceBooking } from '@/components/checkout/checkoutStepConfirmation/CheckoutStepConfirmation';
import { handleItemClickReplaceLogicCallback } from '@/templates/checkout/checkoutSubmit';
import { getServiceTypeCode } from '@/components/checkout/cart/getUpgrades';
import { BUS_TEASER_CONTAINER_ID } from '@/components/busSelection/SeatSelection';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { useTransitionProgress } from '@/Hooks/useReactTransitionProgress';

const CheckoutPageInnerMarkup: FC<{
  checkoutStore: CheckoutStore;
  showContactBar: boolean;
  siteConfig?: MagnoliaSite | null;
  rootNodePath?: string;
  airlineLogos?: ApiAirlineLogo[];
  externalLinks: ExternalLinks;
  initialCartError: boolean;
  confirmationPageMode: boolean;
  displayUrl: string;
  packageCartId: string;
  useParticipantsMock: boolean;
  variantParam?: string;
  pageProps?: Props;
}> = observer(function CheckoutPageInnerMarkup({
  checkoutStore,
  showContactBar,
  siteConfig,
  rootNodePath,
  airlineLogos,
  externalLinks,
  initialCartError,
  confirmationPageMode,
  displayUrl,
  packageCartId,
  useParticipantsMock,
  variantParam,
  pageProps
}): JSX.Element {
  const router = useRouter();
  const { isAuthor } = useGlobalMGLProps() || {};
  const api = useApi(isAuthor);
  const window = useWindow();
  const { t } = useTranslation('Checkout');
  const startTransition = useTransitionProgress();
  const { setIsLastStep } = useCheckoutContext();
  const [activeSubIndex, setActiveSubIndex] = useState<number[]>([0]);
  const [numberOfSubStepsPerStep, setNumberOfSubStepsPerStep] = useState<number[]>([]);
  const [activePaymentTab, setActivePaymentTab] = useState<number>(0);
  const [activePaymentRadio, setActivePaymentRadio] = useState<number>(0);
  const [ignoreBusSeatValidation, setIgnoreBusSeatValidation] = useState<boolean>(false);
  const [disabledPaymentOptions, setDisabledPaymentOptions] = useState<DisabledPaymentOptions>({
    invoice: false,
    online: false,
    deposit: false,
    full: false
  });
  const [remarksFieldIsFilled, setRemarksFieldIsFilled] = useState<boolean>(false);
  const [
    bookingIdAfterInvoiceBooking,
    setBookingIdAfterInvoiceBooking
  ] = useState<BookingIdAfterInvoiceBooking>({ bookingId: undefined, status: undefined });
  const participantsFormRefs = useRef<FormRefs>([]);
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xm });
  const [stepChangeTriggered, setStepChangeTriggered] = useState<boolean>(false);
  const booking = checkoutStore.booking;

  const progressBarItems = useMemo((): ProgressBarItem[] => {
    let numberOfStepsFromStorage;
    if (isClient()) {
      const storage = new SessionStoragePersistence<CheckoutStorage>(CHECKOUT_STORAGE_KEY);
      const { numberOfSteps } = storage.get();
      numberOfStepsFromStorage = numberOfSteps;
    }
    return [
      { label: t(Keys.selectedProduct), icon: <FontAwesomeIcon icon={facPath} /> },
      ...(numberOfSubStepsPerStep.length === 4 ||
      (confirmationPageMode && numberOfStepsFromStorage === 4)
        ? [{ label: t(Keys.extras), icon: <FontAwesomeIcon icon={facExtras} /> }]
        : []),
      { label: t(Keys.participantsAndPayment), icon: <FontAwesomeIcon icon={facPersons} /> },
      { label: t(Keys.confirmation), icon: <FontAwesomeIcon icon={facConfirm} /> }
    ];
  }, [t, numberOfSubStepsPerStep]);
  const [activeIndex, setActiveIndex] = useState<number>(
    confirmationPageMode ? progressBarItems.length - 1 : 0
  );
  const activeIndexRef = useRef<number>(activeIndex);
  const activeSubIndexRef = useRef<number[]>(activeSubIndex);
  const secondLastStepInDesktop = progressBarItems.length - 2;
  const lastStepInDesktop = progressBarItems.length - 1;
  const secondLastStepInMobile = numberOfSubStepsPerStep[numberOfSubStepsPerStep.length - 2] - 1;
  // must include all items used in stepCreator()
  // TODO: crap solution, gonna optimize this after go-live (e.g. make this a Record<string, number | boolean>
  // TODO: and pass it to stepCreator() or something like that)
  const itemLengths = [
    checkoutStore.hotels.length,
    checkoutStore.operas.length,
    checkoutStore.bus.length,
    checkoutStore.transfers.length,
    !!checkoutStore.visa,
    checkoutStore.mealPackages.length,
    checkoutStore.excursionPackages.length,
    checkoutStore.singleExcursions.length,
    checkoutStore.extensions.length
  ];

  useEffect(() => {
    const steps = stepCreator(checkoutStore);
    setNumberOfSubStepsPerStep([...steps]);
    setActiveSubIndex(Array(steps.length).fill(0));
  }, [...itemLengths]);

  useEffect(() => {
    if (!!packageCartId && !!displayUrl && numberOfSubStepsPerStep.length > 0) {
      const storage = new SessionStoragePersistence<CheckoutStorage>(CHECKOUT_STORAGE_KEY);
      storage.set({
        displayUrl,
        packageCartId,
        numberOfSteps: numberOfSubStepsPerStep.filter(steps => steps > 0).length
      });
    }
  }, [numberOfSubStepsPerStep, displayUrl, packageCartId]);

  useEffect(() => {
    if (initialCartError) {
      checkoutStore.errorMessage = t(Keys.noCartError);
    }
  }, [initialCartError]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (
      JSON.stringify(activeSubIndex) !== JSON.stringify(activeSubIndexRef.current) ||
      activeIndex !== activeIndexRef.current
    ) {
      timer = setTimeout(() => {
        window?.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);
    }
    activeIndexRef.current = activeIndex;
    activeSubIndexRef.current = activeSubIndex;

    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, [activeIndex, activeSubIndex]);

  useEffect(() => {
    if (activeIndex === progressBarItems.length - 1) {
      setIsLastStep(true);
    }
  }, [activeIndex, progressBarItems]);

  useEffect(() => {
    if (confirmationPageMode) {
      setActiveIndex(progressBarItems.length - 1);
    }
  }, [confirmationPageMode, progressBarItems]);

  useEffect(() => {
    if (!booking) return;
    let currentDisabledPaymentOptions = { ...disabledPaymentOptions };
    if (booking?.bookingState === ApiBookingState.REQUEST) {
      currentDisabledPaymentOptions = { ...currentDisabledPaymentOptions, online: true };
      setActivePaymentRadio(1);
    } else {
      currentDisabledPaymentOptions = { ...currentDisabledPaymentOptions, online: false };
    }
    if (
      !booking?.depositPayment ||
      (!!booking?.depositPayment && !booking.depositPayment?.amount?.finalPrice)
    ) {
      currentDisabledPaymentOptions = { ...currentDisabledPaymentOptions, deposit: true };
      setActivePaymentTab(1);
    } else {
      currentDisabledPaymentOptions = { ...currentDisabledPaymentOptions, deposit: false };
    }
    setDisabledPaymentOptions(currentDisabledPaymentOptions);
  }, [booking]);

  useEffect(() => {
    if (ignoreBusSeatValidation) {
      setStepChangeTriggered(false);
    }
  }, [ignoreBusSeatValidation]);

  const handleProgressBarBackClick = async (): Promise<void> => {
    if (rootNodePath !== undefined && !!siteConfig && !!checkoutStore.product) {
      if (!!checkoutStore.packageCart?.id) {
        checkoutStore.isLoading = true;
        try {
          await api.remove(checkoutStore.packageCart.id);
        } catch (err) {
          logger('error')('Unable to remove package cart: ', err);
        } finally {
          checkoutStore.isLoading = false;
        }
      }
      startTransition(() => {
        router.push(
          `/${getProductPageBasePath(rootNodePath, siteConfig)}/${
            checkoutStore.product.displayUrl
          }${!!variantParam ? `?${VARIANT_QUERY_PROP}=${variantParam}` : ''}`
        );
      });
    }
  };

  const setIsOnlinePaymentDisabled = (disable: boolean): void => {
    if (disable) {
      setDisabledPaymentOptions(value => ({ ...value, online: true }));
      setActivePaymentRadio(1);
      setRemarksFieldIsFilled(true);
    } else {
      setDisabledPaymentOptions(value => ({ ...value, online: false }));
      setRemarksFieldIsFilled(false);
    }
  };

  const busSelectionValid = (): boolean => {
    return (
      checkoutStore.bus.length === 0 ||
      (checkoutStore.bus.length > 0 &&
        checkoutStore.booking?.bookedItems?.find(
          bookedItem => getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.BU
        )?.specialRequests?.length === checkoutStore.booking?.travelers?.length)
    );
  };

  const itemClickReplaceLogicCallback = async (idx: number): Promise<void> => {
    setStepChangeTriggered(false);
    if (idx === 1) {
      if (busSelectionValid() || ignoreBusSeatValidation) {
        setActiveIndex(idx);
      } else {
        setStepChangeTriggered(true);
        const busTeaserContainer = window?.document?.getElementById(BUS_TEASER_CONTAINER_ID);
        if (!!busTeaserContainer) {
          window?.scroll({
            top: busTeaserContainer.getBoundingClientRect().top + (window?.scrollY || 0) - 100,
            behavior: 'smooth'
          });
        }
      }
    } else {
      await handleItemClickReplaceLogicCallback(
        router,
        idx,
        checkoutStore,
        isDesktop,
        lastStepInDesktop,
        activeIndex,
        activeSubIndex,
        numberOfSubStepsPerStep,
        setActiveSubIndex,
        setActiveIndex,
        setBookingIdAfterInvoiceBooking,
        activePaymentRadio,
        activePaymentTab,
        participantsFormRefs
      );
    }
  };

  return (
    <>
      <div className="checkout__progress-container">
        <ProgressBar
          items={progressBarItems}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          activeSubIndex={activeSubIndex}
          setActiveSubIndex={setActiveSubIndex}
          numberOfSubStepsPerStep={numberOfSubStepsPerStep}
          disableNavigation={activeIndex === lastStepInDesktop}
          onBackClick={handleProgressBarBackClick}
          itemClickReplaceLogicCallback={
            (isDesktop && activeIndex === secondLastStepInDesktop) ||
            (!isDesktop &&
              activeIndex === secondLastStepInDesktop &&
              (activeSubIndex[activeIndex] === secondLastStepInMobile ||
                activeSubIndex[activeIndex] === 0))
              ? itemClickReplaceLogicCallback
              : undefined
          }
          isDesktop={isDesktop}
        />
      </div>
      {!confirmationPageMode && <ErrorMessage checkoutStore={checkoutStore} />}
      {(!initialCartError || confirmationPageMode) && (
        <div className="checkout__content__container">
          <CheckoutStepsContainer
            checkoutStore={checkoutStore}
            activeIndex={activeIndex}
            numberOfItems={progressBarItems.length}
            progressBarItems={progressBarItems}
            externalLinks={externalLinks}
            participantsFormRefs={participantsFormRefs}
            airlineLogos={airlineLogos}
            activeSubIndex={activeSubIndex}
            isDesktop={isDesktop}
            numberOfSubStepsPerStep={numberOfSubStepsPerStep}
            setActivePaymentTab={setActivePaymentTab}
            activePaymentTab={activePaymentTab}
            setActivePaymentRadio={setActivePaymentRadio}
            activePaymentRadio={activePaymentRadio}
            disabledPaymentOptions={disabledPaymentOptions}
            setIsOnlinePaymentDisabled={setIsOnlinePaymentDisabled}
            bookingIdAfterInvoiceBooking={bookingIdAfterInvoiceBooking}
            lastStepInDesktop={lastStepInDesktop}
            remarksFieldIsFilled={remarksFieldIsFilled}
            confirmationPageMode={confirmationPageMode}
            useParticipantsMock={useParticipantsMock}
            stepChangeTriggered={stepChangeTriggered}
            pageProps={pageProps}
            setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
            ignoreBusSeatValidation={ignoreBusSeatValidation}
          />
        </div>
      )}
      <CheckoutFooter
        checkoutStore={checkoutStore}
        showContactBar={showContactBar}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        activeSubIndex={activeSubIndex}
        setActiveSubIndex={setActiveSubIndex}
        numberOfSubStepsPerStep={numberOfSubStepsPerStep}
        progressBarItems={progressBarItems}
        onBackClick={handleProgressBarBackClick}
        itemClickReplaceLogicCallback={
          (isDesktop && (activeIndex === secondLastStepInDesktop || activeIndex === 0)) ||
          (!isDesktop &&
            activeIndex === secondLastStepInDesktop &&
            (activeSubIndex[activeIndex] === secondLastStepInMobile ||
              activeSubIndex[activeIndex] === 0 ||
              activeSubIndex[activeIndex] === 1)) ||
          (activeIndex === 0 &&
            activeSubIndex[activeIndex] === numberOfSubStepsPerStep[activeIndex] - 1)
            ? itemClickReplaceLogicCallback
            : undefined
        }
        initialCartError={initialCartError}
        isDesktop={isDesktop}
        activePaymentRadio={activePaymentRadio}
        setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
      />
      {checkoutStore.isLoading && (
        <div className="checkout__loading">
          <CustomSpinner />
        </div>
      )}
    </>
  );
});

export default CheckoutPageInnerMarkup;
