/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
    } from './';

/**
* 
* @export
* @interface ApiRoom
*/
export interface ApiRoom {
    /**
    * 
    * @type {string}
    * @memberof ApiRoom
    */
    cmsCode: string;
    /**
    * 
    * @type {string}
    * @memberof ApiRoom
    */
    description?: string;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiRoom
    */
    image?: ApiImage;
}

export function ApiRoomFromJSON(json: any): ApiRoom {
return ApiRoomFromJSONTyped(json, false);
}

export function ApiRoomFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiRoom {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'cmsCode': json['cmsCode'],
                    'description': json['description'],
                            'image': ApiImageFromJSON(json['image']),
        };
    }

    export function ApiRoomToJSON(value?: ApiRoom | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'cmsCode': value.cmsCode,
                'description': value.description,
                            'image': ApiImageToJSON(value.image),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

