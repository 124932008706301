import { EventType, PageType } from '@/Tracking/types';

export type Item = { name: string; price?: number; currency?: string };

export default class CheckoutProgress {
  event: EventType;
  pageType: PageType;
  checkoutStep: number;
  checkoutStepName: string;
  product?: Item;
  extras?: Array<Item>;
  transactionId?: string;
  pax?: number;
}
