import React, { Dispatch, FC, JSX, SetStateAction } from 'react';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facInvoice } from '@/Theme/SVG/Icons';
import classNames from 'classnames';

export type DisabledPaymentOptions = {
  invoice: boolean;
  online: boolean;
  deposit: boolean;
  full: boolean;
};

const PaymentContent: FC<{
  activeRadio: number;
  setActiveRadio: Dispatch<SetStateAction<number>>;
  logos: Array<{ url: string; name: string }>;
  disabledOptions: DisabledPaymentOptions;
}> = ({ activeRadio, setActiveRadio, logos, disabledOptions }): JSX.Element => {
  const { t } = useTranslation('Checkout');

  return (
    <div className="payment__content">
      <div
        className={classNames('payment__option', {
          'payment__option--active': activeRadio === 0,
          'payment__option--disabled': disabledOptions.online
        })}
        onClick={(): void => {
          if (!disabledOptions.online) {
            setActiveRadio(0);
          }
        }}
      >
        <div className="payment__radio" />
        <div className="payment__label">
          <div className="payment__label__upper">
            <div>{t(Keys.onlinePayment)}</div>
            <div className="payment__label__icons">
              {logos.map(logo => (
                // eslint-disable-next-line
                <img key={logo.name} src={logo.url} alt={logo.name} />
              ))}
            </div>
          </div>
          <div className="payment__label__lower">{t(Keys.onlinePaymentText)}</div>
        </div>
      </div>
      <div
        className={classNames('payment__option', {
          'payment__option--active': activeRadio === 1,
          'payment__option--disabled': disabledOptions.invoice
        })}
        onClick={(): void => {
          if (!disabledOptions.invoice) {
            setActiveRadio(1);
          }
        }}
      >
        <div className="payment__radio" />
        <FontAwesomeIcon className="payment__single-icon" icon={facInvoice} />
        <div className="payment__label">
          <div className="payment__label__upper payment__label__upper--elevated">
            {t(Keys.invoice)}
          </div>
          <div className="payment__label__lower">{t(Keys.invoiceText)}</div>
        </div>
      </div>
    </div>
  );
};

export default PaymentContent;
