/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ApiProductStateType = {
    SoldOut: 'SOLD_OUT',
    Available: 'AVAILABLE',
    Unavailable: 'UNAVAILABLE'
} as const;
export type ApiProductStateType = typeof ApiProductStateType[keyof typeof ApiProductStateType];


export function instanceOfApiProductStateType(value: any): boolean {
    for (const key in ApiProductStateType) {
        if (Object.prototype.hasOwnProperty.call(ApiProductStateType, key)) {
            if (ApiProductStateType[key as keyof typeof ApiProductStateType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ApiProductStateTypeFromJSON(json: any): ApiProductStateType {
    return ApiProductStateTypeFromJSONTyped(json, false);
}

export function ApiProductStateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProductStateType {
    return json as ApiProductStateType;
}

export function ApiProductStateTypeToJSON(value?: ApiProductStateType | null): any {
    return value as any;
}

