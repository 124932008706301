export const participant: Record<string, string> = {
  title: 'Mr',
  firstName: 'John',
  lastName: 'Doe',
  birthDate: '1975-01-01',
  email: 'dummy@isotravel.com',
  confirmEmail: 'dummy@isotravel.com',
  countryCode: '+45',
  phone: '123456',
  street: 'Anonymous St. 23',
  zipCode: '12345',
  city: 'No Namo City',
  country: 'SE'
};
