'use client';

import React, { ReactElement } from 'react';
import { useMediaQuery, MEDIAQUERY_DEFAULTS } from '@ibe/components';
import sanitize from '@/Util/sanitize';

type Props = {
  priceInformationHeadline: string;
  priceInformationText: string;
};

const PriceInformationStaticPart = (props: Props): ReactElement => {
  const { priceInformationHeadline, priceInformationText } = props;
  const isDesktop = useMediaQuery(`(min-width: ${MEDIAQUERY_DEFAULTS.lg}px)`, true);

  return (
    <div>
      <div className="price-information__container">
        <div className="grid">
          <h5 className="grid-col-12 grid-col-lg-3">{priceInformationHeadline}</h5>
          {isDesktop ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(priceInformationText, true)
              }}
              className="price-information__body grid-col-9"
            />
          ) : (
            <div className="grid-col-12 grid-col-lg-9 grid">
              <div
                className="price-information__body--unwrapped grid-col-12 grid-col-lg-6"
                dangerouslySetInnerHTML={{
                  __html: sanitize(priceInformationText, true)
                }}
              />
            </div>
          )}
        </div>
        <hr className="price-information__line" />
      </div>
    </div>
  );
};

export default PriceInformationStaticPart;
