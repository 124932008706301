import React, { FC } from 'react';
import Image from '@/components/image/Image';
import classNames from 'classnames';
import { Continents } from '@/components/continentMap/helpers';
import { Props } from '@/types/cms/magnolia';

interface ContinentImageProps {
  name: Continents;
  isActive: boolean;
  pageProps?: Props;
}

const ContinentImage: FC<ContinentImageProps> = ({ name, isActive, pageProps }) => {
  return (
    <Image
      src={process.env.NEXT_PUBLIC_MAPS_IMAGES_URL.replace(
        process.env.NEXT_PUBLIC_MAPS_IMAGES_REPLACEMENT,
        name
      )}
      alt={name}
      className={classNames('continent-map__map-image', {
        'continent-map__map-image--active': isActive && name !== Continents.WORLD
      })}
      width={1244}
      height={627}
      priority
      quality={75}
      noLoader
      serverSideContext={pageProps?.albConfig.context}
    />
  );
};

export default ContinentImage;
