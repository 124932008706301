import React, { FC, JSX, useMemo } from 'react';
import CheckoutStore, { ApiComponentAdvanced } from '@/templates/checkout/CheckoutStore';
import { ApiHotel } from '@ibe/api';
import { ApiExtra } from '../../../api/model';
import OperaTeaser from '@/components/operaSelection/OperaTeaser';
import SeatSelection from '@/components/operaSelection/SeatSelection';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { getPrice } from '@/Util/globals';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { observer } from 'mobx-react';
import { Props } from '@/types/cms/magnolia';

const OperaItem: FC<{
  checkoutStore: CheckoutStore;
  opera: ApiComponentAdvanced<ApiHotel>;
  pageProps?: Props;
}> = observer(function OperaItem({ checkoutStore, opera, pageProps }): JSX.Element {
  const { t } = useTranslation('Checkout');
  const language = useCurrentLanguage();

  const cmsExtra = useMemo(() => {
    return opera.selectableItems[0].metaInfo?.contentByMetaType?.extraContent?.content?.[
      opera.selectableItems[0].cmsCode
    ] as ApiExtra | undefined;
  }, [opera]);

  const seatSelection = useMemo(() => {
    const seats = opera.selectableItems[0].rooms
      .map(room => {
        const { totalPrice } = room.roomrates[0];
        return {
          id: room.roomrates[0].id,
          description: room.description,
          price: getPrice(totalPrice, language),
          priceRaw: totalPrice.finalPrice,
          isSelected: room.roomCode === opera?.selectedItems?.[0]?.rooms?.[0]?.roomCode
        };
      })
      .sort((a, b) => a.priceRaw - b.priceRaw);
    const cheapest = seats.shift();
    return !!cheapest ? [...seats, cheapest] : [...seats];
  }, [opera]);

  return (
    <>
      <OperaTeaser
        key={opera.id}
        checkoutStore={checkoutStore}
        opera={opera.selectableItems[0]}
        cmsExtra={cmsExtra}
        pageProps={pageProps}
      />
      <SeatSelection
        checkoutStore={checkoutStore}
        headline={t(Keys.operaHeadline)}
        image={cmsExtra?.illustration}
        selections={[...seatSelection]}
        componentId={opera.id}
        pageProps={pageProps}
      />
    </>
  );
});

export default OperaItem;
