/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
* 
* @export
* @interface ApiProductsCountResponseItem
*/
export interface ApiProductsCountResponseItem {
    /**
    * 
    * @type {string}
    * @memberof ApiProductsCountResponseItem
    */
    continent?: string;
    /**
    * 
    * @type {number}
    * @memberof ApiProductsCountResponseItem
    */
    count?: number;
}

export function ApiProductsCountResponseItemFromJSON(json: any): ApiProductsCountResponseItem {
return ApiProductsCountResponseItemFromJSONTyped(json, false);
}

export function ApiProductsCountResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProductsCountResponseItem {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'continent': json['continent'],
                    'count': json['count'],
        };
    }

    export function ApiProductsCountResponseItemToJSON(value?: ApiProductsCountResponseItem | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'continent': value.continent,
                'count': value.count,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

