'use client';

import React, { Dispatch, FC, ReactElement, SetStateAction, useMemo } from 'react';
import ProgressBarItem from './ProgressBarItem';
import { createPortal } from 'react-dom';
import { useWindow } from '@ibe/components';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretLeft } from '@/Theme/SVG/Icons';
import classNames from 'classnames';
import { useIsComponentMounted } from '@/Hooks/useIsComponentMounted';

export type ProgressBarItem = {
  icon: ReactElement;
  label: string;
};

const ProgressBar: FC<{
  items: ProgressBarItem[];
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  activeSubIndex: number[];
  setActiveSubIndex: Dispatch<SetStateAction<number[]>>;
  numberOfSubStepsPerStep: number[];
  disableNavigation?: boolean;
  onBackClick?: () => void;
  itemClickReplaceLogicCallback?: (idx: number) => void;
  isDesktop: boolean;
}> = ({
  items,
  activeIndex,
  setActiveIndex,
  disableNavigation,
  onBackClick,
  itemClickReplaceLogicCallback,
  activeSubIndex,
  setActiveSubIndex,
  numberOfSubStepsPerStep,
  isDesktop
}): JSX.Element => {
  const { t } = useTranslation('Checkout');
  const window = useWindow();
  const isMounted = useIsComponentMounted();

  const mobileIndex = useMemo((): number => {
    return numberOfSubStepsPerStep.reduce(
      (total, current, idx) =>
        total +
        (idx < activeIndex ? current : idx === activeIndex ? activeSubIndex[activeIndex] : 0),
      1
    );
  }, [numberOfSubStepsPerStep, activeSubIndex, activeIndex]);

  const mobileTotal = useMemo((): number => {
    return numberOfSubStepsPerStep.reduce((total, current) => total + current, 0);
  }, [numberOfSubStepsPerStep]);

  const handleBackClick = async (): Promise<void> => {
    if (
      activeIndex === 0 &&
      !!onBackClick &&
      (isDesktop || (!isDesktop && activeSubIndex[activeIndex] === 0))
    ) {
      onBackClick();
    } else if (activeIndex === 0 && activeSubIndex[activeIndex] > 0 && !isDesktop) {
      let currentActiveSubIndex = [...activeSubIndex];
      currentActiveSubIndex[activeIndex] = currentActiveSubIndex[activeIndex] - 1;
      setActiveSubIndex(currentActiveSubIndex);
    } else if (activeIndex > 0 && activeIndex < items.length - 1) {
      if (!isDesktop) {
        if (activeSubIndex[activeIndex] === 0) {
          setActiveIndex(value => value - 1);
        } else {
          let currentActiveSubIndex = [...activeSubIndex];
          currentActiveSubIndex[activeIndex] = currentActiveSubIndex[activeIndex] - 1;
          setActiveSubIndex(currentActiveSubIndex);
        }
      } else {
        setActiveIndex(value => value - 1);
      }
    }
  };

  const handleItemClick = (idx: number): void => {
    if (!!itemClickReplaceLogicCallback) {
      itemClickReplaceLogicCallback(idx);
    } else if (!!setActiveIndex && !disableNavigation) {
      setActiveIndex(idx);
    }
  };

  return (
    <div className="progressbar">
      <div className="progressbar--desktop">
        <div className="progressbar__line">
          <div style={{ width: `${(activeIndex / (items.length - 1)) * 100}%` }} />
        </div>
        {items.map(({ icon, label }, idx: number) => (
          <ProgressBarItem
            key={label}
            label={label}
            icon={icon}
            activeIndex={activeIndex}
            idx={idx}
            onClick={handleItemClick}
            disableNavigation={disableNavigation}
          />
        ))}
      </div>
      {window?.document?.body ? (
        createPortal(
          activeIndex !== items.length - 1 && isMounted ? (
            <div className="progressbar--mobile">
              <div className="progressbar__inner">
                <div className="progressbar__label--mobile">{items[activeIndex]?.label || ''}</div>
                <div className="progressbar__step">
                  {t(Keys.step, {
                    step: isDesktop ? activeIndex + 1 : mobileIndex,
                    total: isDesktop ? items.length : mobileTotal
                  })}
                </div>
              </div>
              {!!setActiveIndex && activeIndex < items.length - 1 && (
                <FontAwesomeIcon
                  className={classNames('progressbar__step__back', {
                    'progressbar__step__back--disabled': !setActiveIndex || disableNavigation
                  })}
                  icon={facCaretLeft}
                  onClick={!disableNavigation ? handleBackClick : undefined}
                />
              )}
              <div
                className="progressbar__progress"
                style={{
                  width: isDesktop
                    ? `${((activeIndex + 1) / items.length) * 100}%`
                    : `${(mobileIndex / mobileTotal) * 100}%`
                }}
              />
            </div>
          ) : (
            <></>
          ),
          window?.document?.body || document.body
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProgressBar;
