import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppService } from '@ibe/components';
import useConfig from '@/Hooks/useConfig';
import dayjs from 'dayjs';
import { facCalendar, facCaretLeft } from '@/Theme/SVG/Icons';
import { useTransition } from 'react-transition-state';
import { useClickAway } from 'react-use';
import localeData from 'dayjs/plugin/localeData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

dayjs.extend(localeData);

const YEARS_TO_DISPLAY = 3;

type SelectedDate = {
  year: number | null;
  month: number | null;
  day: number | null;
};

const getYears = (): number[] => {
  return [...Array(YEARS_TO_DISPLAY).keys()].map(index => dayjs().get('year') + index);
};

const getMonthsForSelectedYear = (year: number | null): string[] => {
  if (!year) {
    return [];
  } else if (year !== dayjs().get('year')) {
    return dayjs.months();
  } else {
    return dayjs.months().filter((_, idx: number) => idx <= dayjs().get('month'));
  }
};

const getDaysForSelectedMonth = (selectedDate: SelectedDate): number[] => {
  if (!selectedDate.year || selectedDate.month === undefined || selectedDate.month === null) {
    return [];
  } else {
    const daysInMonth = [
      ...Array(dayjs(`${selectedDate.year}-${selectedDate.month + 1}-2`).daysInMonth()).keys()
    ].map(index => ++index);
    if (selectedDate.year === dayjs().get('year') && selectedDate.month === dayjs().get('month')) {
      return daysInMonth.filter(day => day <= dayjs().get('date'));
    } else {
      return daysInMonth;
    }
  }
};

let timer1: ReturnType<typeof setTimeout> | null = null;
let timer2: ReturnType<typeof setTimeout> | null = null;
let timer3: ReturnType<typeof setTimeout> | null = null;
let timer4: ReturnType<typeof setTimeout> | null = null;
let timer5: ReturnType<typeof setTimeout> | null = null;

const Datepicker = ({
  date,
  setDate,
  placeholder,
  customYears
}: {
  date?: string;
  setDate: (date: string) => void;
  placeholder?: string;
  customYears?: number[];
}): JSX.Element => {
  const config = useConfig();
  const app = useAppService();
  const containerRef = useRef<HTMLDivElement>(null);
  const activeDay = containerRef?.current?.querySelector(
    '.alb-datepicker__popup__data__day--active'
  ) as HTMLElement;
  const activeMonth = containerRef?.current?.querySelector(
    '.alb-datepicker__popup__data__month--active'
  ) as HTMLElement;
  const activeYear = containerRef?.current?.querySelector(
    '.alb-datepicker__popup__data__year--active'
  ) as HTMLElement;

  const [offsetIndexStates, setOffsetIndexStates] = useState<
    {
      [Key in 0 | 1 | 2]: { scrollTo: boolean; active: boolean };
    }
  >({
    0: { scrollTo: true, active: true },
    1: { scrollTo: false, active: false },
    2: { scrollTo: false, active: false }
  });
  const [selectedDate, setSelectedDate] = useState<SelectedDate>({
    year: null,
    month: null,
    day: null
  });

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    return () => {
      if (!!timer1) {
        clearTimeout(timer1);
      }
      if (!!timer2) {
        clearTimeout(timer2);
      }
      if (!!timer3) {
        clearTimeout(timer3);
      }
      if (!!timer4) {
        clearTimeout(timer4);
      }
      if (!!timer5) {
        clearTimeout(timer5);
      }
    };
  }, []);

  useEffect(() => {
    timer1 = setTimeout((): void => {
      const element = activeDay || activeMonth || activeYear;
      element?.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }, 500);
  }, [activeDay, activeMonth, activeYear]);

  useClickAway(containerRef, (): void => {
    toggle(false);
  });

  const handleOffsetIndexStates = useCallback(
    (comeFrom: number, goTo: number): void => {
      setOffsetIndexStates(value => ({
        ...value,
        [comeFrom]: { scrollTo: false, active: true },
        [goTo]: { scrollTo: true, active: true }
      }));

      timer2 = setTimeout(() => {
        setOffsetIndexStates(value => ({
          ...value,
          [comeFrom]: { scrollTo: false, active: false }
        }));
      }, 800);
    },
    [offsetIndexStates]
  );

  const setYear = useCallback(
    (year: number): void => {
      setSelectedDate(value => ({ ...value, year }));
      timer3 = setTimeout((): void => {
        handleOffsetIndexStates(0, 1);
      }, 100);
    },
    [selectedDate]
  );

  const setMonth = useCallback(
    (month: string): void => {
      setSelectedDate(value => ({
        ...value,
        month: dayjs.months().findIndex(dayjsMonth => dayjsMonth === month)
      }));
      timer4 = setTimeout((): void => {
        handleOffsetIndexStates(1, 2);
      }, 100);
    },
    [selectedDate]
  );

  const setDay = useCallback(
    (day: number): void => {
      const newSelectedDate = { ...selectedDate, day };
      setSelectedDate(newSelectedDate);
      setDate(
        dayjs(
          `${newSelectedDate.year}-${(newSelectedDate.month || 0) + 1}-${newSelectedDate.day}`
        ).format('YYYY-MM-DD')
      );
      timer5 = setTimeout((): void => {
        toggle(false);
      }, 100);
    },
    [selectedDate]
  );

  const goBack = useCallback(
    (index: number): void => {
      if (index === 1) {
        setSelectedDate(value => ({ ...value, day: null }));
      } else if (index === 0) {
        setSelectedDate(value => ({ ...value, month: null, day: null }));
      }
      handleOffsetIndexStates(index + 1, index);
    },
    [offsetIndexStates, selectedDate]
  );

  return (
    <div className="alb-datepicker" ref={containerRef}>
      {isMounted && (
        <div
          className={classNames('alb-datepicker__popup', {
            'alb-datepicker__popup--hidden': status === 'preEnter' || status === 'exiting'
          })}
        >
          <div
            className={classNames(
              'alb-datepicker__popup__inner',
              `alb-datepicker__popup__inner--${
                Object.entries(offsetIndexStates).find(([, value]) => value.scrollTo)?.[0]
              }`,
              {
                'alb-datepicker__popup__inner--opening': status === 'entering',
                'alb-datepicker__popup__inner--open': status === 'entered'
              }
            )}
          >
            <div
              className={classNames('alb-datepicker__popup__data', {
                'alb-datepicker__popup__data--active': offsetIndexStates[0].active,
                'alb-datepicker__popup__data__year--active': offsetIndexStates[0].active
              })}
            >
              <div className="alb-datepicker__popup__data__inner">
                {(!!customYears ? customYears : getYears()).map(year => (
                  <div
                    key={year}
                    className={classNames('alb-datepicker__year', {
                      'alb-datepicker__year--active': year === selectedDate.year
                    })}
                    onClick={(): void => setYear(year)}
                  >
                    {year}
                  </div>
                ))}
              </div>
            </div>
            <div
              className={classNames('alb-datepicker__popup__data', {
                'alb-datepicker__popup__data--active': offsetIndexStates[1].active,
                'alb-datepicker__popup__data__month--active': offsetIndexStates[1].active
              })}
            >
              <div className="alb-datepicker__popup__data__inner">
                <div className="alb-datepicker__popup__back" onClick={(): void => goBack(0)}>
                  <FontAwesomeIcon icon={facCaretLeft} />
                  <div>{selectedDate.year}</div>
                </div>
                {getMonthsForSelectedYear(selectedDate.year).map((month: string) => (
                  <div
                    key={month}
                    className={classNames('alb-datepicker__month', {
                      'alb-datepicker__month--active':
                        dayjs.months().findIndex(dayjsMonth => dayjsMonth === month) ===
                        selectedDate.month
                    })}
                    onClick={(): void => setMonth(month)}
                    title={month}
                  >
                    {month}
                  </div>
                ))}
              </div>
            </div>
            <div
              className={classNames('alb-datepicker__popup__data', {
                'alb-datepicker__popup__data--active': offsetIndexStates[2].active,
                'alb-datepicker__popup__data__day--active': offsetIndexStates[2].active
              })}
            >
              <div className="alb-datepicker__popup__data__inner">
                <div className="alb-datepicker__popup__back" onClick={(): void => goBack(1)}>
                  <FontAwesomeIcon icon={facCaretLeft} />
                  <div>{dayjs.months()[selectedDate.month || 0]}</div>
                </div>
                {getDaysForSelectedMonth(selectedDate).map((day: number) => (
                  <div
                    key={day}
                    className={classNames('alb-datepicker__day', {
                      'alb-datepicker__day--active': day === selectedDate.day
                    })}
                    onClick={(): void => setDay(day)}
                    title={day.toString()}
                  >
                    {day.toString()}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="alb-datepicker__label" onClick={(): void => toggle(!isMounted)}>
        <FontAwesomeIcon icon={facCalendar} />
        <div
          className={classNames('alb-datepicker__value', { 'alb-datepicker__placeholder': !date })}
        >
          {!!date ? dayjs(date).format(config.displayFormatDate[app.lang]) : placeholder || ''}
        </div>
      </div>
    </div>
  );
};

export default Datepicker;
