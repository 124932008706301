/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
    } from './';

/**
* 
* @export
* @interface ApiItinerary
*/
export interface ApiItinerary {
    /**
    * 
    * @type {string}
    * @memberof ApiItinerary
    */
    dayFrom: string;
    /**
    * 
    * @type {string}
    * @memberof ApiItinerary
    */
    dayTo: string;
    /**
    * 
    * @type {string}
    * @memberof ApiItinerary
    */
    description: string;
    /**
    * 
    * @type {Array<ApiImage>}
    * @memberof ApiItinerary
    */
    images?: Array<ApiImage>;
    /**
    * 
    * @type {string}
    * @memberof ApiItinerary
    */
    title: string;
}

export function ApiItineraryFromJSON(json: any): ApiItinerary {
return ApiItineraryFromJSONTyped(json, false);
}

export function ApiItineraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiItinerary {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'dayFrom': json['dayFrom'],
                    'dayTo': json['dayTo'],
                    'description': json['description'],
                'images': ((json['images'] as Array<any>).map(ApiImageFromJSON)),
                    'title': json['title'],
        };
    }

    export function ApiItineraryToJSON(value?: ApiItinerary | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'dayFrom': value.dayFrom,
                'dayTo': value.dayTo,
                'description': value.description,
                    'images': ((value.images as Array<any>).map(ApiImageToJSON)),
                'title': value.title,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

