'use client';

import { appService, initAuth } from '@ibe/components';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import dayjs from 'dayjs';
import da from 'date-fns/locale/da';
import nb from 'date-fns/locale/nb';
import fi from 'date-fns/locale/fi';
import sv from 'date-fns/locale/sv';
import pl from 'date-fns/locale/pl';
import localeData from 'dayjs/plugin/localeData';
import localeDa from 'dayjs/locale/da';
import localeNb from 'dayjs/locale/nb';
import localeFi from 'dayjs/locale/fi';
import localeSv from 'dayjs/locale/sv';
import localePl from 'dayjs/locale/pl';
import '../Tracking/trackingSubscriptions';
import isClient, { logger } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';
import { Currency, fallbackLng, getOptions, languageMapping, languages } from '@/app/i18n/settings';
import { configService, customConfig, initConfig } from '@/Config/configService';
import AlbConfigModel from '@/Config/AlbConfigModel';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';

dayjs.extend(localeData);

export const getLocale = (lang: string) => {
  switch (lang) {
    case 'da':
      return localeDa;
    case 'no':
      return localeNb;
    case 'fi':
      return localeFi;
    case 'sv':
      return localeSv;
    case 'pl':
      return localePl;
    default:
      return localeDa;
  }
};

configService.mergeIntoConfig(customConfig);

export const initTranslations = async (
  isLocal: string,
  magnoliaHost: string,
  locale?: string,
  translationsVersion?: string
): Promise<void> => {
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(ChainedBackend)
    .init({
      ...getOptions(locale, undefined, isLocal, magnoliaHost, translationsVersion),
      // detection: {
      //   order: ['path', 'htmlTag', 'cookie', 'navigator']
      // },
      preload: isClient() ? languages : []
    });
};

function initLanguage(locale?: string): void {
  registerLocale('da', da);
  registerLocale('no', nb);
  registerLocale('fi', fi);
  registerLocale('sv', sv);
  registerLocale('pl', pl);
  appService.setLang(locale || fallbackLng);
  dayjs.locale(getLocale(locale || fallbackLng));
  setDefaultLocale(locale || fallbackLng);
}

function initCurrency(locale?: string): void {
  if (!isClient()) {
    return;
  }
  appService.setCurrency(languageMapping[locale || '']?.currency || Currency.DKK);
}

export const boot = async (
  albConfig: AlbConfigModel,
  isLocal: string,
  magnoliaHost: string,
  locale: string,
  isServerSide?: boolean,
  magnoliaProps?: Props
): Promise<AlbConfigModel> => {
  const { siteConfig, translationsVersion } = magnoliaProps || {};
  await initTranslations(isLocal, magnoliaHost, locale, translationsVersion);
  initConfig(albConfig, siteConfig);
  const config = configService.get();
  logger()('booting...');
  await appService.hydrate(config, isServerSide || false);
  if (!isServerSide) {
    await initAuth(config);
  }

  if (!isServerSide) {
    initLanguage(locale);
    initCurrency(locale);
  }
  logger()('App hydrated');
  return config;
};
