import React, { FC, JSX } from 'react';
import { ApiFlightItem } from '@ibe/api';
import dayjs from 'dayjs';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import FlightLegs from '@/components/FlightIntro/FlightLegs';
import { ApiAirlineLogo } from '../../../api/model';
import { Props } from '@/types/cms/magnolia';

const FlightSegments: FC<{
  flight: ApiFlightItem;
  airlineLogos?: ApiAirlineLogo[];
  pageProps?: Props;
  label?: string;
}> = ({ flight: { segment }, airlineLogos, pageProps, label }): JSX.Element => {
  const config = useConfig();
  const language = useCurrentLanguage();
  return (
    <div className="flight-segments">
      <div className="flight-segments__label">{`${label} - ${dayjs(segment[0].departure).format(
        config.displayFormatDate[language]
      )}`}</div>
      {!!segment &&
        segment.length > 0 &&
        segment.map(singleSegment =>
          singleSegment.legs.length > 0 ? (
            <FlightLegs
              key={singleSegment.id}
              segment={singleSegment}
              airlineLogos={airlineLogos}
              pageProps={pageProps}
            />
          ) : (
            <></>
          )
        )}
    </div>
  );
};

export default FlightSegments;
