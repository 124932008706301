import React, { Dispatch, FC, JSX, SetStateAction, useEffect } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { SearchForResultsProps } from '@/components/SearchForResults/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  facCalendar,
  facCaretRightThin,
  facGlobeMagnifier,
  facLocationMarker
} from '@/Theme/SVG/Icons';
import Keys from '@/Translations/generated/da/search.json.keys';
import TravelTypesSearchMobile from '@/components/Search/TravelTypesSearchMobile';
import SearchForResultsMobileOverview from '@/components/SearchForResults/SearchForResultsMobileOverview';
import MonthSelector from '@/components/Search/MonthSelector';
import CountriesSearchMobile from '@/components/Search/CountriesSearchMobile';
import { useTransition } from 'react-transition-state';
import SubmitButtonMobile from '@/components/Search/SubmitButtonMobile';

const SearchForResultsMobileContent: FC<
  SearchForResultsProps & {
    singleRoomOnlyRadioChecked: boolean;
    setSingleRoomOnlyRadioChecked: Dispatch<SetStateAction<boolean>>;
    contentIndex: [number, number];
    setContentIndex: Dispatch<SetStateAction<[number, number]>>;
    hasSelectedItems: boolean;
  }
> = ({
  checkboxDispatch,
  checkboxState,
  isInitialSearch,
  singleRoomOnlyRadioChecked,
  setSingleRoomOnlyRadioChecked,
  contentIndex,
  setContentIndex,
  hasSelectedItems,
  isLoading
}): JSX.Element => {
  const { t } = useTranslation('search');

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    toggle(hasSelectedItems && (!contentIndex || contentIndex[0] !== 0));
  }, [checkboxState, contentIndex]);

  return (
    <div className="search-for-results__mobile__content">
      <div
        className="search-for-results__mobile__content__inner"
        style={{ transform: `translateX(${(contentIndex[0] - 1) * -100}dvw)` }}
      >
        <SearchForResultsMobileOverview
          singleRoomOnlyRadioChecked={singleRoomOnlyRadioChecked}
          setSingleRoomOnlyRadioChecked={setSingleRoomOnlyRadioChecked}
          setContentIndex={setContentIndex}
          isLoading={isLoading}
        />
        <div className="search-for-results__mobile__content__step">
          {contentIndex[1] === 0 && (
            <>
              <div
                className="search__content__mobile__back"
                onClick={(): void => setContentIndex([1, 0])}
              >
                <div>
                  <FontAwesomeIcon icon={facGlobeMagnifier} />
                  <div>{t(Keys.destinations)}</div>
                </div>
                <FontAwesomeIcon icon={facCaretRightThin} />
              </div>
              <CountriesSearchMobile
                checkboxState={checkboxState}
                checkboxDispatch={checkboxDispatch}
                isLoading={isLoading}
              />
            </>
          )}
          {contentIndex[1] === 1 && (
            <>
              <div
                className="search__content__mobile__back"
                onClick={(): void => setContentIndex([1, 0])}
              >
                <div>
                  <FontAwesomeIcon icon={facLocationMarker} />
                  <div>{t(Keys.travelTypes)}</div>
                </div>
                <FontAwesomeIcon icon={facCaretRightThin} />
              </div>
              <TravelTypesSearchMobile
                checkboxState={checkboxState}
                checkboxDispatch={checkboxDispatch}
                isLoading={isLoading}
              />
            </>
          )}
          {contentIndex[1] === 2 && (
            <>
              <div
                className="search__content__mobile__back"
                onClick={(): void => setContentIndex([1, 0])}
              >
                <div>
                  <FontAwesomeIcon icon={facCalendar} />
                  <div>{t(Keys.departureTime)}</div>
                </div>
                <FontAwesomeIcon icon={facCaretRightThin} />
              </div>
              <MonthSelector
                checkboxState={checkboxState}
                checkboxDispatch={checkboxDispatch}
                isLoading={isLoading}
              />
            </>
          )}
          {isMounted && (
            <SubmitButtonMobile
              status={status}
              callback={(): void => setContentIndex([0, 0])}
              buttonCallbackCondition={hasSelectedItems}
              isInitialSearch={isInitialSearch}
              checkboxState={checkboxState}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchForResultsMobileContent;
