'use client';

import React, { MouseEvent, ReactElement } from 'react';
import Keys from '@/Translations/generated/da/country.json.keys';
import { smoothScroll, THEMES } from '@/Util/globals';
import { useTranslation } from '@/app/i18n/client';
import Link from '@/components/Link';
import { ApiCountry } from '../../../api/model';
import Intro from '@/components/intro/Intro';
import sanitize from '@/Util/sanitize';
import { Props } from '@/types/cms/magnolia';

const CountryMain = ({
  country,
  pathname,
  pageProps
}: {
  country?: ApiCountry;
  pathname: string;
  pageProps?: Props;
}): ReactElement => {
  const {
    name,
    introTitleGeneral,
    introTextGeneral,
    language,
    capitalCity,
    citizens,
    themeIntroMain
  } = country || {};
  const { t } = useTranslation('country');

  return (
    <>
      <Intro
        theme={themeIntroMain || THEMES.blue}
        headline={introTitleGeneral ? introTitleGeneral : `${t(Keys.headline)} ${name}`}
        pageProps={pageProps}
        pathname={pathname}
        leftSide={
          <div className="mb-xs-3 mb-md-0">
            {!!introTextGeneral && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(introTextGeneral, true)
                }}
              />
            )}
            <a
              className="country-main__container__link"
              onClick={(e: MouseEvent<HTMLAnchorElement>): void => smoothScroll('productTeaser', e)}
            >
              {t(Keys.moreInformation)} {' >'}
            </a>
          </div>
        }
        rightSide={
          <div className="">
            <div className="d-sm-flex">
              {!!citizens && (
                <div className="country-main__container__inner__citizens">
                  <div className="country-main__container__inner__headline">{t(Keys.citizens)}</div>
                  <div className="country-main__container__inner__text">{citizens}</div>
                </div>
              )}
              {!!capitalCity && (
                <div className="country-main__container__inner__capital">
                  <div className="country-main__container__inner__headline">{t(Keys.capital)}</div>
                  <div className="country-main__container__inner__text">{capitalCity}</div>
                </div>
              )}
            </div>
            <div className="country-main__container__inner__second">
              {!!language && (
                <div className="country-main__container__inner__language">
                  <div className="country-main__container__inner__headline">{t(Keys.language)}</div>
                  <div className="country-main__container__inner__text">{language}</div>
                </div>
              )}
            </div>
            {!!country?.cities && country.cities.length > 0 && (
              <div>
                <div className="country-main__container__inner__headline">{t(Keys.cities)}</div>
                <div className="">
                  {country.cities.map(city => (
                    <Link
                      key={city.name}
                      className="country-main__container__inner__text p-xs-0"
                      labelClassName="ignore-style"
                      href={city.mainPage?.url || 'tst'}
                      showCaret
                    >
                      {city.name}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default CountryMain;
