import { useEffect } from 'react';

const useScroll = <T>(
  callback: (evt?: Event, container?: HTMLElement | Window | null, ...cbArgs: T[]) => void,
  effectDependencyList?: any[],
  scrollContainer?: HTMLElement | Window,
  initialCbDelay?: number,
  cbArgs?: T[]
): void => {
  useEffect(
    () => {
      scrollContainer?.removeEventListener('scroll', callback);
      scrollContainer?.addEventListener('scroll', callback);

      const timer = setTimeout((): void => {
        callback(undefined, scrollContainer, ...(cbArgs || []));
      }, initialCbDelay || 0);

      return (): void => {
        scrollContainer?.removeEventListener('scroll', callback);
        if (!!timer) {
          clearTimeout(timer);
        }
      };
    },
    !!effectDependencyList ? [...effectDependencyList, scrollContainer] : [scrollContainer]
  );
};

export default useScroll;
