/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
* 
* @export
* @interface ApiPage
*/
export interface ApiPage {
    /**
    * 
    * @type {string}
    * @memberof ApiPage
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof ApiPage
    */
    title?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiPage
    */
    url: string;
    /**
    * 
    * @type {string}
    * @memberof ApiPage
    */
    breadcrumbTitle?: string;
}

export function ApiPageFromJSON(json: any): ApiPage {
return ApiPageFromJSONTyped(json, false);
}

export function ApiPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPage {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'id': json['id'],
                    'title': json['title'],
                    'url': json['url'],
                    'breadcrumbTitle': json['breadcrumbTitle'],
        };
    }

    export function ApiPageToJSON(value?: ApiPage | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'id': value.id,
                'title': value.title,
                'url': value.url,
                'breadcrumbTitle': value.breadcrumbTitle,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

