/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
* 
* @export
* @interface ApiDepartureDateItem
*/
export interface ApiDepartureDateItem {
    /**
    * 
    * @type {string}
    * @memberof ApiDepartureDateItem
    */
    departureDate?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiDepartureDateItem
    */
    duration?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiDepartureDateItem
    */
    price?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiDepartureDateItem
    */
    departureAirport?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiDepartureDateItem
    */
    remarks?: string;
    /**
    * 
    * @type {boolean}
    * @memberof ApiDepartureDateItem
    */
    isAvailable?: boolean;
    /**
    * 
    * @type {string}
    * @memberof ApiDepartureDateItem
    */
    bookingLink?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiDepartureDateItem
    */
    singleRoomSupplement?: string;
}

export function ApiDepartureDateItemFromJSON(json: any): ApiDepartureDateItem {
return ApiDepartureDateItemFromJSONTyped(json, false);
}

export function ApiDepartureDateItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDepartureDateItem {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'departureDate': json['departureDate'],
                    'duration': json['duration'],
                    'price': json['price'],
                    'departureAirport': json['departureAirport'],
                    'remarks': json['remarks'],
                    'isAvailable': json['isAvailable'],
                    'bookingLink': json['bookingLink'],
                    'singleRoomSupplement': json['singleRoomSupplement'],
        };
    }

    export function ApiDepartureDateItemToJSON(value?: ApiDepartureDateItem | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'departureDate': value.departureDate,
                'duration': value.duration,
                'price': value.price,
                'departureAirport': value.departureAirport,
                'remarks': value.remarks,
                'isAvailable': value.isAvailable,
                'bookingLink': value.bookingLink,
                'singleRoomSupplement': value.singleRoomSupplement,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

