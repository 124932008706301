/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-11-15 13:08:37 */

const Keys = {
  callUs: 'callUs',
  enquire: 'enquire',
  chatWithUs: 'chatWithUs',
  label: 'label',
  phone: 'phone',
  mail: 'mail'
}; export default Keys;