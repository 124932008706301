import {
  ApiAirportComponent,
  ReducerStateItem,
  ReducerStateItems
} from '@/components/Search/searchReducer/types';
import {
  ApiProductsCacheData,
  ApiProductsCacheResponseCountry,
  ApiProductsCacheResponseTravelTypes
} from '@ibe/api';
import dayjs from 'dayjs';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { getUrlWithUpdatedQueryParams } from '@/components/Search/helpers';

export const meetsTravelTypeConditions = (
  travelTypeItems: Array<[string, ReducerStateItem]>,
  travelTypes?: ApiProductsCacheResponseTravelTypes[]
): boolean => {
  return (
    travelTypeItems.length === 0 ||
    travelTypeItems.some(
      ([id]) => !travelTypes || travelTypes.some(travelType => travelType.id === id)
    )
  );
};

export const meetsCountryConditions = (
  continentItems: Array<[string, ReducerStateItem]>,
  countries?: ApiProductsCacheResponseCountry[]
): boolean => {
  return (
    continentItems.length === 0 ||
    continentItems.some(
      ([, item]) => !countries || countries.some(country => !!item.countries?.[country?.id || ''])
    )
  );
};

export const meetsAirportConditions = (
  airportItems: Array<[string, ReducerStateItem]>,
  products?: ApiProductsCacheData[]
): boolean => {
  return (
    airportItems.length === 0 ||
    airportItems.some(([, item]) =>
      item.differentAirportNames?.some(airportName =>
        products?.some(product => product.departureAirport === airportName)
      )
    )
  );
};

export const meetsDateConditions = (
  dateItems: Array<[string, ReducerStateItem]>,
  products?: ApiProductsCacheData[]
): boolean => {
  return (
    dateItems.length === 0 ||
    dateItems.some(([id]) => {
      const dateItemYear = id.split('-')[0];
      const dateItemMonth = id.split('-')[1];
      return products?.some(product => {
        const { productDateYear, productDateMonth } = getCacheDataProductYearMonth(product);
        return dateItemYear === productDateYear && dateItemMonth === productDateMonth;
      });
    })
  );
};

export const getCacheDataProductYearMonth = (
  product: ApiProductsCacheData
): { productDateYear: string; productDateMonth: string } => {
  const productDate = dayjs(product.travelStartDate);
  const productDateYear = productDate?.get('year')?.toString() || '';
  let productDateMonth = productDate?.get('month')?.toString() || '';
  return { productDateYear, productDateMonth };
};

export const doesAirportIncludeName = (
  airport: ApiAirportComponent,
  packageAirportName: string
): boolean => {
  return airport.differentAirportNames.some(
    airportName =>
      airportName === packageAirportName.trim() ||
      airportName.includes(packageAirportName.trim()) ||
      packageAirportName.trim().includes(airportName)
  );
};

export const getCacheDataProductsFilteredByDate = (
  dateItems: Array<[string, ReducerStateItem]>,
  products?: ApiProductsCacheData[]
): ApiProductsCacheData[] => {
  return (
    products?.filter(product => {
      const { productDateYear, productDateMonth } = getCacheDataProductYearMonth(product);
      return (
        dateItems.length === 0 ||
        !!dateItems.find(
          ([id]) => productDateYear === id.split('-')[0] && productDateMonth === id.split('-')[1]
        )
      );
    }) || []
  );
};

export const getCacheDataProductsFilteredByDateAndAirport = (
  dateItems: Array<[string, ReducerStateItem]>,
  airportItems: Array<[string, ReducerStateItem]>,
  products?: ApiProductsCacheData[]
): ApiProductsCacheData[] => {
  return (
    products?.filter(product => {
      const { productDateYear, productDateMonth } = getCacheDataProductYearMonth(product);
      return (
        (dateItems.length === 0 ||
          !!dateItems.find(
            ([id]) => productDateYear === id.split('-')[0] && productDateMonth === id.split('-')[1]
          )) &&
        (airportItems.length === 0 ||
          !!airportItems.find(([, airportItem]) =>
            doesAirportIncludeName(
              {
                id: '0',
                name: '0',
                products: 0,
                differentAirportNames: airportItem.differentAirportNames || [airportItem.name]
              },
              product.departureAirport || ''
            )
          ))
      );
    }) || []
  );
};

export const updateUrl = (
  items: ReducerStateItems,
  searchParams: ReadonlyURLSearchParams,
  pathName: string,
  window?: Window | null
): void => {
  const newUrl = getUrlWithUpdatedQueryParams(items, searchParams, pathName);
  window?.history?.replaceState({ ...window?.history?.state, as: newUrl, url: newUrl }, '', newUrl);
};
