import React, { FC, JSX, PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCross } from '@/Theme/SVG/Icons';
import classNames from 'classnames';

const Pill: FC<PropsWithChildren<{ onClick: () => void; disabled?: boolean }>> = ({
  onClick,
  children,
  disabled
}): JSX.Element => {
  return (
    <div className={classNames('pill', { 'pill--disabled': disabled })} onClick={onClick}>
      <span>{children}</span>
      <FontAwesomeIcon icon={facCross} />
    </div>
  );
};

export default Pill;
