/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiNavigationItem,
        ApiNavigationItemFromJSON,
        ApiNavigationItemFromJSONTyped,
        ApiNavigationItemToJSON,
    } from './';

/**
* 
* @export
* @interface ApiFooterContent
*/
export interface ApiFooterContent {
    /**
    * 
    * @type {string}
    * @memberof ApiFooterContent
    */
    groupName?: string;
    /**
    * 
    * @type {Array<ApiNavigationItem>}
    * @memberof ApiFooterContent
    */
    navigationItem?: Array<ApiNavigationItem>;
}

export function ApiFooterContentFromJSON(json: any): ApiFooterContent {
return ApiFooterContentFromJSONTyped(json, false);
}

export function ApiFooterContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiFooterContent {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'groupName': json['groupName'],
                'navigationItem': ((json['navigationItem'] as Array<any>).map(ApiNavigationItemFromJSON)),
        };
    }

    export function ApiFooterContentToJSON(value?: ApiFooterContent | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'groupName': value.groupName,
                    'navigationItem': ((value.navigationItem as Array<any>).map(ApiNavigationItemToJSON)),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

