import React, { FC, Fragment, JSX } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { faHouse, faPlus } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { getDefaultFormatPrice } from '@/Util/globals';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import classNames from 'classnames';
import { AllItems } from '@/components/checkout/cart/getAllItems';
import CheckoutStore from '@/templates/checkout/CheckoutStore';

const Accommodation: FC<{ allItems: AllItems; checkoutStore: CheckoutStore }> = observer(
  function Accommodation({ allItems, checkoutStore }): JSX.Element {
    const language = useCurrentLanguage();
    const { t } = useTranslation('Checkout');

    const {
      accommodations: { hotelsAndRooms, totalPricePerPerson }
    } = allItems;

    return hotelsAndRooms.length > 0 ? (
      <>
        <hr />
        <div className="cart__headline">{t(Keys.accommodation)}</div>
        <div className="checkout__data">
          {hotelsAndRooms.map(hotel => {
            return (
              <Fragment key={hotel.hotel.id}>
                <div className="checkout__data__section">
                  <FontAwesomeIcon icon={faHouse} />
                  <span className="font-weight-bold">{hotel.hotel.name}</span>
                </div>
                {hotel.rooms.map(room => {
                  return (
                    <div key={room.id}>
                      <div className="checkout__data__accommodation">
                        <FontAwesomeIcon icon={faPlus} />
                        <div>{room.description}</div>
                      </div>
                      {!!room.roomrates?.[0]?.totalPrice?.finalPrice
                        ? getDefaultFormatPrice(
                            room.roomrates[0].totalPrice.finalPrice /
                              (checkoutStore.booking?.travelers?.length || 1),
                            language
                          )
                        : t(Keys.includedAlt)}
                    </div>
                  );
                })}
              </Fragment>
            );
          })}
          <div className="checkout__data__price">
            <FontAwesomeIcon icon={faPlus} />
            <div>
              <div>{t(Keys.surcharge)}</div>
              <div
                className={classNames({
                  checkout__data__included: !totalPricePerPerson?.finalPrice
                })}
              >
                {!!totalPricePerPerson?.finalPrice
                  ? getDefaultFormatPrice(totalPricePerPerson.finalPrice, language)
                  : t(Keys.includedAlt)}
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <></>
    );
  }
);

export default Accommodation;
