'use client';

import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import AccordionSection from '@/components/accordion/AccordionSection';
import AccordionElementList from '@/components/accordion/AccordionElementList';

export interface AccordionData {
  groupHeader?: string | ReactElement | undefined;
  group?: Array<SectionData> | undefined;
}

export interface SectionData {
  sectionHeader?: string;
  sectionText?: string | ReactElement;
}

const SimpleAccordion = ({
  groups,
  externalSelectedAccordion,
  externalSetSelectedAccordion,
  externalPrefix
}: {
  groups: Array<AccordionData>;
  externalSelectedAccordion?: string | null;
  externalSetSelectedAccordion?: Dispatch<SetStateAction<string | null>>;
  externalPrefix?: number;
}) => {
  return (
    <div className="accordion">
      <AccordionElementList
        items={groups}
        bodyCb={group => <AccordionSection group={group?.group} />}
        headerCb={group => <>{group?.groupHeader}</>}
        externalSelectedAccordion={externalSelectedAccordion}
        externalSetSelectedAccordion={externalSetSelectedAccordion}
        externalPrefix={externalPrefix}
      />
    </div>
  );
};
export default SimpleAccordion;
