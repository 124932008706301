import { TFunction } from 'i18next';
import Keys from '@/Translations/generated/da/continentMap.json.keys';
import { EditableAreaOrPageChild } from '@/types/cms/magnolia';
import { MagnoliaContinentNode } from '@/types/cms/continent';

export interface ContinentMapProps extends EditableAreaOrPageChild {
  europeLink?: MagnoliaContinentNode;
  africaLink?: MagnoliaContinentNode;
  middleAmericaLink?: MagnoliaContinentNode;
  northAmericaLink?: MagnoliaContinentNode;
  antarcticaLink?: MagnoliaContinentNode;
  asiaLink?: MagnoliaContinentNode;
  australiaLink?: MagnoliaContinentNode;
  caribbeanLink?: MagnoliaContinentNode;
  middleEastLink?: MagnoliaContinentNode;
  southAmericaLink?: MagnoliaContinentNode;
  continentData: ContinentData[];
}

export interface ContinentData {
  name: Continents;
  link?: MagnoliaContinentNode;
  productCount: number;
}

export interface ContinentDataExtended extends Omit<ContinentData, 'productCount'> {
  label: string;
  top: string;
  left: string;
}

export enum Continents {
  WORLD = 'world',
  EUROPE = 'europe',
  AFRICA = 'africa',
  MIDDLE_AMERICA = 'middleAmerica',
  NORTH_AMERICA = 'northAmerica',
  SOUTH_AMERICA = 'southAmerica',
  ANTARCTICA = 'antarctica',
  AUSTRALIA = 'australia',
  CARIBBEAN = 'caribbean',
  MIDDLE_EAST = 'middleEast',
  ASIA = 'asia'
}

const continentCoordinates = {
  [Continents.WORLD]: { top: '', left: '' },
  [Continents.EUROPE]: { top: '17.94%', left: '52.17%' },
  [Continents.AFRICA]: { top: '40.33%', left: '55.37%' },
  [Continents.MIDDLE_AMERICA]: { top: '31.33%', left: '21.37%' },
  [Continents.NORTH_AMERICA]: { top: '18.33%', left: '26.37%' },
  [Continents.SOUTH_AMERICA]: { top: '57.33%', left: '35.37%' },
  [Continents.ANTARCTICA]: { top: '94.33%', left: '55.37%' },
  [Continents.AUSTRALIA]: { top: '63.33%', left: '85.37%' },
  [Continents.CARIBBEAN]: { top: '37.33%', left: '32.37%' },
  [Continents.MIDDLE_EAST]: { top: '31.33%', left: '62.37%' },
  [Continents.ASIA]: { top: '29.33%', left: '79.37%' }
};

export const getContinentData = (
  t: TFunction,
  props: ContinentMapProps,
  continentData: ContinentData[]
): ContinentDataExtended[] => {
  return Object.values(Continents).map(continent => {
    return {
      name: continent,
      label: t((Keys as Record<string, string>)[continent]),
      link:
        continent !== Continents.WORLD
          ? ({
              ...props[`${continent}Link`],
              name: props?.[`${continent}Link`]?.name || '',
              metaInformation: continentData.find(
                item => item.link?.id === props?.[`${continent}Link`]?.['@id']
              )?.link?.metaInformation
            } as MagnoliaContinentNode)
          : undefined,
      top: continentCoordinates[continent].top,
      left: continentCoordinates[continent].left
    };
  });
};
