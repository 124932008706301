/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiCta,
        ApiCtaFromJSON,
        ApiCtaFromJSONTyped,
        ApiCtaToJSON,
    } from './';

/**
* 
* @export
* @interface ApiContentAreaContent
*/
export interface ApiContentAreaContent {
    /**
    * 
    * @type {string}
    * @memberof ApiContentAreaContent
    */
    title?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContentAreaContent
    */
    richText?: string;
    /**
    * 
    * @type {ApiCta}
    * @memberof ApiContentAreaContent
    */
    cta?: ApiCta;
}

export function ApiContentAreaContentFromJSON(json: any): ApiContentAreaContent {
return ApiContentAreaContentFromJSONTyped(json, false);
}

export function ApiContentAreaContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiContentAreaContent {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'title': json['title'],
                    'richText': json['richText'],
                            'cta': ApiCtaFromJSON(json['cta']),
        };
    }

    export function ApiContentAreaContentToJSON(value?: ApiContentAreaContent | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'title': value.title,
                'richText': value.richText,
                            'cta': ApiCtaToJSON(value.cta),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

