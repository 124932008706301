import React, { useState, ReactElement } from 'react';
import ImageSlider from '@/components/imageSlider/ImageSlider';
import sanitize from '@/Util/sanitize';
import Keys from '@/Translations/generated/da/extensionTeaser.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import AccordionElement from '@/components/accordion/AccordionElement';
import { ApiExtension } from '../../../api/model';
import { Props as PageProps } from '@/types/cms/magnolia';

interface Props {
  teaser: ApiExtension;
  pageProps?: PageProps;
}

const OffcanvasContent = (props: Props): ReactElement => {
  const { teaser, pageProps } = props;
  const { t } = useTranslation('extensionTeaser');
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openHandler = () => setIsOpen(!isOpen);

  return (
    <div className={`offcanvas__content`}>
      <div>
        <div className="offcanvas__content__image-slider grid-col-12">
          {teaser.additionalImages && (
            <ImageSlider images={teaser.additionalImages} pageProps={pageProps} />
          )}
        </div>
        {teaser.description && (
          <div
            className="offcanvas__content__description-left grid-col-12 grid-col-md-5"
            dangerouslySetInnerHTML={{
              __html: sanitize(teaser.description, true)
            }}
          ></div>
        )}
        {teaser.priceDetails && (
          <div className="offcanvas__content__price-section grid-col-12">
            <div>
              {isDesktop ? (
                <div className="offcanvas__content__price-section__content grid-col-12 grid-col-md-8-4">
                  <div
                    dangerouslySetInnerHTML={{ __html: sanitize(teaser.priceDetails, true) }}
                  ></div>
                </div>
              ) : (
                <div className="grid-col-12">
                  <AccordionElement
                    isOpen={isOpen}
                    openHandler={() => openHandler}
                    accordionBody={
                      <div className="offcanvas__content__price-section__content grid-col-12 grid-col-md-8-4">
                        <div
                          dangerouslySetInnerHTML={{ __html: sanitize(teaser.priceDetails, true) }}
                        ></div>
                      </div>
                    }
                    accordionHeader={<>{t(Keys.priceIncludes)}</>}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {teaser.bookingInfo && (
          <div className="offcanvas__content__booking-info grid-col-12">
            <div>
              {isDesktop ? (
                <div className="offcanvas__content__booking-info__content grid-col-12 grid-col-md-8-4">
                  <div
                    dangerouslySetInnerHTML={{ __html: sanitize(teaser.bookingInfo, true) }}
                  ></div>
                </div>
              ) : (
                <div className="grid-col-12">
                  <AccordionElement
                    isOpen={isOpen}
                    openHandler={() => openHandler}
                    accordionBody={
                      <div className="offcanvas__content__booking-info__content grid-col-12 grid-col-md-8-4">
                        <div
                          dangerouslySetInnerHTML={{ __html: sanitize(teaser.bookingInfo, true) }}
                        ></div>
                      </div>
                    }
                    accordionHeader={<>{t(Keys.bookingInfo)}</>}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OffcanvasContent;
