import { SelectOption } from '@/components/Select/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import dayjs from 'dayjs';
import React from 'react';
import { ApiProductsCacheData } from '@ibe/api';
import utc from 'dayjs/plugin/utc';
import { TFunction } from 'i18next';
import { ConfigModel } from '@ibe/services';

dayjs.extend(utc);

export const getParticipantCount = (pacificProduct?: ApiProductsCacheData): number => {
  return parseInt(pacificProduct?.serviceUnitCode?.split('')?.[0] || '0', 10);
};

export type OptionsData = {
  participants: SelectOption<number>[];
  departureDates: SelectOption<string>[];
  departureAirports: SelectOption<string>[];
};

export type SelectedOptions = {
  participants: number;
  departureDate: string;
  departureAirport: string;
};

export const getOptionsData = (
  t: TFunction,
  config: ConfigModel,
  language: string,
  pacificProducts?: ApiProductsCacheData[],
  selectedPacificProduct?: ApiProductsCacheData
): OptionsData => ({
  participants: (
    pacificProducts
      ?.filter(
        product =>
          !selectedPacificProduct ||
          selectedPacificProduct.travelStartDate === product.travelStartDate
      )
      .map(product => ({
        label: (
          <div className="product-selection__option">
            <FontAwesomeIcon icon={faUser} />
            <span>{t(Keys.traveler, { count: getParticipantCount(product) })}</span>
          </div>
        ),
        value: getParticipantCount(product)
      })) || []
  )
    .filter(
      (option, idx, all) => all.findIndex(localOption => localOption.value === option.value) === idx
    )
    .sort((a, b) => a.value - b.value),
  departureDates: (
    pacificProducts?.map(product => ({
      label: dayjs.utc(product.travelStartDate).isValid()
        ? dayjs.utc(product.travelStartDate).format(config.displayFormatDateShort[language])
        : product.travelStartDate || '',
      value: product.travelStartDate || ''
    })) || []
  )
    .filter(
      (option, idx, all) => all.findIndex(localOption => localOption.value === option.value) === idx
    )
    .sort((a, b) =>
      dayjs(a.value).isValid() && dayjs(b.value).isValid()
        ? dayjs(a.value).unix() - dayjs(b.value).unix()
        : 0
    ),
  departureAirports: (
    pacificProducts
      ?.filter(
        product =>
          !selectedPacificProduct ||
          selectedPacificProduct.travelStartDate === product.travelStartDate
      )
      .map(product => ({
        label: product.departureAirport || '',
        value: product.departureAirport || ''
      })) || []
  )
    .filter(
      (option, idx, all) => all.findIndex(localOption => localOption.value === option.value) === idx
    )
    .sort((a, b) => a.label.localeCompare(b.value))
});

export const getInitialSelectedOptions = (
  selectedPacificProduct?: ApiProductsCacheData
): SelectedOptions => ({
  participants: getParticipantCount(selectedPacificProduct),
  departureDate: selectedPacificProduct?.travelStartDate || '',
  departureAirport: selectedPacificProduct?.departureAirport || ''
});
