'use client';

import React, {
  createContext,
  Dispatch,
  FC,
  FormEvent,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FieldSets } from '@/components/magnoliaForm/types';
import { useTranslation } from '@/app/i18n/client';
import { ValidationError } from 'yup';
import { useWindow } from '@ibe/components';
import { useApi } from '@/Hooks/useApi';
import { ApiSendEmailMessageRequestFromJSON } from '@ibe/api';
import { createValidationSchema, getFormData } from '@/components/magnoliaForm/validate';

import { ERROR_CLASS } from '@/components/magnoliaForm/Field';
import { logger, scrollIntoViewWithOffset } from '@/Util/globals';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

export type MagnoliaFormContextType = {
  isDirty: boolean;
  validationErrors: Record<string, string[]>;
  requiredSymbol: string;
  isLoading: boolean;
};

const MagnoliaFormContext = createContext<MagnoliaFormContextType>({
  isDirty: false,
  validationErrors: {},
  requiredSymbol: '*',
  isLoading: false
});

export const useMagnoliaFormContext = (): MagnoliaFormContextType => {
  return useContext<MagnoliaFormContextType>(MagnoliaFormContext);
};

export type FormProps = PropsWithChildren<{
  fieldsets: FieldSets;
  requiredSymbol?: string;
  formTitle?: string;
  errorTitle?: string;
  successTitle?: string;
  successMessage?: string;
  emailSubject?: string;
  emailText?: string;
  emailReceiver: string;
  reopenFormButtonText?: string;
}>;

let timer1: ReturnType<typeof setTimeout> | null = null;
let timer2: ReturnType<typeof setTimeout> | null = null;
let timer3: ReturnType<typeof setTimeout> | null = null;

const Form: FC<
  FormProps & {
    formSentState: 'default' | 'success' | 'error';
    setFormSentState: Dispatch<SetStateAction<'default' | 'success' | 'error'>>;
    setIsFormVisible: Dispatch<SetStateAction<boolean>>;
  }
> = ({
  fieldsets,
  children,
  requiredSymbol = '*',
  errorTitle,
  formSentState,
  setFormSentState,
  setIsFormVisible,
  formTitle,
  emailSubject,
  emailText,
  emailReceiver
}): JSX.Element => {
  const { t } = useTranslation('magnoliaForm');
  const { isAuthor } = useGlobalMGLProps() || {};
  const api = useApi(isAuthor);
  const window = useWindow();
  const [validationErrors, setValidationErrors] = useState<Record<string, string[]>>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    return () => {
      if (!!timer1) {
        clearTimeout(timer1);
      }
      if (!!timer2) {
        clearTimeout(timer2);
      }
      if (!!timer3) {
        clearTimeout(timer3);
      }
    };
  }, []);

  const { validationSchema, fieldNames } = useMemo(() => {
    return createValidationSchema(fieldsets, t);
  }, [fieldsets]);

  const validateForm = async (event: FormEvent<HTMLFormElement>): Promise<boolean> => {
    const formData = getFormData(event, fieldNames);
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setValidationErrors({});
      return true;
    } catch (e) {
      if (e instanceof ValidationError) {
        const validationErrors: Record<string, string[]> = {};
        e.inner.forEach(error => {
          if (error.path) {
            if (!validationErrors[error.path]) {
              validationErrors[error.path] = [];
            }
            validationErrors[error.path].push(error.message);
          }
        });
        setValidationErrors(validationErrors);
      }
      return false;
    }
  };

  const submitForm = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setIsDirty(true);
    const currentEvent = { ...event };
    const isValid = await validateForm(event);
    if (isValid) {
      setIsLoading(true);
      logger()(getFormData(currentEvent, fieldNames, true, true));
      try {
        await api.sendMessage(
          ApiSendEmailMessageRequestFromJSON({
            formData: getFormData(currentEvent, fieldNames, true),
            emailSubject: emailSubject || formTitle || '',
            emailReceiver: emailReceiver,
            emailTextHeadline: emailText || ''
          })
        );
        setFormSentState('success');
        if (!!formRef.current) {
          formRef.current.reset();
        }
        setIsDirty(false);
        timer1 = setTimeout(() => {
          setIsFormVisible(false);
          timer2 = setTimeout(() => {
            scrollIntoViewWithOffset('.magnolia-form', window, 150);
          }, 250);
        }, 250);
      } catch (err) {
        logger('error')('Unable to send form: ', err);
        setFormSentState('error');
      } finally {
        setIsLoading(false);
      }
    } else {
      timer3 = setTimeout(() => {
        const error = window?.document?.querySelector(`.${ERROR_CLASS}`);
        if (!!error) {
          scrollIntoViewWithOffset(`.${ERROR_CLASS}`, window, 150);
        }
      }, 500);
    }
  };

  return (
    <form ref={formRef} onSubmit={submitForm} onChange={validateForm} onBlur={validateForm}>
      <MagnoliaFormContext.Provider
        value={{ isDirty, validationErrors, requiredSymbol, isLoading }}
      >
        {children}
        {formSentState === 'error' && !!errorTitle && (
          <div className="magnolia-form__form-state magnolia-form__form-state--error">
            {errorTitle}
          </div>
        )}
      </MagnoliaFormContext.Provider>
    </form>
  );
};

export default Form;
