import React, { FC } from 'react';
import classNames from 'classnames';
import { CheckboxProps } from '@/components/Search/Checkbox';
import Radio from '@/components/Search/Radio';

const RadioResults: FC<CheckboxProps & { results?: number; isLoading?: boolean }> = ({
  results,
  label,
  isLoading,
  ...rest
}) => {
  const { checked } = rest;

  return (
    <div className="radio-results">
      <Radio
        {...rest}
        disabled={results === 0 || isLoading}
        label={
          <>
            <div>{label}</div>
            {results !== undefined && (
              <div
                className={classNames('checkbox-results__amount', {
                  'checkbox-results__amount--checked': checked,
                  'checkbox-results__amount--disabled': results === 0
                })}
              >
                {results}
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

export default RadioResults;
