/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-11-15 13:08:37 */

const Keys = {
  validationRequired: 'validationRequired',
  validationEmail: 'validationEmail',
  validationConfirmEmail: 'validationConfirmEmail',
  validationCheckboxGroupRequired: 'validationCheckboxGroupRequired',
  validationNumber: 'validationNumber',
  reopenFormButtonText: 'reopenFormButtonText'
}; export default Keys;