'use client';

import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { facChatBubble, facPhone } from '@/Theme/SVG/Icons';
import Keys from '@/Translations/generated/da/ContactBar.json.keys';
import { useTransition } from 'react-transition-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@/app/i18n/client';

interface ContactBarPropsCMS {
  theme: string;
}

const ContactBar = (props: ContactBarPropsCMS) => {
  const { theme } = props;
  const { t } = useTranslation('ContactBar');

  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 600,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    const handleScroll = debounce(() => {
      const { innerHeight, scrollY, document } = window;

      if (scrollY + innerHeight / 2 + 200 > document.documentElement.scrollHeight / 2) {
        toggle(true);
      } else {
        toggle(false);
      }
      setPrevScrollPos(scrollY);
    }, 100);

    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    toggle(false);
  }, []);

  return (
    <>
      {isMounted && (
        <div
          className={`contact-bar ${theme}${
            status === 'preEnter' || status === 'exiting' ? ' contact-bar--hidden' : ''
          }`}
        >
          <div className="contact-bar__text">
            <span>{t(Keys.label)}</span>
          </div>
          <div className="contact-bar__contact">
            <span className="contact-bar__contact-box">
              <FontAwesomeIcon icon={facPhone} />
              <a href={`tel:${t(Keys.phone)}`} className="contact-bar__contact-text">
                <span className="contact-bar__contact-text--mobile">{t(Keys.callUs)}</span>
                <span className="contact-bar__contact-text--desktop">{t(Keys.phone)}</span>
              </a>
            </span>
            <div className="separator" />
            <span className="contact-bar__contact-box">
              <FontAwesomeIcon icon={facChatBubble} />
              <a href={`mailto:${t(Keys.mail)}`} className="contact-bar__contact-text">
                <span className="contact-bar__contact-text--mobile">{t(Keys.enquire)}</span>
                <span className="contact-bar__contact-text--desktop">{t(Keys.chatWithUs)}</span>
              </a>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactBar;
