import React, { ChangeEvent, FC, JSX, useEffect } from 'react';
import Keys from '@/Translations/generated/da/search.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import { useTransition } from 'react-transition-state';
import TextSearchResults from '@/components/Search/TextSearchResults';
import { Spinner } from '@ibe/components';
import { ApiProductComponent } from '@ibe/api';

export const getProductDisplayUrl = (product: ApiProductComponent): string => {
  const split = product?.displayUrl?.split('/') || [];
  return split[split.length - 1];
};

const TextSearchTab: FC<
  Pick<
    SearchProps,
    | 'inputValue'
    | 'handleInputChange'
    | 'totalNumberOfProducts'
    | 'textSearch'
    | 'textSearchInputRef'
    | 'goToResultsPage'
    | 'isLoading'
    | 'productPageBasePath'
    | 'resultsPageBasePath'
  >
> = ({
  inputValue,
  handleInputChange,
  totalNumberOfProducts,
  textSearch,
  textSearchInputRef,
  resultsPageBasePath,
  isLoading,
  productPageBasePath
}): JSX.Element => {
  const { t } = useTranslation('search');
  const { products: textSearchProducts } = textSearch;

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    toggle(!!textSearchProducts && textSearchProducts.length > 0);
  }, [textSearchProducts]);

  return (
    <div className="search__content__container">
      <div className="search__content search__content__input w-100">
        <input
          ref={textSearchInputRef}
          placeholder={t(Keys.inputPlaceholder, { count: totalNumberOfProducts })}
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => handleInputChange(e.target.value)}
        />
        {isLoading && (
          <div className="search__content__spinner">
            <Spinner />
          </div>
        )}
        {isMounted && (
          <TextSearchResults
            textSearch={textSearch}
            productPageBasePath={productPageBasePath}
            resultsPageBasePath={resultsPageBasePath}
            status={status}
          />
        )}
      </div>
    </div>
  );
};

export default TextSearchTab;
