import { ApiProductsCacheResponse } from '@ibe/api';

export type PayloadType = 'continent' | 'travelType' | 'date' | 'airport' | 'none';

export enum Action {
  ADD_OR_REMOVE_SINGLE = 'ADD_OR_REMOVE_SINGLE',
  ADD_OR_REMOVE_ALL_PER_TYPE = 'ADD_OR_REMOVE_ALL_PER_TYPE',
  ADD_OR_REMOVE_ALL = 'ADD_OR_REMOVE_ALL',
  REPLACE_STATE = 'REPLACE_STATE'
}

export type ReducerStateItem = {
  type: PayloadType;
  name: string;
  countries?: { [id: string]: string };
  differentAirportNames?: string[];
};

export type ReducerStateItems = Record<string, ReducerStateItem>;

export type ReducerState = {
  productPackagesTotal: ApiProductsCacheResponse[];
  productPackagesFiltered: ApiProductsCacheResponse[];
  items: ReducerStateItems;
  continents: ApiContinentComponent[];
  travelTypes: ApiTravelTypeComponent[];
  dates: ApiDatesSearchResponseDates[];
  airports: ApiAirportComponent[];
  totalProductsForCountry: number;
  totalProductsForTravelType: number;
  totalProductsForAirport: number;
  totalProductsOverall: number;
};

export type Payload = {
  id: string;
  type: PayloadType;
  name: string;
  items?: Array<{ id: string; name: string; differentAirportNames?: string[] }>;
  checkboxState?: ReducerState;
  differentAirportNames?: string[];
};

export type ReducerAction = {
  type: Action;
  payload: Payload;
};

export type ApiCountryComponent = {
  id: string;
  name: string;
  products: number;
  continent?: string;
};

export type ApiContinentComponent = {
  id: string;
  name: string;
  products: number;
  countries: ApiCountryComponent[];
};

export type ApiTravelTypeComponent = {
  id: string;
  name: string;
  products: number;
};

export type ApiAirportComponent = {
  id: string;
  name: string;
  products: number;
  differentAirportNames: string[];
};

export type NumberArray<NumbersLength extends number> = [number, ...number[]] & {
  length: NumbersLength;
};

export type ApiDatesSearchResponseDates = {
  year: string;
  months: NumberArray<12>;
};
