import React, { FC, ReactElement, useRef } from 'react';
import { useClickAway } from 'react-use';
import classNames from 'classnames';
import { CrossSmall } from '@/Theme/SVG/Svgs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretDownThin } from '@/Theme/SVG/Icons';
import { useTransition } from 'react-transition-state';

export type SelectOption<T> = {
  label: string | ReactElement;
  value: T;
};

interface Props<T> {
  placeholder?: string;
  value: T;
  onChange: (value: T) => void;
  options: SelectOption<T>[];
  className?: string;
}

const Select = <T,>({
  placeholder,
  value,
  onChange,
  options,
  className
}: Props<T>): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);

  useClickAway(containerRef, (): void => {
    toggle(false);
  });

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  const handleClick = (): void => {
    toggle(!isMounted);
  };

  const handleChange = (optionValue: T): void => {
    onChange(optionValue);
    toggle(false);
  };

  return (
    <div ref={containerRef} className={`select-field ${className || ''}`}>
      <button
        className={classNames('select-field__input', { 'select-field__input--active': isMounted })}
        onClick={handleClick}
      >
        <span>
          {!!value
            ? options.find(option => option.value === value)?.label || ''
            : placeholder || ''}
        </span>
        {isMounted ? (
          <CrossSmall />
        ) : (
          <FontAwesomeIcon className="select-field__input--down" icon={facCaretDownThin} />
        )}
      </button>
      {isMounted && (
        <div
          className={classNames('select-field__options', {
            'select-field__options--hidden': status === 'preEnter' || status === 'exiting'
          })}
        >
          {options.map(({ label, value: optionValue }) => (
            <div
              key={`${optionValue}${typeof label === 'string' ? `-${label}` : ''}`}
              className={classNames('select-field__option', {
                'select-field__option--selected': value === optionValue
              })}
              onClick={(): void => handleChange(optionValue)}
            >
              <div>{label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
