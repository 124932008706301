/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
* 
* @export
* @interface ApiFactSheet
*/
export interface ApiFactSheet {
    /**
    * 
    * @type {string}
    * @memberof ApiFactSheet
    */
    startingLocation?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiFactSheet
    */
    transportation?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiFactSheet
    */
    language?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiFactSheet
    */
    priceTooltip?: string;
}

export function ApiFactSheetFromJSON(json: any): ApiFactSheet {
return ApiFactSheetFromJSONTyped(json, false);
}

export function ApiFactSheetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiFactSheet {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'startingLocation': json['startingLocation'],
                    'transportation': json['transportation'],
                    'language': json['language'],
                    'priceTooltip': json['priceTooltip'],
        };
    }

    export function ApiFactSheetToJSON(value?: ApiFactSheet | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'startingLocation': value.startingLocation,
                'transportation': value.transportation,
                'language': value.language,
                'priceTooltip': value.priceTooltip,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

