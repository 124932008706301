'use client';

import React, { ReactElement } from 'react';
import { THEMES } from '@/Util/globals';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ExpandedGallery from '@/components/expandedGallery/ExpandedGallery';
import GridContainer from '@/Layouts/GridContainer';
import { ApiImage } from '../../../api/model';
import { Props } from '@/types/cms/magnolia';

export type ImageGalleryInnerProps = {
  theme?: string;
  headline?: string;
  images: ApiImage[];
  h2Headline?: boolean;
  pageProps?: Props;
};

const ImageGalleryInner = (props: ImageGalleryInnerProps): ReactElement => {
  const { theme, headline, images, h2Headline, pageProps } = props;
  return (
    <>
      {images.length > 0 && (
        <GridContainer className={theme || THEMES.darkGreen}>
          <div className="image-gallery">
            {!!headline && (h2Headline ? <h2>{headline}</h2> : <h3>{headline}</h3>)}
            <ExpandedGallery images={images} pageProps={pageProps} />
          </div>
        </GridContainer>
      )}
    </>
  );
};

export default ImageGalleryInner;
