import React, { FC, JSX, useMemo } from 'react';
import { ApiWebsiteDestinationSearch } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link as NextLink } from '@/Hooks/useReactTransitionProgress';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Header.json.keys';

const WebsiteSearchFullDestinationList: FC<{
  results: ApiWebsiteDestinationSearch[];
  activeIndex: number;
}> = ({ results, activeIndex }): JSX.Element => {
  const { t } = useTranslation('Header');
  const tabIndex = 1;
  const sortedByPriority = useMemo((): ApiWebsiteDestinationSearch[] => {
    return results.sort((a, b) => {
      if (a.priority && b.priority) {
        return b.priority - a.priority;
      } else {
        return 0;
      }
    });
  }, [results]);

  return (
    <div
      style={activeIndex !== tabIndex ? { maxHeight: 0 } : {}}
      className="website-search-full__results__list"
    >
      {sortedByPriority.map(r => {
        return (
          <div key={r.title} className="website-search-full__results__list--row">
            <b>{r.title}</b>
            <NextLink prefetch={false} href={r.url}>
              <span>{t(Keys.learnMore)}</span>
              <FontAwesomeIcon icon={faCircleArrowRight} />
            </NextLink>
            <p>{r.matchedText}</p>
          </div>
        );
      })}
    </div>
  );
};

export default WebsiteSearchFullDestinationList;
