import React from 'react';
import FormDatePicker from './FormDatePicker';
import { DatePickerElement, FormConfig } from '@ibe/components';

interface Props {
  config: DatePickerElement;
  keySuffix?: FormConfig['keySuffix'];
}

const DatePickerWrapper = (props: Props): JSX.Element => {
  const {
    config: {
      key,
      label,
      boldLabel,
      placeholder,
      showErrorIcon,
      disabled,
      withKeyboardSupport,
      showInModal,
      displayFormat,
      minDate,
      maxDate,
      inputClassName,
      useFormValidation,
      setValueToNullWhenFormatIsIncorrect,
      modalLabel
    },
    keySuffix
  } = props;

  return (
    <FormDatePicker
      name={`${key}${keySuffix || ''}`}
      label={label}
      boldLabel={boldLabel}
      showErrors
      dataTestId=""
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      placeholderText={placeholder}
      showErrorIcon={showErrorIcon}
      disabled={disabled}
      withKeyboardSupport={withKeyboardSupport}
      showInModal={showInModal}
      displayFormat={displayFormat}
      minDate={minDate}
      maxDate={maxDate}
      inputClassName={inputClassName}
      useFormValidation={useFormValidation}
      setValueToNullWhenFormatIsIncorrect={setValueToNullWhenFormatIsIncorrect}
      modalLabel={modalLabel}
    />
  );
};

export default DatePickerWrapper;
