'use client';
import React, { FC, JSX, useEffect } from 'react';
import { PRODUCT_REVIEW_CONTAINER_ID } from '@/Util/globals';

const BVReviewsLayout: FC = (): JSX.Element => {
  useEffect(() => {
    const albProductReviewContainer = document.getElementById(PRODUCT_REVIEW_CONTAINER_ID);
    const reviewsElement = document.getElementById('reviews');
    if (albProductReviewContainer && reviewsElement) {
      albProductReviewContainer.style.padding = '0';
      reviewsElement.style.minHeight = '0';
    }
    const showReviews = () => {
      if (albProductReviewContainer && reviewsElement) {
        const contentDiv = albProductReviewContainer.querySelector('div');
        if (contentDiv?.offsetHeight && contentDiv.offsetHeight > 0) {
          albProductReviewContainer.style.padding =
            'clamp(2rem, 2.857vw + 1.43rem, 4rem) clamp(1.5rem, 9.286vw + -0.36rem, 8rem)';
          reviewsElement.style.minHeight = '22rem';
          clearInterval(interval);
        }
      }
    };
    const interval = setInterval(showReviews, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <></>;
};

export default BVReviewsLayout;
