import React, { ReactElement } from 'react';
import { constants } from '@/components/magnoliaPage/componentHelper';

function DefaultFallbackComponent(props: {
  metadata: Record<string, string>;
  data: object;
}): ReactElement {
  const { metadata = {}, data = {} } = props;

  const trimIfObject = (value: string): string => {
    if (value === '[object Object]') {
      return '[object]';
    }
    return value;
  };

  const formatValue = (value: string): string => {
    return trimIfObject(` ${value}`);
  };

  const getTemplateId = (): string => {
    return metadata[constants.TEMPLATE_ID_PROP];
  };

  const propItems = Object.entries(data).map(([key, value]) => (
    <div key={key}>
      {key}: {formatValue(value)}
    </div>
  ));

  return (
    <div>
      <div>
        <strong>
          <em>Template not found: {getTemplateId()}</em>
        </strong>
      </div>
      {propItems}
      <p />
    </div>
  );
}

export default DefaultFallbackComponent;
