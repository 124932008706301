'use client';

import React, { ReactElement } from 'react';
import { Button, Card, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretRightThin, facMinus } from '@/Theme/SVG/Icons';
import classNames from 'classnames';

interface AccordionElementProps {
  accordionBody: ReactElement;
  accordionHeader: ReactElement;
  isOpen: boolean;
  openHandler: () => void;
}

const AccordionElement = ({
  accordionBody,
  accordionHeader,
  isOpen,
  openHandler
}: AccordionElementProps) => (
  <div className="accordion-element__print">
    <div className="accordion-element">
      <Button className="accordion-element__button" onClick={openHandler}>
        <div className="accordion-element__group__header">{accordionHeader}</div>
        {isOpen ? (
          <FontAwesomeIcon
            className={classNames('accordion-element__chevron', {
              'accordion-element__chevron--up': isOpen
            })}
            icon={facMinus}
          />
        ) : (
          <FontAwesomeIcon
            className="accordion-element__chevron accordion-element__chevron--down"
            icon={facCaretRightThin}
          />
        )}
      </Button>
      <Collapse isOpen={isOpen}>
        <Card className="accordion-element__card">
          {!!accordionBody ? <>{accordionBody}</> : <></>}
        </Card>
      </Collapse>
    </div>
    <div className="accordion-element__section__print">
      <div className="accordion-element__group__header">{accordionHeader}</div>
      <div className="accordion-element__body">
        {!!accordionBody ? <>{accordionBody}</> : <></>}
      </div>
    </div>
  </div>
);

export default AccordionElement;
