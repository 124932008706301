/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiFooterContent,
        ApiFooterContentFromJSON,
        ApiFooterContentFromJSONTyped,
        ApiFooterContentToJSON,
    } from './';

/**
* 
* @export
* @interface ApiFooterContentItems
*/
export interface ApiFooterContentItems {
    /**
    * 
    * @type {ApiFooterContent}
    * @memberof ApiFooterContentItems
    */
    footerTopLeft?: ApiFooterContent;
    /**
    * 
    * @type {ApiFooterContent}
    * @memberof ApiFooterContentItems
    */
    footerTopRight?: ApiFooterContent;
}

export function ApiFooterContentItemsFromJSON(json: any): ApiFooterContentItems {
return ApiFooterContentItemsFromJSONTyped(json, false);
}

export function ApiFooterContentItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiFooterContentItems {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                            'footerTopLeft': ApiFooterContentFromJSON(json['footerTopLeft']),
                            'footerTopRight': ApiFooterContentFromJSON(json['footerTopRight']),
        };
    }

    export function ApiFooterContentItemsToJSON(value?: ApiFooterContentItems | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                            'footerTopLeft': ApiFooterContentToJSON(value.footerTopLeft),
                            'footerTopRight': ApiFooterContentToJSON(value.footerTopRight),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

