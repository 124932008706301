export enum Language {
  DA = 'da',
  FI = 'fi',
  NO = 'no',
  PL = 'pl',
  SV = 'sv'
}
export enum Currency {
  EUR = 'EUR'
}

export enum ConfigUrlParams {
  LANGUAGE = 'lng',
  CURRENCY = 'cur'
}

export const languages = Object.values(Language);
export const languageSelection = [Language.DA, Language.FI, Language.NO, Language.PL, Language.SV];
export const currency = Object.values(Currency);
