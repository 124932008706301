import React, { FC, useState } from 'react';
import { ApiHotel } from '@ibe/api';
import { ApiAccommodation, ApiAccommodationFromJSON, ApiExtra } from '../../../api/model';
import Image from '@/components/image/Image';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { default404Image } from '@ibe/components';
import TruncateContent from '@/components/truncateContent/TruncateContent';
import AccommodationContent from '@/components/accommodations/AccommodationContent';
import IsoModal from '@/components/IsoModal';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { THEMES } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

const OperaTeaser: FC<{
  checkoutStore: CheckoutStore;
  opera: ApiHotel;
  cmsExtra?: ApiExtra;
  pageProps?: Props;
}> = ({ opera, cmsExtra, pageProps }): JSX.Element => {
  const { t } = useTranslation('Checkout');
  const [selectedOperaAsAccommodation, setSelectedOperaAsAccommodation] = useState<
    ApiAccommodation | undefined
  >(undefined);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const onTruncate = (isTruncated: boolean): void => {
    setIsTruncated(isTruncated);
  };

  return (
    <>
      <div className="checkout-teaser__container">
        <div className="checkout-teaser">
          <div className="checkout-teaser__left">
            {!!cmsExtra?.image ? (
              <Image
                src={cmsExtra?.image?.imageLink || ''}
                alt={cmsExtra?.image?.title || cmsExtra?.image?.caption || ''}
                width={cmsExtra?.image?.width}
                height={cmsExtra?.image?.height}
                serverSideContext={pageProps?.albConfig.context}
              />
            ) : (
              <Image
                src={default404Image}
                alt="default image"
                width={100}
                height={100}
                serverSideContext={pageProps?.albConfig.context}
              />
            )}
          </div>
          <div className="checkout-teaser__center">
            <h4>{cmsExtra?.name || opera.name}</h4>
            {!!cmsExtra?.description && (
              <div>
                <TruncateContent onTruncate={onTruncate}>{cmsExtra.description}</TruncateContent>
              </div>
            )}
            {!!cmsExtra?.description && isTruncated && (
              <div
                className="checkout-teaser__read-more"
                onClick={(): void =>
                  setSelectedOperaAsAccommodation(
                    ApiAccommodationFromJSON({
                      id: opera.id,
                      name: cmsExtra?.name || opera.name,
                      images: !!cmsExtra?.image ? [cmsExtra.image] : [],
                      shortDescription: cmsExtra.description,
                      rooms: []
                    })
                  )
                }
              >
                {t(Keys.readMore)}
              </div>
            )}
          </div>
          <div className="checkout-teaser__right" />
        </div>
      </div>
      <IsoModal
        className="accommodation__modal"
        toggle={(): void => setSelectedOperaAsAccommodation(undefined)}
        isOpen={!!selectedOperaAsAccommodation}
        onClose={(): void => setSelectedOperaAsAccommodation(undefined)}
        theme={THEMES.blue}
      >
        <div className="accommodation__modal__container">
          <AccommodationContent
            accommodation={selectedOperaAsAccommodation}
            simpleMode
            pageProps={pageProps}
          />
        </div>
      </IsoModal>
    </>
  );
};

export default OperaTeaser;
