import {
  getCacheDataProductsFilteredByDate,
  getCacheDataProductYearMonth,
  meetsAirportConditions,
  meetsCountryConditions,
  meetsTravelTypeConditions
} from '@/components/Search/searchReducer/helpers';
import {
  ApiDatesSearchResponseDates,
  NumberArray,
  PayloadType,
  ReducerStateItem
} from '@/components/Search/searchReducer/types';
import {
  ApiProductsCacheData,
  ApiProductsCacheResponseCountry,
  ApiProductsCacheResponseTravelTypes
} from '@ibe/api';

export const getDatesState = (
  continentItems: [string, ReducerStateItem][],
  dateItems: [string, ReducerStateItem][],
  travelTypeItems: [string, ReducerStateItem][],
  airportItems: [string, ReducerStateItem][],
  apiDates: ApiDatesSearchResponseDates[],
  filtersAreEmpty: boolean,
  payloadType: PayloadType,
  countries?: ApiProductsCacheResponseCountry[],
  travelTypes?: ApiProductsCacheResponseTravelTypes[],
  products?: ApiProductsCacheData[]
): ApiDatesSearchResponseDates[] => {
  if (
    (payloadType !== 'date' &&
      meetsCountryConditions(continentItems, countries) &&
      meetsTravelTypeConditions(travelTypeItems, travelTypes) &&
      meetsAirportConditions(airportItems, products)) ||
    filtersAreEmpty
  ) {
    const filteredCacheDataProducts = getCacheDataProductsFilteredByDate(dateItems, products);
    let monthsProductIsAddedTo: Record<string, boolean> = {};
    for (let product of filteredCacheDataProducts || []) {
      const { productDateYear, productDateMonth } = getCacheDataProductYearMonth(product);
      const yearIndex = apiDates.findIndex(apiDate => apiDate.year === productDateYear);
      const productDateMonthInt = parseInt(productDateMonth || '-1', 10);
      const monthsProductIsAddedToKey = `${productDateYear}-${productDateMonth}`;
      if (yearIndex < 0) {
        const months = Array.from(Array(12), () => 0);
        if (months[productDateMonthInt] !== undefined) {
          months[productDateMonthInt] = 1;
        }
        apiDates = [...apiDates, { year: productDateYear, months: [...months] as NumberArray<12> }];
      } else {
        const months = [...apiDates[yearIndex].months];
        if (
          !monthsProductIsAddedTo[monthsProductIsAddedToKey] &&
          months[productDateMonthInt] !== undefined
        ) {
          months[productDateMonthInt] = months[productDateMonthInt] + 1;
        }
        const foundYear = { ...apiDates[yearIndex], months: months as NumberArray<12> };
        apiDates.splice(yearIndex, 1, foundYear);
      }
      monthsProductIsAddedTo = {
        ...monthsProductIsAddedTo,
        [monthsProductIsAddedToKey]: true
      };
    }
  }

  return apiDates;
};
