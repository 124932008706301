'use client';

import React, { FC, JSX } from 'react';
import { useCheckoutContext } from '@/templates/checkout/CheckoutContextProvider';
import PageUrl from '@/Models/PageUrl';
import { AlbatrosLogoDesktopWhite2 } from '@/Theme/SVG/AlbatrosLogos';
import { Link } from '@/Hooks/useReactTransitionProgress';
import { MagnoliaAreaNode } from '@/types/cms/magnolia';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';

const CheckoutHeader: FC<{ header: MagnoliaAreaNode }> = ({ header }): JSX.Element => {
  const { isLastStep } = useCheckoutContext();

  return isLastStep && !!header ? (
    <EditableArea className="page__top__header" content={header} />
  ) : (
    <Link href={PageUrl.HOME} className="logo" prefetch={false}>
      <AlbatrosLogoDesktopWhite2 />
    </Link>
  );
};

export default CheckoutHeader;
