import React, { Dispatch, FC, RefObject, SetStateAction, useMemo } from 'react';
import { CollapseIndex, SearchProps } from '@/components/Search/SearchDesktopOverlay';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import Tabs from '@/components/SearchForResults/Tabs';
import SearchForResultsDesktopOverlay from '@/components/SearchForResults/SearchForResultsDesktopOverlay';
import Pills from '@/components/SearchForResults/Pills';
import MonthSelectorTab from '@/components/SearchForResults/MonthSelectorTab';
import DepartureAirportTab from '@/components/SearchForResults/DepartureAirportTab';
import SearchForResultsMobileOverlay from '@/components/SearchForResults/SearchForResultsMobileOverlay';
import { ApiProductsCacheResponse } from '@ibe/api';
import { ReducerState } from '@/components/Search/searchReducer/types';

export const getProductsTotalResults = (
  checkboxState: ReducerState,
  productPackages: ApiProductsCacheResponse[],
  totalProductsOverall: number
): number => {
  const currentNumberOfPackages = productPackages.length;
  if (
    Object.entries(checkboxState.items).some(([, item]) => item.type === 'airport') &&
    totalProductsOverall > currentNumberOfPackages
  ) {
    return currentNumberOfPackages;
  } else {
    return totalProductsOverall;
  }
};

const SearchForResultsMarkup: FC<
  SearchProps & {
    textSearchInputRef: RefObject<HTMLInputElement>;
    setCollapseOpen: Dispatch<SetStateAction<CollapseIndex>>;
    hasSelectedItems: boolean;
    isDesktop: boolean;
    closeCollapse: () => void;
    singleRoomOnlyRadioChecked: boolean;
    setSingleRoomOnlyRadioChecked: Dispatch<SetStateAction<boolean>>;
    productPackages: ApiProductsCacheResponse[];
  }
> = ({
  activeIndex,
  setCollapseOpen,
  collapseOpen,
  checkboxState,
  checkboxDispatch,
  setSingleRoomOnlyRadioChecked,
  singleRoomOnlyRadioChecked,
  closeCollapse,
  isInitialSearch,
  productPackages,
  hasSelectedItems,
  isLoading
}): JSX.Element => {
  const isDesktopForResults = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xm });

  const calculatedProductsTotalResults = useMemo(() => {
    return getProductsTotalResults(
      checkboxState,
      productPackages,
      checkboxState.totalProductsOverall
    );
  }, [checkboxState, productPackages, checkboxState.totalProductsOverall]);

  return (
    <div className="search-for-results">
      {isDesktopForResults ? (
        <>
          <Tabs
            collapseOpen={activeIndex}
            setCollapseOpen={setCollapseOpen}
            isLoading={isLoading}
            monthSelectorTab={
              <MonthSelectorTab
                collapseOpen={activeIndex === 3}
                closeCollapse={closeCollapse}
                checkboxDispatch={checkboxDispatch}
                checkboxState={checkboxState}
                isLoading={isLoading}
              />
            }
            departureAirPortTab={
              <DepartureAirportTab
                collapseOpen={activeIndex === 4}
                closeCollapse={closeCollapse}
                checkboxState={checkboxState}
                checkboxDispatch={checkboxDispatch}
                isLoading={isLoading}
              />
            }
            singleRoomOnlyRadioChecked={singleRoomOnlyRadioChecked}
            setSingleRoomOnlyRadioChecked={setSingleRoomOnlyRadioChecked}
          />
          <SearchForResultsDesktopOverlay
            collapseOpen={collapseOpen}
            activeIndex={activeIndex}
            checkboxState={checkboxState}
            checkboxDispatch={checkboxDispatch}
            closeCollapse={closeCollapse}
            isLoading={isLoading}
            calculatedProductsTotalResults={calculatedProductsTotalResults}
          />
        </>
      ) : (
        <SearchForResultsMobileOverlay
          collapseOpen={collapseOpen}
          activeIndex={activeIndex}
          checkboxState={checkboxState}
          checkboxDispatch={checkboxDispatch}
          singleRoomOnlyRadioChecked={singleRoomOnlyRadioChecked}
          setSingleRoomOnlyRadioChecked={setSingleRoomOnlyRadioChecked}
          isInitialSearch={isInitialSearch}
          hasSelectedItems={hasSelectedItems}
          isLoading={isLoading}
        />
      )}
      <Pills
        checkboxState={checkboxState}
        checkboxDispatch={checkboxDispatch}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SearchForResultsMarkup;
