import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { ApiOverlaySeat, ApiOverlaySeatMap, ApiSpecialRequest } from '@ibe/api';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import SeatSelectionLegend from '@/components/busSelection/SeatSelectionLegend';
import classNames from 'classnames';
import { getDefaultFormatPrice } from '@/Util/globals';
import Select from '@/components/Select/Select';
import Checkbox from '@/components/Search/Checkbox';
import { Collapse } from 'reactstrap';

const getSelectedSeatForTraveler = (
  selectedSeats: ApiSpecialRequest[],
  travelerId: string,
  seats?: ApiOverlaySeat[]
): ApiOverlaySeat | undefined => {
  const selectedSeat = selectedSeats.find(
    selectedSeat =>
      !!selectedSeat.travelerPosition && selectedSeat.travelerPosition.toString() === travelerId
  );
  if (!!selectedSeat) {
    return seats?.find(seat => seat.seatNumber === selectedSeat.id);
  } else {
    return undefined;
  }
};

const SeatSelectionUpper: FC<{
  checkoutStore: CheckoutStore;
  seatMap: ApiOverlaySeatMap;
  selectSeat: (seat: ApiOverlaySeat) => Promise<void>;
  fees: Record<string, number>;
  selectedSeats: ApiSpecialRequest[];
  selectedTraveler: string;
  setSelectedTraveler: Dispatch<SetStateAction<string>>;
  isDesktop: boolean;
  setIgnoreBusSeatValidation: Dispatch<SetStateAction<boolean>>;
  ignoreBusSeatValidation: boolean;
  deselectAllSeats: () => Promise<boolean>;
}> = observer(function SeatSelection({
  checkoutStore,
  seatMap,
  selectSeat,
  fees,
  selectedSeats,
  selectedTraveler,
  setSelectedTraveler,
  isDesktop,
  setIgnoreBusSeatValidation,
  ignoreBusSeatValidation,
  deselectAllSeats
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const locale = useCurrentLanguage();
  const { booking: { travelers = [] } = {} } = checkoutStore;
  const { seats } = seatMap;

  const mobileSeatOptions = useMemo(() => {
    return (seatMap?.seats || ([] as ApiOverlaySeat[]))
      .filter(seat => {
        return (
          seat.available &&
          !!seat.feeCode &&
          !selectedSeats.find(
            selectedSeat =>
              selectedSeat.id === seat.seatNumber &&
              selectedSeat.travelerPosition?.toString() !== selectedTraveler
          )
        );
      })
      .sort((a, b) =>
        !isNaN(parseInt(a.seatNumber || '', 10)) && !isNaN(parseInt(b.seatNumber || '', 10))
          ? parseInt(a.seatNumber || '', 10) - (parseInt(b.seatNumber || '', 10) || 0)
          : (a.seatNumber || '').localeCompare(b.seatNumber || '')
      )
      .map(({ seatNumber, feeCode, reservationFeeAmount, reservationFeeCurrency }) => ({
        label: `${seatNumber} - ${feeCode}${
          !!reservationFeeAmount && reservationFeeCurrency
            ? `: ${getDefaultFormatPrice(reservationFeeAmount, locale)}`
            : ''
        }`,
        value: seatNumber || ''
      }));
  }, [seatMap, selectedSeats, selectedTraveler]);

  const onMobileSelectChange = async (value: string): Promise<void> => {
    const foundSeat = seatMap?.seats?.find(
      seat =>
        seat.seatNumber === value &&
        !selectedSeats.some(
          selectedSeat =>
            selectedSeat.id == value &&
            selectedSeat.travelerPosition?.toString() === selectedTraveler
        )
    );
    if (!!foundSeat) {
      await selectSeat(foundSeat);
    }
  };

  const handleNoSeatSelectionChange = async (): Promise<void> => {
    if (ignoreBusSeatValidation) {
      setIgnoreBusSeatValidation(false);
    } else if (selectedSeats.length > 0) {
      const success = await deselectAllSeats();
      if (success) {
        setIgnoreBusSeatValidation(true);
      }
    } else {
      setIgnoreBusSeatValidation(true);
    }
  };

  return (
    <>
      <h5 className="seat-selection__headline h5-medium">{t(Keys.stationSelectHeadline)}</h5>
      <div className="seat-selection__upper">
        <div className="seat-selection__selections">
          <div className="seat-selection__selection" onClick={handleNoSeatSelectionChange}>
            <span>{t(Keys.noSeatSelection)}</span>
            <Checkbox checked={ignoreBusSeatValidation} />
          </div>
        </div>
        <Collapse isOpen={!ignoreBusSeatValidation}>
          <div className="seat-selection__travelers">
            {travelers.map((traveler, idx) => {
              const selectedSeat = getSelectedSeatForTraveler(selectedSeats, traveler.id, seats);
              return (
                <div
                  key={traveler.id}
                  className={classNames('seat-selection__travelers__item', {
                    'seat-selection__travelers__item--active': traveler.id === selectedTraveler
                  })}
                  onClick={(): void => setSelectedTraveler(traveler.id)}
                >
                  {`${idx + 1}. ${t(Keys.traveler_single)}`}
                  {!!selectedSeat && (
                    <span>{` - ${t(Keys.seatNumber)} ${selectedSeat.seatNumber}, ${
                      selectedSeat.feeCode
                    } - ${getDefaultFormatPrice(
                      selectedSeat.reservationFeeAmount || 0,
                      locale
                    )}`}</span>
                  )}
                </div>
              );
            })}
          </div>
          {!isDesktop && (
            <div className="seat-selection__mobile-dropdown">
              <Select
                value={
                  selectedSeats.find(
                    selectedSeat =>
                      !!selectedSeat.travelerPosition &&
                      selectedSeat.travelerPosition.toString() === selectedTraveler
                  )?.id || ''
                }
                onChange={onMobileSelectChange}
                options={mobileSeatOptions}
                placeholder={t(Keys.selectSeat)}
              />
            </div>
          )}
          <SeatSelectionLegend seatMap={seatMap} fees={fees} />
        </Collapse>
      </div>
    </>
  );
});

export default SeatSelectionUpper;
