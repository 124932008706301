import React, { Dispatch, FC, Fragment, ReactElement, SetStateAction } from 'react';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/search.json.keys';
import { TFunction } from 'i18next';
import {
  facCalendarSmall,
  facCaretRightThin,
  facGlobeMagnifier,
  facLocation,
  facPlane
} from '@/Theme/SVG/Icons';
import { Field } from '@/components/Search/Tabs';
import { CollapseIndex, SearchProps } from '@/components/Search/SearchDesktopOverlay';
import classNames from 'classnames';
import Radio from '@/components/Search/Radio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type SearchForResultsProps = Pick<
  SearchProps,
  | 'collapseOpen'
  | 'activeIndex'
  | 'checkboxDispatch'
  | 'checkboxState'
  | 'isInitialSearch'
  | 'isLoading'
>;

const getFields = (t: TFunction): Field[] => [
  { icon: facLocation, label: t(Keys.destinations), index: 1 },
  { icon: facGlobeMagnifier, label: t(Keys.travelTypes), index: 2 },
  { icon: facCalendarSmall, label: t(Keys.departureTime), index: 3 },
  { icon: facPlane, label: t(Keys.departureAirport), index: 4 },
  { label: t(Keys.singleRoomOnly), index: 5 }
];

const Tabs: FC<{
  collapseOpen: CollapseIndex;
  setCollapseOpen: Dispatch<SetStateAction<CollapseIndex>>;
  monthSelectorTab: ReactElement;
  departureAirPortTab: ReactElement;
  singleRoomOnlyRadioChecked: boolean;
  setSingleRoomOnlyRadioChecked: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}> = ({
  collapseOpen,
  setCollapseOpen,
  monthSelectorTab,
  departureAirPortTab,
  singleRoomOnlyRadioChecked,
  setSingleRoomOnlyRadioChecked,
  isLoading
}): JSX.Element => {
  const { t } = useTranslation('search');

  const isSelected = (index: number): boolean => {
    return collapseOpen > 0 && collapseOpen === index;
  };

  const toggleCollapse = (index: CollapseIndex): void => {
    setCollapseOpen(index === collapseOpen ? 0 : index);
  };

  return (
    <div className="search-for-results__buttons">
      {getFields(t).map(({ icon, label, index }) => (
        <Fragment key={index}>
          {index === 5 ? (
            <div className="search-for-results__radio">
              <Radio
                checked={singleRoomOnlyRadioChecked}
                onChange={(): void => setSingleRoomOnlyRadioChecked(value => !value)}
                label={t(Keys.singleRoomOnly)}
                disabled={isLoading}
              />
            </div>
          ) : (
            <div className="position-relative">
              <div
                className={classNames('search-for-results__button', {
                  'search-for-results__button--selected': isSelected(index)
                })}
                onClick={(): void => toggleCollapse(index)}
              >
                <div>
                  {!!icon && <FontAwesomeIcon icon={icon} />}
                  <div className="search-for-results__button__label">{label}</div>
                </div>
                <FontAwesomeIcon icon={facCaretRightThin} />
              </div>
              {index === 3 && monthSelectorTab}
              {index === 4 && departureAirPortTab}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Tabs;
