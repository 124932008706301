'use client';

import React, { FC, JSX, PropsWithChildren } from 'react';
import EditablePage from '../EditablePage/EditablePage';
import { EditablePageProps } from '../EditablePage/EditablePageServer';
import { config } from '@/Util/magnolia';

const EditablePageClientWrapper: FC<
  PropsWithChildren<{
    content: EditablePageProps['content'];
    templateAnnotations: EditablePageProps['templateAnnotations'];
  }>
> = ({ children, content, templateAnnotations }): JSX.Element => {
  return (
    <EditablePage
      content={content}
      config={config}
      templateAnnotations={templateAnnotations as Record<string, string>}
    >
      {children}
    </EditablePage>
  );
};

export default EditablePageClientWrapper;
