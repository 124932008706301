import React, { FC, useMemo } from 'react';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { observer } from 'mobx-react';
import { ApiBooking, ApiBookingState } from '@ibe/api';
import { useSearchParams } from 'next/navigation';
import { CHECKOUT_STATUS_QUERY_PARAM } from '@/Util/globals';
import { ExternalLinks } from '@/components/checkout/TermsAndConditions';
import { Link } from '@/Hooks/useReactTransitionProgress';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { BookingIdAfterInvoiceBooking } from '@/components/checkout/checkoutStepConfirmation/CheckoutStepConfirmation';

type ConfirmationMode =
  | 'CONFIRMED'
  | 'ON_REQUEST'
  | 'REMARKS'
  | 'CONFIRMED_PAYMENT_FAILED'
  | 'ON_REQUEST_PAYMENT_FAILED'
  | undefined;

const Upper: FC<{
  booking: ApiBooking;
  externalLinks: ExternalLinks;
  remarksFieldIsFilled: boolean;
  bookingIdAfterInvoiceBooking: BookingIdAfterInvoiceBooking;
}> = observer(function Upper({
  booking,
  externalLinks,
  remarksFieldIsFilled,
  bookingIdAfterInvoiceBooking
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const searchParams = useSearchParams();

  const regulationsUrl = getNextLinkAttrs(
    { linkType: 'page', linkTypepage: externalLinks.regulationsUrl },
    useGlobalMGLProps()
  );

  const confirmationMode = useMemo((): ConfirmationMode => {
    const paymentStatus =
      searchParams.get(CHECKOUT_STATUS_QUERY_PARAM) || bookingIdAfterInvoiceBooking.status;
    const bookingState = booking?.bookingState;
    if (remarksFieldIsFilled) {
      return 'REMARKS';
    } else if (paymentStatus === 's' && bookingState !== ApiBookingState.REQUEST) {
      return 'CONFIRMED';
    } else if (paymentStatus === 's' && bookingState === ApiBookingState.REQUEST) {
      return 'ON_REQUEST';
    } else if (
      (paymentStatus === 'c' || paymentStatus === 'f') &&
      bookingState !== ApiBookingState.REQUEST
    ) {
      return 'CONFIRMED_PAYMENT_FAILED';
    } else if (
      (paymentStatus === 'c' || paymentStatus === 'f') &&
      bookingState === ApiBookingState.REQUEST
    ) {
      return 'ON_REQUEST_PAYMENT_FAILED';
    } else {
      return undefined;
    }
  }, [booking, searchParams]);

  return (
    <div className="confirmation__upper">
      {confirmationMode === 'CONFIRMED' && (
        <>
          <div className="confirmation__upper__headline">{t(Keys.bookingConfirmed)}</div>
          <div className="confirmation__upper__sub-headline confirmation__upper__sub-headline--bold">
            {t(Keys.thankYouNote)}
          </div>
          <div className="confirmation__upper__booking-number">{`${t(Keys.bookingNumber)} ${
            booking.bookingNumber
          }.`}</div>
        </>
      )}
      {confirmationMode === 'ON_REQUEST' && (
        <>
          <div className="confirmation__upper__headline">{t(Keys.onRequestBooking)}</div>
          <div className="confirmation__upper__sub-headline confirmation__upper__sub-headline--bold">
            {t(Keys.onRequestBookingNote)}
          </div>
          <div className="confirmation__upper__booking-number">{`${t(Keys.bookingNumber)} ${
            booking.bookingNumber
          }.`}</div>
        </>
      )}
      {confirmationMode === 'REMARKS' && (
        <>
          <div className="confirmation__upper__headline">{t(Keys.bookingReceived)}</div>
          <div className="confirmation__upper__sub-headline confirmation__upper__sub-headline--bold">
            {t(Keys.bookingReceivedNote1)}
          </div>
          <div className="confirmation__upper__sub-headline">{t(Keys.bookingReceivedNote2)}</div>
          <div className="confirmation__upper__sub-headline">
            <span>{t(Keys.readMoreLink)}</span>&nbsp;
            <Link href={regulationsUrl.href} prefetch={false}>
              {regulationsUrl.href}
            </Link>
          </div>
          <div className="confirmation__upper__booking-number">{`${t(Keys.bookingNumber)} ${
            booking.bookingNumber
          }.`}</div>
        </>
      )}
      {confirmationMode === 'CONFIRMED_PAYMENT_FAILED' && (
        <>
          <div className="confirmation__upper__headline">
            {t(Keys.bookingReceivedPaymentFailed)}
          </div>
          <div className="confirmation__upper__sub-headline confirmation__upper__sub-headline--bold">
            {t(Keys.bookingReceivedPaymentFailedNote1)}
          </div>
          <div className="confirmation__upper__sub-headline">
            {t(Keys.bookingReceivedPaymentFailedNote2)}
          </div>
          <div className="confirmation__upper__sub-headline">
            <span>{t(Keys.readMoreLink)}</span>&nbsp;
            <Link href={regulationsUrl.href} prefetch={false}>
              {regulationsUrl.href}
            </Link>
          </div>
          <div className="confirmation__upper__booking-number">{`${t(Keys.bookingNumber)} ${
            booking.bookingNumber
          }.`}</div>
        </>
      )}
      {confirmationMode === 'ON_REQUEST_PAYMENT_FAILED' && (
        <>
          <div className="confirmation__upper__headline">
            {t(Keys.onRequestBookingPaymentFailed)}
          </div>
          <div className="confirmation__upper__sub-headline confirmation__upper__sub-headline--bold">
            {t(Keys.onRequestBookingPaymentFailedNote1)}
          </div>
          <div className="confirmation__upper__sub-headline">
            {t(Keys.onRequestBookingPaymentFailedNote2)}
          </div>
          <div className="confirmation__upper__sub-headline">
            <span>{t(Keys.readMoreLink)}</span>&nbsp;
            <Link href={regulationsUrl.href} prefetch={false}>
              {regulationsUrl.href}
            </Link>
          </div>
          <div className="confirmation__upper__booking-number">{`${t(Keys.bookingNumber)} ${
            booking.bookingNumber
          }.`}</div>
        </>
      )}
    </div>
  );
});

export default Upper;
