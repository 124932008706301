import {
  FieldSets,
  FormField,
  HONEYPOT_FIELD_NAME,
  isInputField,
  isSelectField
} from '@/components/magnoliaForm/types';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import Keys from '@/Translations/generated/da/magnoliaForm.json.keys';
import { AnyObject, Maybe } from 'yup/lib/types';
import BaseSchema from 'yup/lib/schema';
import { FormEvent } from 'react';
import { CHECKBOX_GROUP_NAME_DELIMITER } from '@/components/magnoliaForm/formElements/Select';

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends BaseSchema<TType, TContext, TOut> {
    isEmpty(): StringSchema;
  }
}

yup.addMethod<yup.AnySchema>(yup.string, 'isEmpty', function (...args) {
  return this.test({
    message: args[0],
    test: function isEmpty(value: string) {
      return !value;
    }
  });
});

export const getFieldSchema = (
  formField: FormField,
  t: TFunction,
  firstFoundEmailField?: { key: string; label: string }
) => {
  const { mandatory, validation } = formField;
  if (isInputField(formField) || (isSelectField(formField) && formField.type === 'select')) {
    let yupStringBase = yup.string();
    if (mandatory) {
      yupStringBase = yupStringBase.required(t(Keys.validationRequired));
    }
    if (isInputField(formField) && formField.inputType === 'email') {
      yupStringBase = yupStringBase.email(t(Keys.validationEmail));
    }
    if (isInputField(formField) && !!formField.pattern) {
      yupStringBase = yupStringBase.matches(
        new RegExp(formField.pattern),
        formField.patternDescription || ''
      );
    }
    validation?.forEach(singleValidation => {
      if (singleValidation === 'email') {
        yupStringBase = yupStringBase.email(t(Keys.validationEmail));
      }
      if (
        singleValidation === 'confirmEmail' &&
        !!firstFoundEmailField &&
        firstFoundEmailField.key !== formField.controlName
      ) {
        yupStringBase = yupStringBase.oneOf(
          [yup.ref(firstFoundEmailField.key)],
          t(Keys.validationConfirmEmail, {
            email: firstFoundEmailField.label,
            confirmEmail: formField.title
          })
        );
      }
      if (singleValidation === 'number') {
        yupStringBase = yupStringBase.matches(/^\d+$/, t(Keys.validationNumber));
      }
    });
    return yupStringBase;
  } else if (isSelectField(formField) && formField.type === 'checkbox') {
    if (mandatory) {
      return yup
        .array()
        .min(1, t(Keys.validationCheckboxGroupRequired))
        .required(t(Keys.validationCheckboxGroupRequired));
    }
  } else {
    return yup.string();
  }
};

export const createValidationSchema = (fieldsets: FieldSets, t: TFunction) => {
  let fieldNames: string[] = [HONEYPOT_FIELD_NAME];
  const fieldSetsList = fieldsets['@nodes'].map(
    propertyName => fieldsets[(propertyName as unknown) as number]
  );
  let firstFoundEmailField: { key: string; label: string } | undefined = undefined;
  const validationSchema = yup.object({
    [HONEYPOT_FIELD_NAME]: yup.string().isEmpty(),
    ...fieldSetsList.reduce((total, current) => {
      const fieldsList = current.fields['@nodes'].map(
        propertyName => current.fields[(propertyName as unknown) as number]
      );
      const currentFieldList = fieldsList.reduce((fieldTotal, fieldCurrent) => {
        if (!!fieldCurrent.edits && fieldCurrent.edits['@nodes'].length > 0) {
          const editsList = fieldCurrent.edits['@nodes'].map(
            propertyName => fieldCurrent.edits![(propertyName as unknown) as number]
          );
          const currentEditFieldList = editsList.reduce((editsTotal, editsCurrent) => {
            if (!!editsCurrent.controlName) {
              if (
                !firstFoundEmailField &&
                ((isInputField(editsCurrent) && editsCurrent.inputType === 'email') ||
                  editsCurrent.validation?.includes('email'))
              ) {
                firstFoundEmailField = {
                  key: editsCurrent.controlName,
                  label: editsCurrent.title || ''
                };
              }
              fieldNames = [...fieldNames, editsCurrent.controlName];
              return {
                ...editsTotal,
                [editsCurrent.controlName]: getFieldSchema(editsCurrent, t, firstFoundEmailField)
              };
            } else {
              return { ...editsTotal };
            }
          }, {});
          return { ...fieldTotal, ...currentEditFieldList };
        } else {
          if (!!fieldCurrent.controlName) {
            if (
              !firstFoundEmailField &&
              ((isInputField(fieldCurrent) && fieldCurrent.inputType === 'email') ||
                fieldCurrent.validation?.includes('email'))
            ) {
              firstFoundEmailField = {
                key: fieldCurrent.controlName,
                label: fieldCurrent.title || ''
              };
            }
            fieldNames = [...fieldNames, fieldCurrent.controlName];
            return {
              ...fieldTotal,
              [fieldCurrent.controlName]: getFieldSchema(fieldCurrent, t, firstFoundEmailField)
            };
          } else {
            return { ...fieldTotal };
          }
        }
      }, {});
      return { ...total, ...currentFieldList };
    }, {})
  });
  return { validationSchema, fieldNames };
};

export const getFormData = (
  event: FormEvent<HTMLFormElement>,
  fieldNames: string[],
  stringsOnly?: boolean,
  findAdditionalHidden?: boolean
): Record<string, string> => {
  const elementsList = Object.entries(event.currentTarget.elements);

  function getArrayValues(
    fieldName: string,
    hasCheckboxValues: [string, Element][]
  ): string[] | string {
    const array = hasCheckboxValues
      .filter(([, checkbox]) => (checkbox as HTMLInputElement).checked)
      .map(([key]) => key.replace(fieldName, '').replace(CHECKBOX_GROUP_NAME_DELIMITER, ''));
    if (stringsOnly) {
      return array.join(', ');
    }
    return array;
  }

  let newFieldNames = [...fieldNames];
  if (findAdditionalHidden) {
    const additionalHidden = elementsList
      .filter(
        ([, element]) =>
          (element as HTMLInputElement).type === 'hidden' &&
          !fieldNames.includes((element as HTMLInputElement).name) &&
          !!(element as HTMLInputElement).value
      )
      .map(([key]) => key);
    newFieldNames = [...newFieldNames, ...additionalHidden];
  }

  return Object.fromEntries(
    newFieldNames
      .map(fieldName => {
        const hasCheckboxValues = elementsList.filter(
          ([key]) => key.includes(fieldName) && key.includes(CHECKBOX_GROUP_NAME_DELIMITER)
        );
        return [
          fieldName,
          hasCheckboxValues.length > 0
            ? getArrayValues(fieldName, hasCheckboxValues)
            : (event.currentTarget.elements.namedItem(fieldName) as HTMLInputElement)?.value
        ];
      })
      .filter(([key, value]) => !!value || key === HONEYPOT_FIELD_NAME)
  );
};
