'use client';

import React, { FC } from 'react';
import { SubmitButton } from '@/components/magnoliaForm/types';
import { Spinner } from 'reactstrap';
import Button from '@/components/Button';
import { useMagnoliaFormContext } from '@/components/magnoliaForm/Form';

const Submit: FC<SubmitButton> = ({ buttonText }): JSX.Element => {
  const { isLoading } = useMagnoliaFormContext();
  return (
    <div className="magnolia-form__field magnolia-form__button">
      <Button type="submit" color="primary">
        {buttonText}
        {isLoading && <Spinner />}
      </Button>
    </div>
  );
};

export default Submit;
