import React, { FC, JSX } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { ApiBookedItem, ApiPrice } from '@ibe/api';
import { useTranslation } from '@/app/i18n/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { getDefaultFormatPrice } from '@/Util/globals';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import classNames from 'classnames';
import { AllItems } from '@/components/checkout/cart/getAllItems';
import { UpgradeItemType } from '@/components/checkout/cart/getUpgrades';
import { TFunction } from 'i18next';

export const getUpgradesHeadline = (type: UpgradeItemType, t: TFunction): string => {
  return type === 'TRANSFER' ? t(Keys.transfer) : type === 'BUS' ? t(Keys.bus) : t(Keys.opera);
};

export const getAllPricesAreEqual = (prices: ApiPrice[]): boolean => {
  return prices
    .map(priceByPerson => priceByPerson?.finalPrice || 0)
    .every((element, _, all) => element === all[0]);
};

export const getSortedPricesByAmount = (allPrices: ApiPrice[]): Record<number, ApiPrice[]> => {
  return allPrices.reduce(
    (total, current) =>
      !!current?.finalPrice
        ? {
            ...total,
            [current.finalPrice]: !!total[current.finalPrice]
              ? [...total[current.finalPrice], current]
              : [current]
          }
        : total,
    {} as Record<number, ApiPrice[]>
  );
};

const getPriceItem = (
  item: ApiBookedItem,
  price: ApiPrice,
  t: TFunction,
  language: string,
  numberOfPrices?: number
) => {
  return (
    <div key={item.id} className="checkout__data__price checkout__data__price--inner">
      <div>{`${!!numberOfPrices ? `${numberOfPrices}x ` : ''}${item.name}`}</div>
      <div
        className={classNames({
          checkout__data__included: price.finalPrice === 0
        })}
      >
        {!price.finalPrice
          ? t(Keys.includedAlt)
          : getDefaultFormatPrice(price.finalPrice, language)}
      </div>
    </div>
  );
};

const Upgrades: FC<{ allItems: AllItems }> = observer(function Upgrades({ allItems }): JSX.Element {
  const language = useCurrentLanguage();
  const { t } = useTranslation('Checkout');

  const {
    upgrades: { all, totalPricePerPerson }
  } = allItems;

  return all.length > 0 ? (
    <>
      <hr />
      <div className="cart__headline">{t(Keys.upgrades)}</div>
      <div className="checkout__data">
        {all.map(({ item, type }) => {
          const allPrices = Object.values(item.priceByPersonId);
          const firstPrice = allPrices[0];
          const allPricesAreEqual = getAllPricesAreEqual(Object.values(item.priceByPersonId));

          return (
            <div key={item.id} className="checkout__data__section">
              <FontAwesomeIcon icon={faPlus} />
              <div>
                <div className="font-weight-bold">{getUpgradesHeadline(type, t)}</div>
                {allPricesAreEqual ? (
                  getPriceItem(item, firstPrice, t, language)
                ) : (
                  <>
                    {Object.values(getSortedPricesByAmount(allPrices)).map(prices =>
                      getPriceItem(item, prices[0], t, language, prices.length)
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
        <div className="checkout__data__price">
          <FontAwesomeIcon icon={faPlus} />
          <div className="checkout__data__price__alt">
            <div>{t(Keys.surcharge)}</div>
            <div
              className={classNames({
                checkout__data__included: totalPricePerPerson.finalPrice === 0
              })}
            >
              {totalPricePerPerson.finalPrice === 0
                ? t(Keys.includedAlt)
                : getDefaultFormatPrice(totalPricePerPerson.finalPrice, language)}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
});

export default Upgrades;
