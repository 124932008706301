'use client';

import React, { FC, PropsWithChildren, useContext } from 'react';
import { TemplateAnnotations } from '@magnolia/template-annotations';
import { EditorContext } from '@magnolia/react-editor';
import { constants, EditorContextType, refresh } from '@/components/magnoliaPage/componentHelper';
import EditableComment from '../EditableComponent/EditableComment';

const EditableComponentClient: FC<PropsWithChildren<{ content: Record<string, any> }>> = ({
  content,
  children
}) => {
  const { templateDefinitions, templateAnnotations } = useContext<EditorContextType>(EditorContext);

  const getAnnotation = (): string => {
    if (!content) {
      return '';
    }
    const pageTemplateId = content[constants.TEMPLATE_ID_PROP];
    if (templateDefinitions && templateDefinitions[pageTemplateId]) {
      return TemplateAnnotations.getComponentCommentString(
        content,
        templateDefinitions[pageTemplateId]
      );
    }
    if (templateAnnotations && templateAnnotations[content?.['@path']]) {
      return templateAnnotations[content?.['@path']];
    }
    return '';
  };

  const onReady = (): void => {
    refresh();
  };

  return (
    <>
      <EditableComment annotation={getAnnotation()} callback={onReady} />
      {children}
      {!!getAnnotation() ? (
        <EditableComment annotation={constants.CLOSED_COMPONENT_COMMENT} />
      ) : (
        <EditableComment annotation="" />
      )}
    </>
  );
};

export default EditableComponentClient;
