/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
* 
* @export
* @interface ApiNavigationItem
*/
export interface ApiNavigationItem {
    /**
    * 
    * @type {string}
    * @memberof ApiNavigationItem
    */
    name: string;
    /**
    * 
    * @type {string}
    * @memberof ApiNavigationItem
    */
    linkType: string;
    /**
    * 
    * @type {string}
    * @memberof ApiNavigationItem
    */
    link: string;
}

export function ApiNavigationItemFromJSON(json: any): ApiNavigationItem {
return ApiNavigationItemFromJSONTyped(json, false);
}

export function ApiNavigationItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiNavigationItem {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'name': json['name'],
                    'linkType': json['linkType'],
                    'link': json['link'],
        };
    }

    export function ApiNavigationItemToJSON(value?: ApiNavigationItem | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'name': value.name,
                'linkType': value.linkType,
                'link': value.link,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

