import React, { Dispatch, FC, JSX, SetStateAction, useState } from 'react';
import Button from '@/components/Button';
import Keys from '@/Translations/generated/da/search.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchForResultsProps } from '@/components/SearchForResults/Tabs';
import MobileOverlay from '@/components/MobileOverlay/MobileOverlay';
import SearchForResultsMobileContent from '@/components/SearchForResults/SearchForResultsMobileContent';
import { facFilter } from '@/Theme/SVG/Icons';

const SearchForResultsMobileOverlay: FC<
  SearchForResultsProps & {
    singleRoomOnlyRadioChecked: boolean;
    setSingleRoomOnlyRadioChecked: Dispatch<SetStateAction<boolean>>;
    hasSelectedItems: boolean;
  }
> = ({
  collapseOpen,
  activeIndex,
  checkboxDispatch,
  checkboxState,
  singleRoomOnlyRadioChecked,
  setSingleRoomOnlyRadioChecked,
  isInitialSearch,
  hasSelectedItems,
  isLoading
}): JSX.Element => {
  const { t } = useTranslation('search');
  const [contentIndex, setContentIndex] = useState<[number, number]>([0, 0]);

  return (
    <div className="search-for-results__mobile">
      <Button
        color="primary"
        className="search-for-results__mobile__button"
        onClick={(): void => setContentIndex(value => (value[0] === 0 ? [1, 0] : [0, 0]))}
      >
        <span>{t(Keys.filters)}</span>
        <FontAwesomeIcon icon={facFilter} />
      </Button>
      <MobileOverlay
        overlayOpen={contentIndex[0] !== 0}
        setOverlayOpen={(): void => setContentIndex([0, 0])}
        className="mobile-overlay--search"
        headerContent={<h2>{t(Keys.filters)}</h2>}
        content={
          <SearchForResultsMobileContent
            collapseOpen={collapseOpen}
            activeIndex={activeIndex}
            checkboxState={checkboxState}
            checkboxDispatch={checkboxDispatch}
            singleRoomOnlyRadioChecked={singleRoomOnlyRadioChecked}
            setSingleRoomOnlyRadioChecked={setSingleRoomOnlyRadioChecked}
            isInitialSearch={isInitialSearch}
            contentIndex={contentIndex}
            setContentIndex={setContentIndex}
            hasSelectedItems={hasSelectedItems}
            isLoading={isLoading}
          />
        }
      />
    </div>
  );
};

export default SearchForResultsMobileOverlay;
