import React, { FC, JSX, MouseEvent, useEffect, useState } from 'react';
import Keys from '@/Translations/generated/da/product.json.keys';
import { useTranslation } from '@/app/i18n/client';
import StarRating from '@/components/starRating/StarRating';
import dayjs from 'dayjs';
import { customConfig } from '@/Config/configService';
import { fallbackLng } from '@/app/i18n/settings';
import { ApiProductWithReviews } from '@ibe/api';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { Next, Previous } from '@/Theme/SVG/Svgs';
import classNames from 'classnames';
import { PRODUCT_REVIEW_CONTAINER_ID, THEMES } from '@/Util/globals';
import GridContainer from '@/Layouts/GridContainer';
import Link from '@/components/Link';
import { useWindow } from '@ibe/components';

const SLIDE_INTERVAL = 15000;
let interval: ReturnType<typeof setInterval> | undefined = undefined;

const ProductReviewsCompact: FC<{
  reviews?: ApiProductWithReviews;
  themeReviews?: string;
}> = ({ reviews, themeReviews }): JSX.Element => {
  const language = useCurrentLanguage();
  const window = useWindow();
  const { t } = useTranslation('product');
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    if (!!interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      setActiveIndex(value => {
        return value === (reviews?.reviews?.length || 0) - 1 ? 0 : value + 1;
      });
    }, SLIDE_INTERVAL);

    return () => {
      if (!!interval) {
        clearInterval(interval);
      }
    };
  }, [reviews]);

  const handleClick = (isPrev: boolean): void => {
    if (isPrev && activeIndex > 0) {
      setActiveIndex(value => value - 1);
    } else if (!isPrev && activeIndex < (reviews?.reviews?.length || 0) - 1) {
      setActiveIndex(value => value + 1);
    }
  };

  const onLinkClick = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    const mainReview = window?.document.getElementById(PRODUCT_REVIEW_CONTAINER_ID);
    if (!!mainReview) {
      mainReview.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return !!reviews && !!reviews.reviews && reviews.reviews.length > 0 ? (
    <GridContainer className="product-reviews__container">
      <div className={classNames('product-reviews', themeReviews || THEMES.darkGreen)}>
        <div className="product-reviews__bars">
          {reviews.reviews.map((_, idx) => (
            <span
              key={idx}
              className={classNames('product-reviews__bars__item', {
                'product-reviews__bars__item--active': idx === activeIndex
              })}
            />
          ))}
        </div>
        <button
          className={classNames(
            'nav_btn nav_btn_left expanded-gallery__button expanded-gallery__button--next product-reviews--previous',
            { 'product-reviews--inactive': activeIndex === 0 }
          )}
          onClick={(): void => handleClick(true)}
        >
          <Previous />
        </button>
        <div className="product-reviews__inner">
          <div
            className="product-reviews__list"
            style={{
              width: `${reviews.reviews.length * 100}%`,
              minWidth: `${reviews.reviews.length * 100}%`,
              transform: `translateX(${activeIndex * (100 / reviews.reviews.length) * -1}%)`
            }}
          >
            {reviews.reviews.map((review, idx) => (
              <div
                key={idx}
                className="product-reviews__item"
                style={{ width: `${100 / (reviews?.reviews?.length || 0)}%` }}
              >
                <StarRating
                  className="product-reviews__rating"
                  rating={review.numberOfStars}
                  maxRating={Math.round(review.numberOfStars)}
                />
                <div className="product-reviews__name">{`${review.name} - ${dayjs(
                  review.date
                ).format(customConfig?.displayFormatDate?.[language || fallbackLng])}`}</div>
                <div className="product-reviews__text">{`"${review.reviewText}"`}</div>
                <Link className="product-reviews__link" onClick={onLinkClick} showCaret>
                  {t(Keys.readMore)}
                </Link>
              </div>
            ))}
          </div>
        </div>
        <button
          className={classNames(
            'nav_btn nav_btn_right expanded-gallery__button expanded-gallery__button--next',
            { 'product-reviews--inactive': activeIndex === reviews.reviews.length - 1 }
          )}
          onClick={(): void => handleClick(false)}
        >
          <Next />
        </button>
      </div>
    </GridContainer>
  ) : (
    <></>
  );
};

export default ProductReviewsCompact;
