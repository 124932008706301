import {
  doesAirportIncludeName,
  meetsCountryConditions,
  meetsDateConditions,
  meetsTravelTypeConditions
} from '@/components/Search/searchReducer/helpers';
import {
  ApiAirportComponent,
  Payload,
  PayloadType,
  ReducerStateItem
} from '@/components/Search/searchReducer/types';
import {
  ApiProductsCacheData,
  ApiProductsCacheResponseCountry,
  ApiProductsCacheResponseTravelTypes
} from '@ibe/api';

export const getAirportsState = (
  continentItems: [string, ReducerStateItem][],
  dateItems: [string, ReducerStateItem][],
  travelTypeItems: [string, ReducerStateItem][],
  airportItems: [string, ReducerStateItem][],
  apiAirports: ApiAirportComponent[],
  filtersAreEmpty: boolean,
  payloadType: PayloadType,
  payload: Payload,
  countries?: ApiProductsCacheResponseCountry[],
  travelTypes?: ApiProductsCacheResponseTravelTypes[],
  products?: ApiProductsCacheData[]
): ApiAirportComponent[] => {
  if (
    (payloadType !== 'airport' &&
      meetsCountryConditions(continentItems, countries) &&
      meetsTravelTypeConditions(travelTypeItems, travelTypes) &&
      meetsDateConditions(dateItems, products)) ||
    filtersAreEmpty
  ) {
    if (!!products && products?.some(product => !!product.departureAirport)) {
      let airportsProductIsAddedTo: string[] = [];
      for (let product of products) {
        const packageAirport = product.departureAirport;
        if (!!packageAirport) {
          const airportIndex = apiAirports.findIndex(ApiAirport =>
            doesAirportIncludeName(ApiAirport, packageAirport)
          );
          if (airportIndex < 0) {
            apiAirports = [
              ...apiAirports,
              {
                id: packageAirport,
                name: packageAirport,
                products: 1,
                differentAirportNames: [packageAirport]
              }
            ];
          } else {
            const foundAirport = {
              ...apiAirports[airportIndex],
              products: !airportsProductIsAddedTo.find(airportProductIsAddedTo =>
                doesAirportIncludeName(
                  {
                    id: '0',
                    name: '0',
                    products: 0,
                    differentAirportNames: [airportProductIsAddedTo]
                  },
                  packageAirport
                )
              )
                ? apiAirports[airportIndex].products + 1
                : apiAirports[airportIndex].products,
              differentAirportNames: !apiAirports[airportIndex].differentAirportNames.includes(
                packageAirport
              )
                ? [...apiAirports[airportIndex].differentAirportNames, packageAirport]
                : [...apiAirports[airportIndex].differentAirportNames]
            };
            apiAirports.splice(airportIndex, 1, foundAirport);
          }
          airportsProductIsAddedTo = [...airportsProductIsAddedTo, packageAirport];
        }
      }
    }
  }
  if (
    payloadType === 'airport' &&
    !airportItems.find(([airportItemId, airportItem]) =>
      doesAirportIncludeName(
        {
          id: airportItemId,
          name: airportItem.name,
          products: 0,
          differentAirportNames: airportItem.differentAirportNames || []
        },
        payload.name
      )
    )
  ) {
    const apiAirportIndex = apiAirports.findIndex(
      apiAirport => doesAirportIncludeName(apiAirport, payload.name) && apiAirport.products === 0
    );
    if (apiAirportIndex >= 0) {
      apiAirports.splice(apiAirportIndex, 1);
    }
  }

  return apiAirports;
};
