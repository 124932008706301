import React, { FC, JSX } from 'react';
import { MagnoliaNode } from '@/types/cms/magnolia';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { Link } from '@/Hooks/useReactTransitionProgress';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import Checkbox from '@/components/Search/Checkbox';
import { observer } from 'mobx-react';
import classNames from 'classnames';

export type ExternalLinks = {
  privacyPolicyUrl: MagnoliaNode;
  regulationsUrl: MagnoliaNode;
  tAndCInsuranceUrl: string;
  adobeReaderUrl: string;
};

const TermsAndConditions: FC<{
  externalLinks: ExternalLinks;
  checkoutStore: CheckoutStore;
}> = observer(function TermsAndConditions({
  externalLinks: { privacyPolicyUrl, regulationsUrl, adobeReaderUrl },
  checkoutStore
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const globalProps = useGlobalMGLProps();

  const composedPrivacyPolicyUrl = getNextLinkAttrs(
    { linkType: 'page', linkTypepage: privacyPolicyUrl },
    globalProps
  );
  const composedRegulationsUrl = getNextLinkAttrs(
    { linkType: 'page', linkTypepage: regulationsUrl },
    globalProps
  );

  const handleCheckbox = (): void => {
    checkoutStore.termsAndConditionsChecked = !checkoutStore.termsAndConditionsChecked;
  };

  return (
    <div className="checkout__t-and-c">
      <hr />
      <p id={checkoutStore.termsAndConditionsNotCheckedErrorId}>
        <span>{t(Keys.tAndC1)}</span>
        {!!adobeReaderUrl && (
          <Link href={adobeReaderUrl} target="_blank" prefetch={false}>
            {t(Keys.tAndC2)}
          </Link>
        )}
      </p>
      <h5>{t(Keys.tAndC3)}</h5>
      <div
        className={classNames('checkout__t-and-c__checkbox', {
          'checkout__t-and-c__checkbox--error':
            !checkoutStore.termsAndConditionsChecked &&
            checkoutStore.termsAndConditionsNotCheckedError
        })}
      >
        <Checkbox checked={checkoutStore.termsAndConditionsChecked} onChange={handleCheckbox} />
        <p onClick={handleCheckbox}>
          <span>{t(Keys.tandC4)}</span>
          {!!composedPrivacyPolicyUrl?.href && (
            <Link href={composedPrivacyPolicyUrl.href} target="_blank" prefetch={false}>
              {t(Keys.tAndC5)}
            </Link>
          )}
          <span>{t(Keys.tAndC6)}</span>
          {!!composedRegulationsUrl?.href && (
            <Link href={composedRegulationsUrl.href} target="_blank" prefetch={false}>
              {t(Keys.tAndC7)}
            </Link>
          )}
        </p>
      </div>
    </div>
  );
});

export default TermsAndConditions;
