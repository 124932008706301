import React, { FC, JSX, useEffect, useState } from 'react';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import classNames from 'classnames';
import ResultsNavItem from '@/components/Search/ResultsNavItem';
import RadioResults from '@/components/Search/RadioResults';
import { useTransition } from 'react-transition-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretRightThin, facLocationMarker } from '@/Theme/SVG/Icons';
import {
  Action,
  ApiContinentComponent,
  ApiCountryComponent
} from '@/components/Search/searchReducer/types';

export const numberOfProductsPerContinent = (continent?: ApiContinentComponent) => {
  return (
    continent?.countries?.reduce((total, current) => {
      return total + (current.products || 0);
    }, 0) || 0
  );
};

const CountriesSearchMobile: FC<
  Pick<SearchProps, 'checkboxState' | 'checkboxDispatch' | 'isLoading'>
> = ({ checkboxState, checkboxDispatch, isLoading }): JSX.Element => {
  const { continents } = checkboxState;

  const [selectedContinent, setSelectedContinent] = useState<ApiContinentComponent | undefined>(
    undefined
  );
  const [
    { status: statusCountries, isMounted: isMountedCountries },
    toggleCountries
  ] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    toggleCountries(!!selectedContinent);
  }, [selectedContinent]);

  const handleContinentClick = (continent: ApiContinentComponent): void => {
    setSelectedContinent(continent);
  };

  const checkSingleCountry = async (country: ApiCountryComponent): Promise<void> => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: country?.continent || '',
        type: 'continent',
        name: selectedContinent?.name || '',
        items: [{ id: country?.id || '', name: country.name }]
      }
    });
  };

  const backToContinents = (): void => {
    setSelectedContinent(undefined);
  };

  return (
    <div className="search__content__mobile search__content__mobile--slide">
      <div
        className={classNames('search__content__mobile__inner', {
          'search__content__mobile__inner--transformed': !!selectedContinent
        })}
      >
        <div>
          {continents.map(continent => (
            <ResultsNavItem
              key={continent.id}
              label={continent.name || ''}
              amount={numberOfProductsPerContinent(continent)}
              onClick={(): void => handleContinentClick(continent)}
            />
          ))}
        </div>
        <div>
          {isMountedCountries && (
            <div
              className={classNames('search__content__mobile__element', {
                'search__content__mobile__element--hidden':
                  statusCountries === 'preEnter' || statusCountries === 'exiting'
              })}
            >
              <div className="search__content__mobile__back" onClick={backToContinents}>
                <div>
                  <FontAwesomeIcon icon={facLocationMarker} />
                  <div>{selectedContinent?.name}</div>
                </div>
                <FontAwesomeIcon icon={facCaretRightThin} />
              </div>
              {selectedContinent?.countries?.map(country => (
                <RadioResults
                  key={country.id}
                  label={country.name}
                  checked={
                    !!country?.id &&
                    !!country?.continent &&
                    !!checkboxState.items[country.continent]?.countries?.[country.id]
                  }
                  onChange={(): Promise<void> => checkSingleCountry(country)}
                  results={country.products || 0}
                  isLoading={isLoading}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountriesSearchMobile;
