import React, {
  createContext,
  FC,
  JSX,
  PropsWithChildren,
  useEffect,
  useRef,
  useState
} from 'react';
import { Next, Previous } from '@/Theme/SVG/Svgs';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import classNames from 'classnames';

export const ScrollContainerContext = createContext<HTMLDivElement | null>(null);

const showNavButtons = (numberOfItems: number, isXMedium: boolean, isXXLarge: boolean): boolean => {
  return (
    (!isXMedium && !isXXLarge && numberOfItems > 1) ||
    (isXMedium && !isXXLarge && numberOfItems > 3) ||
    (isXXLarge && numberOfItems > 4)
  );
};

const SelectionItems: FC<
  PropsWithChildren<{
    numberOfItems: number;
    numberOfRooms?: number;
    label: string;
    className?: string;
  }>
> = ({ children, numberOfItems, numberOfRooms, label, className }): JSX.Element => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const isXMedium = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xm });
  const isXXLarge = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xxl });
  const [scrollState, setScrollState] = useState<'left' | 'none' | 'right'>('left');

  useEffect(() => {
    const onScroll = (e: Event): void => {
      const container = e.target as HTMLDivElement;
      if (container.scrollLeft < 10) {
        setScrollState('left');
      } else if (container.scrollLeft > container.scrollWidth - container.clientWidth - 10) {
        setScrollState('right');
      } else {
        setScrollState('none');
      }
    };

    if (!!scrollContainer.current) {
      scrollContainer.current.addEventListener('scroll', onScroll);
    }

    return (): void => {
      scrollContainer?.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft = 0;
    }
  }, [scrollContainer.current]);

  const handleNavigation = (previous: boolean): void => {
    if (!scrollContainer.current) return;
    const containerWidth = scrollContainer.current.getBoundingClientRect().width;
    const elementWidth =
      !isXMedium && !isXXLarge
        ? containerWidth
        : isXMedium && !isXXLarge
        ? containerWidth / 3
        : containerWidth / 4;
    scrollContainer.current.scrollBy({
      left: elementWidth * (previous ? -1 : 1),
      behavior: 'smooth'
    });
  };

  return (
    <div className={classNames('selection-items', className || '')}>
      <div className="selection-items__container">
        {numberOfItems > 1 && (
          <div
            className={classNames('selection-items__nav', {
              'selection-items__nav--no-btns': !showNavButtons(numberOfItems, isXMedium, isXXLarge)
            })}
          >
            <div className="selection-items__label">{label}</div>
            {showNavButtons(numberOfItems, isXMedium, isXXLarge) && (
              <div className="selection-items__nav__btns">
                <span
                  className={classNames({
                    'selection-items__nav__btns--disabled': scrollState === 'left'
                  })}
                  onClick={(): void =>
                    scrollState !== 'left' ? handleNavigation(true) : undefined
                  }
                >
                  <Previous />
                </span>
                <span
                  className={classNames({
                    'selection-items__nav__btns--disabled': scrollState === 'right'
                  })}
                  onClick={(): void =>
                    scrollState !== 'right' ? handleNavigation(false) : undefined
                  }
                >
                  <Next />
                </span>
              </div>
            )}
          </div>
        )}
        <div ref={scrollContainer} className="selection-items__scroll">
          <div
            className={classNames('selection-items__inner', {
              'selection-items__inner--centered': !showNavButtons(
                numberOfItems,
                isXMedium,
                isXXLarge
              )
            })}
          >
            <ScrollContainerContext.Provider value={scrollContainer.current}>
              {children}
            </ScrollContainerContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectionItems;
