import React, { FC, Fragment, MouseEvent, useEffect, useRef, useState } from 'react';
import Link from '@/components/Link';
import { useHorizontalDrag, useWindow } from '@ibe/components';
import {
  INTEREST_LIST_URL_PARAMETER,
  PRODUCT_AVAILABILITY_STATE,
  smoothScroll
} from '@/Util/globals';
import Keys from '@/Translations/generated/da/product.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

export type ContentNavigationType = Array<{
  title: string;
  id: string;
  hideInNavbar?: boolean;
}>;

interface ContentNavigationProps {
  items: ContentNavigationType;
  ctaItem: { label: string; callback: (e: MouseEvent<HTMLAnchorElement>) => void };
  productsAvailabilityState: PRODUCT_AVAILABILITY_STATE;
  productCode: string;
}

const ContentNavigation: FC<ContentNavigationProps> = ({
  items,
  ctaItem,
  productsAvailabilityState,
  productCode
}) => {
  const { t } = useTranslation('product');
  const { siteConfig, rootNodePath } = useGlobalMGLProps() || {};
  const centerContainerRef = useRef<HTMLDivElement>(null);
  const window = useWindow();
  const [activeElement, setActiveElement] = useState<string>('');

  const { isDown } = useHorizontalDrag('product-page-content-navigation', 350);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveElement(entry.target.id);
          }
        });
      },
      { threshold: 0.22 }
    );
    items.forEach(item => {
      const element = document.getElementById(item.id);
      if (!!element) {
        observer.observe(element);
      }
    });

    return () => {
      items.forEach(item => {
        const element = document.getElementById(item.id);
        if (!!element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (!!window?.location?.hash) {
      timer = setTimeout((): void => {
        const hash = window.location.hash.substring(1);
        smoothScroll(hash, undefined, (): void => setActiveElement(hash));
      }, 2000);
    }

    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, [window]);

  return (
    <nav className="content-navigation">
      <div
        id="product-page-content-navigation"
        className={`scroll-container${isDown ? ' scroll-container--grab' : ''}`}
      >
        <div className="center-container" ref={centerContainerRef}>
          <div>
            {items.map(item =>
              !item.hideInNavbar ? (
                <a
                  key={item.id}
                  className={item.id === activeElement ? 'content-navigation__active' : ''}
                  href={`#${item.id}`}
                  onClick={(e: MouseEvent<HTMLAnchorElement>): void =>
                    smoothScroll(item.id, e, (): void => setActiveElement(item.id))
                  }
                  draggable={false}
                >
                  {item.title}
                </a>
              ) : (
                <Fragment key={item.id} />
              )
            )}
          </div>
        </div>
      </div>
      {productsAvailabilityState !== PRODUCT_AVAILABILITY_STATE.pastTripNoFutureAvail &&
      productsAvailabilityState !== PRODUCT_AVAILABILITY_STATE.soldOutNoFutureAvail ? (
        <Link className="cta-item" onClick={ctaItem.callback} showCaret>
          {ctaItem.label}
        </Link>
      ) : (
        <Link
          className="cta-item"
          href={`${siteConfig?.parameters.interestListPage.replace(
            rootNodePath || '',
            ''
          )}?${INTEREST_LIST_URL_PARAMETER}=${productCode}`}
          showCaret
        >
          {t(Keys.interestList)}
        </Link>
      )}
    </nav>
  );
};

export default ContentNavigation;
