import React, { FC, JSX, ReactElement } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDefaultFormatPrice } from '@/Util/globals';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { ApiBookedItem, ApiPrice } from '@ibe/api';
import { TFunction } from 'i18next';
import { getAllPricesAreEqual, getSortedPricesByAmount } from '@/components/checkout/cart/Upgrades';
import classNames from 'classnames';

const getPerPersonPriceString = (
  numberOfTravelers: number,
  language: string,
  t: TFunction,
  pricePerPerson?: ApiPrice
): string => {
  return `${numberOfTravelers} x ${
    !!pricePerPerson?.finalPrice
      ? getDefaultFormatPrice(pricePerPerson.finalPrice, language)
      : t(Keys.includedAlt)
  }`;
};

const OverviewAndTotalPriceItem: FC<{
  checkoutStore: CheckoutStore;
  item: ApiBookedItem;
  headline: string;
  subHeadline?: ReactElement;
  removeItem: (item: ApiBookedItem) => void;
  showFullPriceFactorOne?: boolean;
  showItemName?: boolean;
}> = observer(function OverviewAndTotalPriceItem({
  checkoutStore,
  item,
  headline,
  subHeadline,
  removeItem,
  showFullPriceFactorOne,
  showItemName
}): JSX.Element {
  const language = useCurrentLanguage();
  const { t } = useTranslation('Checkout');

  const { booking: { travelers } = {} } = checkoutStore;
  const allPricesAreEqual = getAllPricesAreEqual(Object.values(item.priceByPersonId || {}));

  return !!item?.price?.finalPrice ? (
    <div className="checkout__data__section">
      <FontAwesomeIcon
        icon={faTrashCan}
        className="checkout__data__icon--clickable"
        onClick={(): void => removeItem(item)}
      />
      <div>
        <div className="font-weight-bold">{headline}</div>
        {!!subHeadline && subHeadline}
        {showItemName && <div>{item.name}</div>}
        <div className="checkout__data__price checkout__data__price--inner">
          <div className={classNames({ 'flex-xs-column': !allPricesAreEqual })}>
            {!showFullPriceFactorOne && (
              <>
                {allPricesAreEqual
                  ? getPerPersonPriceString(
                      Object.values(item.priceByPersonId || {}).filter(Boolean).length,
                      language,
                      t,
                      Object.values(item.priceByPersonId || {})[0]
                    )
                  : Object.values(
                      getSortedPricesByAmount(Object.values(item.priceByPersonId || {}))
                    ).map(prices => (
                      <div key={prices[0].finalPrice}>
                        {getPerPersonPriceString(prices.length, language, t, prices[0])}
                      </div>
                    ))}
              </>
            )}
            {showFullPriceFactorOne && (
              <>{`1 x ${getDefaultFormatPrice(
                item.price.finalPrice / (checkoutStore.booking?.travelers?.length || 1),
                language
              )}`}</>
            )}
          </div>
          <div>{getDefaultFormatPrice(item.price.finalPrice, language)}</div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
});

export default OverviewAndTotalPriceItem;
