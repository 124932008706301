/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
* 
* @export
* @interface ApiClimateInfo
*/
export interface ApiClimateInfo {
    /**
    * 
    * @type {string}
    * @memberof ApiClimateInfo
    */
    name?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiClimateInfo
    */
    minTemp: string;
    /**
    * 
    * @type {string}
    * @memberof ApiClimateInfo
    */
    maxTemp: string;
    /**
    * 
    * @type {string}
    * @memberof ApiClimateInfo
    */
    precipitation: string;
}

export function ApiClimateInfoFromJSON(json: any): ApiClimateInfo {
return ApiClimateInfoFromJSONTyped(json, false);
}

export function ApiClimateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiClimateInfo {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'name': json['name'],
                    'minTemp': json['minTemp'],
                    'maxTemp': json['maxTemp'],
                    'precipitation': json['precipitation'],
        };
    }

    export function ApiClimateInfoToJSON(value?: ApiClimateInfo | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'name': value.name,
                'minTemp': value.minTemp,
                'maxTemp': value.maxTemp,
                'precipitation': value.precipitation,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

