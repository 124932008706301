import React, { FC, JSX } from 'react';
import Keys from '@/Translations/generated/da/search.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import CheckboxResults from '@/components/Search/CheckboxResults';
import { ApiTravelType } from '../../../api/model';
import { Action } from '@/components/Search/searchReducer/types';
import classNames from 'classnames';
import Button from '@/components/Button';
import { Spinner } from '@ibe/components';

const TravelTypesSearchTab: FC<
  Pick<SearchProps, 'checkboxDispatch' | 'checkboxState' | 'isLoading'> & {
    fullSearchView?: boolean;
    closeCollapse?: () => void;
    calculatedProductsTotalResults?: number;
  }
> = ({
  checkboxDispatch,
  checkboxState,
  fullSearchView,
  closeCollapse,
  isLoading,
  calculatedProductsTotalResults
}): JSX.Element => {
  const { t } = useTranslation('search');
  const { travelTypes, totalProductsOverall } = checkboxState;

  const checkSingleTravelType = async (travelType: ApiTravelType): Promise<void> => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: travelType.id || '',
        name: travelType.name || '',
        type: 'travelType'
      }
    });
  };

  return (
    <>
      <div
        className={classNames('search__content search__content--checkboxes', {
          'search__content--checkboxes-expanded': fullSearchView
        })}
      >
        {travelTypes.map(travelType => (
          <CheckboxResults
            key={travelType.id}
            label={travelType.name}
            checked={!!travelType?.id && !!checkboxState.items[travelType.id]}
            onChange={(): Promise<void> => checkSingleTravelType(travelType)}
            results={travelType.products || 0}
            isLoading={isLoading}
          />
        ))}
      </div>
      {fullSearchView && (
        <div className="search__content__submit">
          <Button color="primary" onClick={closeCollapse} disabled={isLoading}>
            <span>{`${t(Keys.apply)} (${
              calculatedProductsTotalResults !== undefined
                ? calculatedProductsTotalResults
                : totalProductsOverall
            })`}</span>
            {isLoading && <Spinner />}
          </Button>
        </div>
      )}
    </>
  );
};

export default TravelTypesSearchTab;
