import React, { FC, ReactNode } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import parse from 'html-react-parser';
import sanitize from '@/Util/sanitize';

const TruncateContent: FC<{
  children: string | ReactNode;
  lines?: number;
  onTruncate?: (isTruncated: boolean) => void;
}> = ({ children, lines = 4, onTruncate }): JSX.Element => {
  return (
    <TruncateMarkup lines={lines} ellipsis="..." onTruncate={onTruncate}>
      <span className="truncated-markup">
        {typeof children === 'string' ? parse(sanitize(children, true)) : children}
      </span>
    </TruncateMarkup>
  );
};

export default TruncateContent;
