import React, { Dispatch, FC, JSX, SetStateAction } from 'react';
import { useTranslation } from '@/app/i18n/client';
import ResultsNavItem from '@/components/Search/ResultsNavItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCalendarSmall, facGlobeMagnifier, facLocation, facPerson } from '@/Theme/SVG/Icons';
import Keys from '@/Translations/generated/da/search.json.keys';
import RadioResults from '@/components/Search/RadioResults';

const SearchForResultsMobileOverview: FC<{
  singleRoomOnlyRadioChecked: boolean;
  setSingleRoomOnlyRadioChecked: Dispatch<SetStateAction<boolean>>;
  setContentIndex: Dispatch<SetStateAction<[number, number]>>;
  isLoading: boolean;
}> = ({
  singleRoomOnlyRadioChecked,
  setSingleRoomOnlyRadioChecked,
  setContentIndex,
  isLoading
}): JSX.Element => {
  const { t } = useTranslation('search');

  return (
    <div className="search-for-results__mobile__content__step search-for-results__mobile__content__overview">
      <ResultsNavItem
        label={
          <div>
            <FontAwesomeIcon icon={facLocation} />
            <span>{t(Keys.destinations)}</span>
          </div>
        }
        onClick={(): void => setContentIndex([2, 0])}
      />
      <ResultsNavItem
        label={
          <div>
            <FontAwesomeIcon icon={facGlobeMagnifier} />
            <span>{t(Keys.travelTypes)}</span>
          </div>
        }
        onClick={(): void => setContentIndex([2, 1])}
      />
      <ResultsNavItem
        label={
          <div>
            <FontAwesomeIcon icon={facCalendarSmall} />
            <span>{t(Keys.departureTime)}</span>
          </div>
        }
        onClick={(): void => setContentIndex([2, 2])}
      />
      {/* TODO: hide for now, see TODOs in searchForResults.scss */}
      {/*<RadioResults*/}
      {/*  label={*/}
      {/*    <>*/}
      {/*      <FontAwesomeIcon icon={facPerson} />*/}
      {/*      <span>{t(Keys.singleRoomOnly)}</span>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  checked={singleRoomOnlyRadioChecked}*/}
      {/*  onChange={(): void => setSingleRoomOnlyRadioChecked(value => !value)}*/}
      {/*  isLoading={isLoading}*/}
      {/*/>*/}
    </div>
  );
};

export default SearchForResultsMobileOverview;
