import React, { Dispatch, FC, JSX, SetStateAction, useState } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import BusItem from '@/components/busSelection/BusItem';
import AccommodationContent from '@/components/accommodations/AccommodationContent';
import IsoModal from '@/components/IsoModal';
import { ApiAccommodation } from '../../../api/model';
import { THEMES } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

const BusSelection: FC<{
  checkoutStore: CheckoutStore;
  stepChangeTriggered: boolean;
  pageProps?: Props;
  setIgnoreBusSeatValidation: Dispatch<SetStateAction<boolean>>;
  ignoreBusSeatValidation: boolean;
}> = observer(function BusSelection({
  checkoutStore,
  stepChangeTriggered,
  pageProps,
  setIgnoreBusSeatValidation,
  ignoreBusSeatValidation
}): JSX.Element {
  const [selectedBusAsAccommodation, setSelectedBusAsAccommodation] = useState<
    ApiAccommodation | undefined
  >(undefined);

  return (
    <>
      <div className="bus-teasers">
        {checkoutStore.bus.map(bus => {
          return (
            <BusItem
              key={bus.id}
              checkoutStore={checkoutStore}
              bus={bus}
              setSelectedBusAsAccommodation={setSelectedBusAsAccommodation}
              stepChangeTriggered={stepChangeTriggered}
              pageProps={pageProps}
              setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
              ignoreBusSeatValidation={ignoreBusSeatValidation}
            />
          );
        })}
      </div>
      <IsoModal
        className="accommodation__modal"
        toggle={(): void => setSelectedBusAsAccommodation(undefined)}
        isOpen={!!selectedBusAsAccommodation}
        onClose={(): void => setSelectedBusAsAccommodation(undefined)}
        theme={THEMES.blue}
      >
        <div className="accommodation__modal__container">
          <AccommodationContent
            accommodation={selectedBusAsAccommodation}
            simpleMode
            pageProps={pageProps}
          />
        </div>
      </IsoModal>
    </>
  );
});

export default BusSelection;
