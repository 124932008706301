'use client';

import React, { FC, useEffect, useState } from 'react';
import Form, { FormProps } from '@/components/magnoliaForm/Form';
import { Button, Collapse } from 'reactstrap';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/magnoliaForm.json.keys';
import { scrollIntoViewWithOffset } from '@/Util/globals';
import { useWindow } from '@ibe/components';

let timer1: ReturnType<typeof setTimeout> | null = null;
let timer2: ReturnType<typeof setTimeout> | null = null;

const FormContainer: FC<FormProps> = ({
  fieldsets,
  children,
  requiredSymbol,
  formTitle,
  errorTitle,
  successTitle,
  successMessage,
  emailSubject,
  emailText,
  reopenFormButtonText,
  emailReceiver
}): JSX.Element => {
  const { t } = useTranslation('magnoliaForm');
  const window = useWindow();
  const [isFormVisible, setIsFormVisible] = useState<boolean>(true);
  const [formKey, setFormKey] = useState<number>(1);
  const [formSentState, setFormSentState] = useState<'default' | 'success' | 'error'>('default');

  useEffect(() => {
    return () => {
      if (!!timer1) {
        clearTimeout(timer1);
      }
      if (!!timer2) {
        clearTimeout(timer2);
      }
    };
  }, []);

  const reopenForm = (): void => {
    setFormSentState('default');
    setFormKey(value => value + 1);
    timer1 = setTimeout(() => {
      setIsFormVisible(true);
      timer2 = setTimeout(() => {
        scrollIntoViewWithOffset('.magnolia-form', window, 150);
      }, 250);
    }, 250);
  };

  return (
    <>
      <Collapse isOpen={isFormVisible}>
        <Form
          key={formKey}
          fieldsets={fieldsets}
          requiredSymbol={requiredSymbol}
          formTitle={formTitle}
          errorTitle={errorTitle}
          successTitle={successTitle}
          successMessage={successMessage}
          emailSubject={emailSubject}
          emailText={emailText}
          formSentState={formSentState}
          setFormSentState={setFormSentState}
          setIsFormVisible={setIsFormVisible}
          emailReceiver={emailReceiver}
        >
          {children}
        </Form>
      </Collapse>
      <Collapse isOpen={!isFormVisible}>
        <div className="magnolia-form__reopen">
          <Button onClick={reopenForm} color="primary">
            {reopenFormButtonText || t(Keys.reopenFormButtonText)}
          </Button>
        </div>
      </Collapse>
      {formSentState === 'success' && !!successTitle && (
        <div className="magnolia-form__form-state magnolia-form__form-state--success">
          <div>{successTitle}</div>
          {!!successMessage && <div>{successMessage}</div>}
        </div>
      )}
    </>
  );
};

export default FormContainer;
