'use client';

import React, { FC, JSX, useEffect, useMemo, useRef, useState } from 'react';
import GridContainer from '@/Layouts/GridContainer';
import { EditableAreaOrPageChild } from '@/types/cms/magnolia';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import { useMediaQuery } from '@ibe/components';
import { useIsComponentMounted } from '@/Hooks/useIsComponentMounted';
import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';
import sanitize from '@/Util/sanitize';
const IframeResizer = dynamic(
  () => import('@open-iframe-resizer/react').then(module => module.IframeResizer),
  { ssr: false }
);

const IFrame: FC<
  EditableAreaOrPageChild & {
    iframeUrl: string;
    urlParams?: string;
    useIframeResizer: 'true' | 'false';
    iframeHeights: {
      defaultHeight: string;
      sm?: string;
      md?: string;
      xm?: string;
      lg?: string;
      xl?: string;
      xxl?: string;
    };
  }
> = ({ iframeUrl, iframeHeights, useIframeResizer, urlParams }): JSX.Element => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const isMounted = useIsComponentMounted();
  const searchParams = useSearchParams();
  const isSm = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.sm });
  const isMd = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const isXm = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xm });
  const isLg = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.lg });
  const isXl = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xl });
  const isXxl = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xxl });

  const [finalIframeUrl, setFinalIframeUrl] = useState<string>('');

  useEffect(() => {
    if (!urlParams) {
      setFinalIframeUrl(iframeUrl);
    } else {
      const urlParamsList = urlParams.split(',');
      const urlObject = new URL(iframeUrl);
      if (!!urlObject) {
        const existingQueryParams = new URLSearchParams(urlObject.search);
        for (let i = 0; i < urlParamsList.length; i++) {
          const param = urlParamsList[i].trim();
          const value = searchParams.get(param);
          if (!!value) {
            existingQueryParams.append(param, sanitize(value));
          }
        }
        urlObject.search = existingQueryParams.toString();
        setFinalIframeUrl(urlObject.toString());
      }
    }
  }, [urlParams, searchParams]);

  const height = useMemo(() => {
    const { defaultHeight, sm, md, xm, lg, xl, xxl } = iframeHeights || {};
    if (isXxl) {
      return parseInt(xxl || xl || lg || xm || md || sm || defaultHeight, 10);
    } else if (!isXxl && isXl) {
      return parseInt(xl || lg || xm || md || sm || defaultHeight, 10);
    } else if (!isXxl && !isXl && isLg) {
      return parseInt(lg || xm || md || sm || defaultHeight, 10);
    } else if (!isXxl && !isXl && !isLg && isXm) {
      return parseInt(xm || md || sm || defaultHeight, 10);
    } else if (!isXxl && !isXl && !isLg && !isXm && isMd) {
      return parseInt(md || sm || defaultHeight, 10);
    } else if (!isXxl && !isXl && !isLg && !isXm && !isMd && isSm) {
      return parseInt(sm || defaultHeight, 10);
    } else {
      return parseInt(defaultHeight, 10);
    }
  }, [iframeHeights, isSm, isMd, isXm, isLg, isXl, isXxl]);

  return isMounted && !!finalIframeUrl ? (
    <GridContainer>
      {useIframeResizer === 'true' ? (
        <IframeResizer
          src={finalIframeUrl}
          seamless
          style={{
            border: '0',
            width: '100%',
            height: '100dvh'
          }}
        />
      ) : (
        <iframe
          ref={iframeRef}
          src={finalIframeUrl}
          seamless
          style={{
            border: '0',
            width: '100%',
            height: `${height}px`
          }}
        />
      )}
    </GridContainer>
  ) : (
    <></>
  );
};

export default IFrame;
