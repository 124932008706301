import React, { Fragment, JSX, useCallback, useEffect } from 'react';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import SimpleAccordion from '@/components/accordion/SimpleAccordion';
import { useTransitionMap } from 'react-transition-state';
import SightseeingContent from '@/components/sightseeing/SightseeingContent';
import { ApiSightseeingItem } from '../../../api/model';
import { EditableAreaOrPageChild } from '@/types/cms/magnolia';

const SightseeingList = ({
  sightseeingItems,
  theme,
  pageProps
}: {
  sightseeingItems?: ApiSightseeingItem[];
  theme: string;
} & Partial<EditableAreaOrPageChild>): JSX.Element => {
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });

  const { stateMap, toggle, setItem } = useTransitionMap<string>({
    timeout: 0,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    if (!!sightseeingItems && sightseeingItems.length > 0) {
      sightseeingItems.forEach(sightseeingItem => {
        sightseeingItem.id && setItem(sightseeingItem?.id);
      });

      sightseeingItems[0].id && toggle(sightseeingItems[0].id);
    }
  }, [sightseeingItems]);

  const handleSightseeingClick = useCallback((id: string) => {
    toggle(id);
  }, []);

  return (
    <div className={`sightseeing grid-section ${theme}`}>
      {!isDesktop ? (
        <>
          {!!sightseeingItems && sightseeingItems.length > 0 && (
            <SimpleAccordion
              groups={
                sightseeingItems.map(sightseeingItem => ({
                  groupHeader: sightseeingItem?.title || '',
                  group: [
                    {
                      sectionHeader: '',
                      sectionText: (
                        <SightseeingContent sightseeingItem={sightseeingItem} hideTitles={true} />
                      )
                    }
                  ]
                })) || []
              }
            />
          )}
        </>
      ) : (
        <>
          <div className="sightseeing__container">
            <div className="sightseeing__list">
              <div className="sightseeing__list__sticky">
                {!!sightseeingItems &&
                  sightseeingItems.length > 0 &&
                  sightseeingItems.map(sightseeingItem =>
                    sightseeingItem && sightseeingItem.id ? (
                      <div
                        key={sightseeingItem.id}
                        className={`sightseeing__name${
                          stateMap.get(sightseeingItem.id)?.isMounted ? ' active' : ''
                        }`}
                        onClick={() => handleSightseeingClick(sightseeingItem.id || '')}
                      >
                        <h4>{sightseeingItem.title}</h4>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
              </div>
            </div>
            {!!sightseeingItems &&
              sightseeingItems.length > 0 &&
              sightseeingItems.map(sightseeingItem =>
                sightseeingItem.id ? (
                  <Fragment key={sightseeingItem.id}>
                    {stateMap.get(sightseeingItem.id)?.status !== 'unmounted' &&
                      stateMap.get(sightseeingItem.id)?.isMounted && (
                        <SightseeingContent
                          sightseeingItem={sightseeingItem}
                          className={`${
                            stateMap.get(sightseeingItem.id)?.status !== 'preEnter' &&
                            stateMap.get(sightseeingItem.id)?.status !== 'exiting'
                              ? 'sightseeing__content--visible'
                              : ''
                          }${
                            stateMap.get(sightseeingItem.id)?.status === 'exiting'
                              ? ' sightseeing__content--exiting'
                              : ''
                          }`}
                          pageProps={pageProps}
                        />
                      )}
                  </Fragment>
                ) : (
                  <></>
                )
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default SightseeingList;
