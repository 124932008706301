import React, { ChangeEvent, FC, JSX } from 'react';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import Keys from '@/Translations/generated/da/search.json.keys';
import { useTranslation } from '@/app/i18n/client';
import TextSearchResults from '@/components/Search/TextSearchResults';
import { Spinner } from '@ibe/components';

const TextSearchMobile: FC<
  Pick<
    SearchProps,
    | 'inputValue'
    | 'handleInputChange'
    | 'totalNumberOfProducts'
    | 'textSearch'
    | 'textSearchInputRef'
    | 'isLoading'
    | 'productPageBasePath'
    | 'resultsPageBasePath'
    | 'isInitialSearch'
  >
> = ({
  inputValue,
  handleInputChange,
  totalNumberOfProducts,
  textSearch,
  textSearchInputRef,
  isLoading,
  productPageBasePath,
  resultsPageBasePath
}): JSX.Element => {
  const { t } = useTranslation('search');

  return (
    <div className="search__content__mobile search__content__mobile__input">
      <div className="position-relative">
        <input
          ref={textSearchInputRef}
          placeholder={t(Keys.inputPlaceholder, { count: totalNumberOfProducts })}
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => handleInputChange(e.target.value)}
        />
        {isLoading && (
          <div className="search__content__spinner">
            <Spinner />
          </div>
        )}
      </div>
      <TextSearchResults
        textSearch={textSearch}
        productPageBasePath={productPageBasePath}
        resultsPageBasePath={resultsPageBasePath}
      />
    </div>
  );
};

export default TextSearchMobile;
