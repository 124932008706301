import React, { FC, JSX, useMemo, useRef } from 'react';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import { Action, ReducerStateItem } from '@/components/Search/searchReducer/types';
import Pill from '@/components/SearchForResults/Pill';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/search.json.keys';
import { useHorizontalDrag } from '@ibe/components';
import classNames from 'classnames';
import useScroll from '@/Hooks/useScroll';
import { useTransition } from 'react-transition-state';

type ReducerStateItemExtended = ReducerStateItem & {
  id: string;
  continentId?: string;
  continentName?: string;
};

const Pills: FC<Pick<SearchProps, 'checkboxState' | 'checkboxDispatch' | 'isLoading'>> = ({
  checkboxDispatch,
  checkboxState,
  isLoading
}): JSX.Element => {
  const { t } = useTranslation('search');
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [{ status: statusLeft, isMounted: isMountedLeft }, toggleLeft] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });
  const [{ status: statusRight, isMounted: isMountedRight }, toggleRight] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  const { isDown } = useHorizontalDrag('search-results-content-pills', 350);

  useScroll(
    (evt?: Event, containerElement?: HTMLElement | Window | null): void => {
      const evtTarget =
        (evt?.target as HTMLDivElement | undefined) || (containerElement as HTMLElement);
      if (!evtTarget) return;
      if (evtTarget.scrollLeft <= 5 || evtTarget.scrollWidth <= evtTarget.clientWidth) {
        toggleLeft(false);
      } else {
        toggleLeft(true);
      }

      if (
        evtTarget.scrollLeft >= evtTarget.scrollWidth - evtTarget.clientWidth - 5 ||
        evtTarget.scrollWidth <= evtTarget.clientWidth
      ) {
        toggleRight(false);
      } else {
        toggleRight(true);
      }
    },
    [scrollContainer, toggleLeft, toggleRight],
    scrollContainer?.current as HTMLElement,
    1000
  );

  const flattenedStateItems = useMemo((): ReducerStateItemExtended[] => {
    return Object.entries(checkboxState.items).reduce(
      (total: ReducerStateItemExtended[], [key, item]: [string, ReducerStateItem]) => {
        return [
          ...total,
          ...(!!item.countries
            ? Object.entries(item.countries).map(([id, name]) => ({
                id,
                continentId: key,
                continentName: item.name,
                type: item.type,
                name
              }))
            : [{ ...item, id: key }])
        ];
      },
      []
    );
  }, [checkboxState]);

  const removePill = (item: ReducerStateItemExtended) => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload:
        item.type === 'continent'
          ? {
              id: item.continentId || '',
              type: 'continent',
              name: item.continentName || '',
              items: [{ id: item.id, name: item.name }]
            }
          : item.type === 'travelType'
          ? {
              id: item.id,
              name: item.name,
              type: 'travelType'
            }
          : {
              id: item.id,
              name: item.name,
              type: 'date'
            }
    });
  };

  const removeAllPills = (): void => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_ALL,
      payload: {
        id: '',
        type: 'none',
        name: ''
      }
    });
  };

  return (
    <div className="search__content__pills__container">
      {statusLeft !== 'unmounted' && isMountedLeft && (
        <div
          className={classNames('search__content__pills__fog search__content__pills__fog--left', {
            'search__content__pills__fog--visible':
              statusLeft !== 'preEnter' && statusLeft !== 'exiting'
          })}
        />
      )}
      <div
        ref={scrollContainer}
        id="search-results-content-pills"
        className={classNames('search__content__pills', { 'search__content__pills--grab': isDown })}
      >
        {flattenedStateItems.map(item => (
          <Pill key={item.id} onClick={(): void => removePill(item)} disabled={isLoading}>
            {item.name}
          </Pill>
        ))}
        {flattenedStateItems.length > 2 && (
          <div className="search__content__pills__remove-all" onClick={removeAllPills}>
            {t(Keys.clearAll)}
          </div>
        )}
      </div>
      {statusRight !== 'unmounted' && isMountedRight && (
        <div
          className={classNames('search__content__pills__fog search__content__pills__fog--right', {
            'search__content__pills__fog--visible':
              statusRight !== 'preEnter' && statusRight !== 'exiting'
          })}
        />
      )}
    </div>
  );
};

export default Pills;
