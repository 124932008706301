'use client';

import React, { ReactElement } from 'react';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import { Props as PageProps } from '@/types/cms/magnolia';

type Props = {
  name?: string;
  showButton?: boolean;
  theme?: string;
  leftSide?: ReactElement;
  rightSide?: ReactElement;
  headline?: string;
  disableBreadcrumb?: boolean;
  singleColumn?: ReactElement;
  className?: string;
  pageProps?: PageProps;
  pathname: string;
};

const Intro = (props: Props): ReactElement => {
  const {
    theme,
    leftSide,
    rightSide,
    headline,
    disableBreadcrumb,
    singleColumn,
    className,
    pageProps,
    pathname
  } = props;

  return (
    <>
      <div className={`intro ${theme || ''} ${className || ''}`}>
        {!disableBreadcrumb && <Breadcrumb pageProps={pageProps} pathname={pathname} />}
        {!!headline && <h2 className="intro__container__headline">{headline}</h2>}
        <div className="intro__container d-lg-flex">
          {!!singleColumn ? (
            <div className="intro__container__single">{singleColumn}</div>
          ) : (
            <>
              <div className="intro__container__left">{!!leftSide ? leftSide : <></>}</div>
              <div className="intro__container__right">{!!rightSide ? rightSide : <></>}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Intro;
