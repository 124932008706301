import React, { FC, JSX, useMemo } from 'react';
import { ApiTransfer } from '@ibe/api';
import CheckoutStore, { ApiComponentAdvanced } from '@/templates/checkout/CheckoutStore';
import SelectionItem from '@/components/selectionItems/SelectionItem';
import SelectionItems from '@/components/selectionItems/SelectionItems';
import { observer } from 'mobx-react';
import { Props } from '@/types/cms/magnolia';

const Transfers: FC<{
  transferComponent: ApiComponentAdvanced<ApiTransfer>;
  checkoutStore: CheckoutStore;
  pageProps?: Props;
}> = observer(function Transfers({ transferComponent, checkoutStore, pageProps }): JSX.Element {
  const { selectableItems: allTransfers, selectedItems: selectedTransfers, id } = transferComponent;
  const selectedTransfer = selectedTransfers[0];

  const sortedTransfers = useMemo(
    (): ApiTransfer[] =>
      allTransfers.slice().sort((a, b) => {
        if (!a.units[0]?.price?.finalPrice && !!b.units[0]?.price?.finalPrice) {
          return -1;
        } else if (!!a.units[0]?.price?.finalPrice && !b.units[0]?.price?.finalPrice) {
          return 1;
        } else if (!a.units[0]?.price?.finalPrice && !b.units[0]?.price?.finalPrice) {
          return a.name.localeCompare(b.name);
        } else {
          return a.units[0]?.price?.finalPrice - b.units[0]?.price?.finalPrice;
        }
      }),
    [allTransfers]
  );

  return (
    <SelectionItems
      numberOfItems={allTransfers.length}
      label={allTransfers?.[0]?.name || ''}
      className="selection-items__transfer"
    >
      {sortedTransfers.map((transfer: ApiTransfer) => {
        return (
          <SelectionItem
            key={transfer.units[0].id}
            itemId={transfer.id}
            selectedItemId={selectedTransfer?.id}
            cartSelectionId={transfer.units[0].id}
            isOnlyItem={allTransfers.length === 1}
            componentId={id}
            checkoutStore={checkoutStore}
            name={transfer.units?.[0]?.description || transfer.units?.[0]?.description || ''}
            price={transfer.units[0].price}
            pageProps={pageProps}
          />
        );
      })}
    </SelectionItems>
  );
});

export default Transfers;
