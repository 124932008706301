import { ApiProduct } from '../../../api/model';
import dayjs from 'dayjs';
import { VARIANT_QUERY_PROP_DELIMITER } from '@/Util/globals';
import { ApiProductsCacheResponse } from '@ibe/api';

export const getVariantParam = (
  product?: ApiProductsCacheResponse,
  travelStartDate?: string,
  variants?: ApiProduct[],
  productPackagePackageCode?: string
): string => {
  if (!travelStartDate || !dayjs(travelStartDate).isValid()) {
    return '';
  }
  const travelStart = dayjs(travelStartDate);
  let dateOnly = '';
  if (travelStart.isValid()) {
    const month = travelStart.month() + 1;
    const day = travelStart.date();
    dateOnly = `${travelStart.year()}${month <= 9 ? '0' : ''}${month}${day <= 9 ? '0' : ''}${day}`;
  }
  if (!variants || (!!variants && variants.length === 0)) {
    return dateOnly;
  } else {
    return variants.reduce((total, current) => {
      if (
        !!productPackagePackageCode &&
        !!product &&
        product.variants?.some(
          variant =>
            (variant.packageCode || (variant as ApiProduct).name) === productPackagePackageCode
        ) &&
        !!current.name &&
        !!dateOnly
      ) {
        return `${productPackagePackageCode}${VARIANT_QUERY_PROP_DELIMITER}${dateOnly}`;
      } else {
        return total;
      }
    }, dateOnly);
  }
};
