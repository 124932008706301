import React, { FC, JSX, useMemo, useState } from 'react';
import CheckoutStore, { ApiComponentAdvanced } from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import Excursion from '@/components/checkoutExcursions/Excursion';
import { ApiAccommodation, ApiAccommodationFromJSON, ApiExcursion } from '../../../api/model';
import AccommodationContent from '@/components/accommodations/AccommodationContent';
import IsoModal from '@/components/IsoModal';
import { ApiExtraService } from '@ibe/api';
import { THEMES } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

const SingleExcursions: FC<{
  checkoutStore: CheckoutStore;
  externalExcursions?: ApiComponentAdvanced<ApiExtraService>[];
  pageProps?: Props;
  selectOrDeselectExternalExcursion?: (excursion: ApiExtraService, select: boolean) => void;
}> = observer(function SingleExcursions({
  checkoutStore,
  externalExcursions,
  selectOrDeselectExternalExcursion,
  pageProps
}): JSX.Element {
  const [selectedExcursion, setSelectedExcursion] = useState<ApiExcursion | undefined>(undefined);

  const transformedSelectedExcursion = useMemo((): ApiAccommodation | undefined => {
    return !!selectedExcursion
      ? ApiAccommodationFromJSON({
          id: selectedExcursion.id,
          name: selectedExcursion.name,
          images: selectedExcursion.images,
          shortDescription: selectedExcursion.description,
          rooms: []
        })
      : undefined;
  }, [selectedExcursion]);

  return (
    <>
      <div className="single-excursions">
        {(externalExcursions || checkoutStore.singleExcursions).map(singleExcursion =>
          !!singleExcursion.selectableItems[0] ? (
            <Excursion
              key={singleExcursion.id}
              excursion={singleExcursion.selectableItems[0]}
              isSelected={singleExcursion.selectedItems?.length > 0}
              checkoutStore={checkoutStore}
              setSelectedExcursion={setSelectedExcursion}
              componentId={singleExcursion.id}
              selectOrDeselectExternalExcursion={selectOrDeselectExternalExcursion}
              pageProps={pageProps}
            />
          ) : (
            <></>
          )
        )}
      </div>
      <IsoModal
        className="accommodation__modal"
        toggle={(): void => setSelectedExcursion(undefined)}
        isOpen={!!selectedExcursion}
        onClose={(): void => setSelectedExcursion(undefined)}
        theme={THEMES.blue}
      >
        <div className="accommodation__modal__container">
          <AccommodationContent
            accommodation={transformedSelectedExcursion}
            simpleMode
            pageProps={pageProps}
          />
        </div>
      </IsoModal>
    </>
  );
});

export default SingleExcursions;
