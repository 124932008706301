/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
* 
* @export
* @interface ApiCta
*/
export interface ApiCta {
    /**
    * 
    * @type {string}
    * @memberof ApiCta
    */
    label?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCta
    */
    linkType: string;
    /**
    * 
    * @type {object}
    * @memberof ApiCta
    */
    linkTypepage?: object;
    /**
    * 
    * @type {string}
    * @memberof ApiCta
    */
    linkTypeexternal?: string;
    /**
    * 
    * @type {object}
    * @memberof ApiCta
    */
    linkTypedownload?: object;
    /**
    * 
    * @type {object}
    * @memberof ApiCta
    */
    linkTypecontinent?: object;
    /**
    * 
    * @type {object}
    * @memberof ApiCta
    */
    linkTypecountry?: object;
    /**
    * 
    * @type {object}
    * @memberof ApiCta
    */
    linkTypecity?: object;
    /**
    * 
    * @type {object}
    * @memberof ApiCta
    */
    linkTypetravelType?: object;
    /**
    * 
    * @type {object}
    * @memberof ApiCta
    */
    linkTypeproduct?: object;
}

export function ApiCtaFromJSON(json: any): ApiCta {
return ApiCtaFromJSONTyped(json, false);
}

export function ApiCtaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCta {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'label': json['label'],
                    'linkType': json['linkType'],
                    'linkTypepage': json['linkTypepage'],
                    'linkTypeexternal': json['linkTypeexternal'],
                    'linkTypedownload': json['linkTypedownload'],
                    'linkTypecontinent': json['linkTypecontinent'],
                    'linkTypecountry': json['linkTypecountry'],
                    'linkTypecity': json['linkTypecity'],
                    'linkTypetravelType': json['linkTypetravelType'],
                    'linkTypeproduct': json['linkTypeproduct'],
        };
    }

    export function ApiCtaToJSON(value?: ApiCta | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'label': value.label,
                'linkType': value.linkType,
                'linkTypepage': value.linkTypepage,
                'linkTypeexternal': value.linkTypeexternal,
                'linkTypedownload': value.linkTypedownload,
                'linkTypecontinent': value.linkTypecontinent,
                'linkTypecountry': value.linkTypecountry,
                'linkTypecity': value.linkTypecity,
                'linkTypetravelType': value.linkTypetravelType,
                'linkTypeproduct': value.linkTypeproduct,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

