import React, { FC } from 'react';
import classNames from 'classnames';
import Checkbox, { CheckboxProps } from '@/components/Search/Checkbox';

const CheckboxResults: FC<CheckboxProps & { results: number; isLoading?: boolean }> = ({
  results,
  isLoading,
  ...rest
}) => {
  const { checked } = rest;

  return (
    <div className="checkbox-results">
      <Checkbox {...rest} disabled={isLoading} />
      <div
        className={classNames('checkbox-results__amount', {
          'checkbox-results__amount--checked': checked
        })}
      >
        {results}
      </div>
    </div>
  );
};

export default CheckboxResults;
