import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { CHECKOUT_BOOKING_ID_QUERY_PARAM, CHECKOUT_STATUS_QUERY_PARAM } from '@/Util/globals';
import CheckoutStore, {
  CHECKOUT_LOCAL_STORAGE_KEY,
  CheckoutLocalStorage
} from '@/templates/checkout/CheckoutStore';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { FormRefs } from '@/components/checkout/ParticipantsForm';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { BookingIdAfterInvoiceBooking } from '@/components/checkout/checkoutStepConfirmation/CheckoutStepConfirmation';
import { SessionPersistence } from '@ibe/services';

export const handleFormValues = async (
  router: AppRouterInstance,
  idx: number,
  checkoutStore: CheckoutStore,
  activeIndex: number,
  activeSubIndex: number[],
  setActiveSubIndex: Dispatch<SetStateAction<number[]>>,
  setActiveIndex: Dispatch<SetStateAction<number>>,
  setBookingIdAfterInvoiceBooking: Dispatch<SetStateAction<BookingIdAfterInvoiceBooking>>,
  activePaymentRadio: number,
  activePaymentTab: number,
  participantsFormRefs: MutableRefObject<FormRefs>,
  checkForTermsAndConditions?: boolean,
  checkForMediaCode?: boolean,
  increaseSubStep?: boolean
): Promise<void> => {
  let rerouteUrl = '';
  try {
    const formValues = await Promise.all(
      participantsFormRefs.current.map(ref => ref?.triggerSubmit())
    );
    let remarksForm = undefined;
    if (formValues.length === (checkoutStore.selectedPacificProduct?.maxOccupancy || 1) + 1) {
      remarksForm = formValues.pop();
    }
    if (
      !formValues ||
      (!!formValues && formValues.some(form => Object.keys(form).length === 0)) ||
      (checkForTermsAndConditions && !checkoutStore.termsAndConditionsChecked) ||
      (checkForMediaCode && !checkoutStore.mediaCode.translated)
    ) {
      if (checkForMediaCode && !checkoutStore.mediaCode.translated) {
        checkoutStore.mediaCodeError = true;
      }
      const firstErrorField = window?.document?.getElementsByClassName('input--error')?.[0];
      const mediaCodeErrorField = window?.document?.getElementsByClassName(
        'media-codes--error'
      )?.[0];
      const firstField = firstErrorField || mediaCodeErrorField;
      if (!!firstField) {
        window?.scrollTo({
          top: firstField.getBoundingClientRect().top + (window?.scrollY || 0) - 100,
          left: 0,
          behavior: 'smooth'
        });
      }
      return;
    }
    if (increaseSubStep) {
      let currentActiveSubIndex = [...activeSubIndex];
      currentActiveSubIndex[activeIndex] = currentActiveSubIndex[activeIndex] + 1;
      setActiveSubIndex(currentActiveSubIndex);
    } else {
      checkoutStore.isLoading = true;
      rerouteUrl = await checkoutStore.handleBooking(
        formValues,
        activePaymentRadio === 0,
        activePaymentTab === 0,
        remarksForm
      );
    }
  } catch (err) {
    checkoutStore.errorMessage = Keys.bookingOrCartError;
  } finally {
    checkoutStore.isLoading = false;
  }
  const storage = new SessionPersistence<CheckoutLocalStorage>(CHECKOUT_LOCAL_STORAGE_KEY);
  storage.set({
    productName: checkoutStore.product.productTitle,
    productCode: checkoutStore.product.name,
    productTeaserImage: checkoutStore.product.teaserImageLarge
  });
  if (!!rerouteUrl && activePaymentRadio === 0) {
    router.push(rerouteUrl);
  } else if (!!rerouteUrl && activePaymentRadio === 1) {
    const url = new URL(`${window?.location?.origin || 'https://localhost'}${rerouteUrl}`);
    setBookingIdAfterInvoiceBooking({
      bookingId: url.searchParams.get(CHECKOUT_BOOKING_ID_QUERY_PARAM),
      status: url.searchParams.get(CHECKOUT_STATUS_QUERY_PARAM)
    });
    setActiveIndex(idx);
  }
};

export const handleSubmit = async (
  router: AppRouterInstance,
  idx: number,
  checkoutStore: CheckoutStore,
  activeIndex: number,
  activeSubIndex: number[],
  setActiveSubIndex: Dispatch<SetStateAction<number[]>>,
  setActiveIndex: Dispatch<SetStateAction<number>>,
  setBookingIdAfterInvoiceBooking: Dispatch<SetStateAction<BookingIdAfterInvoiceBooking>>,
  activePaymentRadio: number,
  activePaymentTab: number,
  participantsFormRefs: MutableRefObject<FormRefs>
): Promise<void> => {
  if (!checkoutStore.termsAndConditionsChecked) {
    checkoutStore.termsAndConditionsNotCheckedError = true;
  }
  if (!checkoutStore.mediaCode.translated) {
    checkoutStore.mediaCodeError = true;
  }
  await handleFormValues(
    router,
    idx,
    checkoutStore,
    activeIndex,
    activeSubIndex,
    setActiveSubIndex,
    setActiveIndex,
    setBookingIdAfterInvoiceBooking,
    activePaymentRadio,
    activePaymentTab,
    participantsFormRefs,
    true,
    true
  );
};

export const handleItemClickReplaceLogicCallback = async (
  router: AppRouterInstance,
  idx: number,
  checkoutStore: CheckoutStore,
  isDesktop: boolean,
  lastStepInDesktop: number,
  activeIndex: number,
  activeSubIndex: number[],
  numberOfSubStepsPerStep: number[],
  setActiveSubIndex: Dispatch<SetStateAction<number[]>>,
  setActiveIndex: Dispatch<SetStateAction<number>>,
  setBookingIdAfterInvoiceBooking: Dispatch<SetStateAction<BookingIdAfterInvoiceBooking>>,
  activePaymentRadio: number,
  activePaymentTab: number,
  participantsFormRefs: MutableRefObject<FormRefs>
): Promise<void> => {
  // if it's the step after the formStep (second index in second last step) or after the mediaCode step in mobile
  if (
    !isDesktop &&
    idx === lastStepInDesktop &&
    (activeSubIndex[activeIndex] === 0 || activeSubIndex[activeIndex] === 1)
  ) {
    await handleFormValues(
      router,
      idx,
      checkoutStore,
      activeIndex,
      activeSubIndex,
      setActiveSubIndex,
      setActiveIndex,
      setBookingIdAfterInvoiceBooking,
      activePaymentRadio,
      activePaymentTab,
      participantsFormRefs,
      false,
      activeSubIndex[activeIndex] === 1,
      true
    );
  } else if (
    (isDesktop && idx === lastStepInDesktop) ||
    (!isDesktop &&
      idx === lastStepInDesktop &&
      activeSubIndex[activeIndex] === numberOfSubStepsPerStep[activeIndex] - 1)
  ) {
    await handleSubmit(
      router,
      idx,
      checkoutStore,
      activeIndex,
      activeSubIndex,
      setActiveSubIndex,
      setActiveIndex,
      setBookingIdAfterInvoiceBooking,
      activePaymentRadio,
      activePaymentTab,
      participantsFormRefs
    );
  }
};
