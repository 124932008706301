'use client';

import React, { ReactElement, useMemo } from 'react';
import { MagnoliaVideoContent, MagnoliaVideos } from '@/types/cms/magnolia';
import VideoGalleryInner from '@/components/videoGallery/VideoGalleryInner';
import { ApiVideoContent } from '../../../api/model';
import { isMagnoliaVideos } from '@/types/typeGuards';

interface Props {
  videos: MagnoliaVideos | ApiVideoContent[];
}

const VideoGallery = (props: Props): ReactElement => {
  const { videos } = props;

  const transformedVideos = useMemo(() => {
    return isMagnoliaVideos(videos)
      ? videos['@nodes'].map(node => videos[node as keyof MagnoliaVideoContent])
      : videos;
  }, []);

  return <VideoGalleryInner videos={transformedVideos} />;
};

export default VideoGallery;
