import React, { FC, JSX } from 'react';
import { ApiBooking, ApiFlightItem, ApiItemType, ApiTraveler } from '@ibe/api';
import { isHotel } from '@/components/checkout/cart/getAccommodations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCalendar, facDuration, facPerson } from '@/Theme/SVG/Icons';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import dayjs from 'dayjs';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react';
import { ApiAirlineLogo } from '../../../../api/model';
import Image from '@/components/image/Image';
import { getDefaultFormatPrice } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

dayjs.extend(utc);

const Lower: FC<{
  booking: ApiBooking;
  checkoutStore: CheckoutStore;
  airlineLogos?: ApiAirlineLogo[];
  productName?: string;
  pageProps?: Props;
}> = observer(function Lower({
  booking,
  checkoutStore,
  airlineLogos,
  pageProps,
  productName
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const config = useConfig();
  const locale = useCurrentLanguage();
  const hotel = booking.bookedItems.find(item => isHotel(item));
  const packageItem = booking.bookedItems.find(item => item.itemType === ApiItemType.PACKAGE);
  const flights = booking.items.filter(
    item => item.itemType === ApiItemType.FLIGHT
  ) as ApiFlightItem[];
  const numberOfDays = dayjs
    .utc(booking.travelEndDate)
    .diff(dayjs.utc(booking.travelStartDate), 'day');
  const numberOfTravelers = booking.travelers.length;
  const travelerApplication = booking.travelers[0];

  return (
    <div className="confirmation__lower">
      {!!hotel && (
        <div className="confirmation__lower__hotel">
          <div className="confirmation__lower__hotel__name">
            {productName || packageItem?.name || hotel.name}
          </div>
          <div className="confirmation__lower__hotel__info">
            <div className="checkout__data">
              <div>
                <FontAwesomeIcon icon={facDuration} />
                <span>{t(Keys.day, { count: numberOfDays })}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={facCalendar} />
                <span>{`${dayjs
                  .utc(booking.travelStartDate)
                  .format(config.displayFormatDateShort[locale])
                  .slice(0, -5)} - ${dayjs
                  .utc(booking.travelEndDate)
                  .format(config.displayFormatDateShort[locale])}`}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={facPerson} />
                <span>{t(Keys.traveler, { count: numberOfTravelers })}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="confirmation__lower__travelers">
        <div className="confirmation__lower__travelers__inner">
          <div className="confirmation__lower__applicant">
            <div className="confirmation__lower__applicant__label">{t(Keys.travelApplication)}</div>
            <div className="confirmation__lower__applicant__container">
              <div className="confirmation__lower__applicant__address">
                <div>{travelerApplication?.salutation}</div>
                <div>{`${travelerApplication?.firstName} ${travelerApplication?.lastName}`}</div>
                <div>{travelerApplication?.address?.street || ''}</div>
                <div>{`${travelerApplication?.address?.postalCode || ''} ${
                  travelerApplication?.address?.city
                }`}</div>
                <div>
                  {checkoutStore.baseData?.countries?.find(
                    country => country.code === travelerApplication?.address?.countryCode
                  )?.description || travelerApplication?.address?.countryCode}
                </div>
              </div>
              <div className="confirmation__lower__applicant__contact">
                <div>{`${t(Keys.emailLabel)} ${
                  travelerApplication?.communicationDetails?.email || ''
                }`}</div>
                <div>{`${t(Keys.phoneLabel)} ${
                  travelerApplication?.communicationDetails?.phone || ''
                }`}</div>
              </div>
            </div>
          </div>
          <div className="confirmation__lower__travelers__list">
            <div className="confirmation__lower__travelers__list__label">
              {t(Keys.travelParticipantSimple)}
            </div>
            <table>
              <tbody>
                {booking.travelers.map((traveler: ApiTraveler, idx: number) => (
                  <tr key={traveler.id}>
                    <th>{`${idx + 1}. ${t(Keys.travelerAlt)}`}</th>
                    <th>{traveler.salutation}</th>
                    <th>{traveler.firstName}</th>
                    <th>{traveler.lastName}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {!!booking.notes && booking.notes.length > 0 && (
          <div className="confirmation__lower__travelers__remarks">
            <div className="confirmation__lower__travelers__list__label">{t(Keys.notes)}</div>
            <div>{booking.notes[0].text}</div>
          </div>
        )}
      </div>
      {flights.length > 0 && flights[0]?.segment?.length > 0 && (
        <>
          <hr />
          <div className="confirmation__lower__flight">
            <div className="confirmation__lower__flight__headline">{t(Keys.flightData)}</div>
            {flights.map(flight => (
              <div key={flight.id} className="confirmation__lower__flight__inner">
                <div className="confirmation__lower__flight__labels">
                  <div>{t(Keys.flightDataDeparture)}</div>
                  <div>{t(Keys.flightDataReturn)}</div>
                </div>
                <div className="confirmation__lower__flight__data">
                  <table>
                    <tbody>
                      <tr>
                        <th>{`${
                          flight.segment[0].origin.description || flight.segment[0].origin.code
                        } - ${
                          flight.segment[0].destination.description ||
                          flight.segment[0].destination.code
                        }`}</th>
                        {!checkoutStore.hasInvalidFlights(flights) && (
                          <>
                            <th>{`${dayjs
                              .utc(flight.segment[0].departure)
                              .format('HH:mm')} - ${dayjs
                              .utc(flight.segment[0].arrival)
                              .format('HH:mm')}`}</th>
                            {!!airlineLogos?.[0]?.logo && (
                              <th>
                                <Image
                                  className="flight-legs__stops__image"
                                  src={airlineLogos[0].logo.imageLink || ''}
                                  width={airlineLogos[0].logo.width}
                                  height={airlineLogos[0].logo.height}
                                  alt=""
                                  useDefaultLoader
                                  serverSideContext={pageProps?.albConfig.context}
                                />
                              </th>
                            )}
                          </>
                        )}
                      </tr>
                      <tr>
                        <th>{`${
                          flight.segment[flight.segment.length - 1].origin.description ||
                          flight.segment[flight.segment.length - 1].origin.code
                        } - ${
                          flight.segment[flight.segment.length - 1].destination.description ||
                          flight.segment[flight.segment.length - 1].destination.code
                        }`}</th>
                        {!checkoutStore.hasInvalidFlights(flights) && (
                          <>
                            <th>{`${dayjs
                              .utc(flight.segment[flight.segment.length - 1].departure)
                              .format('HH:mm')} - ${dayjs
                              .utc(flight.segment[flight.segment.length - 1].arrival)
                              .format('HH:mm')}`}</th>

                            {!!airlineLogos?.[1]?.logo && (
                              <th>
                                <Image
                                  className="flight-legs__stops__image"
                                  src={airlineLogos[1].logo.imageLink || ''}
                                  width={airlineLogos[1].logo.width}
                                  height={airlineLogos[1].logo.height}
                                  alt=""
                                  useDefaultLoader
                                  serverSideContext={pageProps?.albConfig.context}
                                />
                              </th>
                            )}
                          </>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <hr />
      <div className="confirmation__lower__total">
        {t(Keys.totalPrice)}&nbsp;&nbsp;
        {getDefaultFormatPrice(booking.price.finalPrice, locale)}
      </div>
    </div>
  );
});

export default Lower;
