import React, { FC } from 'react';
import { Collapse } from 'reactstrap';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import Button from '@/components/Button';
import Keys from '@/Translations/generated/da/search.json.keys';
import { useTranslation } from '@/app/i18n/client';
import CheckboxResults from '@/components/Search/CheckboxResults';
import { Action, ApiAirportComponent } from '@/components/Search/searchReducer/types';
import { Spinner } from '@ibe/components';

const DepartureAirportTab: FC<
  Pick<SearchProps, 'collapseOpen' | 'checkboxState' | 'checkboxDispatch' | 'isLoading'> & {
    closeCollapse: () => void;
  }
> = ({ collapseOpen, closeCollapse, checkboxDispatch, checkboxState, isLoading }): JSX.Element => {
  const { t } = useTranslation('search');
  const { airports, totalProductsForAirport } = checkboxState;

  const checkSingleAirport = ({ id, name, differentAirportNames }: ApiAirportComponent): void => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: id,
        name: name,
        differentAirportNames,
        type: 'airport'
      }
    });
  };

  return (
    <Collapse className="search-for-results__collapse--single" isOpen={collapseOpen}>
      <div className="search-for-results__dropdown search-for-results__airports">
        {airports.map(singleAirport => (
          <CheckboxResults
            key={`${singleAirport.id}${singleAirport.name}`}
            label={singleAirport.name}
            checked={
              !!Object.entries(checkboxState.items).find(
                ([key, value]) => value.type === 'airport' && key === singleAirport.id
              )
            }
            onChange={(): void => checkSingleAirport(singleAirport)}
            results={singleAirport.products}
            isLoading={isLoading}
          />
        ))}
      </div>
      <div className="search__content__submit">
        <Button color="primary" onClick={closeCollapse} disabled={isLoading}>
          <span>{`${t(Keys.apply)} (${totalProductsForAirport})`}</span>
          {isLoading && <Spinner />}
        </Button>
      </div>
    </Collapse>
  );
};

export default DepartureAirportTab;
