import React, { JSX, forwardRef } from 'react';
import FadeIn from '@/components/SearchForResults/FadeIn';
import ResultsListItem from '@/components/SearchForResults/ResultsListItem';
import LazyLoad from 'react-lazyload';
import { ApiProductsCacheResponse } from '@ibe/api';
import { Props } from '@/types/cms/magnolia';

const ResultListItemLazy = forwardRef<
  HTMLDivElement,
  {
    productPackage: ApiProductsCacheResponse;
    activeView: number;
    pageProps?: Props;
    productPageBasePath: string;
  }
>(function ResultListItemLazy(
  { productPackage, activeView, pageProps, productPageBasePath },
  ref
): JSX.Element {
  return (
    <div key={productPackage.packageCode} ref={ref}>
      <LazyLoad
        key={productPackage.packageCode}
        throttle={300}
        offset={400}
        height={420}
        scroll
        resize
      >
        <FadeIn>
          <ResultsListItem
            key={productPackage.packageCode}
            product={productPackage}
            productPageBasePath={productPageBasePath}
            activeView={activeView}
            pageProps={pageProps}
          />
        </FadeIn>
      </LazyLoad>
    </div>
  );
});

export default ResultListItemLazy;
