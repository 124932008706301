'use client';

import React, { FC, JSX } from 'react';
import CustomSpinner from '@/components/CustomSpinner/CustomSpinner';
import {
  ApiProductsCacheResponse,
  ApiWebsiteDestinationSearch,
  ApiWebsiteFaqSearch,
  ApiWebsiteOtherPagesSearch
} from '@ibe/api';
import WebsiteSearchFullTeaserList from '@/components/websiteSearch/WebsiteSearchFullTeaserList';
import WebsiteSearchFullDestinationList from '@/components/websiteSearch/WebsiteSearchFullDestinationList';
import WebsiteSearchFullFaqList from '@/components/websiteSearch/WebsiteSearchFullFaqList';
import { Props } from '@/types/cms/magnolia';
import WebsiteSearchFullOtherPagesList from '@/components/websiteSearch/WebsiteSearchFullOtherPagesList';

export type Results = {
  product: ApiProductsCacheResponse[];
  destination: ApiWebsiteDestinationSearch[];
  otherPages: ApiWebsiteOtherPagesSearch[];
  faq: ApiWebsiteFaqSearch[];
};

const WebsiteSearchFull: FC<{
  activeIndex: number;
  isLoading: boolean;
  results: Results;
  pageProps?: Props;
}> = ({ activeIndex, isLoading, results, pageProps }): JSX.Element => {
  return (
    <div className="website-search-full__results__container">
      {isLoading && (
        <div className="website-search__results__spinner">
          <CustomSpinner />
        </div>
      )}
      <div
        className="website-search-full__results"
        style={{ transform: `translateX(${activeIndex * -25}%)` }}
      >
        <WebsiteSearchFullTeaserList
          results={results.product}
          pageProps={pageProps}
          activeIndex={activeIndex}
        />
        <WebsiteSearchFullDestinationList results={results.destination} activeIndex={activeIndex} />
        <WebsiteSearchFullOtherPagesList results={results.otherPages} activeIndex={activeIndex} />
        <WebsiteSearchFullFaqList results={results.faq} activeIndex={activeIndex} />
      </div>
    </div>
  );
};

export default WebsiteSearchFull;
