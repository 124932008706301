'use client';

import React, { ReactElement } from 'react';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import { THEMES } from '@/Util/globals';
import GridContainer from '@/Layouts/GridContainer';

const ContentModule = ({
  contentRow,
  theme,
  headline
}: {
  contentRow?: object;
  theme?: string;
  headline?: string;
}): ReactElement => {
  return (
    <GridContainer className={`content-module ${theme || THEMES.darkGreen}`}>
      {!!headline && <h3 className="mb-xs-5">{headline}</h3>}
      {!!contentRow ? <EditableArea content={contentRow} /> : <></>}
    </GridContainer>
  );
};

export default ContentModule;
