import { apis, logger, REVALIDATION_DELAY, REVALIDATION_TAG } from '@/Util/globals';
import { ApiService, AppServiceServer } from '@ibe/services';
import {
  Configuration as ModelApiConfiguration,
  Middleware as ModelApiMiddleware,
  RequestContext as ModelApiRequestContext,
  ModelApi
} from '../../api/model';
import {
  FetchParams,
  Middleware as IbeApiMiddleware,
  RequestContext as IbeApiRequestContext
} from '@ibe/api';
import { fallbackCurrency, languageMapping } from '@/app/i18n/settings';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

const credentials = {
  username: process.env.MAGNOLIA_AUTH_USER,
  password: process.env.MAGNOLIA_AUTH_PASSWORD
};

const getConfig = (isAuthor?: boolean, ignoreCaching?: boolean) => {
  return {
    basePath: apis.base,
    fetchApi: fetch,
    middleware: [
      new (class implements ModelApiMiddleware {
        async pre(context: ModelApiRequestContext) {
          logger('debug')(`Fetching from model API with url PRE: ${context.url}`);
          return {
            ...context,
            init: {
              ...context.init,
              // cache: (isAuthor ? 'no-store' : 'force-cache') as RequestCache,
              cache: undefined,
              next: ignoreCaching
                ? { revalidate: 0 }
                : { revalidate: isAuthor ? 0 : REVALIDATION_DELAY, tags: [REVALIDATION_TAG] }
            }
          };
        }
        async post(context: ModelApiRequestContext) {
          logger('debug')(`Fetching from model API with url POST: ${context.url}`);
        }
      })()
    ]
  };
};

const receiveModelApi = (isAuthor?: boolean, ignoreCaching?: boolean) => {
  let instance: ModelApi;

  function createInstance(): ModelApi {
    return new ModelApi(
      new ModelApiConfiguration({ ...getConfig(isAuthor, ignoreCaching), ...credentials })
    );
  }

  return (): ModelApi => {
    if (!instance) {
      instance = createInstance();
      return instance;
    } else {
      return instance;
    }
  };
};

export const getModelApi = (isAuthor?: boolean, ignoreCaching?: boolean): ModelApi => {
  return receiveModelApi(isAuthor, ignoreCaching)();
};

export const useModelApiClient = (isAuthor?: boolean): ModelApi => {
  const { magnoliaHostClient, magnoliaApiBase, isProduction } = useGlobalMGLProps() || {};
  return new ModelApi(
    new ModelApiConfiguration({
      ...getConfig(isAuthor),
      basePath: `${isProduction ? magnoliaHostClient : ''}${magnoliaApiBase}`
    })
  );
};

export const getIbeApiServer = (
  locale: string,
  tenant?: string,
  isAuthor?: boolean,
  ignoreCaching?: boolean
): ApiService => {
  return AppServiceServer.getInstance(
    locale,
    tenant,
    languageMapping[locale]?.currency || fallbackCurrency,
    { basePath: process.env.IBE_API_URL }
  ).api.withMiddleware(
    new (class implements IbeApiMiddleware {
      async pre(context: IbeApiRequestContext): Promise<void | FetchParams> {
        logger('debug')(
          `Fetching from IBE API with url PRE: ${context.url}, full: ${JSON.stringify(
            context.init
          )}`
        );
        return {
          ...context,
          init: {
            ...context.init,
            // cache: (isAuthor ? 'no-store' : 'force-cache') as RequestCache,
            cache: undefined,
            next: ignoreCaching
              ? { revalidate: 0 }
              : { revalidate: isAuthor ? 0 : REVALIDATION_DELAY, tags: [REVALIDATION_TAG] }
          }
        };
      }
      async post(context: ModelApiRequestContext) {
        logger('debug')(`Fetching from IBE API with url POST: ${context.url}`);
      }
    })()
  );
};
