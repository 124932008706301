import React, { FC, JSX } from 'react';
import FlightSegments from '@/components/FlightIntro/FlightSegments';
import { ApiAirlineLogo } from '../../../api/model';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import { Props } from '@/types/cms/magnolia';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';

dayjs.extend(utc);

const FlightIntro: FC<{
  checkoutStore: CheckoutStore;
  airlineLogos?: ApiAirlineLogo[];
  pageProps?: Props;
}> = observer(function FlightIntro({ checkoutStore, airlineLogos, pageProps }): JSX.Element {
  const { t } = useTranslation('Checkout');
  return (
    <div className="flight-intro">
      {checkoutStore.flights.map((flight, idx) => (
        <div key={flight.id} className="flight-intro__segments">
          <FlightSegments
            flight={flight}
            airlineLogos={airlineLogos}
            pageProps={pageProps}
            label={idx === 0 ? t(Keys.outboundFlight) : t(Keys.inboundFlight)}
          />
        </div>
      ))}
    </div>
  );
});

export default FlightIntro;
