import React, { FC, Fragment, JSX } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import Link from '@/components/Link';
import { ExternalLinks } from '@/components/checkout/TermsAndConditions';
import GridContainer from '@/Layouts/GridContainer';
import InsuranceItem from '@/components/checkout/insurance/InsuranceItem';
import SuspenseFallbackComponent from '@/components/SuspenseFallbackComponent/SuspenseFallbackComponent';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { Language } from '@/Translations';
import { ApiInsurance, ApiInsuranceUnit } from '@ibe/api';

const Insurance: FC<{
  checkoutStore: CheckoutStore;
  externalLinks: ExternalLinks;
  insurancesLoading: boolean;
}> = observer(function Insurances({
  checkoutStore,
  externalLinks,
  insurancesLoading
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const locale = useCurrentLanguage();
  const relatedServices = checkoutStore.insurance?.selectableItems?.[0]?.relatedServices || [];

  return !!checkoutStore.insurance ? (
    <GridContainer className="insurance">
      <h2 className="checkout__headline">{t(Keys.insurance)}</h2>
      <p>{t(Keys.insuranceText)}</p>
      {!!externalLinks.tAndCInsuranceUrl && (
        <div>
          <Link href={externalLinks.tAndCInsuranceUrl} target="_blank">
            {t(Keys.readMore)}
          </Link>
        </div>
      )}
      <div className="insurance__travelers">
        {(checkoutStore.booking?.travelers || []).map((traveler, idx) => (
          <div key={traveler.id} className="insurance__travelers__item">
            <div className="insurance__travelers__label">
              {idx === 0
                ? t(Keys.insuranceParticipantApplicant, { number: idx + 1 })
                : t(Keys.insuranceParticipant, { number: idx + 1 })}
            </div>
            <div className="insurance__items">
              {relatedServices
                .filter(
                  relatedService =>
                    ((relatedService.selectableItems?.[0] as ApiInsurance | undefined)
                      ?.units?.[0] as ApiInsuranceUnit)?.proposedParticipantRelation?.length ===
                      0 ||
                    ((relatedService.selectableItems?.[0] as ApiInsurance | undefined)
                      ?.units?.[0] as ApiInsuranceUnit)?.proposedParticipantRelation?.includes(
                      parseInt(traveler.id || '-1', 10)
                    )
                )
                .map(relatedService =>
                  !!relatedService?.selectableItems?.[0] ? (
                    <InsuranceItem
                      key={`${relatedService.code}-${
                        (relatedService.selectableItems?.[0] as ApiInsurance)?.units?.[0]?.id
                      }`}
                      relatedService={relatedService}
                      checkoutStore={checkoutStore}
                      componentId={checkoutStore.insurance?.id}
                      componentUnitId={
                        checkoutStore.insurance?.selectableItems?.[0]?.units?.[0]?.id
                      }
                      travelerPosition={traveler.id}
                    />
                  ) : (
                    <Fragment key={relatedService.code} />
                  )
                )}
            </div>
            <div className="insurance__footer">
              {!!externalLinks.tAndCInsuranceUrl && (
                <Link
                  className="insurance__footer__link"
                  href={externalLinks.tAndCInsuranceUrl}
                  target="_blank"
                >
                  {t(Keys.insuranceTAndCLinkLabel)}
                </Link>
              )}
              {(locale === Language.DA ||
                locale === Language.SV ||
                locale === Language.NO ||
                locale === Language.PL) && (
                <div className="insurance__footer__supplier">
                  <div>{t(Keys.suppliedBy)}</div>
                  <img
                    src={
                      locale === Language.DA || locale === Language.SV || locale === Language.NO
                        ? '/logos/logo_gouda.svg'
                        : '/logos/logo_allianz-travel.png'
                    }
                    alt={
                      locale === Language.DA || locale === Language.SV || locale === Language.NO
                        ? 'Gouda'
                        : 'Allianz Travel'
                    }
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {insurancesLoading && <SuspenseFallbackComponent fitToContainer spinnerOnly />}
    </GridContainer>
  ) : (
    <></>
  );
});

export default Insurance;
