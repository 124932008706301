import { useState } from 'react';
import { useMount } from 'react-use';

export const useIsComponentMounted = (): boolean => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useMount(() => {
    setIsMounted(true);
  });

  return isMounted;
};
