'use client';

import { createContext, FC, PropsWithChildren, JSX, useContext, useState } from 'react';
import classNames from 'classnames';
import { ApiImage } from '../../../api/model';

export type CheckoutContextType = {
  isLastStep: boolean;
  setIsLastStep: (isLastStep: boolean) => void;
  clientHeroImage?: ApiImage;
  setClientHeroImage: (clientHeroImage: ApiImage) => void;
};

export const CheckoutContext = createContext<CheckoutContextType>({
  isLastStep: false,
  setIsLastStep: () => {},
  clientHeroImage: undefined,
  setClientHeroImage: () => {}
});

export const CheckoutContextProvider: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const [isLastStep, setIsLastStep] = useState<boolean>(false);
  const [clientHeroImage, setClientHeroImage] = useState<ApiImage | undefined>(undefined);

  return (
    <div className={classNames('checkout', { 'checkout--last': isLastStep })}>
      <CheckoutContext.Provider
        value={{
          isLastStep,
          setIsLastStep,
          clientHeroImage,
          setClientHeroImage
        }}
      >
        {children}
      </CheckoutContext.Provider>
    </div>
  );
};

export const useCheckoutContext = (): CheckoutContextType => {
  return useContext(CheckoutContext);
};
