import { useMemo } from 'react';
import dayjs from 'dayjs';
import { getLocale } from '@/Config/config';
import Keys from '@/Translations/generated/da/search.json.keys';
import localeEn from 'dayjs/locale/en';
import { useTranslation } from '@/app/i18n/client';

const useMonthLabels = (shortVersion?: boolean) => {
  const { t } = useTranslation('search');

  return useMemo((): string[] => {
    const savedLocale = dayjs.locale();
    dayjs.locale(localeEn);
    const translatedMonths = dayjs
      .months()
      .map(month => t((Keys as Record<string, string>)[`${month}${shortVersion ? '_short' : ''}`]));
    dayjs.locale(getLocale(savedLocale));
    return translatedMonths;
  }, [shortVersion]);
};

export default useMonthLabels;
