'use client';

import React, { FC } from 'react';
import {
  FormField,
  InputField,
  isInputField,
  isSelectField
} from '@/components/magnoliaForm/types';
import Input from '@/components/magnoliaForm/formElements/Input';
import Select from '@/components/magnoliaForm/formElements/Select';
import classNames from 'classnames';
import { useMagnoliaFormContext } from '@/components/magnoliaForm/Form';

export const ERROR_CLASS = 'magnolia-form__field--error';

const Field: FC<FormField> = (props): JSX.Element => {
  const { isDirty, validationErrors, requiredSymbol } = useMagnoliaFormContext();
  const { editLength, controlName, fieldWidth } = props as InputField;

  return (
    <div
      className={classNames('magnolia-form__field', editLength || '', {
        [ERROR_CLASS]: isDirty && !!validationErrors[controlName]
      })}
      style={{ flex: fieldWidth || '1' }}
    >
      {isInputField(props) && <Input {...props} />}
      {isSelectField(props) && <Select {...props} requiredSymbol={requiredSymbol} />}
      {isDirty && !!validationErrors[controlName] && (
        <div className="magnolia-form__field__error">{validationErrors[controlName]?.[0]}</div>
      )}
    </div>
  );
};

export default Field;
