import React, { FC, JSX, ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretRightThin } from '@/Theme/SVG/Icons';

const ResultsNavItem: FC<{
  label: string | ReactElement;
  amount?: number;
  onClick: () => void;
}> = ({ label, amount, onClick }): JSX.Element => {
  return (
    <div className="radio-results radio-results--nav-item" onClick={onClick}>
      <div className="checkbox radio">
        <div className="checkbox__field">
          <FontAwesomeIcon icon={facCaretRightThin} />
        </div>
        <div className="checkbox__label">
          <div>{label}</div>
          {amount !== undefined && <div className="checkbox-results__amount">{amount}</div>}
        </div>
      </div>
    </div>
  );
};

export default ResultsNavItem;
