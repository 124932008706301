import React, { Dispatch, FC, RefObject, useEffect, useRef, useState } from 'react';
import { Collapse } from 'reactstrap';
import { ReducerAction, ReducerState } from '@/components/Search/searchReducer/types';
import TextSearchTab from '@/components/Search/TextSearchTab';
import CountriesSearchTab from '@/components/Search/CountriesSearchTab';
import TravelTypesSearchTab from '@/components/Search/TravelTypesSearchTab';
import { ApiTextSearchResponse } from '@ibe/api';
import MonthSelectorSearchTab from '@/components/Search/MonthSelectorSearchTab';

export type CollapseIndex = 0 | 1 | 2 | 3 | 4 | 5;

export type SearchProps = {
  collapseOpen: boolean;
  activeIndex: CollapseIndex;
  handleInputChange: (value: string) => void;
  inputValue: string;
  totalNumberOfProducts: number;
  textSearch: Required<ApiTextSearchResponse>;
  checkboxState: ReducerState;
  checkboxDispatch: Dispatch<ReducerAction>;
  textSearchInputRef: RefObject<HTMLInputElement>;
  goToResultsPage: () => void;
  isLoading: boolean;
  productPageBasePath: string;
  isInitialSearch?: boolean;
  resultsPageBasePath: string;
};

const SearchDesktopOverlay: FC<SearchProps> = ({
  activeIndex,
  inputValue,
  handleInputChange,
  totalNumberOfProducts,
  checkboxDispatch,
  checkboxState,
  textSearch,
  textSearchInputRef,
  goToResultsPage,
  isLoading,
  productPageBasePath,
  resultsPageBasePath
}) => {
  const { continents, travelTypes, dates } = checkboxState;
  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
  const activeIndexRef = useRef<CollapseIndex>(0);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    let timer2: ReturnType<typeof setTimeout> | null = null;
    if (activeIndex !== activeIndexRef.current) {
      if (activeIndexRef.current === 0) {
        activeIndexRef.current = activeIndex;
        setCollapseOpen(true);
      } else if (activeIndex > 0) {
        setCollapseOpen(false);
        timer = setTimeout(() => {
          activeIndexRef.current = activeIndex;
          setCollapseOpen(true);
        }, 400);
      } else if (activeIndex === 0) {
        setCollapseOpen(false);
        timer2 = setTimeout((): void => {
          activeIndexRef.current = activeIndex;
        }, 600);
      }
    }

    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
      if (!!timer2) {
        clearTimeout(timer2);
      }
    };
  }, [activeIndex]);

  return (
    <Collapse isOpen={collapseOpen}>
      {activeIndexRef.current === 1 && continents.length > 0 && (
        <CountriesSearchTab
          checkboxState={checkboxState}
          checkboxDispatch={checkboxDispatch}
          isLoading={isLoading}
        />
      )}
      {activeIndexRef.current === 2 && travelTypes.length > 0 && (
        <TravelTypesSearchTab
          checkboxState={checkboxState}
          checkboxDispatch={checkboxDispatch}
          isLoading={isLoading}
        />
      )}
      {activeIndexRef.current === 3 && dates.length > 0 && (
        <MonthSelectorSearchTab
          checkboxDispatch={checkboxDispatch}
          checkboxState={checkboxState}
          isLoading={isLoading}
        />
      )}
      {activeIndexRef.current === 4 && (
        <TextSearchTab
          handleInputChange={handleInputChange}
          inputValue={inputValue}
          totalNumberOfProducts={totalNumberOfProducts}
          textSearch={textSearch}
          textSearchInputRef={textSearchInputRef}
          goToResultsPage={goToResultsPage}
          isLoading={isLoading}
          productPageBasePath={productPageBasePath}
          resultsPageBasePath={resultsPageBasePath}
        />
      )}
    </Collapse>
  );
};

export default SearchDesktopOverlay;
