/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiClimateArea,
        ApiClimateAreaFromJSON,
        ApiClimateAreaFromJSONTyped,
        ApiClimateAreaToJSON,
        ApiContentArea,
        ApiContentAreaFromJSON,
        ApiContentAreaFromJSONTyped,
        ApiContentAreaToJSON,
        ApiCountry,
        ApiCountryFromJSON,
        ApiCountryFromJSONTyped,
        ApiCountryToJSON,
        ApiFooterContentItems,
        ApiFooterContentItemsFromJSON,
        ApiFooterContentItemsFromJSONTyped,
        ApiFooterContentItemsToJSON,
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
        ApiInfoSectionFact,
        ApiInfoSectionFactFromJSON,
        ApiInfoSectionFactFromJSONTyped,
        ApiInfoSectionFactToJSON,
        ApiMetaInformation,
        ApiMetaInformationFromJSON,
        ApiMetaInformationFromJSONTyped,
        ApiMetaInformationToJSON,
        ApiPage,
        ApiPageFromJSON,
        ApiPageFromJSONTyped,
        ApiPageToJSON,
        ApiVideo,
        ApiVideoFromJSON,
        ApiVideoFromJSONTyped,
        ApiVideoToJSON,
        ApiVideoContent,
        ApiVideoContentFromJSON,
        ApiVideoContentFromJSONTyped,
        ApiVideoContentToJSON,
    } from './';

/**
* 
* @export
* @interface ApiCity
*/
export interface ApiCity {
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    id?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    name: string;
    /**
    * 
    * @type {ApiCountry}
    * @memberof ApiCity
    */
    country?: ApiCountry;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTitleGeneral?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTextGeneral?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    citizens?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    foundation?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    language?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    contentAreaTitle?: string;
    /**
    * 
    * @type {Array<ApiContentArea>}
    * @memberof ApiCity
    */
    contentArea?: Array<ApiContentArea>;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    content?: string;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiCity
    */
    heroBackgroundImage?: ApiImage;
    /**
    * 
    * @type {ApiVideo}
    * @memberof ApiCity
    */
    heroBackgroundVideo?: ApiVideo;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTitleProducts?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTextProducts?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    productTeasersHeadline?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTitleImages?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTextImages?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTitleVideos?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTextVideos?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTitleMap?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTextMap?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    aspectRatio?: string;
    /**
    * 
    * @type {Array<ApiImage>}
    * @memberof ApiCity
    */
    imageGallery?: Array<ApiImage>;
    /**
    * 
    * @type {Array<ApiVideoContent>}
    * @memberof ApiCity
    */
    cityVideos?: Array<ApiVideoContent>;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTitleFacts?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTextFacts?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    religion?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    currency?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    heightAboveSeaLevel?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    infoSectionTitle?: string;
    /**
    * 
    * @type {ApiInfoSectionFact}
    * @memberof ApiCity
    */
    infoSectionsFacts?: ApiInfoSectionFact;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTitleClimate?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    introTextClimate?: string;
    /**
    * 
    * @type {Array<ApiClimateArea>}
    * @memberof ApiCity
    */
    climateAreas?: Array<ApiClimateArea>;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiCity
    */
    map?: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    themeContentArea?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    themeIntroClimate?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    themeIntroMain?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    themeIntroMedia?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    themeIntroFacts?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    themeIntroTrips?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    themeIntroMap?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    themeProductTeasers?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    themeImageGallery?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCity
    */
    themeFactsAccordion?: string;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCity
    */
    footerMainPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCity
    */
    footerProductsPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCity
    */
    footerImagesPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCity
    */
    footerVideosPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCity
    */
    footerFactsPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCity
    */
    footerClimatePage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCity
    */
    footerMapPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCity
    */
    metaInformation?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCity
    */
    metaInformationLightProduct?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCity
    */
    metaInformationLightImage?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCity
    */
    metaInformationLightVideos?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCity
    */
    metaInformationLightFacts?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCity
    */
    metaInformationLightClimate?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCity
    */
    metaInformationLightMap?: ApiMetaInformation;
    /**
    * 
    * @type {ApiPage}
    * @memberof ApiCity
    */
    mainPage?: ApiPage;
    /**
    * 
    * @type {Array<ApiPage>}
    * @memberof ApiCity
    */
    subPages?: Array<ApiPage>;
}

export function ApiCityFromJSON(json: any): ApiCity {
return ApiCityFromJSONTyped(json, false);
}

export function ApiCityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCity {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'id': json['id'],
                    'name': json['name'],
                            'country': ApiCountryFromJSON(json['country']),
                    'introTitleGeneral': json['introTitleGeneral'],
                    'introTextGeneral': json['introTextGeneral'],
                    'citizens': json['citizens'],
                    'foundation': json['foundation'],
                    'language': json['language'],
                    'contentAreaTitle': json['contentAreaTitle'],
                'contentArea': ((json['contentArea'] as Array<any>).map(ApiContentAreaFromJSON)),
                    'content': json['content'],
                            'heroBackgroundImage': ApiImageFromJSON(json['heroBackgroundImage']),
                            'heroBackgroundVideo': ApiVideoFromJSON(json['heroBackgroundVideo']),
                    'introTitleProducts': json['introTitleProducts'],
                    'introTextProducts': json['introTextProducts'],
                    'productTeasersHeadline': json['productTeasersHeadline'],
                    'introTitleImages': json['introTitleImages'],
                    'introTextImages': json['introTextImages'],
                    'introTitleVideos': json['introTitleVideos'],
                    'introTextVideos': json['introTextVideos'],
                    'introTitleMap': json['introTitleMap'],
                    'introTextMap': json['introTextMap'],
                    'aspectRatio': json['aspectRatio'],
                'imageGallery': ((json['imageGallery'] as Array<any>).map(ApiImageFromJSON)),
                'cityVideos': ((json['cityVideos'] as Array<any>).map(ApiVideoContentFromJSON)),
                    'introTitleFacts': json['introTitleFacts'],
                    'introTextFacts': json['introTextFacts'],
                    'religion': json['religion'],
                    'currency': json['currency'],
                    'heightAboveSeaLevel': json['heightAboveSeaLevel'],
                    'infoSectionTitle': json['infoSectionTitle'],
                            'infoSectionsFacts': ApiInfoSectionFactFromJSON(json['infoSectionsFacts']),
                    'introTitleClimate': json['introTitleClimate'],
                    'introTextClimate': json['introTextClimate'],
                'climateAreas': ((json['climateAreas'] as Array<any>).map(ApiClimateAreaFromJSON)),
                            'map': ApiImageFromJSON(json['map']),
                    'themeContentArea': json['themeContentArea'],
                    'themeIntroClimate': json['themeIntroClimate'],
                    'themeIntroMain': json['themeIntroMain'],
                    'themeIntroMedia': json['themeIntroMedia'],
                    'themeIntroFacts': json['themeIntroFacts'],
                    'themeIntroTrips': json['themeIntroTrips'],
                    'themeIntroMap': json['themeIntroMap'],
                    'themeProductTeasers': json['themeProductTeasers'],
                    'themeImageGallery': json['themeImageGallery'],
                    'themeFactsAccordion': json['themeFactsAccordion'],
                            'footerMainPage': ApiFooterContentItemsFromJSON(json['footerMainPage']),
                            'footerProductsPage': ApiFooterContentItemsFromJSON(json['footerProductsPage']),
                            'footerImagesPage': ApiFooterContentItemsFromJSON(json['footerImagesPage']),
                            'footerVideosPage': ApiFooterContentItemsFromJSON(json['footerVideosPage']),
                            'footerFactsPage': ApiFooterContentItemsFromJSON(json['footerFactsPage']),
                            'footerClimatePage': ApiFooterContentItemsFromJSON(json['footerClimatePage']),
                            'footerMapPage': ApiFooterContentItemsFromJSON(json['footerMapPage']),
                            'metaInformation': ApiMetaInformationFromJSON(json['metaInformation']),
                            'metaInformationLightProduct': ApiMetaInformationFromJSON(json['metaInformationLightProduct']),
                            'metaInformationLightImage': ApiMetaInformationFromJSON(json['metaInformationLightImage']),
                            'metaInformationLightVideos': ApiMetaInformationFromJSON(json['metaInformationLightVideos']),
                            'metaInformationLightFacts': ApiMetaInformationFromJSON(json['metaInformationLightFacts']),
                            'metaInformationLightClimate': ApiMetaInformationFromJSON(json['metaInformationLightClimate']),
                            'metaInformationLightMap': ApiMetaInformationFromJSON(json['metaInformationLightMap']),
                            'mainPage': ApiPageFromJSON(json['mainPage']),
                'subPages': ((json['subPages'] as Array<any>).map(ApiPageFromJSON)),
        };
    }

    export function ApiCityToJSON(value?: ApiCity | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'id': value.id,
                'name': value.name,
                            'country': ApiCountryToJSON(value.country),
                'introTitleGeneral': value.introTitleGeneral,
                'introTextGeneral': value.introTextGeneral,
                'citizens': value.citizens,
                'foundation': value.foundation,
                'language': value.language,
                'contentAreaTitle': value.contentAreaTitle,
                    'contentArea': ((value.contentArea as Array<any>).map(ApiContentAreaToJSON)),
                'content': value.content,
                            'heroBackgroundImage': ApiImageToJSON(value.heroBackgroundImage),
                            'heroBackgroundVideo': ApiVideoToJSON(value.heroBackgroundVideo),
                'introTitleProducts': value.introTitleProducts,
                'introTextProducts': value.introTextProducts,
                'productTeasersHeadline': value.productTeasersHeadline,
                'introTitleImages': value.introTitleImages,
                'introTextImages': value.introTextImages,
                'introTitleVideos': value.introTitleVideos,
                'introTextVideos': value.introTextVideos,
                'introTitleMap': value.introTitleMap,
                'introTextMap': value.introTextMap,
                'aspectRatio': value.aspectRatio,
                    'imageGallery': ((value.imageGallery as Array<any>).map(ApiImageToJSON)),
                    'cityVideos': ((value.cityVideos as Array<any>).map(ApiVideoContentToJSON)),
                'introTitleFacts': value.introTitleFacts,
                'introTextFacts': value.introTextFacts,
                'religion': value.religion,
                'currency': value.currency,
                'heightAboveSeaLevel': value.heightAboveSeaLevel,
                'infoSectionTitle': value.infoSectionTitle,
                            'infoSectionsFacts': ApiInfoSectionFactToJSON(value.infoSectionsFacts),
                'introTitleClimate': value.introTitleClimate,
                'introTextClimate': value.introTextClimate,
                    'climateAreas': ((value.climateAreas as Array<any>).map(ApiClimateAreaToJSON)),
                            'map': ApiImageToJSON(value.map),
                'themeContentArea': value.themeContentArea,
                'themeIntroClimate': value.themeIntroClimate,
                'themeIntroMain': value.themeIntroMain,
                'themeIntroMedia': value.themeIntroMedia,
                'themeIntroFacts': value.themeIntroFacts,
                'themeIntroTrips': value.themeIntroTrips,
                'themeIntroMap': value.themeIntroMap,
                'themeProductTeasers': value.themeProductTeasers,
                'themeImageGallery': value.themeImageGallery,
                'themeFactsAccordion': value.themeFactsAccordion,
                            'footerMainPage': ApiFooterContentItemsToJSON(value.footerMainPage),
                            'footerProductsPage': ApiFooterContentItemsToJSON(value.footerProductsPage),
                            'footerImagesPage': ApiFooterContentItemsToJSON(value.footerImagesPage),
                            'footerVideosPage': ApiFooterContentItemsToJSON(value.footerVideosPage),
                            'footerFactsPage': ApiFooterContentItemsToJSON(value.footerFactsPage),
                            'footerClimatePage': ApiFooterContentItemsToJSON(value.footerClimatePage),
                            'footerMapPage': ApiFooterContentItemsToJSON(value.footerMapPage),
                            'metaInformation': ApiMetaInformationToJSON(value.metaInformation),
                            'metaInformationLightProduct': ApiMetaInformationToJSON(value.metaInformationLightProduct),
                            'metaInformationLightImage': ApiMetaInformationToJSON(value.metaInformationLightImage),
                            'metaInformationLightVideos': ApiMetaInformationToJSON(value.metaInformationLightVideos),
                            'metaInformationLightFacts': ApiMetaInformationToJSON(value.metaInformationLightFacts),
                            'metaInformationLightClimate': ApiMetaInformationToJSON(value.metaInformationLightClimate),
                            'metaInformationLightMap': ApiMetaInformationToJSON(value.metaInformationLightMap),
                            'mainPage': ApiPageToJSON(value.mainPage),
                    'subPages': ((value.subPages as Array<any>).map(ApiPageToJSON)),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

