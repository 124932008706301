'use client';

import React, { ReactElement } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { ApiCity } from '../../../api/model';
import { THEMES } from '@/Util/globals';
import Keys from '@/Translations/generated/da/city.json.keys';
import sanitize from '@/Util/sanitize';
import ClimateTable from '@/components/climateTable/ClimateTable';
import Intro from '@/components/intro/Intro';
import { Props } from '@/types/cms/magnolia';

const CityClimate = ({
  city,
  pageProps,
  pathname
}: {
  city?: ApiCity;
  pathname: string;
  pageProps?: Props;
}): ReactElement => {
  const { name, introTitleClimate, introTextClimate = '', climateAreas, themeIntroClimate } =
    city || {};
  const { t } = useTranslation('city');

  return (
    <>
      <Intro
        theme={themeIntroClimate || THEMES.blue}
        headline={introTitleClimate ? introTitleClimate : `${t(Keys.headlineClimate)} ${name}`}
        pageProps={pageProps}
        pathname={pathname}
        singleColumn={
          <>
            <div dangerouslySetInnerHTML={{ __html: sanitize(introTextClimate, true) }} />
            {!!climateAreas &&
              climateAreas.length > 0 &&
              climateAreas.map(climateArea => (
                <ClimateTable key={climateArea.headline} climateArea={climateArea} />
              ))}
          </>
        }
      />
    </>
  );
};

export default CityClimate;
