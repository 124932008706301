/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiCity,
        ApiCityFromJSON,
        ApiCityFromJSONTyped,
        ApiCityToJSON,
        ApiClimateArea,
        ApiClimateAreaFromJSON,
        ApiClimateAreaFromJSONTyped,
        ApiClimateAreaToJSON,
        ApiContentArea,
        ApiContentAreaFromJSON,
        ApiContentAreaFromJSONTyped,
        ApiContentAreaToJSON,
        ApiContinent,
        ApiContinentFromJSON,
        ApiContinentFromJSONTyped,
        ApiContinentToJSON,
        ApiFooterContentItems,
        ApiFooterContentItemsFromJSON,
        ApiFooterContentItemsFromJSONTyped,
        ApiFooterContentItemsToJSON,
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
        ApiInfoSection,
        ApiInfoSectionFromJSON,
        ApiInfoSectionFromJSONTyped,
        ApiInfoSectionToJSON,
        ApiInfoSectionFact,
        ApiInfoSectionFactFromJSON,
        ApiInfoSectionFactFromJSONTyped,
        ApiInfoSectionFactToJSON,
        ApiMetaInformation,
        ApiMetaInformationFromJSON,
        ApiMetaInformationFromJSONTyped,
        ApiMetaInformationToJSON,
        ApiPage,
        ApiPageFromJSON,
        ApiPageFromJSONTyped,
        ApiPageToJSON,
        ApiSightseeingItem,
        ApiSightseeingItemFromJSON,
        ApiSightseeingItemFromJSONTyped,
        ApiSightseeingItemToJSON,
        ApiVideo,
        ApiVideoFromJSON,
        ApiVideoFromJSONTyped,
        ApiVideoToJSON,
        ApiVideoContent,
        ApiVideoContentFromJSON,
        ApiVideoContentFromJSONTyped,
        ApiVideoContentToJSON,
    } from './';

/**
* 
* @export
* @interface ApiCountry
*/
export interface ApiCountry {
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    id?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    name: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    language?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    capitalCity?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    citizens?: string;
    /**
    * 
    * @type {ApiContinent}
    * @memberof ApiCountry
    */
    continent: ApiContinent;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    contentAreaTitle?: string;
    /**
    * 
    * @type {Array<ApiContentArea>}
    * @memberof ApiCountry
    */
    contentArea: Array<ApiContentArea>;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiCountry
    */
    teaserImage: ApiImage;
    /**
    * 
    * @type {Array<ApiImage>}
    * @memberof ApiCountry
    */
    imageGallery?: Array<ApiImage>;
    /**
    * 
    * @type {Array<ApiVideoContent>}
    * @memberof ApiCountry
    */
    countryVideos?: Array<ApiVideoContent>;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiCountry
    */
    introImage?: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTitleGeneral?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTextGeneral: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTitleClimate?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTextClimate?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTitleFacts?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTextFacts?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTitleMap?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTextMap?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    aspectRatio?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTitleImages?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTextImages?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTitleVideos?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTextVideos?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTitleProducts?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTextProducts?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTitleSightseeing?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTextSightseeing?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTitleVaccination?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTextVaccination?: string;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiCountry
    */
    introImageVaccination?: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introInformationVaccination?: string;
    /**
    * 
    * @type {Array<ApiSightseeingItem>}
    * @memberof ApiCountry
    */
    sightseeingItems?: Array<ApiSightseeingItem>;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    religion?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    currency?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    area?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    infoSectionTitle?: string;
    /**
    * 
    * @type {ApiInfoSectionFact}
    * @memberof ApiCountry
    */
    infoSectionsFacts?: ApiInfoSectionFact;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    sherpaCode?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    introTitleEntry?: string;
    /**
    * 
    * @type {Array<ApiInfoSection>}
    * @memberof ApiCountry
    */
    infoSectionsEntry?: Array<ApiInfoSection>;
    /**
    * 
    * @type {Array<ApiInfoSection>}
    * @memberof ApiCountry
    */
    infoSectionsVaccination?: Array<ApiInfoSection>;
    /**
    * 
    * @type {Array<ApiClimateArea>}
    * @memberof ApiCountry
    */
    climateAreas?: Array<ApiClimateArea>;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiCountry
    */
    map: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    content?: string;
    /**
    * 
    * @type {ApiVideo}
    * @memberof ApiCountry
    */
    heroBackgroundVideo?: ApiVideo;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiCountry
    */
    heroBackgroundImage?: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeContentArea?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeIntroClimate?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeIntroVaccination?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeVaccinationInformationRichText?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeFactsAccordion?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeIntroEntry?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeIntroMain?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeIntroMap?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeIntroMedia?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeIntroFacts?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeIntroSightseeing?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeSightseeingItems?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeIntroTrips?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeProductTeasers?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    themeImageGallery?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountry
    */
    productTeasersHeadline?: string;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCountry
    */
    footerMainPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCountry
    */
    footerProductsPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCountry
    */
    footerImagesPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCountry
    */
    footerVideosPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCountry
    */
    footerSightseeingPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCountry
    */
    footerFactsPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCountry
    */
    footerEntryPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCountry
    */
    footerVaccinationPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCountry
    */
    footerClimatePage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiFooterContentItems}
    * @memberof ApiCountry
    */
    footerMapPage?: ApiFooterContentItems;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCountry
    */
    metaInformation?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCountry
    */
    metaInformationLightProduct?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCountry
    */
    metaInformationLightImage?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCountry
    */
    metaInformationLightVideos?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCountry
    */
    metaInformationLightSightseeing?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCountry
    */
    metaInformationLightFacts?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCountry
    */
    metaInformationLightEntry?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCountry
    */
    metaInformationLightVaccination?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCountry
    */
    metaInformationLightClimate?: ApiMetaInformation;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiCountry
    */
    metaInformationLightMap?: ApiMetaInformation;
    /**
    * 
    * @type {Array<ApiCity>}
    * @memberof ApiCountry
    */
    cities?: Array<ApiCity>;
    /**
    * 
    * @type {ApiPage}
    * @memberof ApiCountry
    */
    mainPage?: ApiPage;
    /**
    * 
    * @type {Array<ApiPage>}
    * @memberof ApiCountry
    */
    subPages?: Array<ApiPage>;
    /**
    * 
    * @type {number}
    * @memberof ApiCountry
    */
    products?: number;
}

export function ApiCountryFromJSON(json: any): ApiCountry {
return ApiCountryFromJSONTyped(json, false);
}

export function ApiCountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCountry {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'id': json['id'],
                    'name': json['name'],
                    'language': json['language'],
                    'capitalCity': json['capitalCity'],
                    'citizens': json['citizens'],
                            'continent': ApiContinentFromJSON(json['continent']),
                    'contentAreaTitle': json['contentAreaTitle'],
                'contentArea': ((json['contentArea'] as Array<any>).map(ApiContentAreaFromJSON)),
                            'teaserImage': ApiImageFromJSON(json['teaserImage']),
                'imageGallery': ((json['imageGallery'] as Array<any>).map(ApiImageFromJSON)),
                'countryVideos': ((json['countryVideos'] as Array<any>).map(ApiVideoContentFromJSON)),
                            'introImage': ApiImageFromJSON(json['introImage']),
                    'introTitleGeneral': json['introTitleGeneral'],
                    'introTextGeneral': json['introTextGeneral'],
                    'introTitleClimate': json['introTitleClimate'],
                    'introTextClimate': json['introTextClimate'],
                    'introTitleFacts': json['introTitleFacts'],
                    'introTextFacts': json['introTextFacts'],
                    'introTitleMap': json['introTitleMap'],
                    'introTextMap': json['introTextMap'],
                    'aspectRatio': json['aspectRatio'],
                    'introTitleImages': json['introTitleImages'],
                    'introTextImages': json['introTextImages'],
                    'introTitleVideos': json['introTitleVideos'],
                    'introTextVideos': json['introTextVideos'],
                    'introTitleProducts': json['introTitleProducts'],
                    'introTextProducts': json['introTextProducts'],
                    'introTitleSightseeing': json['introTitleSightseeing'],
                    'introTextSightseeing': json['introTextSightseeing'],
                    'introTitleVaccination': json['introTitleVaccination'],
                    'introTextVaccination': json['introTextVaccination'],
                            'introImageVaccination': ApiImageFromJSON(json['introImageVaccination']),
                    'introInformationVaccination': json['introInformationVaccination'],
                'sightseeingItems': ((json['sightseeingItems'] as Array<any>).map(ApiSightseeingItemFromJSON)),
                    'religion': json['religion'],
                    'currency': json['currency'],
                    'area': json['area'],
                    'infoSectionTitle': json['infoSectionTitle'],
                            'infoSectionsFacts': ApiInfoSectionFactFromJSON(json['infoSectionsFacts']),
                    'sherpaCode': json['sherpaCode'],
                    'introTitleEntry': json['introTitleEntry'],
                'infoSectionsEntry': ((json['infoSectionsEntry'] as Array<any>).map(ApiInfoSectionFromJSON)),
                'infoSectionsVaccination': ((json['infoSectionsVaccination'] as Array<any>).map(ApiInfoSectionFromJSON)),
                'climateAreas': ((json['climateAreas'] as Array<any>).map(ApiClimateAreaFromJSON)),
                            'map': ApiImageFromJSON(json['map']),
                    'content': json['content'],
                            'heroBackgroundVideo': ApiVideoFromJSON(json['heroBackgroundVideo']),
                            'heroBackgroundImage': ApiImageFromJSON(json['heroBackgroundImage']),
                    'themeContentArea': json['themeContentArea'],
                    'themeIntroClimate': json['themeIntroClimate'],
                    'themeIntroVaccination': json['themeIntroVaccination'],
                    'themeVaccinationInformationRichText': json['themeVaccinationInformationRichText'],
                    'themeFactsAccordion': json['themeFactsAccordion'],
                    'themeIntroEntry': json['themeIntroEntry'],
                    'themeIntroMain': json['themeIntroMain'],
                    'themeIntroMap': json['themeIntroMap'],
                    'themeIntroMedia': json['themeIntroMedia'],
                    'themeIntroFacts': json['themeIntroFacts'],
                    'themeIntroSightseeing': json['themeIntroSightseeing'],
                    'themeSightseeingItems': json['themeSightseeingItems'],
                    'themeIntroTrips': json['themeIntroTrips'],
                    'themeProductTeasers': json['themeProductTeasers'],
                    'themeImageGallery': json['themeImageGallery'],
                    'productTeasersHeadline': json['productTeasersHeadline'],
                            'footerMainPage': ApiFooterContentItemsFromJSON(json['footerMainPage']),
                            'footerProductsPage': ApiFooterContentItemsFromJSON(json['footerProductsPage']),
                            'footerImagesPage': ApiFooterContentItemsFromJSON(json['footerImagesPage']),
                            'footerVideosPage': ApiFooterContentItemsFromJSON(json['footerVideosPage']),
                            'footerSightseeingPage': ApiFooterContentItemsFromJSON(json['footerSightseeingPage']),
                            'footerFactsPage': ApiFooterContentItemsFromJSON(json['footerFactsPage']),
                            'footerEntryPage': ApiFooterContentItemsFromJSON(json['footerEntryPage']),
                            'footerVaccinationPage': ApiFooterContentItemsFromJSON(json['footerVaccinationPage']),
                            'footerClimatePage': ApiFooterContentItemsFromJSON(json['footerClimatePage']),
                            'footerMapPage': ApiFooterContentItemsFromJSON(json['footerMapPage']),
                            'metaInformation': ApiMetaInformationFromJSON(json['metaInformation']),
                            'metaInformationLightProduct': ApiMetaInformationFromJSON(json['metaInformationLightProduct']),
                            'metaInformationLightImage': ApiMetaInformationFromJSON(json['metaInformationLightImage']),
                            'metaInformationLightVideos': ApiMetaInformationFromJSON(json['metaInformationLightVideos']),
                            'metaInformationLightSightseeing': ApiMetaInformationFromJSON(json['metaInformationLightSightseeing']),
                            'metaInformationLightFacts': ApiMetaInformationFromJSON(json['metaInformationLightFacts']),
                            'metaInformationLightEntry': ApiMetaInformationFromJSON(json['metaInformationLightEntry']),
                            'metaInformationLightVaccination': ApiMetaInformationFromJSON(json['metaInformationLightVaccination']),
                            'metaInformationLightClimate': ApiMetaInformationFromJSON(json['metaInformationLightClimate']),
                            'metaInformationLightMap': ApiMetaInformationFromJSON(json['metaInformationLightMap']),
                'cities': ((json['cities'] as Array<any>).map(ApiCityFromJSON)),
                            'mainPage': ApiPageFromJSON(json['mainPage']),
                'subPages': ((json['subPages'] as Array<any>).map(ApiPageFromJSON)),
                    'products': json['products'],
        };
    }

    export function ApiCountryToJSON(value?: ApiCountry | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'id': value.id,
                'name': value.name,
                'language': value.language,
                'capitalCity': value.capitalCity,
                'citizens': value.citizens,
                            'continent': ApiContinentToJSON(value.continent),
                'contentAreaTitle': value.contentAreaTitle,
                    'contentArea': ((value.contentArea as Array<any>).map(ApiContentAreaToJSON)),
                            'teaserImage': ApiImageToJSON(value.teaserImage),
                    'imageGallery': ((value.imageGallery as Array<any>).map(ApiImageToJSON)),
                    'countryVideos': ((value.countryVideos as Array<any>).map(ApiVideoContentToJSON)),
                            'introImage': ApiImageToJSON(value.introImage),
                'introTitleGeneral': value.introTitleGeneral,
                'introTextGeneral': value.introTextGeneral,
                'introTitleClimate': value.introTitleClimate,
                'introTextClimate': value.introTextClimate,
                'introTitleFacts': value.introTitleFacts,
                'introTextFacts': value.introTextFacts,
                'introTitleMap': value.introTitleMap,
                'introTextMap': value.introTextMap,
                'aspectRatio': value.aspectRatio,
                'introTitleImages': value.introTitleImages,
                'introTextImages': value.introTextImages,
                'introTitleVideos': value.introTitleVideos,
                'introTextVideos': value.introTextVideos,
                'introTitleProducts': value.introTitleProducts,
                'introTextProducts': value.introTextProducts,
                'introTitleSightseeing': value.introTitleSightseeing,
                'introTextSightseeing': value.introTextSightseeing,
                'introTitleVaccination': value.introTitleVaccination,
                'introTextVaccination': value.introTextVaccination,
                            'introImageVaccination': ApiImageToJSON(value.introImageVaccination),
                'introInformationVaccination': value.introInformationVaccination,
                    'sightseeingItems': ((value.sightseeingItems as Array<any>).map(ApiSightseeingItemToJSON)),
                'religion': value.religion,
                'currency': value.currency,
                'area': value.area,
                'infoSectionTitle': value.infoSectionTitle,
                            'infoSectionsFacts': ApiInfoSectionFactToJSON(value.infoSectionsFacts),
                'sherpaCode': value.sherpaCode,
                'introTitleEntry': value.introTitleEntry,
                    'infoSectionsEntry': ((value.infoSectionsEntry as Array<any>).map(ApiInfoSectionToJSON)),
                    'infoSectionsVaccination': ((value.infoSectionsVaccination as Array<any>).map(ApiInfoSectionToJSON)),
                    'climateAreas': ((value.climateAreas as Array<any>).map(ApiClimateAreaToJSON)),
                            'map': ApiImageToJSON(value.map),
                'content': value.content,
                            'heroBackgroundVideo': ApiVideoToJSON(value.heroBackgroundVideo),
                            'heroBackgroundImage': ApiImageToJSON(value.heroBackgroundImage),
                'themeContentArea': value.themeContentArea,
                'themeIntroClimate': value.themeIntroClimate,
                'themeIntroVaccination': value.themeIntroVaccination,
                'themeVaccinationInformationRichText': value.themeVaccinationInformationRichText,
                'themeFactsAccordion': value.themeFactsAccordion,
                'themeIntroEntry': value.themeIntroEntry,
                'themeIntroMain': value.themeIntroMain,
                'themeIntroMap': value.themeIntroMap,
                'themeIntroMedia': value.themeIntroMedia,
                'themeIntroFacts': value.themeIntroFacts,
                'themeIntroSightseeing': value.themeIntroSightseeing,
                'themeSightseeingItems': value.themeSightseeingItems,
                'themeIntroTrips': value.themeIntroTrips,
                'themeProductTeasers': value.themeProductTeasers,
                'themeImageGallery': value.themeImageGallery,
                'productTeasersHeadline': value.productTeasersHeadline,
                            'footerMainPage': ApiFooterContentItemsToJSON(value.footerMainPage),
                            'footerProductsPage': ApiFooterContentItemsToJSON(value.footerProductsPage),
                            'footerImagesPage': ApiFooterContentItemsToJSON(value.footerImagesPage),
                            'footerVideosPage': ApiFooterContentItemsToJSON(value.footerVideosPage),
                            'footerSightseeingPage': ApiFooterContentItemsToJSON(value.footerSightseeingPage),
                            'footerFactsPage': ApiFooterContentItemsToJSON(value.footerFactsPage),
                            'footerEntryPage': ApiFooterContentItemsToJSON(value.footerEntryPage),
                            'footerVaccinationPage': ApiFooterContentItemsToJSON(value.footerVaccinationPage),
                            'footerClimatePage': ApiFooterContentItemsToJSON(value.footerClimatePage),
                            'footerMapPage': ApiFooterContentItemsToJSON(value.footerMapPage),
                            'metaInformation': ApiMetaInformationToJSON(value.metaInformation),
                            'metaInformationLightProduct': ApiMetaInformationToJSON(value.metaInformationLightProduct),
                            'metaInformationLightImage': ApiMetaInformationToJSON(value.metaInformationLightImage),
                            'metaInformationLightVideos': ApiMetaInformationToJSON(value.metaInformationLightVideos),
                            'metaInformationLightSightseeing': ApiMetaInformationToJSON(value.metaInformationLightSightseeing),
                            'metaInformationLightFacts': ApiMetaInformationToJSON(value.metaInformationLightFacts),
                            'metaInformationLightEntry': ApiMetaInformationToJSON(value.metaInformationLightEntry),
                            'metaInformationLightVaccination': ApiMetaInformationToJSON(value.metaInformationLightVaccination),
                            'metaInformationLightClimate': ApiMetaInformationToJSON(value.metaInformationLightClimate),
                            'metaInformationLightMap': ApiMetaInformationToJSON(value.metaInformationLightMap),
                    'cities': ((value.cities as Array<any>).map(ApiCityToJSON)),
                            'mainPage': ApiPageToJSON(value.mainPage),
                    'subPages': ((value.subPages as Array<any>).map(ApiPageToJSON)),
                'products': value.products,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

