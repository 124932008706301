import { EventType } from '@/Tracking/types';

export default class BeginCheckout {
  event: EventType;
  pageType: string;
  airport?: string;
  currency?: string;
  price?: number;
  roundTripCode?: string;
  roundTripName?: string;
  duration?: number;
}
