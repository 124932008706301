import React, { FC, JSX, useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import CountriesSearchTab from '@/components/Search/CountriesSearchTab';
import TravelTypesSearchTab from '@/components/Search/TravelTypesSearchTab';
import { SearchForResultsProps } from '@/components/SearchForResults/Tabs';
import { CollapseIndex } from '@/components/Search/SearchDesktopOverlay';

const SearchForResultsDesktopOverlay: FC<
  SearchForResultsProps & {
    closeCollapse: () => void;
    calculatedProductsTotalResults?: number;
  }
> = ({
  collapseOpen,
  activeIndex,
  checkboxDispatch,
  checkboxState,
  closeCollapse,
  isLoading,
  calculatedProductsTotalResults
}): JSX.Element => {
  const { continents, travelTypes } = checkboxState;
  const [activeIndexFromProps, setActiveIndexFromProps] = useState<CollapseIndex>(0);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (activeIndex > 0) {
      setActiveIndexFromProps(activeIndex);
    } else {
      timer = setTimeout((): void => {
        setActiveIndexFromProps(0);
      }, 600);
    }

    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, [activeIndex]);

  return (
    <Collapse className="search-for-results__collapse" isOpen={collapseOpen}>
      {(activeIndex === 1 || activeIndexFromProps === 1) && continents.length > 0 && (
        <CountriesSearchTab
          checkboxState={checkboxState}
          checkboxDispatch={checkboxDispatch}
          fullSearchView
          closeCollapse={closeCollapse}
          isLoading={isLoading}
          calculatedProductsTotalResults={calculatedProductsTotalResults}
        />
      )}
      {(activeIndex === 2 || activeIndexFromProps === 2) && travelTypes.length > 0 && (
        <TravelTypesSearchTab
          checkboxState={checkboxState}
          checkboxDispatch={checkboxDispatch}
          fullSearchView
          closeCollapse={closeCollapse}
          isLoading={isLoading}
          calculatedProductsTotalResults={calculatedProductsTotalResults}
        />
      )}
    </Collapse>
  );
};

export default SearchForResultsDesktopOverlay;
