import React, { FC, JSX, ReactElement } from 'react';
import classNames from 'classnames';

const ProgressBarItem: FC<{
  label: string;
  activeIndex: number;
  idx: number;
  icon: ReactElement;
  onClick: (index: number) => void;
  disableNavigation?: boolean;
}> = ({ label, activeIndex, idx, icon, onClick, disableNavigation }): JSX.Element => {
  const handleClick = (idx: number): void => {
    if (idx < activeIndex && !disableNavigation) {
      onClick(idx);
    }
  };

  return (
    <div
      key={label}
      className={classNames('progressbar__item', {
        'progressbar__item--active': activeIndex === idx,
        'progressbar__item--passed': activeIndex > idx,
        'progressbar__item--clickable': activeIndex > idx && !!onClick && !disableNavigation
      })}
      onClick={(): void => {
        if (!disableNavigation) {
          handleClick(idx);
        }
      }}
    >
      {icon}
      <div className="progressbar__item__label">{label}</div>
    </div>
  );
};

export default ProgressBarItem;
