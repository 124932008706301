'use client';

import React, { FC, Fragment, JSX, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Props } from '@/types/cms/magnolia';
import { useModelApiClient } from '@/Util/api';
import { logger } from '@/Util/globals';
import { ApiPage } from '../../../api/model';
import { Link } from '@/Hooks/useReactTransitionProgress';

interface BreadcrumbProps {
  breadcrumbMap?: Record<string, string>;
  pathname?: string;
  pageProps?: Props;
}

const Breadcrumb: FC<BreadcrumbProps> = ({ breadcrumbMap, pathname, pageProps }): JSX.Element => {
  const { continent, country, city, page, siteConfig, isAuthor } = pageProps || {};
  const modelApiClient = useModelApiClient(isAuthor);

  const [crumbs, setCrumbs] = useState<Record<string, string>>({});

  useEffect(() => {
    (async (): Promise<void> => {
      if (!!breadcrumbMap) {
        setCrumbs(breadcrumbMap);
      } else if (!!pathname) {
        const pathParts = pathname.split('/').filter(part => part !== '');
        const pathLabels = pathParts.slice();

        if (!!continent && pathParts[1] === continent.metaInformation?.displayUrl) {
          pathLabels[1] = continent.name;
        }
        if (!!country) {
          if (pathLabels[2] === country.metaInformation?.displayUrl) {
            pathLabels[2] = country.name;
          }
          if (
            !city &&
            !!pathLabels[3] &&
            !!pageProps?.page.title &&
            pathLabels[3] !== pageProps?.page.title
          ) {
            pathLabels[3] = pageProps?.page.title;
          }
        }
        if (!!city) {
          if (pathLabels[3] === city.metaInformation?.displayUrl) {
            pathLabels[3] = city.name;
          }
          if (
            !!pathLabels[4] &&
            !!pageProps?.page.title &&
            pathLabels[4] !== pageProps?.page.title
          ) {
            pathLabels[4] = pageProps?.page.title;
          }
        }

        let pages: ApiPage[] = [];
        try {
          pages = await modelApiClient.getPageProps(
            'parentPages',
            page?.['@id'] || '',
            siteConfig?.name || ''
          );
        } catch (err) {
          logger('error')('Unable to fetch page breadcrumbs');
        }

        if (pages.length > 0) {
          for (let i = 0; i < pages.length; i++) {
            pathLabels[i] = pages[i]?.breadcrumbTitle || pages[i]?.title || '';
          }
        }
        if (pages.length > 1) {
          pathLabels.reverse();
        }

        const manualBreadcrumbMap: Record<string, string> = {};
        for (let j = 0; j < pathLabels.length; j++) {
          const url = `/${pathParts.slice(0, j + 1).join('/')}`;
          const label = `${pathLabels[j].charAt(0).toUpperCase()}${pathLabels[j].slice(1)}`.replace(
            /-/g,
            ' '
          );
          manualBreadcrumbMap[decodeURIComponent(label)] = decodeURIComponent(url);
        }
        setCrumbs(manualBreadcrumbMap);
      }
    })();
  }, [breadcrumbMap, pathname]);

  return (
    <div className="breadcrumb-container">
      {Object.entries(crumbs || {}).map(([label, url], idx) => (
        <Fragment key={`${label}-${url}`}>
          {idx > 0 && (
            <div className="breadcrumb-separator">
              <FontAwesomeIcon icon={faCaretRight} />
            </div>
          )}
          <div
            className={`breadcrumb-item${
              idx === Object.entries(crumbs || {}).length - 1 ? ' breadcrumb-item--bold' : ''
            }`}
          >
            <Link href={url}>{label}</Link>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
