import React, { Dispatch, FC, JSX, SetStateAction } from 'react';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretRightThin } from '@/Theme/SVG/Icons';
import classNames from 'classnames';
import { Button, Collapse } from 'reactstrap';
import { TFunction } from 'i18next';
import PaymentContent, {
  DisabledPaymentOptions
} from '@/components/checkout/payment/PaymentContent';

const getOptions = (t: TFunction): string[] => {
  return [t(Keys.deposit), t(Keys.fullPayment)];
};

const getDisabledState = (disabledOptions: DisabledPaymentOptions, idx: number): boolean => {
  return (idx === 0 && disabledOptions.deposit) || (idx === 1 && disabledOptions.full);
};

const PaymentMobile: FC<{
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  activeRadio: number;
  setActiveRadio: Dispatch<SetStateAction<number>>;
  logos: Array<{ url: string; name: string }>;
  disabledOptions: DisabledPaymentOptions;
}> = ({
  activeTab,
  setActiveTab,
  activeRadio,
  setActiveRadio,
  logos,
  disabledOptions
}): JSX.Element => {
  const { t } = useTranslation('Checkout');

  return (
    <div className="payment__selection__mobile">
      {getOptions(t).map((option: string, idx: number) => (
        <div
          key={option}
          className={classNames('payment__selection__mobile__option', {
            'payment__selection__mobile__option--disabled': getDisabledState(disabledOptions, idx)
          })}
        >
          <Button
            className={classNames('payment__selection__mobile__button', {
              'payment__selection__mobile__button--disabled': getDisabledState(disabledOptions, idx)
            })}
            onClick={(): void => setActiveTab(idx)}
            disabled={getDisabledState(disabledOptions, idx)}
          >
            <div className="payment__selection__mobile__header">{option}</div>
            <FontAwesomeIcon
              className={classNames('payment__selection__mobile__chevron', {
                'payment__selection__mobile__chevron--active': activeTab === idx
              })}
              icon={facCaretRightThin}
            />
          </Button>
          <Collapse isOpen={activeTab === idx}>
            <PaymentContent
              activeRadio={activeRadio}
              setActiveRadio={setActiveRadio}
              logos={logos}
              disabledOptions={disabledOptions}
            />
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default PaymentMobile;
