import React, { Dispatch, FC, JSX, SetStateAction } from 'react';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import classNames from 'classnames';
import PaymentContent, {
  DisabledPaymentOptions
} from '@/components/checkout/payment/PaymentContent';

const PaymentDesktop: FC<{
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  activeRadio: number;
  setActiveRadio: Dispatch<SetStateAction<number>>;
  logos: Array<{ url: string; name: string }>;
  disabledOptions: DisabledPaymentOptions;
}> = ({
  activeTab,
  setActiveTab,
  activeRadio,
  setActiveRadio,
  logos,
  disabledOptions
}): JSX.Element => {
  const { t } = useTranslation('Checkout');

  return (
    <div className="payment__selection">
      <div className="payment__tabs">
        <div
          className={classNames('payment__tab', {
            'payment__tab--active': activeTab === 0,
            'payment__tab--disabled': disabledOptions.deposit
          })}
          onClick={(): void => {
            if (!disabledOptions.deposit) {
              setActiveTab(0);
            }
          }}
        >
          {t(Keys.deposit)}
        </div>
        <div
          className={classNames('payment__tab', {
            'payment__tab--active': activeTab === 1,
            'payment__tab--disabled': disabledOptions.full
          })}
          onClick={(): void => {
            if (!disabledOptions.full) {
              setActiveTab(1);
            }
          }}
        >
          {t(Keys.fullPayment)}
        </div>
      </div>
      <PaymentContent
        activeRadio={activeRadio}
        setActiveRadio={setActiveRadio}
        logos={logos}
        disabledOptions={disabledOptions}
      />
    </div>
  );
};

export default PaymentDesktop;
