/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
        ApiRoom,
        ApiRoomFromJSON,
        ApiRoomFromJSONTyped,
        ApiRoomToJSON,
        ApiShip,
        ApiShipFromJSON,
        ApiShipFromJSONTyped,
        ApiShipToJSON,
    } from './';

/**
* 
* @export
* @interface ApiAccommodation
*/
export interface ApiAccommodation {
    /**
    * 
    * @type {string}
    * @memberof ApiAccommodation
    */
    id?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiAccommodation
    */
    accommodationType?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiAccommodation
    */
    cmsCode?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiAccommodation
    */
    address?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiAccommodation
    */
    descriptionBottom?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiAccommodation
    */
    descriptionTopRight?: string;
    /**
    * 
    * @type {Array<ApiImage>}
    * @memberof ApiAccommodation
    */
    images?: Array<ApiImage>;
    /**
    * 
    * @type {string}
    * @memberof ApiAccommodation
    */
    moreInformation?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiAccommodation
    */
    name?: string;
    /**
    * 
    * @type {number}
    * @memberof ApiAccommodation
    */
    numberOfStars?: number;
    /**
    * 
    * @type {string}
    * @memberof ApiAccommodation
    */
    shortDescription?: string;
    /**
    * 
    * @type {ApiShip}
    * @memberof ApiAccommodation
    */
    relatedShip?: ApiShip;
    /**
    * 
    * @type {Array<ApiRoom>}
    * @memberof ApiAccommodation
    */
    rooms?: Array<ApiRoom>;
}

export function ApiAccommodationFromJSON(json: any): ApiAccommodation {
return ApiAccommodationFromJSONTyped(json, false);
}

export function ApiAccommodationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAccommodation {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'id': json['id'],
                    'accommodationType': json['accommodationType'],
                    'cmsCode': json['cmsCode'],
                    'address': json['address'],
                    'descriptionBottom': json['descriptionBottom'],
                    'descriptionTopRight': json['descriptionTopRight'],
                'images': ((json['images'] as Array<any>).map(ApiImageFromJSON)),
                    'moreInformation': json['moreInformation'],
                    'name': json['name'],
                    'numberOfStars': json['numberOfStars'],
                    'shortDescription': json['shortDescription'],
                            'relatedShip': ApiShipFromJSON(json['relatedShip']),
                'rooms': ((json['rooms'] as Array<any>).map(ApiRoomFromJSON)),
        };
    }

    export function ApiAccommodationToJSON(value?: ApiAccommodation | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'id': value.id,
                'accommodationType': value.accommodationType,
                'cmsCode': value.cmsCode,
                'address': value.address,
                'descriptionBottom': value.descriptionBottom,
                'descriptionTopRight': value.descriptionTopRight,
                    'images': ((value.images as Array<any>).map(ApiImageToJSON)),
                'moreInformation': value.moreInformation,
                'name': value.name,
                'numberOfStars': value.numberOfStars,
                'shortDescription': value.shortDescription,
                            'relatedShip': ApiShipToJSON(value.relatedShip),
                    'rooms': ((value.rooms as Array<any>).map(ApiRoomToJSON)),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

