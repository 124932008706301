import React, { FC, useState } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import Extension from '@/components/checkout/extensions/Extension';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { ApiAccommodation } from '../../../../api/model';
import AccommodationContent from '@/components/accommodations/AccommodationContent';
import IsoModal from '@/components/IsoModal';
import { THEMES } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

const Extensions: FC<{ checkoutStore: CheckoutStore; pageProps?: Props }> = observer(
  function Extensions({ checkoutStore, pageProps }): JSX.Element {
    const { t } = useTranslation('Checkout');
    const [selectedExtensionAsAccommodation, setSelectedExtensionAsAccommodation] = useState<
      ApiAccommodation | undefined
    >(undefined);

    const selectedExtension = checkoutStore.extensions.find(
      extension => !!extension.selectedItems && extension.selectedItems.length > 0
    );

    return (
      <div className="extension__container">
        <h2 className="checkout__headline">{t(Keys.travelExtension)}</h2>
        <p>{t(Keys.travelExtensionText)}</p>
        {checkoutStore.extensions.map(extension => (
          <Extension
            key={extension.id}
            extensionId={extension.id}
            checkoutStore={checkoutStore}
            extension={extension.selectableItems[0]}
            extensionSelected={extension.selectedItems?.[0]}
            isSelected={extension.id === selectedExtension?.id}
            isDisabled={!!selectedExtension && selectedExtension.id !== extension.id}
            setSelectedExtensionAsAccommodation={setSelectedExtensionAsAccommodation}
            pageProps={pageProps}
          />
        ))}
        <IsoModal
          className="accommodation__modal"
          toggle={(): void => setSelectedExtensionAsAccommodation(undefined)}
          isOpen={!!selectedExtensionAsAccommodation}
          onClose={(): void => setSelectedExtensionAsAccommodation(undefined)}
          theme={THEMES.blue}
        >
          <div className="accommodation__modal__container">
            <AccommodationContent
              accommodation={selectedExtensionAsAccommodation}
              simpleMode
              pageProps={pageProps}
            />
          </div>
        </IsoModal>
      </div>
    );
  }
);

export default Extensions;
