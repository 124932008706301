import React, { FC, JSX } from 'react';
import classNames from 'classnames';
import { CheckboxProps } from '@/components/Search/Checkbox';

const Toggle: FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  disabled,
  className
}): JSX.Element => {
  const handleChange = (): void => {
    if (!disabled && !!onChange) {
      onChange();
    }
  };

  return (
    <label
      className={classNames(
        'toggle',
        className || '',
        { 'toggle--disabled': disabled },
        { 'toggle--checked': checked }
      )}
    >
      <input type="checkbox" checked={checked} onChange={handleChange} />
      {!!label && <div className="toggle__label">{label}</div>}
      <div className="toggle__field" />
    </label>
  );
};

export default Toggle;
