import React, { ReactElement } from 'react';
import sanitize from '@/Util/sanitize';
import ImageSlider from '@/components/imageSlider/ImageSlider';
import { ApiExcursion } from '../../../api/model';
import { Props } from '@/types/cms/magnolia';

const ExcursionContent = ({
  excursion,
  className,
  pageProps
}: {
  excursion?: ApiExcursion;
  className?: string;
  pageProps?: Props;
}): ReactElement => {
  return (
    <div className={`${className || ''}`}>
      {excursion?.images && excursion.images.length > 0 && (
        <ImageSlider images={excursion.images} pageProps={pageProps} />
      )}
      <div>
        <h4>{excursion?.name}</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitize(excursion?.description || '', true)
          }}
        />
        <div>{excursion?.price}</div>
      </div>
    </div>
  );
};

export default ExcursionContent;
