import React, { FC } from 'react';
import classNames from 'classnames';
import Keys from '@/Translations/generated/da/search.json.keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretRight } from '@/Theme/SVG/Icons';
import { TransitionStatus } from 'react-transition-state';
import { useTranslation } from '@/app/i18n/client';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import { ROOT_PORTAL_CONTAINER } from '@/Util/globals';
import { createPortal } from 'react-dom';
import { Spinner } from '@ibe/components';
import sanitize from '@/Util/sanitize';
import { ReducerState } from '@/components/Search/searchReducer/types';

const SubmitButtonMobile: FC<
  Pick<SearchProps, 'isInitialSearch' | 'isLoading'> & {
    status?: TransitionStatus;
    buttonCallbackCondition: boolean;
    callback?: () => void;
    checkboxState: ReducerState;
  }
> = ({
  status,
  buttonCallbackCondition,
  callback,
  isInitialSearch,
  isLoading,
  checkboxState
}): JSX.Element => {
  const { t } = useTranslation('search');
  const portalContainer = document.getElementById(ROOT_PORTAL_CONTAINER);
  const { totalProductsOverall } = checkboxState;

  return (
    <>
      {createPortal(
        <div className="search__content__mobile">
          <div
            className={classNames('search__submit__mobile-container', {
              'search__submit__mobile-container--hidden':
                status === 'preEnter' || status === 'exiting',
              'search__submit__mobile-container--results': !isInitialSearch
            })}
          >
            <button
              className="search__submit"
              onClick={buttonCallbackCondition ? callback : undefined}
              disabled={isLoading}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    !isInitialSearch
                      ? `${t(Keys.apply)} (${totalProductsOverall})`
                      : totalProductsOverall !== undefined
                      ? t(Keys.showResults, { amount: totalProductsOverall })
                      : t(Keys.gem)
                  )
                }}
              />
              {isInitialSearch && <FontAwesomeIcon icon={facCaretRight} />}
              {isLoading && <Spinner />}
            </button>
          </div>
        </div>,
        portalContainer || document.body
      )}
    </>
  );
};

export default SubmitButtonMobile;
