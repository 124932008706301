import React, { FC, useMemo } from 'react';
import CheckoutStore, { ApiComponentAdvanced } from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import {
  ApiAddItemRequestFromJSON,
  ApiComponentFromJSON,
  ApiExtraService,
  ApiHotel,
  ApiRelatedService,
  ApiSpecialRequestFromJSON
} from '@ibe/api';
import { isExtraService } from '@/types/typeGuards';
import { API_ITEM_SERVICE_CODE } from '@/Util/globals';
import HotelSelection from '@/components/hotelSelection/HotelSelection';
import SingleExcursions from '@/components/checkoutExcursions/SingleExcursions';
import { Props } from '@/types/cms/magnolia';

const RelatedServices: FC<{
  checkoutStore: CheckoutStore;
  relatedServices: ApiRelatedService[];
  extensionUnitRateId?: string;
  extensionId: string;
  pageProps?: Props;
}> = observer(function RelatedServices({
  checkoutStore,
  relatedServices,
  extensionUnitRateId,
  extensionId,
  pageProps
}): JSX.Element {
  const { t } = useTranslation('Checkout');

  const hotels = useMemo(() => {
    const hotelServices = relatedServices
      .filter(service => {
        const firstSelectable = service.selectableItems?.[0];
        return (
          isExtraService(firstSelectable) &&
          firstSelectable?.serviceType?.code === API_ITEM_SERVICE_CODE.EXTENSION_HOTEL
        );
      })
      .reduce(
        (
          total: Record<string, { selectableItems: ApiHotel[]; selectedItems: ApiHotel[] }>,
          current: ApiRelatedService
        ) => {
          const key = `${(current.selectableItems?.[0] as ApiHotel).start}-${
            (current.selectableItems?.[0] as ApiHotel).end
          }`;
          return {
            ...total,
            [key]: {
              selectableItems: [
                ...(total[key]?.selectableItems || []),
                ...((current.selectableItems || []) as ApiHotel[])
              ],
              selectedItems:
                !!current.selectedItems && current.selectedItems.length > 0
                  ? [...((current.selectedItems || []) as ApiHotel[])]
                  : [...(total[key]?.selectedItems || [])]
            }
          };
        },
        {}
      );
    return Object.entries(hotelServices).map(
      ([key, hotel]) =>
        ApiComponentFromJSON({
          id: key,
          selectableItems: hotel.selectableItems,
          selectedItems: hotel.selectedItems,
          startDate: hotel.selectableItems?.[0]?.start,
          endDate: hotel.selectableItems?.[0]?.end
        }) as ApiComponentAdvanced<ApiHotel>
    );
  }, [relatedServices]);

  const excursions = useMemo(() => {
    return relatedServices
      .filter(service => {
        const firstSelectable = service.selectableItems?.[0];
        return (
          isExtraService(firstSelectable) &&
          firstSelectable?.serviceType?.code === API_ITEM_SERVICE_CODE.EXTENSION_EXCURSION
        );
      })
      .map(
        excursion =>
          ApiComponentFromJSON({
            id: excursion.code,
            selectableItems: excursion.selectableItems || [],
            selectedItems: excursion.selectedItems || [],
            images: []
          }) as ApiComponentAdvanced<ApiExtraService>
      );
  }, [relatedServices]);

  const selectExternalHotel = async (hotel: ApiHotel, roomUnitRateId?: string): Promise<void> => {
    if (!!extensionUnitRateId) {
      const selectedExcursions = excursions.filter(
        singleExcursion => singleExcursion.selectedItems?.length > 0
      );
      const selectedHotels = hotels
        .filter(
          relatedServiceHotel =>
            relatedServiceHotel.selectedItems?.length > 0 &&
            !relatedServiceHotel.selectableItems?.find(
              selectableItem => selectableItem.code === hotel.code
            )
        )
        .flatMap(selectedHotels => selectedHotels.selectableItems || []);
      await checkoutStore.selectItemsInCart(extensionId, [extensionUnitRateId], {
        [extensionUnitRateId]: ApiAddItemRequestFromJSON({
          specialRequests: [
            ...selectedExcursions.map(singleExcursion =>
              ApiSpecialRequestFromJSON({
                code: singleExcursion.selectedItems[0].code
              })
            ),
            ...selectedHotels.map(selectedHotel =>
              ApiSpecialRequestFromJSON({
                code: selectedHotel.code,
                id: ((selectedHotel as unknown) as ApiExtraService)?.extraUnits?.[0]?.unitRates?.[0]
                  ?.id
              })
            ),
            ApiSpecialRequestFromJSON({
              code: hotel.code,
              id:
                roomUnitRateId ||
                ((hotel as unknown) as ApiExtraService)?.extraUnits?.[0]?.unitRates?.[0]?.id
            })
          ],
          date: checkoutStore.booking?.travelStartDate,
          count: 1,
          notes: [],
          travelers: []
        })
      });
    }
  };

  const selectOrDeselectExternalExcursion = async (
    excursion: ApiExtraService,
    select: boolean
  ): Promise<void> => {
    if (!!extensionUnitRateId) {
      const selectedHotels = hotels.filter(hotel => hotel.selectedItems?.length > 0);
      const selectedExcursions = excursions.filter(
        singleExcursion => singleExcursion.selectedItems?.length > 0
      );
      const hotelSpecialRequests = [
        ...selectedHotels.map(hotel =>
          ApiSpecialRequestFromJSON({
            code: hotel.selectedItems[0].code,
            id: ((hotel.selectedItems[0] as unknown) as ApiExtraService)?.extraUnits?.[0]
              ?.unitRates?.[0]?.id
          })
        )
      ];
      await checkoutStore.selectItemsInCart(extensionId, [extensionUnitRateId], {
        [extensionUnitRateId]: ApiAddItemRequestFromJSON({
          count: 1,
          data: excursion.start,
          specialRequests: [
            ...hotelSpecialRequests,
            ...selectedExcursions
              .filter(singleExcursion => singleExcursion.selectedItems?.[0].code !== excursion.code)
              .map(singleExcursion =>
                ApiSpecialRequestFromJSON({
                  code: singleExcursion.selectedItems[0].code
                })
              ),
            ...(select ? [{ ...ApiSpecialRequestFromJSON({ code: excursion.code }) }] : [])
          ],
          notes: [],
          travelers: []
        })
      });
    }
  };

  return (
    <div className="related-service__container">
      {Object.keys(hotels).length > 0 && (
        <>
          <div className="related-service__headline">{t(Keys.hotelSelection)}</div>
          <HotelSelection
            checkoutStore={checkoutStore}
            externalHotels={hotels}
            selectExternalHotel={selectExternalHotel}
            pageProps={pageProps}
            isExtension
          />
        </>
      )}
      {excursions.length > 0 && (
        <>
          <div className="related-service__headline">{t(Keys.additionalExcursions)}</div>
          <SingleExcursions
            checkoutStore={checkoutStore}
            externalExcursions={excursions}
            selectOrDeselectExternalExcursion={selectOrDeselectExternalExcursion}
            pageProps={pageProps}
          />
        </>
      )}
    </div>
  );
});

export default RelatedServices;
