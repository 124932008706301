import React, { FC, useEffect } from 'react';
import GridContainer from '@/Layouts/GridContainer';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { observer } from 'mobx-react';
import SingleExcursions from '@/components/checkoutExcursions/SingleExcursions';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import MealSelection from '@/components/mealSelection/mealSelection';
import VisaTeaser from '@/components/visaTeaser/VisaTeaser';
import ExcursionPackages from '@/components/checkoutExcursions/ExcursionPackages';
import CheckoutStep from '@/components/checkout/CheckoutStep';
import classNames from 'classnames';
import Extensions from '@/components/checkout/extensions/Extensions';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { CheckoutStepName, EventType } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import ExtrasSelection from '@/components/extrasSelection/ExtrasSelection';
import { Props } from '@/types/cms/magnolia';

const getLastIndex = (checkoutStore: CheckoutStore): number => {
  const firstStepMissing = !checkoutStore.visa && checkoutStore.mealPackages.length === 0;
  const secondStepMissing =
    checkoutStore.excursionPackages.length === 0 && checkoutStore.singleExcursions.length === 0;
  if (firstStepMissing && secondStepMissing) {
    return 0;
  } else if (firstStepMissing || secondStepMissing) {
    return 1;
  } else {
    return 2;
  }
};

const CheckoutStepExtras: FC<{
  checkoutStore: CheckoutStore;
  isDesktop: boolean;
  activeSubIndex: number[];
  activeIndex: number;
  numberOfSubStepsPerStep: number[];
  index: number;
  pageProps?: Props;
}> = observer(function CheckoutStepExtras({
  checkoutStore,
  isDesktop,
  activeSubIndex,
  activeIndex,
  numberOfSubStepsPerStep,
  index,
  pageProps
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const config = useConfig();
  const locale = useCurrentLanguage();

  useEffect(() => {
    if (
      index === activeIndex &&
      !!checkoutStore.selectedPacificProduct &&
      !!checkoutStore.product
    ) {
      broadcastEvent(EventType.CHECKOUT_PROGRESS, config, locale, {
        checkout: { step: index + 1, stepName: CheckoutStepName.EXTRAS },
        data: {
          booking: checkoutStore.booking,
          cacheDataProduct: checkoutStore.selectedPacificProduct,
          product: checkoutStore.product
        }
      });
    }
  }, [index, activeIndex, checkoutStore.selectedPacificProduct, checkoutStore.product]);

  return (
    <div className={classNames('mobile-slide', { 'mobile-slide--active': index === activeIndex })}>
      <div
        className="mobile-slide__inner"
        style={{
          width: isDesktop ? 'initial' : `${numberOfSubStepsPerStep[index] * 100}%`,
          transform: `translateX(${
            activeSubIndex[index] * (100 / numberOfSubStepsPerStep[index]) * -1
          }%)`
        }}
      >
        {(!!checkoutStore.visa ||
          checkoutStore.mealPackages.length > 0 ||
          checkoutStore.extras.length > 0) && (
          <CheckoutStep
            index={0}
            activeIndex={activeSubIndex[activeIndex]}
            numberOfItems={numberOfSubStepsPerStep[activeIndex]}
            baseClass="mobile-slide__step"
            ignore={isDesktop}
          >
            {!!checkoutStore.visa && (
              <GridContainer
                className={classNames({
                  'grid-section--last-element':
                    !!checkoutStore.visa &&
                    checkoutStore.mealPackages.length === 0 &&
                    checkoutStore.extras.length === 0 &&
                    checkoutStore.excursionPackages.length === 0 &&
                    checkoutStore.singleExcursions.length === 0 &&
                    checkoutStore.extensions.length === 0
                })}
              >
                <h2 className="checkout__headline">{t(Keys.visa)}</h2>
                <p>{t(Keys.visaInfo)}</p>
                <VisaTeaser
                  visa={checkoutStore.visa.selectableItems[0]}
                  checkoutStore={checkoutStore}
                  isSelected={checkoutStore.visa.selectedItems.length > 0}
                  componentId={checkoutStore.visa.id}
                  pageProps={pageProps}
                />
              </GridContainer>
            )}
            {checkoutStore.mealPackages.length > 0 && (
              <GridContainer
                className={classNames({
                  'grid-section--last-element':
                    checkoutStore.mealPackages.length > 0 &&
                    checkoutStore.extras.length > 0 &&
                    checkoutStore.excursionPackages.length === 0 &&
                    checkoutStore.singleExcursions.length === 0 &&
                    checkoutStore.extensions.length === 0
                })}
              >
                <h2 className="checkout__headline">{t(Keys.meal)}</h2>
                <p>{t(Keys.mealInfo)}</p>
                <MealSelection checkoutStore={checkoutStore} pageProps={pageProps} />
              </GridContainer>
            )}
            {checkoutStore.extras.length > 0 && (
              <GridContainer
                className={classNames({
                  'grid-section--last-element':
                    checkoutStore.extras.length > 0 &&
                    checkoutStore.excursionPackages.length === 0 &&
                    checkoutStore.singleExcursions.length === 0 &&
                    checkoutStore.extensions.length === 0
                })}
              >
                <h2 className="checkout__headline">{t(Keys.extra)}</h2>
                <p>{t(Keys.extraSubText)}</p>
                <ExtrasSelection checkoutStore={checkoutStore} pageProps={pageProps} />
              </GridContainer>
            )}
          </CheckoutStep>
        )}
        {(checkoutStore.excursionPackages.length > 0 ||
          checkoutStore.singleExcursions.length > 0) && (
          <CheckoutStep
            index={!checkoutStore.visa && checkoutStore.mealPackages.length === 0 ? 0 : 1}
            activeIndex={activeSubIndex[activeIndex]}
            numberOfItems={numberOfSubStepsPerStep[activeIndex]}
            baseClass="mobile-slide__step"
            ignore={isDesktop}
          >
            {checkoutStore.excursionPackages.length > 0 && (
              <GridContainer
                className={classNames({
                  'grid-section--last-element':
                    checkoutStore.excursionPackages.length > 0 &&
                    checkoutStore.singleExcursions.length === 0 &&
                    checkoutStore.extensions.length === 0
                })}
              >
                <h2 className="checkout__headline">{t(Keys.excursionPackages)}</h2>
                <p>{t(Keys.excursionPackagesInfo)}</p>
                <ExcursionPackages checkoutStore={checkoutStore} pageProps={pageProps} />
              </GridContainer>
            )}
            {checkoutStore.singleExcursions.length > 0 && (
              <GridContainer
                className={classNames({
                  'grid-section--last-element':
                    checkoutStore.singleExcursions.length > 0 &&
                    checkoutStore.extensions.length === 0
                })}
              >
                <h2 className="checkout__headline">{t(Keys.SingleExcursions)}</h2>
                <p>{t(Keys.SingleExcursionsInfo)}</p>
                <SingleExcursions checkoutStore={checkoutStore} pageProps={pageProps} />
              </GridContainer>
            )}
          </CheckoutStep>
        )}
        {checkoutStore.extensions.length > 0 && (
          <CheckoutStep
            index={getLastIndex(checkoutStore)}
            activeIndex={activeSubIndex[activeIndex]}
            numberOfItems={numberOfSubStepsPerStep[activeIndex]}
            baseClass="mobile-slide__step"
            ignore={isDesktop}
          >
            <GridContainer
              className={classNames({
                'grid-section--last-element': checkoutStore.extensions.length > 0
              })}
            >
              <Extensions checkoutStore={checkoutStore} pageProps={pageProps} />
            </GridContainer>
          </CheckoutStep>
        )}
      </div>
    </div>
  );
});

export default CheckoutStepExtras;
