/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiContentArea,
        ApiContentAreaFromJSON,
        ApiContentAreaFromJSONTyped,
        ApiContentAreaToJSON,
    } from './';

/**
* 
* @export
* @interface ApiProductInformation
*/
export interface ApiProductInformation {
    /**
    * 
    * @type {string}
    * @memberof ApiProductInformation
    */
    name?: string;
    /**
    * 
    * @type {Array<ApiContentArea>}
    * @memberof ApiProductInformation
    */
    contentAreas?: Array<ApiContentArea>;
}

export function ApiProductInformationFromJSON(json: any): ApiProductInformation {
return ApiProductInformationFromJSONTyped(json, false);
}

export function ApiProductInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProductInformation {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'name': json['name'],
                'contentAreas': ((json['contentAreas'] as Array<any>).map(ApiContentAreaFromJSON)),
        };
    }

    export function ApiProductInformationToJSON(value?: ApiProductInformation | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'name': value.name,
                    'contentAreas': ((value.contentAreas as Array<any>).map(ApiContentAreaToJSON)),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

