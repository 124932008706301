import React, { Dispatch, FC, JSX, SetStateAction, useEffect, useMemo, useState } from 'react';
import CheckoutStore, { ApiComponentAdvanced } from '@/templates/checkout/CheckoutStore';
import { ApiOverlaySeatMap, ApiOverlaySeatMapRequestFromJSON, ApiTransfer } from '@ibe/api';
import { ApiAccommodation, ApiExtra } from '../../../api/model';
import SeatSelection from '@/components/busSelection/SeatSelection';
import BusTeaser from '@/components/busSelection/BusTeaser';
import { observer } from 'mobx-react';
import StationSelection from '@/components/busSelection/StationSelection';
import { useApi } from '@/Hooks/useApi';
import { API_ITEM_SERVICE_CODE, logger, SEATMAP_IMAGE_REQUEST_SESSION_ID } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

const BusItem: FC<{
  checkoutStore: CheckoutStore;
  bus: ApiComponentAdvanced<ApiTransfer>;
  setSelectedBusAsAccommodation: Dispatch<SetStateAction<ApiAccommodation | undefined>>;
  stepChangeTriggered: boolean;
  pageProps?: Props;
  setIgnoreBusSeatValidation: Dispatch<SetStateAction<boolean>>;
  ignoreBusSeatValidation: boolean;
}> = observer(function BusItem({
  checkoutStore,
  bus,
  stepChangeTriggered,
  setSelectedBusAsAccommodation,
  pageProps,
  setIgnoreBusSeatValidation,
  ignoreBusSeatValidation
}): JSX.Element {
  const api = useApi(pageProps?.isAuthor);
  const [seatMap, setSeatMap] = useState<ApiOverlaySeatMap | undefined>(undefined);
  const [imageUrl, setImageUrl] = useState<string>('');
  const { selectedPacificProduct } = checkoutStore;

  useEffect(() => {
    (async () => {
      try {
        const res = await api.getOverlaySeatMap(
          ApiOverlaySeatMapRequestFromJSON({
            serviceCode: bus.selectedItems[0].code,
            serviceTypeCode: API_ITEM_SERVICE_CODE.BU,
            travelDate: selectedPacificProduct.travelStartDate
          })
        );
        setSeatMap(res?.seatMap);
        if (!!res?.seatMap?.seatMapPatternCode) {
          const image = await api.getOverlaySeatMapGraphic(
            res.seatMap.seatMapPatternCode,
            SEATMAP_IMAGE_REQUEST_SESSION_ID
          );
          if (!!image) {
            setImageUrl(URL.createObjectURL(image));
          }
        }
      } catch (err) {
        logger('error')('Unable to load seatMap: ', err);
      }
    })();
  }, [selectedPacificProduct]);

  const cmsExtra = useMemo(() => {
    return bus.selectableItems[0].metaInfo?.contentByMetaType?.extraContent?.content?.[
      bus.selectableItems[0].cmsCode
    ] as ApiExtra | undefined;
  }, [bus]);

  return (
    <>
      <BusTeaser
        key={bus.id}
        bus={bus.selectedItems[0]}
        cmsExtra={cmsExtra}
        setSelectedBusAsAccommodation={setSelectedBusAsAccommodation}
        pageProps={pageProps}
      />
      <StationSelection
        bus={bus.selectedItems[0]}
        componentId={bus.id}
        checkoutStore={checkoutStore}
      />
      {!!seatMap && (
        <SeatSelection
          checkoutStore={checkoutStore}
          seatMap={seatMap}
          seatMapImageUrl={imageUrl}
          bus={bus.selectedItems[0]}
          componentId={bus.id}
          stepChangeTriggered={stepChangeTriggered}
          setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
          ignoreBusSeatValidation={ignoreBusSeatValidation}
        />
      )}
    </>
  );
});

export default BusItem;
