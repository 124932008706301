'use client';

import React, { ReactElement } from 'react';
import { THEMES } from '@/Util/globals';
import ContentRow from '@/components/contentModule/ContentRow';
import GridContainer from '@/Layouts/GridContainer';
import { ApiContentArea } from '../../../api/model';
import { Props as PageProps } from '@/types/cms/magnolia';

type Props = {
  contentRow?: ApiContentArea[];
  theme?: string;
  headline?: string;
  pageProps?: PageProps;
};

const ContentModuleDynamic = (props: Props): ReactElement => {
  const { theme, headline, contentRow, pageProps } = props;
  return (
    <GridContainer className={theme || THEMES.darkGreen}>
      <div className="content-module" id="contentArea">
        {!!headline && <h3 className="mb-xs-5">{headline}</h3>}
        {!!contentRow &&
          contentRow.map((row, index) => {
            return (
              <div className="content-row__container" key={index}>
                {index > 0 && <hr />}
                <ContentRow
                  leftSide={row.leftSide as 'text' | 'image' | 'empty'}
                  rightSide={row.rightSide as 'text' | 'image' | 'empty'}
                  imageLeft={row.imageLeft}
                  imageRight={row.imageRight}
                  textLeft={row.textLeft}
                  textRight={row.textRight}
                  pageProps={pageProps}
                />
              </div>
            );
          })}
      </div>
    </GridContainer>
  );
};

export default ContentModuleDynamic;
