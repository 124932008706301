'use client';

import Keys from '@/Translations/generated/da/country.json.keys';
import React, { JSX } from 'react';
import sanitize from '@/Util/sanitize';
import { useTranslation } from '@/app/i18n/client';
import AccordionElementList from '@/components/accordion/AccordionElementList';
import { THEMES } from '@/Util/globals';
import { ApiCountry } from '../../../api/model';
import Intro from '@/components/intro/Intro';
import GridContainer from '@/Layouts/GridContainer';
import { Props } from '@/types/cms/magnolia';

const CountryEntry = ({
  country,
  pathname,
  pageProps
}: {
  country?: ApiCountry;
  pathname: string;
  pageProps?: Props;
}): JSX.Element => {
  const { introTitleEntry, name, infoSectionsEntry, themeIntroEntry } = country || {};
  const { t } = useTranslation('country');

  return (
    <>
      <Intro
        theme={themeIntroEntry || THEMES.blue}
        headline={introTitleEntry ? introTitleEntry : `${t(Keys.headlineEntry)} ${name}`}
        pageProps={pageProps}
        pathname={pathname}
        singleColumn={
          <div className="intro__container__sub-headline">
            {t(Keys.subHeadlineEntry)} {name}
          </div>
        }
      />
      {infoSectionsEntry && (
        <GridContainer>
          <AccordionElementList
            items={infoSectionsEntry}
            bodyCb={item => (
              <div className="accordion-element__section__container">
                <div
                  className="accordion-element__section__text"
                  dangerouslySetInnerHTML={{ __html: sanitize(item.description, true) }}
                />
              </div>
            )}
            headerCb={item => (
              <div className="intro__container__accordionHeadline">{item.headline}</div>
            )}
            indexerProp="description"
          />
        </GridContainer>
      )}
    </>
  );
};

export default CountryEntry;
