import React, { FC, JSX, useState } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import MealTeaser from './mealTeaser';
import AccommodationContent from '@/components/accommodations/AccommodationContent';
import IsoModal from '@/components/IsoModal';
import { ApiAccommodation } from '../../../api/model';
import { THEMES } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

const MealSelection: FC<{ checkoutStore: CheckoutStore; pageProps?: Props }> = observer(
  function MealSelection({ checkoutStore, pageProps }): JSX.Element {
    const [selectedMealAsAccommodation, setSelectedMealAsAccommodation] = useState<
      ApiAccommodation | undefined
    >(undefined);

    return (
      <>
        <div className="meal-selection">
          {checkoutStore.mealPackages.map(mealPackage =>
            !!mealPackage?.selectableItems?.[0] ? (
              <MealTeaser
                key={mealPackage.id}
                mealPackage={mealPackage.selectableItems[0]}
                checkoutStore={checkoutStore}
                componentId={mealPackage.id}
                isSelected={!!mealPackage?.selectedItems?.[0]}
                setSelectedMealAsAccommodation={setSelectedMealAsAccommodation}
                pageProps={pageProps}
              />
            ) : (
              <></>
            )
          )}
        </div>
        <IsoModal
          className="accommodation__modal"
          toggle={(): void => setSelectedMealAsAccommodation(undefined)}
          isOpen={!!selectedMealAsAccommodation}
          onClose={(): void => setSelectedMealAsAccommodation(undefined)}
          theme={THEMES.blue}
        >
          <div className="accommodation__modal__container">
            <AccommodationContent
              accommodation={selectedMealAsAccommodation}
              simpleMode
              pageProps={pageProps}
            />
          </div>
        </IsoModal>
      </>
    );
  }
);

export default MealSelection;
