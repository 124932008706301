/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiClimateInfo,
        ApiClimateInfoFromJSON,
        ApiClimateInfoFromJSONTyped,
        ApiClimateInfoToJSON,
    } from './';

/**
* 
* @export
* @interface ApiClimateArea
*/
export interface ApiClimateArea {
    /**
    * 
    * @type {string}
    * @memberof ApiClimateArea
    */
    headline: string;
    /**
    * 
    * @type {Array<ApiClimateInfo>}
    * @memberof ApiClimateArea
    */
    climateInfos?: Array<ApiClimateInfo>;
}

export function ApiClimateAreaFromJSON(json: any): ApiClimateArea {
return ApiClimateAreaFromJSONTyped(json, false);
}

export function ApiClimateAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiClimateArea {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'headline': json['headline'],
                'climateInfos': ((json['climateInfos'] as Array<any>).map(ApiClimateInfoFromJSON)),
        };
    }

    export function ApiClimateAreaToJSON(value?: ApiClimateArea | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'headline': value.headline,
                    'climateInfos': ((value.climateInfos as Array<any>).map(ApiClimateInfoToJSON)),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

