'use client';

import React, { FC, JSX } from 'react';
import { useMount } from 'react-use';
import { useWindow } from '@ibe/components';

const removeStyleAttributes = (element: Element): void => {
  if (element.hasChildNodes()) {
    Array.from(element.children).forEach(child => {
      child.removeAttribute('style');
      removeStyleAttributes(child);
    });
  }
};

const RichTextClient: FC<{ id: string }> = ({ id }): JSX.Element => {
  const window = useWindow();

  useMount(() => {
    if (!!window) {
      const container = window.document.getElementById(id);
      if (!!container) {
        removeStyleAttributes(container);
      }
    }
  });

  return <></>;
};

export default RichTextClient;
