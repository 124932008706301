import { ApiProductsCacheData, ApiProductsCacheResponse } from '@ibe/api';
import dayjs from 'dayjs';

export type SortedCacheProductsByDate = {
  [year_month: string]: ApiProductsCacheResponse[];
};

export const YEAR_MONTH_DELIMITER = '-';

export const getSortedCacheProductsByDate = (
  products: ApiProductsCacheResponse[]
): SortedCacheProductsByDate => {
  const cacheResponsesSplitByDate = products.reduce(
    (total: ApiProductsCacheResponse[], product: ApiProductsCacheResponse) => {
      const splitCacheResponses = product.products?.reduce(
        (
          totalProducts: { [date: string]: ApiProductsCacheResponse },
          current: ApiProductsCacheData
        ) => {
          return {
            ...totalProducts,
            [current.travelStartDate || '']: {
              ...product,
              products: [...(totalProducts[current.travelStartDate || '']?.products || []), current]
            }
          };
        },
        {}
      );
      return !!splitCacheResponses && Object.keys(splitCacheResponses).length > 0
        ? [...total, ...Object.entries(splitCacheResponses).map(([, value]) => value)]
        : [...total];
    },
    []
  );

  return cacheResponsesSplitByDate
    .sort((a, b) => {
      if (!!a.products?.[0]?.travelStartDate && !b.products?.[0]?.travelStartDate) {
        return -1;
      } else if (!a.products?.[0]?.travelStartDate && !!b.products?.[0]?.travelStartDate) {
        return 1;
      } else if (!a.products?.[0]?.travelStartDate && !b.products?.[0]?.travelStartDate) {
        return 0;
      } else {
        return (
          dayjs(a.products?.[0]?.travelStartDate).unix() -
          dayjs(b.products?.[0]?.travelStartDate).unix()
        );
      }
    })
    .reduce((total: SortedCacheProductsByDate, current: ApiProductsCacheResponse) => {
      let year: string = '';
      let month: string = '';
      const foundProduct = current.products?.find(product => !!product.travelStartDate);
      if (!!foundProduct?.travelStartDate) {
        year = dayjs(foundProduct.travelStartDate).year().toString();
        month = dayjs(foundProduct.travelStartDate).month().toString();
      }
      return {
        ...total,
        [`${year}${YEAR_MONTH_DELIMITER}${month}`]: [...(total[`${year}-${month}`] || []), current]
      };
    }, {});
};
