import React, { useMemo } from 'react';

export interface TemperatureElementProps {
  minTemp: string;
  maxTemp: string;
}

const TemperatureElement = (props: TemperatureElementProps) => {
  const { minTemp, maxTemp } = props;

  const { colorCode } = useMemo(() => {
    return {
      colorCode:
        (Number(minTemp) + Number(maxTemp)) / 2 > 24
          ? 'high'
          : (Number(minTemp) + Number(maxTemp)) / 2 > 14
          ? 'mid'
          : (Number(minTemp) + Number(maxTemp)) / 2 > 9
          ? 'low'
          : 'cold'
    };
  }, [minTemp, maxTemp]);

  return (
    <div className={`temperature-element ${colorCode}`}>{`${minTemp} / ${maxTemp}`} &deg;</div>
  );
};
export default TemperatureElement;
