/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
* 
* @export
* @interface ApiCountriesRQ
*/
export interface ApiCountriesRQ {
    /**
    * 
    * @type {string}
    * @memberof ApiCountriesRQ
    */
    site: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountriesRQ
    */
    lang?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiCountriesRQ
    */
    continentId?: string;
    /**
    * 
    * @type {number}
    * @memberof ApiCountriesRQ
    */
    limit?: number;
}

export function ApiCountriesRQFromJSON(json: any): ApiCountriesRQ {
return ApiCountriesRQFromJSONTyped(json, false);
}

export function ApiCountriesRQFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCountriesRQ {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'site': json['site'],
                    'lang': json['lang'],
                    'continentId': json['continentId'],
                    'limit': json['limit'],
        };
    }

    export function ApiCountriesRQToJSON(value?: ApiCountriesRQ | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'site': value.site,
                'lang': value.lang,
                'continentId': value.continentId,
                'limit': value.limit,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

