import React, { PropsWithChildren, useState, JSX } from 'react';
import { useMount } from 'react-use';
import classNames from 'classnames';

const FadeIn = (props: PropsWithChildren<{}>): JSX.Element => {
  const { children } = props;
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useMount(() => {
    setIsMounted(true);
  });

  return (
    <div className={classNames('fade-in', { 'fade-in--transition': isMounted })}>{children}</div>
  );
};

export default FadeIn;
