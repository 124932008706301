'use client';

import React, { FC, PropsWithChildren, JSX } from 'react';
import { DefaultPageProps } from '@/types/cms/magnolia';
import ContactBar from '@/components/contactBar/ContactBar';
import Navigation from '@/components/navigation/Navigation';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import { THEMES } from '@/Util/globals';
import ContentModuleDynamic from '@/components/contentModule/ContentModuleDynamic';
import { PageComponentProps } from '@/components/magnoliaPage/componentHelper';
import CountryMain from '@/components/CountryIntros/CountryMain';

export const CountryPageInner: FC<
  PropsWithChildren<
    DefaultPageProps &
      PageComponentProps & {
        text: string;
        tel: string;
        mail: string;
        themeContactInformation: string;
        showContactBar: boolean;
        pathname: string;
      }
  >
> = (props): JSX.Element => {
  const {
    header,
    footer,
    themeContactInformation,
    showContactBar,
    children,
    upperPageContent,
    pageProps,
    pathname
  } = props;

  const { country } = pageProps || {};

  const {
    name,
    mainPage,
    subPages,
    themeContentArea,
    themeIntroMain,
    contentAreaTitle,
    contentArea
  } = country || {};

  return (
    <div className="country-page">
      <div className="page__top">
        {header && <EditableArea className="page__top__header" content={header} />}
        {showContactBar && <ContactBar theme={themeContactInformation || THEMES.darkGreen} />}
      </div>
      <div className="page__center">
        {upperPageContent}
        {!!mainPage && !!subPages && (
          <Navigation className={themeIntroMain || THEMES.blue} items={[mainPage, ...subPages]} />
        )}
        <CountryMain country={country} pageProps={pageProps} pathname={pathname} />
        {!!contentArea && contentArea.length > 0 && (
          <ContentModuleDynamic
            headline={contentAreaTitle ? contentAreaTitle : name}
            theme={themeContentArea}
            contentRow={contentArea}
            pageProps={pageProps}
          />
        )}
        {children}
      </div>
      <div className="page__bottom">{footer && <EditableArea content={footer} />}</div>
    </div>
  );
};

export default CountryPageInner;
