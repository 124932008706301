import { Channel } from '@ibe/services';
import PageView from '@/Tracking/eventClasses/pageView';
import Search from '@/Tracking/eventClasses/Search';
import BeginCheckout from '@/Tracking/eventClasses/beginCheckout';
import Purchase from '@/Tracking/eventClasses/purchase';
import CheckoutProgress from '@/Tracking/eventClasses/checkoutProgress';
import { Language } from '@/Translations';
import { EventType } from '@/Tracking/types';
import { tracking } from '@/Tracking/setup';
import AlbConfigModel from '@/Config/AlbConfigModel';
import { logger } from '@/Util/globals';

let pageViewEvent: Channel<PageView>;
let searchEvent: Channel<Search>;
let beginCheckoutEvent: Channel<BeginCheckout>;
let purchaseEvent: Channel<Purchase>;
let checkoutProgressEvent: Channel<CheckoutProgress>;

export const getEventClasses = (config: AlbConfigModel, locale: string) => {
  const newPageViewEvent =
    pageViewEvent ||
    (() => {
      pageViewEvent = new Channel<PageView>(PageView, 'page-view-event', (payload: PageView) => {
        if (!!config.googleTagManagerId?.[locale as `${Language}`]) {
          logger()('Tracking Event:', EventType.PAGE_VIEW, payload);
          tracking?.plugins
            ?.enable('google-tag-manager')
            .then(() => tracking?.track(EventType.PAGE_VIEW, payload));
        }
      });
      return pageViewEvent;
    })();
  const newSearchEvent =
    searchEvent ||
    (() => {
      searchEvent = new Channel<Search>(Search, 'search-event', (payload: Search) => {
        if (!!config.googleTagManagerId?.[locale as `${Language}`]) {
          logger()('Tracking Event:', EventType.SEARCH, payload);
          tracking?.plugins
            ?.enable('google-tag-manager')
            .then(() => tracking?.track(EventType.SEARCH, payload));
        }
      });
      return searchEvent;
    })();
  const newBeginCheckoutEvent =
    beginCheckoutEvent ||
    (() => {
      beginCheckoutEvent = new Channel<BeginCheckout>(
        BeginCheckout,
        'begin-checkout-event',
        (payload: BeginCheckout) => {
          if (!!config.googleTagManagerId?.[locale as `${Language}`]) {
            logger()('Tracking Event:', EventType.BEGIN_CHECKOUT, payload);
            tracking?.plugins
              ?.enable('google-tag-manager')
              .then(() => tracking?.track(EventType.BEGIN_CHECKOUT, payload));
          }
        }
      );
      return beginCheckoutEvent;
    })();
  const newPurchaseEvent =
    purchaseEvent ||
    (() => {
      purchaseEvent = new Channel<Purchase>(Purchase, 'purchase-event', (payload: Purchase) => {
        if (!!config.googleTagManagerId?.[locale as `${Language}`]) {
          logger()('Tracking Event:', EventType.PURCHASE, payload);
          tracking?.plugins
            ?.enable('google-tag-manager')
            .then(() => tracking?.track(EventType.PURCHASE, payload));
        }
      });
      return purchaseEvent;
    })();
  const newCheckoutProgressEvent =
    checkoutProgressEvent ||
    (() => {
      checkoutProgressEvent = new Channel<CheckoutProgress>(
        CheckoutProgress,
        'checkout-progress-event',
        (payload: CheckoutProgress) => {
          if (!!config.googleTagManagerId?.[locale as `${Language}`]) {
            logger()('Tracking Event:', EventType.CHECKOUT_PROGRESS, payload);
            tracking?.plugins
              ?.enable('google-tag-manager')
              .then(() => tracking?.track(EventType.CHECKOUT_PROGRESS, payload));
          }
        }
      );
      return checkoutProgressEvent;
    })();
  return {
    pageViewEvent: newPageViewEvent,
    searchEvent: newSearchEvent,
    beginCheckoutEvent: newBeginCheckoutEvent,
    purchaseEvent: newPurchaseEvent,
    checkoutProgressEvent: newCheckoutProgressEvent
  };
};
