import React from 'react';
import Image from '@/components/image/Image';
import { Link } from '@/Hooks/useReactTransitionProgress';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import {
  MagnoliaDamNode,
  MagnoliaNode,
  LinkSwitchableMixin,
  Props as PageProps
} from '@/types/cms/magnolia';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

interface Props {
  features: MagnoliaNode & { [a: string]: FeatureItem };
  pageProps?: PageProps;
}

interface FeatureItem extends MagnoliaNode, LinkSwitchableMixin {
  featureText: string;
  image: MagnoliaDamNode;
  openNewBrowserWindow?: boolean;
  pageProps?: PageProps;
}

const FeatureList = (props: Props) => {
  const { features, pageProps } = props;
  return (
    <div className="feature-list pt-4 pb-3 pb-sm-4 px-3 px-sm-4">
      <div className="feature-list__container">
        {features['@nodes'].map((item, index) => (
          <FeatureListItem key={index} {...features[item]} pageProps={pageProps} />
        ))}
      </div>
    </div>
  );
};

const FeatureListItem = (props: FeatureItem) => {
  const { image, featureText, linkTypedownload, linkTypeexternal, pageProps, linkTypepage } = props;
  const linkAttrs = getNextLinkAttrs(props, useGlobalMGLProps());

  const imageElement = (
    <div className="feature-list__iconBox">
      {image && image['@link'] && (
        <Image
          src={image['@link']}
          alt="icon"
          className="feature-list__icon"
          width={100}
          height={100}
          serverSideContext={pageProps?.albConfig.context}
        />
      )}
    </div>
  );

  return (
    <div className="feature-list__item">
      {(linkAttrs?.href && (linkTypedownload || linkTypeexternal || linkTypepage) && (
        <Link {...linkAttrs} prefetch={false}>
          {imageElement}
        </Link>
      )) ||
        imageElement}
      {featureText && <div className="feature-list__text">{featureText}</div>}
    </div>
  );
};

export default FeatureList;
