import { RefObject, useEffect, useState } from 'react';

const FULL_SCALE_DEFAULT_FACTOR: number = 1.3;

const useScrollZoom = (
  outerContainerRef: RefObject<HTMLDivElement>,
  preventZoom?: boolean,
  fullScaleFactor: number = FULL_SCALE_DEFAULT_FACTOR
): number => {
  const [scale, setScale] = useState<number>(fullScaleFactor);

  useEffect(() => {
    const handleScroll = (): void => {
      const elementScrollPosition = outerContainerRef.current?.getBoundingClientRect()?.top || 0;
      const elementHeight = outerContainerRef.current?.getBoundingClientRect()?.height || 0;
      const scrollHeight = window?.scrollY || 0;
      const scrollPercentage = (scrollHeight * 2 + elementScrollPosition) / elementHeight;
      const scaleDifference = FULL_SCALE_DEFAULT_FACTOR - 1;
      if (scrollPercentage >= 0 && scrollPercentage <= 1) {
        setScale(fullScaleFactor - scaleDifference * scrollPercentage);
      }
    };

    if (!preventZoom) {
      window?.removeEventListener('scroll', handleScroll);
      window?.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return (): void => {
      if (!preventZoom) {
        window?.removeEventListener('scroll', handleScroll);
      }
    };
  }, [preventZoom, outerContainerRef]);

  return scale;
};

export default useScrollZoom;
