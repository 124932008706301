import React, { FC, JSX } from 'react';
import classNames from 'classnames';
import Keys from '@/Translations/generated/da/search.json.keys';
import { getProductDisplayUrl } from '@/components/Search/TextSearchTab';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import { useTranslation } from '@/app/i18n/client';
import { TransitionStatus } from 'react-transition-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facGlobeMagnifier } from '@/Theme/SVG/Icons';
import { ApiProductComponent } from '@ibe/api';
import { Link } from '@/Hooks/useReactTransitionProgress';

const TextSearchResults: FC<
  Pick<SearchProps, 'textSearch' | 'productPageBasePath' | 'resultsPageBasePath'> & {
    status?: TransitionStatus;
  }
> = ({ textSearch, productPageBasePath, status, resultsPageBasePath }): JSX.Element => {
  const { t } = useTranslation('search');
  const { products: textSearchProducts } = textSearch;

  return (
    <div
      className={classNames('search__content__input__dropdown', {
        'search__content__input__dropdown--hidden': status === 'preEnter' || status === 'exiting'
      })}
    >
      {!!textSearchProducts && textSearchProducts.length > 0 && (
        <>
          <div className="search__content__input__dropdown__headline">
            <FontAwesomeIcon icon={facGlobeMagnifier} />
            <span>{`${t(Keys.travels)} (${textSearchProducts.length})`}</span>
          </div>
          {textSearchProducts.map((textSearchProduct: ApiProductComponent) => (
            <div
              key={textSearchProduct.productTitle}
              className="search__content__input__dropdown__item"
            >
              <Link href={`/${productPageBasePath}/${getProductDisplayUrl(textSearchProduct)}`}>
                {textSearchProduct.productTitle}
              </Link>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default TextSearchResults;
