/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
    } from './';

/**
* 
* @export
* @interface ApiVideo
*/
export interface ApiVideo {
    /**
    * 
    * @type {string}
    * @memberof ApiVideo
    */
    name?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiVideo
    */
    videoSrc?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiVideo
    */
    backupVideoSrc?: string;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiVideo
    */
    placeholderImage?: ApiImage;
}

export function ApiVideoFromJSON(json: any): ApiVideo {
return ApiVideoFromJSONTyped(json, false);
}

export function ApiVideoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVideo {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'name': json['name'],
                    'videoSrc': json['videoSrc'],
                    'backupVideoSrc': json['backupVideoSrc'],
                            'placeholderImage': ApiImageFromJSON(json['placeholderImage']),
        };
    }

    export function ApiVideoToJSON(value?: ApiVideo | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'name': value.name,
                'videoSrc': value.videoSrc,
                'backupVideoSrc': value.backupVideoSrc,
                            'placeholderImage': ApiImageToJSON(value.placeholderImage),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

