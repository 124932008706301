import React, { Dispatch, FC, JSX, SetStateAction } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { useTransition } from 'react-transition-state';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facExtras } from '@/Theme/SVG/Icons';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { Close } from '@/Theme/SVG/Svgs';
import Cart from '@/components/checkout/cart/Cart';
import { observer } from 'mobx-react';

const CartMobileWrapper: FC<{
  checkoutStore: CheckoutStore;
  setIgnoreBusSeatValidation: Dispatch<SetStateAction<boolean>>;
}> = observer(function CartMobileWrapper({
  checkoutStore,
  setIgnoreBusSeatValidation
}): JSX.Element {
  const { t } = useTranslation('Checkout');

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  return (
    <>
      <div className="checkout__footer__price-button" onClick={(): void => toggle(!isMounted)}>
        <FontAwesomeIcon icon={facExtras} />
        <span>{t(Keys.viewProduct)}</span>
      </div>
      {isMounted ? (
        <div
          className={classNames('cart__mobile-wrapper', {
            'cart__mobile-wrapper--hidden': status === 'preEnter' || status === 'exiting'
          })}
        >
          <div
            className={classNames('cart__mobile-wrapper__backdrop', {
              'cart__mobile-wrapper__backdrop--hidden':
                status === 'preEnter' || status === 'exiting'
            })}
            onClick={(): void => toggle(false)}
          />
          <div
            className={classNames('cart__mobile-wrapper__layer', {
              'cart__mobile-wrapper__layer--hidden': status === 'preEnter' || status === 'exiting'
            })}
          >
            <div className="cart__mobile-wrapper__layer__close" onClick={(): void => toggle(false)}>
              <Close />
            </div>
            <div className="cart__mobile-wrapper__layer__inner">
              <Cart
                checkoutStore={checkoutStore}
                setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default CartMobileWrapper;
