'use client';

import React, { FC, JSX, useEffect } from 'react';
import { INTEREST_LIST_HIDDEN_FIELD_NAME } from '@/Util/globals';

const InterestListPageClient: FC<{ productCode?: string }> = ({ productCode }): JSX.Element => {
  useEffect(() => {
    const formButton = document.querySelector('.magnolia-form__button button') as HTMLButtonElement;
    if (!!formButton) {
      formButton.disabled = !productCode;
    }
  }, [productCode]);

  useEffect(() => {
    if (!!productCode) {
      const form = document.querySelector('.magnolia-form__content form');
      if (!!form) {
        const hiddenField = document.createElement('input');
        hiddenField.id = INTEREST_LIST_HIDDEN_FIELD_NAME;
        hiddenField.name = INTEREST_LIST_HIDDEN_FIELD_NAME;
        hiddenField.type = 'hidden';
        hiddenField.value = productCode;
        form.appendChild(hiddenField);
      }
    }
  }, [productCode]);

  return <></>;
};

export default InterestListPageClient;
