'use client';

import React, { ReactElement } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { ApiCity, ApiVideoContent } from '../../../api/model';
import Intro from '@/components/intro/Intro';
import Keys from '@/Translations/generated/da/city.json.keys';
import { THEMES } from '@/Util/globals';
import VideoGallery from '@/components/videoGallery/VideoGallery';
import { MagnoliaVideos, Props } from '@/types/cms/magnolia';
import videoGallery from '@/components/videoGallery/VideoGallery';
import sanitize from '@/Util/sanitize';

const CityVideos = ({
  city,
  pageProps,
  pathname
}: {
  city?: ApiCity;
  pathname: string;
  pageProps?: Props;
}): ReactElement => {
  const { name, introTitleVideos, introTextVideos, cityVideos, themeIntroMedia } = city || {};
  const { t } = useTranslation('city');

  return (
    <>
      <Intro
        headline={introTitleVideos ? introTitleVideos : `${t(Keys.headlineVideos)} ${name}`}
        pageProps={pageProps}
        pathname={pathname}
        singleColumn={
          <div>
            {!!introTextVideos && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(introTextVideos, true)
                }}
              />
            )}
          </div>
        }
        theme={themeIntroMedia || THEMES.blue}
      />
      {!!videoGallery && <VideoGallery videos={cityVideos as MagnoliaVideos | ApiVideoContent[]} />}
    </>
  );
};

export default CityVideos;
