import { createTheme, CustomTheme } from '@ibe/components';
import { CSSObjectWithLabel, StylesConfig as ReactSelectStyles } from 'react-select';

const getTheme = () => {
  const reactSelectStyles: ReactSelectStyles = {
    singleValue: undefined,
    option: (provided, state) =>
      ({
        ...provided,
        cursor: 'pointer',
        color: state.isSelected ? '#fff' : '#28282f',
        backgroundColor: state.isSelected ? '#28336b' : 'none',
        '&:hover': {
          backgroundColor: '#28336b',
          color: '#fff'
        }
      } as CSSObjectWithLabel),
    indicatorsContainer: provided =>
      ({
        ...provided,
        height: '32px'
      } as CSSObjectWithLabel),
    dropdownIndicator: provided =>
      ({
        ...provided,
        color: '#D50101'
      } as CSSObjectWithLabel),
    control: (provided, state) =>
      ({
        ...provided,
        borderRadius: '.25rem',
        minHeight: 'auto',
        boxShadow: state.isFocused ? '0 0 0 .2rem rgba(40,51,107,0.25)' : 'none',
        borderColor: '#ced4da'
      } as CSSObjectWithLabel),
    menu: provided =>
      ({
        ...provided,
        zIndex: 6
      } as CSSObjectWithLabel),
    container: undefined,
    placeholder: undefined
  };

  const customTheme: CustomTheme = {
    bootstrap: {
      borderRadius: '.25rem',
      borderWidth: '1px',
      dark: '#28282f',
      gray100: '#f2f2f2',
      gray200: '#ededed',
      gray300: '#e4ecef',
      gray400: '#d7d7d7',
      gray500: '#9d9d9c',
      gray600: '#898989',
      gray700: '#5f5f5f',
      gray800: '#28282f',
      gray900: '#28282f',
      inputBorderColor: '#5CB5B4',
      inputBorderRadius: '.25rem',
      inputColor: '#5f5f5f',
      inputErrorColor: '#dc3545',
      inputFocusBorderColor: '#5CB5B4',
      inputFocusBoxShadow: '0 0 0 .2rem rgba(40,51,107,0.25)',
      inputFontSize: '1rem',
      inputFontWeight: '300',
      inputLineHeight: '1.5',
      primary: '#D50101',
      secondary: '#5CB5B4',
      spacer: '1rem',
      white: '#fff'
    },
    searchCriteriaDisplay: {
      color: '#4f5a95',
      backgroundColor: '#fff'
    },
    reactSelectStyles,
    paxSelectionOptions: {
      withIcon: false,
      shortTitle: false,
      longTitle: true
    },
    useCalenderIcon: true,
    seatMapCategoriesColors: {
      STANDARD: '#e4ecef'
    }
  };

  return createTheme(customTheme);
};

export default getTheme;
