import React, { CSSProperties, ReactNode } from 'react';

interface NavButtonProps {
  id?: string;
  onClick: () => void;
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
}
export const NavButton = ({
  onClick,
  className = 'navBtn',
  children,
  style,
  id
}: NavButtonProps) => (
  <>
    <button id={id || ''} className={className} onClick={onClick} style={style || {}}>
      {children}
    </button>
  </>
);
