'use client';

import React, { useState } from 'react';
import styles from '@/components/alertBox/AlertBox.module.scss';
import { Button as ReactButton, Card, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import sanitize from '@/Util/sanitize';

interface Props {
  header: string;
  text?: string;
}

const AlertBox = ({ header, text }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <ReactButton
        className={styles.alertBox__button}
        onClick={(): void => setIsOpen(value => !value)}
      >
        <span>{header}</span>
        <FontAwesomeIcon
          className={`${styles.alertBox__chevron} ${isOpen ? styles.alertBox__chevronUp : ''}`}
          icon={faChevronDown}
        />
      </ReactButton>
      <Collapse isOpen={isOpen}>
        <Card className={styles.alertBox__card}>
          {text && <div className="" dangerouslySetInnerHTML={{ __html: sanitize(text, true) }} />}
        </Card>
      </Collapse>
    </div>
  );
};

export default AlertBox;
