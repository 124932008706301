import React, { FC, useEffect } from 'react';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import MonthSelector from 'components/Search/MonthSelector';
import TextSearchMobile from '@/components/Search/TextSearchMobile';
import TravelTypesSearchMobile from '@/components/Search/TravelTypesSearchMobile';
import CountriesSearchMobile from '@/components/Search/CountriesSearchMobile';
import { useTransition } from 'react-transition-state';
import SubmitButtonMobile from '@/components/Search/SubmitButtonMobile';

const SearchMobileOverlay: FC<SearchProps & { hasSelectedItems: boolean }> = ({
  activeIndex,
  inputValue,
  handleInputChange,
  totalNumberOfProducts,
  checkboxDispatch,
  checkboxState,
  textSearch,
  textSearchInputRef,
  goToResultsPage,
  isLoading,
  productPageBasePath,
  hasSelectedItems,
  isInitialSearch,
  collapseOpen,
  resultsPageBasePath
}) => {
  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    toggle(hasSelectedItems && collapseOpen && activeIndex !== 4);
  }, [checkboxState, collapseOpen, activeIndex]);

  return (
    <>
      {activeIndex === 1 && (
        <CountriesSearchMobile
          checkboxState={checkboxState}
          checkboxDispatch={checkboxDispatch}
          isLoading={isLoading}
        />
      )}
      {activeIndex === 2 && (
        <TravelTypesSearchMobile
          checkboxState={checkboxState}
          checkboxDispatch={checkboxDispatch}
          isLoading={isLoading}
        />
      )}
      {activeIndex === 3 && (
        <MonthSelector
          checkboxDispatch={checkboxDispatch}
          checkboxState={checkboxState}
          isLoading={isLoading}
        />
      )}
      {activeIndex === 4 && (
        <TextSearchMobile
          handleInputChange={handleInputChange}
          inputValue={inputValue}
          totalNumberOfProducts={totalNumberOfProducts}
          textSearch={textSearch}
          textSearchInputRef={textSearchInputRef}
          isLoading={isLoading}
          productPageBasePath={productPageBasePath}
          resultsPageBasePath={resultsPageBasePath}
          isInitialSearch={isInitialSearch}
        />
      )}
      {isMounted && (
        <SubmitButtonMobile
          status={status}
          callback={goToResultsPage}
          buttonCallbackCondition={hasSelectedItems}
          isInitialSearch={isInitialSearch}
          isLoading={isLoading}
          checkboxState={checkboxState}
        />
      )}
    </>
  );
};

export default SearchMobileOverlay;
