export enum EventType {
  PAGE_VIEW = 'pageView',
  SEARCH = 'search',
  BEGIN_CHECKOUT = 'beginCheckout',
  PURCHASE = 'purchase',
  CHECKOUT_PROGRESS = 'checkoutProgress'
}

export enum SearchCategory {
  TOUR = 'Tour Search',
  WEBSITE = 'Website Search'
}

export enum PageType {
  TOUR = 'Tour',
  EXPLORE = 'Explore',
  PRACTICAL_INFO = 'Practical info',
  THEME = 'Theme',
  HOME = 'Home',
  WORLD_PART = 'World part',
  ABOUT_US = 'About us',
  TOUR_SEARCH = 'Tour search',
  WEBSITE_SEARCH = 'Website search',
  BOOKING_FORM = 'Booking form',
  ERROR_PAGE = '404',
  FAQ_PAGE = 'Faq'
}

export enum CheckoutStepName {
  SERVICES = 'Select services',
  EXTRAS = 'Select extras',
  DETAILS = 'Enter detail & payment',
  BOOKING = 'Booking confirmation'
}
