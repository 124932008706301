import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCoins, facGlobe, facLanguage, facVaccine, facVisum } from '@/Theme/SVG/Icons';
import Keys from '@/Translations/generated/da/TravelInformation.json.keys';
import React from 'react';
import { useTranslation } from '@/app/i18n/client';

const FactsDesktop = (props: {
  travelType: string | undefined;
  language: string | undefined;
  visum: boolean | undefined;
  vaccination: boolean | undefined;
  currency: string | undefined;
}) => {
  const { travelType, language, visum, vaccination, currency } = props;
  const { t } = useTranslation('TravelInformation');

  return (
    <div className="travel-information__facts--desktop">
      <div className="travel-information__fact--desktop">
        <FontAwesomeIcon icon={facLanguage} />
        <div className="travel-information__fact__data">
          <div>{t(Keys.language)}</div>
          <p>{language}</p>
        </div>
      </div>
      <div className="travel-information__fact--desktop">
        <FontAwesomeIcon icon={facVisum} />
        <div className="travel-information__fact__data">
          <div>{t(Keys.visum)}</div>
          <p>{visum ? t(Keys.yes) : t(Keys.no)}</p>
        </div>
      </div>
      <div className="travel-information__fact--desktop">
        <FontAwesomeIcon icon={facVaccine} />
        <div className="travel-information__fact__data">
          <div>{t(Keys.vaccination)}</div>
          <p>{vaccination ? t(Keys.yes) : t(Keys.no)}</p>
        </div>
      </div>
      <div className="travel-information__fact--desktop">
        <FontAwesomeIcon icon={facCoins} />
        <div className="travel-information__fact__data">
          <div>{t(Keys.currency)}</div>
          <p>{currency}</p>
        </div>
      </div>
    </div>
  );
};

export default FactsDesktop;
