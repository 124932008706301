'use client';

import React, { FC, JSX, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from '@ibe/components';
import {
  FROM_SEARCH_PARAMETER,
  getProductPageBasePath,
  getResultsPageBasePath,
  MEDIAQUERY_DEFAULTS,
  searchDataSessionStorage
} from '@/Util/globals';
import { CollapseIndex } from '@/components/Search/SearchDesktopOverlay';
import { MagnoliaSite, Props } from '@/types/cms/magnolia';
import { useRouter, useSearchParams } from 'next/navigation';
import { useRequests } from '@/components/Search/useRequests';
import { ApiProductsCacheResponse, ApiTextSearchResponse } from '@ibe/api';
import ResultsList from '@/components/SearchForResults/ResultsList';
import SearchUI from '@/components/Search/SearchUI';
import {
  getCheckboxStateItemsFromSearchParamsQuery,
  getFilteredProductPackages,
  getUrlWithUpdatedQueryParams,
  hasSelectedItems
} from '@/components/Search/helpers';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { EventType, SearchCategory } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { useTransitionProgress } from '@/Hooks/useReactTransitionProgress';
import { useCheckboxStateReducer } from '@/components/Search/searchReducer/useCheckboxStateReducer';
import { Action } from '@/components/Search/searchReducer/types';
import useMonthLabels from '@/Hooks/useMonthLabels';

const SearchInner: FC<{
  isInitialSearch?: boolean;
  productPackagesTotal: ApiProductsCacheResponse[];
  siteConfig?: MagnoliaSite;
  totalNumberOfProducts: number;
  rootNodePath: string;
  pageProps?: Props;
}> = ({
  isInitialSearch,
  productPackagesTotal,
  siteConfig,
  totalNumberOfProducts,
  rootNodePath,
  pageProps
}): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const config = useConfig();
  const locale = useCurrentLanguage();
  const startTransition = useTransitionProgress();
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const [inputValue, setInputValue] = useState<string>('');
  const [collapseOpen, setCollapseOpen] = useState<CollapseIndex>(0);
  const [textSearch, setTextSearch] = useState<Required<ApiTextSearchResponse>>({
    products: []
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const textSearchInputRef = useRef<HTMLInputElement>(null);
  const [singleRoomOnlyRadioChecked, setSingleRoomOnlyRadioChecked] = useState<boolean>(false);
  const [hideUnavailableTripsChecked, setHideUnavailableTripsChecked] = useState<boolean>(false);

  const [checkboxState, checkboxDispatch] = useCheckboxStateReducer(productPackagesTotal);

  const { getTextSearchResults } = useRequests(setIsLoading, setTextSearch, pageProps, siteConfig);
  const monthLabels = useMonthLabels();

  useEffect(() => {
    const { travelTypes, continents } = checkboxState;
    const checkboxStateItemsFromStorage = searchDataSessionStorage.get();
    const checkboxStateItemsFromQuery =
      travelTypes.length > 0 && continents.length > 0
        ? getCheckboxStateItemsFromSearchParamsQuery(
            searchParams,
            travelTypes,
            continents,
            monthLabels
          )
        : null;
    if (
      (searchDataSessionStorage.exists() && !!checkboxStateItemsFromStorage) ||
      !!checkboxStateItemsFromQuery
    ) {
      checkboxDispatch({
        type: Action.REPLACE_STATE,
        payload: {
          id: '',
          type: 'none',
          name: '',
          checkboxState: {
            ...checkboxState,
            items: (checkboxStateItemsFromStorage || checkboxStateItemsFromQuery)!
          }
        }
      });
      searchDataSessionStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (!!timeout.current) {
      clearTimeout(timeout.current);
    }
    if (inputValue.length > 0) {
      timeout.current = setTimeout((): Promise<void> => getTextSearchResults(inputValue), 300);
    }

    return () => {
      if (!!timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [inputValue]);

  const filteredProductPackages = useMemo(
    (): ApiProductsCacheResponse[] =>
      getFilteredProductPackages(
        checkboxState,
        singleRoomOnlyRadioChecked,
        hideUnavailableTripsChecked
      ),
    [
      singleRoomOnlyRadioChecked,
      hideUnavailableTripsChecked,
      checkboxState,
      checkboxState.items,
      Object.keys(checkboxState.items).length
    ]
  );

  const handleInputChange = (value: string): void => {
    let newTextSearch = { ...textSearch };
    if (!!newTextSearch.products && newTextSearch.products.length > 0) {
      newTextSearch = {
        ...newTextSearch,
        products: []
      };
    }
    setTextSearch(newTextSearch);
    setInputValue(value);
  };

  const closeCollapse = (): void => {
    setCollapseOpen(0);
  };

  const goToResultsPage = (): void => {
    startTransition(() => {
      broadcastEvent(EventType.SEARCH, config, locale, {
        search: { searchCategory: SearchCategory.TOUR },
        data: {
          pageProps,
          doBroadcastHomePageSearchEvent: true
        }
      });
      searchDataSessionStorage.set(checkboxState.items);
      const resultsPage = getResultsPageBasePath(rootNodePath, siteConfig);
      router.push(
        `${resultsPage}${getUrlWithUpdatedQueryParams(
          checkboxState.items,
          searchParams,
          undefined,
          [[FROM_SEARCH_PARAMETER, 'true']]
        )}`
      );
    });
  };

  return (
    <>
      <SearchUI
        collapseOpen={collapseOpen > 0}
        activeIndex={collapseOpen}
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        totalNumberOfProducts={totalNumberOfProducts}
        textSearch={textSearch}
        checkboxDispatch={checkboxDispatch}
        checkboxState={checkboxState}
        textSearchInputRef={textSearchInputRef}
        goToResultsPage={goToResultsPage}
        isLoading={isLoading}
        isDesktop={isDesktop}
        productPageBasePath={getProductPageBasePath(rootNodePath, siteConfig)}
        setCollapseOpen={setCollapseOpen}
        hasSelectedItems={hasSelectedItems(checkboxState)}
        closeCollapse={closeCollapse}
        isInitialSearch={isInitialSearch}
        singleRoomOnlyRadioChecked={singleRoomOnlyRadioChecked}
        setSingleRoomOnlyRadioChecked={setSingleRoomOnlyRadioChecked}
        productPackages={filteredProductPackages}
        resultsPageBasePath={getResultsPageBasePath(rootNodePath, siteConfig)}
      />
      {!isInitialSearch && (
        <ResultsList
          productPackages={filteredProductPackages}
          hideUnavailableTripsChecked={hideUnavailableTripsChecked}
          setHideUnavailableTripsChecked={setHideUnavailableTripsChecked}
          productPageBasePath={getProductPageBasePath(rootNodePath, siteConfig)}
          isLoading={isLoading}
          pageProps={pageProps}
        />
      )}
    </>
  );
};

export default SearchInner;
