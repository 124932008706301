import { EventType } from '@/Tracking/types';

export default class Purchase {
  event: EventType;
  pageType: string;
  airport?: string;
  duration?: number;
  currency?: string;
  price?: number;
  roundTripCode?: string;
  roundTripName?: string;
  customerZipCode?: string;
  customerCity?: string;
  pax?: number;
  transactionId?: string;
}
