import { ApiProductsCacheResponse } from '@ibe/api';
import {
  ApiAirportComponent,
  ApiContinentComponent,
  ApiDatesSearchResponseDates,
  ApiTravelTypeComponent,
  Payload,
  ReducerState
} from '@/components/Search/searchReducer/types';
import {
  getCacheDataProductsFilteredByDateAndAirport,
  meetsAirportConditions,
  meetsCountryConditions,
  meetsDateConditions,
  meetsTravelTypeConditions
} from '@/components/Search/searchReducer/helpers';
import { addEmptyButCheckedFilters } from '@/components/Search/searchReducer/addEmptyButCheckedFilters';
import { getTravelTypesState } from '@/components/Search/searchReducer/checkboxStatesPerType/getTravelTypesState';
import { getCountriesState } from '@/components/Search/searchReducer/checkboxStatesPerType/getCountriesState';
import { getDatesState } from '@/components/Search/searchReducer/checkboxStatesPerType/getDatesState';
import { getAirportsState } from '@/components/Search/searchReducer/checkboxStatesPerType/getAirportsState';

export const getFilteredProductsAndCheckboxes = (
  state: ReducerState,
  payload: Payload
): ReducerState => {
  const { type: payloadType } = payload;
  const { items, productPackagesTotal, dates, travelTypes, continents, airports } = state;

  const itemsList = Object.entries(items);
  const continentItems = itemsList.filter(([, item]) => item.type === 'continent');
  const travelTypeItems = itemsList.filter(([, item]) => item.type === 'travelType');
  const dateItems = itemsList.filter(([, item]) => item.type === 'date');
  const airportItems = itemsList.filter(([, item]) => item.type === 'airport');
  const filtersAreEmpty = itemsList.length === 0;

  let productPackagesFiltered: ApiProductsCacheResponse[] = [];
  let apiDates: ApiDatesSearchResponseDates[] =
    payloadType === 'date' && !filtersAreEmpty ? [...dates] : [];
  let apiContinents: ApiContinentComponent[] =
    payloadType === 'continent' && !filtersAreEmpty ? [...continents] : [];
  let apiTravelTypes: ApiTravelTypeComponent[] =
    payloadType === 'travelType' && !filtersAreEmpty ? [...travelTypes] : [];
  let apiAirports: ApiAirportComponent[] =
    payloadType === 'airport' && !filtersAreEmpty ? [...airports] : [];
  let totalProductsOverall = 0;
  let totalProductsForCountry = 0;
  let totalProductsForTravelType = 0;
  let totalProductsForAirport = 0;

  for (let productPackage of productPackagesTotal) {
    const { products, countries, travelTypes } = productPackage;

    apiTravelTypes = getTravelTypesState(
      continentItems,
      dateItems,
      travelTypeItems,
      airportItems,
      apiTravelTypes,
      filtersAreEmpty,
      payloadType,
      payload,
      countries,
      travelTypes,
      products
    );

    apiContinents = getCountriesState(
      continentItems,
      dateItems,
      travelTypeItems,
      airportItems,
      apiContinents,
      filtersAreEmpty,
      payloadType,
      payload,
      countries,
      travelTypes,
      products
    );

    apiDates = getDatesState(
      continentItems,
      dateItems,
      travelTypeItems,
      airportItems,
      apiDates,
      filtersAreEmpty,
      payloadType,
      countries,
      travelTypes,
      products
    );

    apiAirports = getAirportsState(
      continentItems,
      dateItems,
      travelTypeItems,
      airportItems,
      apiAirports,
      filtersAreEmpty,
      payloadType,
      payload,
      countries,
      travelTypes,
      products
    );

    if (
      meetsCountryConditions(continentItems, countries) &&
      meetsTravelTypeConditions(travelTypeItems, travelTypes) &&
      meetsAirportConditions(airportItems, products) &&
      meetsDateConditions(dateItems, products)
    ) {
      const filteredCacheDataProducts = getCacheDataProductsFilteredByDateAndAirport(
        dateItems,
        airportItems,
        productPackage.products
      );
      productPackagesFiltered = [
        ...productPackagesFiltered,
        {
          ...productPackage,
          products: !!filteredCacheDataProducts ? [...filteredCacheDataProducts] : undefined
        }
      ];
      totalProductsOverall = totalProductsOverall + 1;
    }

    if (meetsCountryConditions(continentItems, countries)) {
      totalProductsForCountry = totalProductsForCountry + 1;
    }
    if (meetsTravelTypeConditions(travelTypeItems, travelTypes)) {
      totalProductsForTravelType = totalProductsForTravelType + 1;
    }
    if (meetsAirportConditions(airportItems, products)) {
      totalProductsForAirport = totalProductsForAirport + 1;
    }
  }

  const {
    apiContinents: ApiContinentsReturned,
    apiDates: apiDatesReturned,
    apiTravelTypes: apiTravelTypesReturned,
    apiAirports: apiAirportsReturned
  } = addEmptyButCheckedFilters(
    continentItems,
    dateItems,
    travelTypeItems,
    airportItems,
    apiContinents,
    apiDates,
    apiTravelTypes,
    apiAirports,
    filtersAreEmpty
  );

  return {
    ...state,
    productPackagesFiltered,
    continents: ApiContinentsReturned,
    travelTypes: apiTravelTypesReturned,
    dates: apiDatesReturned,
    airports: apiAirportsReturned,
    totalProductsForCountry,
    totalProductsForTravelType,
    totalProductsForAirport,
    totalProductsOverall
  };
};
