import React, { FC } from 'react';
import { Collapse } from 'reactstrap';
import MonthSelector from 'components/Search/MonthSelector';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import Button from '@/components/Button';
import Keys from '@/Translations/generated/da/search.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { Spinner } from '@ibe/components';

const MonthSelectorTab: FC<
  Pick<SearchProps, 'collapseOpen' | 'checkboxState' | 'checkboxDispatch' | 'isLoading'> & {
    closeCollapse: () => void;
  }
> = ({ collapseOpen, closeCollapse, checkboxDispatch, checkboxState, isLoading }): JSX.Element => {
  const { t } = useTranslation('search');
  const { totalProductsOverall } = checkboxState;

  return (
    <Collapse className="search-for-results__collapse--single" isOpen={collapseOpen}>
      <MonthSelector
        mobileStyleInDesktop
        checkboxState={checkboxState}
        checkboxDispatch={checkboxDispatch}
        isLoading={isLoading}
      >
        <div className="search__content__submit">
          <Button color="primary" onClick={closeCollapse} disabled={isLoading}>
            <span>{`${t(Keys.apply)} (${totalProductsOverall})`}</span>
            {isLoading && <Spinner />}
          </Button>
        </div>
      </MonthSelector>
    </Collapse>
  );
};

export default MonthSelectorTab;
