import React, { FC, JSX, useMemo } from 'react';
import { ApiProduct } from '../../../api/model';
import Image from '@/components/image/Image';
import { ApiProductsCacheData, ApiProductWithReviews } from '@ibe/api';
import Keys from '@/Translations/generated/da/productTeaser.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { getDefaultFormatPrice, mapToApiImage, VARIANT_QUERY_PROP } from '@/Util/globals';
import Button from '@/components/Button';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import StarRating from '@/components/starRating/StarRating';
import { Props } from '@/types/cms/magnolia';
import { getVariantParam } from '@/components/ProductsDatesPrices/getVariantParam';
import { Link } from '@/Hooks/useReactTransitionProgress';

export type ExtendedProduct = ApiProduct & { review?: ApiProductWithReviews | null };

const ProductTeaser: FC<{
  product: ExtendedProduct;
  productPackage?: ApiProductsCacheData;
  numberOfPacificProducts: number;
  productPagePath?: string;
  originalProduct?: ApiProduct;
  pageProps?: Props;
}> = ({
  product,
  productPackage,
  numberOfPacificProducts,
  productPagePath,
  originalProduct,
  pageProps
}): JSX.Element => {
  const language = useCurrentLanguage();
  const { t } = useTranslation('productTeaser');
  const {
    teaserImageMedium,
    teaserImageLarge,
    tagline,
    newProduct,
    productPageBasePath,
    displayUrl,
    variants,
    productTitle
  } = product;
  const { duration, startingPriceAmount, travelStartDate, packageCode } = productPackage || {};
  const teaserImage = useMemo(() => {
    return mapToApiImage(teaserImageMedium || teaserImageLarge);
  }, [teaserImageMedium, teaserImageLarge]);

  const variantParam = useMemo(() => {
    return getVariantParam(originalProduct, travelStartDate, variants, packageCode);
  }, [travelStartDate, variants, packageCode, originalProduct]);

  return (
    <Link
      className="product-teaser"
      href={`/${productPagePath || productPageBasePath}/${displayUrl}${
        !!variantParam ? `?${VARIANT_QUERY_PROP}=${variantParam}` : ''
      }`}
    >
      <div className="product-teaser__inner">
        {!!teaserImage && (
          <div className="product-teaser__img-container">
            <Image
              src={teaserImage.imageLink || ''}
              alt={teaserImage.title || ''}
              width={640}
              height={480}
              aspectRatio="4-3"
              serverSideContext={pageProps?.albConfig.context}
            />
            {newProduct && <div className="product-teaser__badge">{t(Keys.new)}</div>}
          </div>
        )}
        <div className="product-teaser__content">
          {!!productPackage && (
            <div className="product-teaser__pacific">
              <div className="product-teaser__pacific__infos">
                <div>{t(Keys.day, { count: duration })}</div>
                <div className="product-teaser__divider">|</div>
                <div>{t(Keys.departure, { count: numberOfPacificProducts })}</div>
                <div className="product-teaser__divider">|</div>
                <div>{`${t(Keys.from)} ${getDefaultFormatPrice(
                  startingPriceAmount || 0,
                  language
                )}`}</div>
              </div>
              {!!product.review?.averageRating && product.review.averageRating > -1 && (
                <StarRating
                  rating={product.review.averageRating}
                  maxRating={Math.round(product.review.averageRating)}
                  className="product-teaser__rating"
                />
              )}
            </div>
          )}
          <p className="product-teaser__text">{productTitle}</p>
          <p className="product-teaser__tagline">{tagline}</p>
        </div>
      </div>
      <div className="product-teaser__button">
        <Button color="primary" showCaret>
          {t(Keys.goToTravel)}
        </Button>
      </div>
    </Link>
  );
};

export default ProductTeaser;
