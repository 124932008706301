'use client';

import React, { JSX, ReactElement } from 'react';
import Keys from '@/Translations/generated/da/country.json.keys';
import Map from '@/components/map/Map';
import { useTranslation } from '@/app/i18n/client';
import { THEMES } from '@/Util/globals';
import { ApiCity } from '../../../api/model';
import { AspectRatio } from '@/Util/imageLoader';
import Intro from '@/components/intro/Intro';
import sanitize from '@/Util/sanitize';
import { Props } from '@/types/cms/magnolia';

const CityMap = ({
  city,
  pageProps,
  pathname
}: {
  city?: ApiCity;
  pageProps?: Props;
  pathname: string;
}): JSX.Element => {
  const { map, introTitleMap, introTextMap, aspectRatio, themeIntroMap, name } = city || {};
  const { t } = useTranslation('city');

  return (
    <>
      <Intro
        theme={themeIntroMap || THEMES.blue}
        headline={introTitleMap ? introTitleMap : `${t(Keys.headlineMap)} ${name || ''}`}
        pageProps={pageProps}
        pathname={pathname}
        singleColumn={
          <div>
            <div className="country-map__description">
              {introTextMap && (
                <div dangerouslySetInnerHTML={{ __html: sanitize(introTextMap, true) }} />
              )}
            </div>
            <div className="country-map__map-wrapper">
              <Map
                map={map}
                theme={themeIntroMap ? themeIntroMap : THEMES.darkGreen}
                aspectRatio={aspectRatio as AspectRatio}
                pageProps={pageProps}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default CityMap;
