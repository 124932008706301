import React, { Dispatch, FC, JSX, SetStateAction, useMemo } from 'react';
import Select, { SelectOption } from '@/components/Select/Select';
import Keys from '@/Translations/generated/da/product.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { ApiProductsCacheResponse } from '@ibe/api';

const Filters: FC<{
  departureAirport: string;
  setDepartureAirport: Dispatch<SetStateAction<string>>;
  productPackages: ApiProductsCacheResponse[];
}> = ({ departureAirport, setDepartureAirport, productPackages }): JSX.Element => {
  const { t } = useTranslation('product');

  const departureAirportsOptions = useMemo((): SelectOption<string>[] => {
    return [
      { label: t(Keys.reset), value: '' },
      ...productPackages
        .flatMap(productPackage => productPackage.products)
        .reduce((total, current) => {
          return !total.find(
            item => !!current?.departureAirport && item.value === current.departureAirport
          ) && !!current?.departureAirport
            ? [
                ...total,
                { label: current.departureAirport || '', value: current.departureAirport || '' }
              ]
            : [...total];
        }, [] as SelectOption<string>[])
    ];
  }, [productPackages]);

  return departureAirportsOptions.length > 1 ? (
    <div className="dates-prices__filters">
      <Select
        className="dates-prices__filters__departure"
        placeholder={t(Keys.departureAirports)}
        value={departureAirport}
        onChange={setDepartureAirport}
        options={[...departureAirportsOptions]}
      />
    </div>
  ) : (
    <></>
  );
};

export default Filters;
