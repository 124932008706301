import React, { FC } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import { useTranslation } from '@/app/i18n/client';
import OperaItem from '@/components/operaSelection/OperaItem';
import { Props } from '@/types/cms/magnolia';

const OperaSelection: FC<{ checkoutStore: CheckoutStore; pageProps?: Props }> = observer(
  function OperaSelection({ checkoutStore, pageProps }): JSX.Element {
    const { t } = useTranslation('Checkout');

    return (
      <div className="opera-teasers">
        {checkoutStore.operas.map(opera => {
          return (
            <OperaItem
              key={opera.id}
              checkoutStore={checkoutStore}
              opera={opera}
              pageProps={pageProps}
            />
          );
        })}
      </div>
    );
  }
);

export default OperaSelection;
