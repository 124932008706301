import CheckoutStore from '@/templates/checkout/CheckoutStore';
import {
  ApiBookedItem,
  ApiItemType,
  ApiPrice,
  ApiPriceFromJSON,
  ApiSpecialRequestGroup
} from '@ibe/api';
import { API_ITEM_SERVICE_CODE, logger } from '@/Util/globals';
import { getAllPricesAreEqual } from '@/components/checkout/cart/Upgrades';

export type UpgradeItemType = 'OPERA' | 'BUS' | 'TRANSFER';
export type UpgradeItem = { item: ApiBookedItem; type: UpgradeItemType };

export type UpgradesData = {
  all: Array<UpgradeItem>;
  totalPricePerPerson: ApiPrice;
};

export const getServiceTypeCode = (bookedItem: ApiBookedItem): string => {
  let serviceTypeCode = '';
  try {
    if (!!bookedItem.idParent) {
      const decodedParentId = JSON.parse(atob(bookedItem.idParent));
      if (!!decodedParentId?.idParent) {
        serviceTypeCode = JSON.parse(atob(decodedParentId.idParent))?.serviceTypeCode || '';
      }
    }
  } catch (e) {
    logger('error')('Unable to decode/parse', e);
  }
  return serviceTypeCode;
};

export const getUpgrades = (checkoutStore: CheckoutStore): UpgradesData => {
  const { booking, packageCart } = checkoutStore;

  const transfers =
    booking?.bookedItems?.filter(
      bookedItem =>
        bookedItem.itemType === ApiItemType.TRANSFER &&
        !!bookedItem.price?.finalPrice &&
        !bookedItem.specialRequests?.some(
          specialRequest => specialRequest.group === ApiSpecialRequestGroup.SEATRESERVATION
        )
    ) || [];

  const operas =
    booking?.bookedItems?.filter(bookedItem => {
      return (
        bookedItem.itemType === ApiItemType.HOTEL &&
        getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.OPERA
      );
    }) || [];

  const bus =
    booking?.bookedItems?.filter(
      bookedItem =>
        getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.BU &&
        !!bookedItem?.specialRequests &&
        bookedItem.specialRequests.length > 0
    ) || [];

  const all = [
    ...operas.map(item => ({ item, type: 'OPERA' as UpgradeItemType })),
    ...transfers.map(item => ({ item, type: 'TRANSFER' as UpgradeItemType })),
    ...bus.map(item => ({ item, type: 'BUS' as UpgradeItemType }))
  ];
  const firstWithPrice = all.find(({ item }) =>
    !!item.priceByPersonId ? !!Object.values(item.priceByPersonId)[0] : false
  );
  return {
    all,
    totalPricePerPerson: ApiPriceFromJSON({
      ...(!!firstWithPrice?.item?.priceByPersonId
        ? Object.values(firstWithPrice.item.priceByPersonId)[0]
        : {}),
      finalPrice: all.reduce((total: number, current: UpgradeItem) => {
        const prices = Object.values(current.item.priceByPersonId).filter(Boolean);
        return (
          total +
          (!!current.item?.priceByPersonId
            ? !getAllPricesAreEqual(prices)
              ? Object.values(current.item.priceByPersonId).reduce(
                  (total, current) => total + (current?.finalPrice || 0),
                  0
                ) / prices.length
              : prices?.[0]?.finalPrice
            : 0)
        );
      }, 0),
      modifiers: []
    })
  };
};
