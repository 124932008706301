import React, { Dispatch, FC, JSX, SetStateAction } from 'react';
import GridContainer from '@/Layouts/GridContainer';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import PaymentDesktop from '@/components/checkout/payment/PaymentDesktop';
import PaymentMobile from '@/components/checkout/payment/PaymentMobile';
import { DisabledPaymentOptions } from '@/components/checkout/payment/PaymentContent';
import { observer } from 'mobx-react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
// import VoucherCode from '@/components/checkout/payment/VoucherCode';

const logos = [
  { url: './logos/logo_dankort.svg', name: 'Dankort' },
  { url: './logos/logo_mastercard.svg', name: 'Mastercard' },
  { url: './logos/logo_visa.svg', name: 'Visa' },
  { url: './logos/logo_jcb.svg', name: 'JCB' },
  { url: './logos/logo_apple_pay.svg', name: 'Apple Pay' },
  { url: './logos/logo_mobile_pay.svg', name: 'Mobile Pay' }
];

const Payment: FC<{
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<any>>;
  disabledOptions: DisabledPaymentOptions;
  setActiveRadio: Dispatch<SetStateAction<number>>;
  activeRadio: number;
  checkoutStore: CheckoutStore;
}> = observer(function Payment({
  activeTab,
  setActiveTab,
  disabledOptions,
  activeRadio,
  setActiveRadio
  // checkoutStore
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xm });

  return (
    <GridContainer className="payment">
      <h2 className="checkout__headline">{t(Keys.paymentOptions)}</h2>
      <p className="payment__text">{t(Keys.paymentText)}</p>
      {/* TODO: will be used some time after go-live */}
      {/*<VoucherCode checkoutStore={checkoutStore} />*/}
      <div className="payment__headline">{t(Keys.selectPayment)}</div>
      {isDesktop ? (
        <PaymentDesktop
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeRadio={activeRadio}
          setActiveRadio={setActiveRadio}
          logos={logos}
          disabledOptions={disabledOptions}
        />
      ) : (
        <PaymentMobile
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeRadio={activeRadio}
          setActiveRadio={setActiveRadio}
          logos={logos}
          disabledOptions={disabledOptions}
        />
      )}
    </GridContainer>
  );
});

export default Payment;
