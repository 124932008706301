'use client';

import React, { FC, ReactElement, useCallback, JSX, useState } from 'react';
import { ApiProduct } from '../../../api/model';
import {
  ApiProductsCacheRequestFromJSON,
  ApiProductsCacheResponse,
  ApiProductWithReviews
} from '@ibe/api';
import ProductTeaser, { ExtendedProduct } from '@/components/productTeasers/ProductTeaser';
import TeaserCarousel from '@/components/TeaserCarousel/TeaserCarousel';
import { MagnoliaSite, Props } from '@/types/cms/magnolia';
import { getProductPageBasePath, logger } from '@/Util/globals';
import { getSortedCacheProductsByDate } from '@/components/SearchForResults/helperFns';
import { getEarliestPackage } from '@/templates/country/ProductPageInnerGetSelectedPackage';
import { useMount } from 'react-use';
import { useApi } from '@/Hooks/useApi';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';

const ProductTeasersCarousel: FC<{
  products: ExtendedProduct[];
  siteConfig?: MagnoliaSite;
  rootNodePath: string;
  reviews?: ApiProductWithReviews;
  pageProps?: Props;
}> = ({ products, siteConfig, rootNodePath, pageProps }): JSX.Element => {
  const api = useApi(pageProps?.isAuthor);
  const locale = useCurrentLanguage();
  const [productPackages, setProductPackages] = useState<ApiProductsCacheResponse[]>([]);
  const [extendedProducts, setExtendedProducts] = useState<ExtendedProduct[]>(products);

  useMount(
    async (): Promise<void> => {
      try {
        const { productList, manualProductList } = products
          .flatMap(product => [product, ...(product.variants || [])])
          .reduce(
            (
              total: { productList: string[]; manualProductList: string[] },
              current: ExtendedProduct
            ) => {
              if (current.isManualTour) {
                return { ...total, manualProductList: [...total.manualProductList, current.name] };
              } else {
                return { ...total, productList: [...total.productList, current.name] };
              }
            },
            { productList: [], manualProductList: [] }
          );
        const [newProductPackages, reviews] = await Promise.all([
          api.getProductsList(
            ApiProductsCacheRequestFromJSON({
              productCodes: productList,
              manualProductCodes: manualProductList,
              languageCode: locale
            })
          ),
          api.productReviews([...products.map(product => product.name || '<EMPTY>')])
        ]);
        if (!!newProductPackages && newProductPackages.length > 0) {
          setProductPackages(newProductPackages);
        }
        if (!!reviews && reviews.length > 0) {
          setExtendedProducts(
            products.map((product: ApiProduct, idx: number) => ({
              ...product,
              review: !!reviews?.[idx] ? reviews[idx] : null
            }))
          );
        }
      } catch {
        logger('error')('Unable to fetch products for teaser');
      }
    }
  );

  const renderItem = useCallback(
    (product: ApiProduct): ReactElement => {
      const filteredProductPackages = productPackages.filter(
        productPackage =>
          productPackage.packageCode === product.name ||
          (!!product.variants &&
            product.variants
              .map(variant => variant.name)
              .includes(productPackage?.packageCode || ''))
      );
      const sortedProductPackages = getSortedCacheProductsByDate(filteredProductPackages);
      const earliestProduct = getEarliestPackage(sortedProductPackages);
      const cheapestProduct = earliestProduct?.products?.reduce((total, current) => {
        return (current.startingPriceAmount || 0) <= (total.startingPriceAmount || 0)
          ? current
          : total;
      }, earliestProduct?.products?.[0]);
      const code = cheapestProduct?.packageCode || '';
      const foundVariant = product.variants?.find(variant => {
        return code === variant.name;
      });
      const numberOfPacificProducts = Object.entries(sortedProductPackages)
        .flatMap(([, value]) => value)
        ?.filter(cacheResponse =>
          cacheResponse.products?.some(product => (product.availability || 0) > 0)
        ).length;

      return (
        <ProductTeaser
          product={
            !!foundVariant
              ? { ...foundVariant, displayUrl: product.displayUrl, variants: product.variants }
              : product
          }
          originalProduct={product}
          productPackage={cheapestProduct}
          numberOfPacificProducts={numberOfPacificProducts}
          productPagePath={getProductPageBasePath(rootNodePath, siteConfig)}
          pageProps={pageProps}
        />
      );
    },
    [productPackages]
  );

  return (
    <>
      {extendedProducts.length > 0 ? (
        <TeaserCarousel items={extendedProducts} renderItem={renderItem} />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProductTeasersCarousel;
