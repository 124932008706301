'use client';

import React, { ElementType } from 'react';
import { EditableComponent } from '@magnolia/react-editor';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import { SwitchableLinkProps } from './Link';
import { MagnoliaComponentNode, MagnoliaNode } from '@/types/cms/magnolia';

type ListStyleType = 'bullet' | 'ordered' | 'inline';
type LinkListLinkType = MagnoliaNode & { [key: `${number}`]: SwitchableLinkProps };

export interface Props extends MagnoliaComponentNode {
  subTitle: string;
  listStyle: ListStyleType;
  links?: LinkListLinkType;
}

const LinkList = (props: Props): JSX.Element => {
  const { links, subTitle, listStyle = 'bullet' } = props;
  const Tag = tag[listStyle];
  return (
    <div className={'link-list'}>
      {subTitle ? <h4>{subTitle}</h4> : null}
      <Tag className={`${listStyle === 'inline' ? 'list-inline' : ''}`}>
        {!!links ? <EditableArea content={links} customView={LinkListElement} /> : <></>}
      </Tag>
    </div>
  );
};

function LinkListElement({ content }: { content: LinkListLinkType }) {
  return (
    <>
      {content['@nodes']
        .filter(key => !!content[key as keyof LinkListLinkType])
        .map(nodeName => (
          <li key={(content[nodeName as keyof LinkListLinkType] as SwitchableLinkProps)['@id']}>
            <EditableComponent content={content[nodeName as keyof LinkListLinkType]} />
          </li>
        ))}
    </>
  );
}

const tag: { [a in ListStyleType]: ElementType } = {
  bullet: 'ul',
  ordered: 'ol',
  inline: 'ul'
};

export default LinkList;
