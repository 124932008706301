'use client';

import React, { useEffect, useMemo, useRef, useState, RefObject } from 'react';
import Keys from '@/Translations/generated/da/hero.json.keys';
import Hls from 'hls.js';
import { useInView } from 'react-intersection-observer';
import useScrollZoom from '@/Hooks/useScrollZoom';
import { useTranslation } from '@/app/i18n/client';
import { ApiImage } from '../../../api/model';
import isClient, { logger, YOUTUBE_NO_COOKIE_HOST } from '@/Util/globals';
import YouTube from 'react-youtube';
import classNames from 'classnames';

export type VideoProps = {
  videoSrc: string;
  backupVideoSrc?: string;
  className?: string;
  placeholderImage?: ApiImage;
  placeholderImageLink?: string;
  fullPlayerMode?: boolean;
  preload?: string;
  useLazyLoading?: boolean;
  useManualLazyLoading?: boolean;
  loadManually?: boolean;
  destroyVideo?: boolean;
  onVideoDestroyed?: () => void;
  useScaleEffect?: boolean;
  noFullHeight?: boolean;
};

const M3U8String = '.m3u8';

const getMimeType = (srcUrl: string): string => {
  const mimeType = new URL(srcUrl).pathname.split('.')[
    new URL(srcUrl).pathname.split('.').length - 1
  ];
  return mimeType === M3U8String.substring(1) ? 'application/x-mpegURL' : `video/${mimeType}`;
};

const setHlsLevels = (hls: Hls): void => {
  hls.startLevel = hls.levels.length - 1;
  hls.loadLevel = hls.levels.length - 1;
  hls.currentLevel = hls.levels.length - 1;
  hls.nextLevel = hls.levels.length - 1;
  hls.nextLoadLevel = hls.levels.length - 1;
  hls.firstLevel = hls.levels.length - 1;
};

const setNativeSources = (
  videoRef: RefObject<HTMLVideoElement>,
  videoSrc: string,
  backupVideoSrc?: string
): void => {
  if (!videoRef.current) return;
  const mainSource = videoRef.current.querySelector(
    '.native-video__source--main'
  ) as HTMLSourceElement;
  const backupSource = videoRef.current.querySelector(
    '.native-video__source--backup'
  ) as HTMLSourceElement;
  if (!!mainSource) {
    mainSource.src = videoSrc.trim();
  }
  if (!!backupSource && !!backupVideoSrc) {
    backupSource.src = backupVideoSrc.trim();
  }
};

export const Video = ({
  videoSrc,
  backupVideoSrc,
  className,
  placeholderImage,
  fullPlayerMode,
  preload,
  useLazyLoading,
  useManualLazyLoading,
  loadManually,
  useScaleEffect,
  noFullHeight,
  placeholderImageLink
}: VideoProps) => {
  const { t } = useTranslation('hero');
  const videoRef = useRef<HTMLVideoElement>(null);
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const [hlsItem, setHlsItem] = useState<Hls | null>(null);
  const isYoutube = !videoSrc.includes(M3U8String) && videoSrc.length < 15;

  const [ref, inView] = useInView({ threshold: 0.1 });

  const zoomScale = useScrollZoom(outerContainerRef, !useScaleEffect);

  const isLazyLoadStatusSufficient = useMemo((): boolean | undefined => {
    return (
      (!useLazyLoading || (useLazyLoading && inView)) &&
      (!useManualLazyLoading || (useManualLazyLoading && loadManually))
    );
  }, [useLazyLoading, inView, useManualLazyLoading, loadManually]);

  const removeDueToLazyLoading = useMemo((): boolean | undefined => {
    return (useManualLazyLoading && !loadManually) || (useLazyLoading && !inView);
  }, [useManualLazyLoading, loadManually, useLazyLoading, inView]);

  // initialize hls.js video stream
  useEffect(() => {
    (async (): Promise<void> => {
      if (
        Hls.isSupported() &&
        !!videoSrc &&
        !!videoRef.current &&
        isLazyLoadStatusSufficient &&
        !isYoutube
      ) {
        const hls = new Hls();
        setHlsItem(hls);

        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          logger()('hls attached to video');
        });
        hls.on(Hls.Events.MANIFEST_PARSED, function (_, data) {
          setHlsLevels(hls);
          logger()(
            `hls manifest loaded, ${data.levels.length} quality levels found. Active level: ${hls.startLevel}`
          );
        });

        hls.loadSource(videoSrc);
        hls.attachMedia(videoRef.current);
        await videoRef.current.play();
        setHlsLevels(hls);
      }
    })();
  }, [videoSrc, videoRef, useLazyLoading, inView, useManualLazyLoading, loadManually]);

  // initialize native video stream
  useEffect(() => {
    (async (): Promise<void> => {
      if (!Hls.isSupported() && !!videoRef.current && isLazyLoadStatusSufficient && !isYoutube) {
        setNativeSources(videoRef, videoSrc, backupVideoSrc);
        videoRef.current.load();
        await videoRef.current.play();
      }
    })();
  }, [videoRef, useLazyLoading, inView, useManualLazyLoading, loadManually]);

  // destroy hls.js video stream
  useEffect(() => {
    if (!!hlsItem && removeDueToLazyLoading && !isYoutube) {
      hlsItem.detachMedia();
      hlsItem.destroy();
      setHlsItem(null);
    }
  }, [hlsItem, useManualLazyLoading, loadManually, useLazyLoading, inView, videoRef]);

  // destroy native video stream
  useEffect(() => {
    if (!Hls.isSupported() && !!videoRef.current && removeDueToLazyLoading && !isYoutube) {
      setNativeSources(videoRef, '', '');
      videoRef.current.load();
    }
  }, [videoRef, useManualLazyLoading, loadManually, useLazyLoading, inView]);

  return (
    <div
      ref={outerContainerRef}
      className={classNames('video w-100 overflow-hidden', { 'h-100': !noFullHeight })}
    >
      <div
        className="video-container h-100 w-100"
        ref={useLazyLoading ? ref : undefined}
        style={useScaleEffect ? { transform: `scale(${zoomScale})` } : {}}
      >
        {isYoutube ? (
          <div className="h-100 w-100 overflow-hidden">
            {(inView || fullPlayerMode) && (
              <YouTube
                className="video-container__iframe"
                videoId={videoSrc}
                opts={{
                  playerVars: {
                    autoplay: 1,
                    loop: fullPlayerMode ? 0 : 1,
                    playlist: videoSrc,
                    rel: 0,
                    controls: fullPlayerMode ? 1 : 0,
                    mute: 1,
                    modestBranding: true
                  },
                  host: YOUTUBE_NO_COOKIE_HOST
                }}
              />
            )}
          </div>
        ) : Hls.isSupported() ? (
          <video
            ref={videoRef}
            className={className || ''}
            muted={!fullPlayerMode}
            autoPlay
            playsInline
            loop={!fullPlayerMode}
            controls={fullPlayerMode}
            preload={preload || 'auto'}
            poster={placeholderImageLink || placeholderImage?.imageLink}
          />
        ) : (
          <video
            ref={videoRef}
            className={className || ''}
            muted={!fullPlayerMode}
            autoPlay
            playsInline
            loop={!fullPlayerMode}
            controls={fullPlayerMode}
            preload={preload || 'auto'}
            poster={placeholderImageLink || placeholderImage?.imageLink}
          >
            {isClient() && (
              <>
                <source className="native-video__source--main" type={getMimeType(videoSrc)} />
                {!!backupVideoSrc && (
                  <>
                    <source
                      className="native-video__source--backup"
                      type={getMimeType(backupVideoSrc)}
                    />
                    <div>
                      <div>{t(Keys.notSupported)}</div>
                      <div>{t(Keys.downloadLink, { downloadLink: backupVideoSrc.trim() })}</div>
                    </div>
                  </>
                )}
              </>
            )}
          </video>
        )}
      </div>
    </div>
  );
};
