/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-11-15 13:08:37 */

const Keys = {
  search: 'search',
  destinations: 'destinations',
  travels: 'travels',
  travelTypes: 'travelTypes',
  departureTime: 'departureTime',
  showResults: 'showResults',
  inputPlaceholder: 'inputPlaceholder',
  all: 'all',
  gem: 'gem',
  departureAirport: 'departureAirport',
  singleRoomOnly: 'singleRoomOnly',
  clearAll: 'clearAll',
  foundTravels: 'foundTravels',
  hideCanceledTrips: 'hideCanceledTrips',
  productImageView: 'productImageView',
  mapView: 'mapView',
  calendarView: 'calendarView',
  apply: 'apply',
  filters: 'filters',
  foundTrips: 'foundTrips',
  foundTrips_plural: 'foundTrips_plural',
  hideUnavailableTrips: 'hideUnavailableTrips',
  price: 'price',
  duration: 'duration',
  departureDate: 'departureDate',
  low: 'low',
  high: 'high',
  departureDates: 'departureDates',
  day: 'day',
  day_plural: 'day_plural',
  from: 'from',
  soldOut: 'soldOut',
  goToProduct: 'goToProduct',
  sortBy: 'sortBy',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  January_short: 'January_short',
  February_short: 'February_short',
  March_short: 'March_short',
  April_short: 'April_short',
  May_short: 'May_short',
  June_short: 'June_short',
  July_short: 'July_short',
  August_short: 'August_short',
  September_short: 'September_short',
  October_short: 'October_short',
  November_short: 'November_short',
  December_short: 'December_short'
}; export default Keys;