import React, { FC, JSX, useMemo } from 'react';
import {
  ApiAddItemRequestFromJSON,
  ApiInsurance,
  ApiRelatedService,
  ApiSpecialRequestFromJSON
} from '@ibe/api';
import { getDefaultFormatPrice, MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { ApiInsurance as CmsInsurance } from '../../../../api/model';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { CircleCheckMark } from '@/Theme/SVG/Svgs';
import classNames from 'classnames';
import { useMediaQuery } from '@ibe/components';
import Button from '@/components/Button';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';

const InsuranceItem: FC<{
  relatedService: ApiRelatedService;
  checkoutStore: CheckoutStore;
  componentId?: string;
  componentUnitId?: string;
  travelerPosition?: string;
}> = observer(function InsuranceItem({
  relatedService,
  checkoutStore,
  componentId,
  componentUnitId,
  travelerPosition
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const locale = useCurrentLanguage();
  const isLarge = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.lg });
  const item = relatedService?.selectableItems?.[0] as ApiInsurance;
  const { name, cmsCode, units, metaInfo } = item || {};
  const unit = units?.[0];
  const specialRequestsFromSelectedInsurances =
    checkoutStore.packageCart?.itemsBookingOptions?.[componentUnitId || 'EMPTY']?.specialRequests;

  const cmsInsurance = useMemo(() => {
    return metaInfo?.contentByMetaType?.insuranceContent?.content?.[cmsCode] as
      | CmsInsurance
      | undefined;
  }, [metaInfo, cmsCode]);

  const isSelected = useMemo(() => {
    return (
      specialRequestsFromSelectedInsurances?.some(
        specialRequest =>
          specialRequest.id === item?.units?.[0]?.id &&
          specialRequest.travelerPosition === parseInt(travelerPosition || '-1', 10)
      ) || false
    );
  }, [specialRequestsFromSelectedInsurances, travelerPosition]);

  const showRecommendedBadge = useMemo(() => {
    if (!checkoutStore.packageCart || !checkoutStore.booking) return;
    const aYearFromToday = dayjs().add(365, 'day');
    const startDate = dayjs(checkoutStore.packageCart.packageModel.startDate);
    return (
      cmsInsurance?.recommended &&
      aYearFromToday.isAfter(startDate, 'day') &&
      (!cmsInsurance?.price ||
        !checkoutStore.booking?.price ||
        (!!cmsInsurance?.price &&
          !!checkoutStore.booking?.price &&
          checkoutStore.booking.price.finalPrice / (checkoutStore.booking?.travelers?.length || 1) <
            cmsInsurance.price))
    );
  }, [checkoutStore.packageCart, checkoutStore.booking, cmsInsurance]);

  const selectItem = async (remove: boolean): Promise<void> => {
    if (!!componentId && !!componentUnitId) {
      await checkoutStore.selectItemsInCart(componentId, [componentUnitId], {
        [componentUnitId]: ApiAddItemRequestFromJSON({
          count: 1,
          date: checkoutStore?.packageCart?.packageModel?.startDate,
          specialRequests: [
            ...(specialRequestsFromSelectedInsurances || []).filter(
              specialRequest =>
                !remove ||
                (remove && specialRequest.id !== item?.units?.[0]?.id) ||
                specialRequest.travelerPosition !== parseInt(travelerPosition || '-1', 10)
            ),
            ...(remove
              ? []
              : [
                  ApiSpecialRequestFromJSON({
                    id: item?.units?.[0]?.id,
                    travelerPosition,
                    code: '12' // just a random number...
                  })
                ])
          ],
          notes: [],
          travelers: []
        })
      });
    }
  };

  return (
    <div
      className={classNames('insurance__item', {
        'insurance__item--recommended': showRecommendedBadge
      })}
    >
      {showRecommendedBadge && (
        <div className="insurance__item__recommended">
          <span>{t(Keys.recommended)}</span>
        </div>
      )}
      {!!unit?.averagePricePerPerson?.finalPrice && !isLarge && (
        <div className="insurance__item__price insurance__item__price--mobile">
          <span className="insurance__item__price__inner">
            {getDefaultFormatPrice(unit.averagePricePerPerson.finalPrice, locale)}
          </span>
        </div>
      )}
      <div
        className={classNames('insurance__item__header', {
          'insurance__item__header--full': !cmsInsurance?.features
        })}
      >
        <h4 className="insurance__item__headline">{name}</h4>
        {!!units?.[0]?.name && (
          <div className="insurance__item__headline--sub">{units[0].name}</div>
        )}
      </div>
      {!!cmsInsurance && (
        <ul className="insurance__item__features">
          {cmsInsurance.features?.map(feature => (
            <li key={feature}>
              <CircleCheckMark />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      )}
      <div className="insurance__item__footer">
        {!isLarge ? (
          <div className="selection-item__select">
            <button
              type="button"
              className={classNames('selection-item__select__button', {
                'selection-item__select__button--active': isSelected
              })}
              onClick={(): Promise<void> => selectItem(isSelected)}
            >
              <span>{isSelected ? t(Keys.added) : t(Keys.add)}</span>
              <div className="selection-item__select__button__radio" />
            </button>
          </div>
        ) : (
          <div className="insurance__item__footer__inner">
            {!!unit?.averagePricePerPerson?.finalPrice && (
              <div className="insurance__item__price">
                {getDefaultFormatPrice(unit.averagePricePerPerson.finalPrice, locale)}
              </div>
            )}
            <Button
              className={classNames('checkout-teaser__button', {
                'checkout-teaser__button--selected': isSelected
              })}
              color="primary"
              onClick={(): Promise<void> => selectItem(isSelected)}
            >
              {isSelected ? t(Keys.added) : t(Keys.add)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
});

export default InsuranceItem;
