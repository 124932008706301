import {
  ApiAirportComponent,
  ApiContinentComponent,
  ApiDatesSearchResponseDates,
  ApiTravelTypeComponent,
  NumberArray,
  ReducerStateItem
} from '@/components/Search/searchReducer/types';
import { doesAirportIncludeName } from '@/components/Search/searchReducer/helpers';

export const addEmptyButCheckedFilters = (
  continentItems: [string, ReducerStateItem][],
  dateItems: [string, ReducerStateItem][],
  travelTypeItems: [string, ReducerStateItem][],
  airportItems: [string, ReducerStateItem][],
  apiContinents: ApiContinentComponent[],
  apiDates: ApiDatesSearchResponseDates[],
  apiTravelTypes: ApiTravelTypeComponent[],
  apiAirports: ApiAirportComponent[],
  filtersAreEmpty: boolean
) => {
  if (!filtersAreEmpty) {
    for (let [travelTypeItemId, travelTypeItem] of travelTypeItems) {
      if (!apiTravelTypes.find(apiTravelType => apiTravelType.id === travelTypeItemId)) {
        apiTravelTypes = [
          ...apiTravelTypes,
          { id: travelTypeItemId, name: travelTypeItem.name, products: 0 }
        ];
      }
    }

    for (let [continentItemId, continentItem] of continentItems) {
      const apiContinentIndex = apiContinents.findIndex(
        apiContinent => apiContinent.id === continentItemId
      );
      if (apiContinentIndex < 0) {
        const apiContinentCountries = !!continentItem.countries
          ? Object.entries(continentItem.countries).map(
              ([continentItemCountryId, continentItemCountryName]) => ({
                id: continentItemCountryId,
                name: continentItemCountryName,
                products: 0,
                continent: continentItemId
              })
            )
          : [];
        apiContinents = [
          ...apiContinents,
          {
            id: continentItemId,
            name: continentItem.name,
            products: 0,
            countries: apiContinentCountries
          }
        ];
      } else {
        for (let [continentItemCountryId, continentItemCountryName] of Object.entries(
          continentItem.countries || {}
        )) {
          if (
            !apiContinents[apiContinentIndex].countries.find(
              apiContinentCountry => apiContinentCountry.id === continentItemCountryId
            )
          ) {
            apiContinents[apiContinentIndex] = {
              ...apiContinents[apiContinentIndex],
              countries: [
                ...apiContinents[apiContinentIndex].countries,
                {
                  id: continentItemCountryId,
                  name: continentItemCountryName,
                  products: 0,
                  continent: apiContinents[apiContinentIndex].name
                }
              ]
            };
          }
        }
      }
    }

    for (let [dateItemId] of dateItems) {
      const dateItemYear = dateItemId.split('-')[0];
      const dateItemMonth = parseInt(dateItemId.split('-')[1], 10);
      const dateItemIndex = apiDates.findIndex(apiDate => apiDate.year === dateItemYear);
      if (dateItemIndex < 0) {
        const dateItemMonths = Array.from(Array(12), () => 0);
        dateItemMonths[dateItemMonth] = 1;
        apiDates = [...apiDates, { year: dateItemYear, months: dateItemMonths as NumberArray<12> }];
      } else {
        if (!apiDates[dateItemIndex].months[dateItemMonth]) {
          apiDates[dateItemIndex].months[dateItemMonth] = 1;
        }
      }
    }

    for (let [, airportItem] of airportItems) {
      if (!apiAirports.find(apiAirport => doesAirportIncludeName(apiAirport, airportItem.name))) {
        apiAirports = [
          ...apiAirports,
          {
            id: airportItem.name,
            name: airportItem.name,
            products: 0,
            differentAirportNames: [airportItem.name]
          }
        ];
      }
    }
  }

  for (let apiContinent of apiContinents) {
    if (!!apiContinent.countries) {
      apiContinent.countries = apiContinent.countries
        .filter(apiContinentCountry => !!apiContinentCountry.name)
        .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }
  }

  return {
    apiContinents: apiContinents.sort((a, b) => (a.name || '').localeCompare(b.name || '')),
    apiDates,
    apiTravelTypes: apiTravelTypes.sort((a, b) => (a.name || '').localeCompare(b.name || '')),
    apiAirports: apiAirports.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
  };
};
