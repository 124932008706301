import React, { FC, JSX, ReactElement } from 'react';
import classNames from 'classnames';
import { CheckboxProps } from '@/components/Search/Checkbox';
import { Label } from 'reactstrap';

const Radio: FC<
  Omit<CheckboxProps, 'label'> & {
    onChange?: Partial<CheckboxProps>['onChange'];
    label?: string | ReactElement;
  }
> = ({ checked, onChange, label, id, name, manual, disabled }): JSX.Element => {
  const handleChange = (): void => {
    if (!disabled && !!onChange && !manual) {
      onChange();
    }
  };

  return (
    <Label
      className={classNames(
        'checkbox radio',
        { 'checkbox--disabled': disabled },
        { 'checkbox--checked': checked }
      )}
    >
      <input
        id={id}
        type={manual ? 'radio' : 'checkbox'}
        name={name}
        checked={checked}
        onChange={handleChange}
      />
      <div className="checkbox__field">
        {(checked || manual) && <div className="radio__active" />}
      </div>
      {!!label && (
        <Label check={manual} className="checkbox__label" for={id}>
          {label}
        </Label>
      )}
    </Label>
  );
};

export default Radio;
