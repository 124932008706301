import React, { FC, JSX, useEffect, useRef } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { useTranslation } from '@/app/i18n/client';
import GridContainer from '@/Layouts/GridContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import classNames from 'classnames';

const TIME_TO_CLOSE = 16000;
let timeout: ReturnType<typeof setTimeout>;

const ErrorMessage: FC<{ checkoutStore: CheckoutStore }> = observer(function ErrorMessage({
  checkoutStore
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const ref = useRef<HTMLDivElement>(null);
  const message = checkoutStore.errorMessage;

  useEffect(() => {
    if (!message) return;
    if (!!timeout) {
      clearTimeout(timeout);
    }
    if (!!ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    timeout = setTimeout((): void => {
      checkoutStore.errorMessage = null;
    }, TIME_TO_CLOSE);

    return () => {
      if (!!timeout) {
        clearTimeout(timeout);
      }
    };
  }, [message, ref, ref.current]);

  const handleClick = (): void => {
    checkoutStore.errorMessage = null;
  };

  return !!message ? (
    <div ref={ref} className="checkout__error">
      <GridContainer>
        <div className="checkout__error__inner">
          {t(message)}
          <FontAwesomeIcon
            icon={faTimes}
            className="checkout__error__close"
            onClick={handleClick}
          />
        </div>
      </GridContainer>
    </div>
  ) : (
    <></>
  );
});

export default ErrorMessage;
