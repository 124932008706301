import CheckoutStore from '@/templates/checkout/CheckoutStore';
import {
  ApiBookedItem,
  ApiComponentType,
  ApiFlightItem,
  ApiItemType,
  ApiPrice,
  ApiPriceModifier
} from '@ibe/api';
import {
  AccommodationsData,
  getAccommodations
} from '@/components/checkout/cart/getAccommodations';
import { getUpgrades, UpgradesData } from '@/components/checkout/cart/getUpgrades';
import {
  AdditionalPurchasesData,
  getAdditionalPurchases
} from '@/components/checkout/cart/getAdditionalPurchases';

export type Discount = {
  discount: ApiPrice;
  promoCodes?: string[];
};

export type AllItems = {
  main?: ApiBookedItem;
  voucher?: ApiPriceModifier;
  discount?: Discount;
  flight?: ApiFlightItem;
  accommodations: AccommodationsData;
  upgrades: UpgradesData;
  additionalPurchases: AdditionalPurchasesData;
  insurances: Record<string, ApiBookedItem[]>;
};

export const getAllItems = (checkoutStore: CheckoutStore): AllItems => {
  return {
    main: checkoutStore?.booking?.bookedItems?.find(
      bookedItem => bookedItem.componentType === ApiComponentType.MAIN
    ),
    voucher: checkoutStore?.voucherModifier,
    discount: !!checkoutStore?.booking?.totalDiscount
      ? {
          discount: checkoutStore?.booking?.totalDiscount,
          promoCodes: checkoutStore?.booking?.promoCodes
        }
      : undefined,
    flight: checkoutStore?.booking?.items?.find(item => item.itemType === ApiItemType.FLIGHT) as
      | ApiFlightItem
      | undefined,
    accommodations: getAccommodations(checkoutStore),
    upgrades: getUpgrades(checkoutStore),
    additionalPurchases: getAdditionalPurchases(checkoutStore),
    insurances: (checkoutStore.booking?.travelers || []).reduce(
      (total: Record<string, ApiBookedItem[]>, traveler) => {
        return {
          ...total,
          [traveler.id]:
            checkoutStore?.booking?.bookedItems?.filter(
              bookedItem =>
                bookedItem.itemType === ApiItemType.INSURANCE &&
                !bookedItem.componentType &&
                bookedItem.paxRequirement?.paxes?.travelersIds?.includes(traveler.id)
            ) || []
        };
      },
      {}
    )
  };
};
