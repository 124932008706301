/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiContentAreaContent,
        ApiContentAreaContentFromJSON,
        ApiContentAreaContentFromJSONTyped,
        ApiContentAreaContentToJSON,
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
    } from './';

/**
* 
* @export
* @interface ApiContentArea
*/
export interface ApiContentArea {
    /**
    * 
    * @type {ApiContentAreaContent}
    * @memberof ApiContentArea
    */
    textLeft?: ApiContentAreaContent;
    /**
    * 
    * @type {ApiContentAreaContent}
    * @memberof ApiContentArea
    */
    textRight?: ApiContentAreaContent;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiContentArea
    */
    imageLeft?: ApiImage;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiContentArea
    */
    imageRight?: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiContentArea
    */
    description?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContentArea
    */
    title?: string;
    /**
    * 
    * @type {Array<ApiImage>}
    * @memberof ApiContentArea
    */
    images?: Array<ApiImage>;
    /**
    * 
    * @type {string}
    * @memberof ApiContentArea
    */
    leftSide?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContentArea
    */
    rightSide?: string;
}

export function ApiContentAreaFromJSON(json: any): ApiContentArea {
return ApiContentAreaFromJSONTyped(json, false);
}

export function ApiContentAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiContentArea {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                            'textLeft': ApiContentAreaContentFromJSON(json['textLeft']),
                            'textRight': ApiContentAreaContentFromJSON(json['textRight']),
                            'imageLeft': ApiImageFromJSON(json['imageLeft']),
                            'imageRight': ApiImageFromJSON(json['imageRight']),
                    'description': json['description'],
                    'title': json['title'],
                'images': ((json['images'] as Array<any>).map(ApiImageFromJSON)),
                    'leftSide': json['leftSide'],
                    'rightSide': json['rightSide'],
        };
    }

    export function ApiContentAreaToJSON(value?: ApiContentArea | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                            'textLeft': ApiContentAreaContentToJSON(value.textLeft),
                            'textRight': ApiContentAreaContentToJSON(value.textRight),
                            'imageLeft': ApiImageToJSON(value.imageLeft),
                            'imageRight': ApiImageToJSON(value.imageRight),
                'description': value.description,
                'title': value.title,
                    'images': ((value.images as Array<any>).map(ApiImageToJSON)),
                'leftSide': value.leftSide,
                'rightSide': value.rightSide,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

