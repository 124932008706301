'use client';

import React, { JSX } from 'react';
import Button from '@/components/Button';
import Keys from '@/Translations/generated/da/travelTypeTeaser.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import { MagnoliaNode } from '@/types/cms/magnolia';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

interface Props {
  link: MagnoliaNode;
}

const TravelTypeTeaserButton = (props: Props): JSX.Element => {
  const { link } = props;
  const { t } = useTranslation('travelTypeTeaser');
  const linkAttr = getNextLinkAttrs(
    {
      linkTypepage: link,
      linkType: 'page'
    },
    useGlobalMGLProps()
  );
  return (
    <div>
      <Button color="primary" showCaret href={linkAttr.href}>
        {t(Keys.buttonText)}
      </Button>
    </div>
  );
};

export default TravelTypeTeaserButton;
