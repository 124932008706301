import React, { JSX, useEffect, useRef, useState } from 'react';
import { inEditorAsync } from '@/components/magnoliaPage/componentHelper';

interface Props {
  annotation: string;
  callback?: () => void;
  alwaysRender?: boolean;
}

let timer: ReturnType<typeof setTimeout> | null = null;

const EditableComment = ({ annotation, callback, alwaysRender = false }: Props): JSX.Element => {
  const [render, setRender] = useState<boolean>(false);
  const [renderedAnnotation, setRenderedAnnotation] = useState<string>('');
  const commentRef = useRef<Comment | null>(null);
  const mountedRef = useRef<boolean>(false);

  useEffect(() => {
    mountedRef.current = true;
    if (alwaysRender && annotation) {
      setRender(alwaysRender);
    } else {
      inEditorAsync().then((inEditor: boolean): void => {
        // NOTE:
        // This mountedRef variable is for ensuring component is mounted before set state.
        if (mountedRef.current && inEditor) {
          setRender(inEditor);
        }
      });
    }
    updateAnnotation();

    return (): void => {
      mountedRef.current = false;
      removeAnnotation();
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const setRef = (node: HTMLDivElement): void => {
    renderAnnotation(node);
  };

  const removeAnnotation = (): void => {
    if (commentRef.current && commentRef.current.parentNode) {
      commentRef.current.parentNode.removeChild(commentRef.current);
      commentRef.current = null;
    }
  };

  const updateAnnotation = (): void => {
    const localAnnotation = annotation || '';

    if (commentRef.current && renderedAnnotation !== localAnnotation) {
      commentRef.current.textContent = localAnnotation;
      setRenderedAnnotation(localAnnotation);
      if (typeof callback === 'function') {
        timer = setTimeout(() => callback(), 0);
      }
    }
  };

  const renderAnnotation = (element: HTMLDivElement): void => {
    if (element) {
      removeAnnotation();

      commentRef.current = document.createComment(renderedAnnotation);
      element.before(commentRef.current);
      updateAnnotation();
      setRender(false);
    }
  };

  return render ? <div ref={setRef} /> : <></>;
};

export default EditableComment;
