import {
  meetsAirportConditions,
  meetsDateConditions,
  meetsTravelTypeConditions
} from '@/components/Search/searchReducer/helpers';
import {
  ApiContinentComponent,
  Payload,
  PayloadType,
  ReducerStateItem
} from '@/components/Search/searchReducer/types';
import {
  ApiProductsCacheData,
  ApiProductsCacheResponseCountry,
  ApiProductsCacheResponseTravelTypes
} from '@ibe/api';

export const getCountriesState = (
  continentItems: [string, ReducerStateItem][],
  dateItems: [string, ReducerStateItem][],
  travelTypeItems: [string, ReducerStateItem][],
  airportItems: [string, ReducerStateItem][],
  apiContinents: ApiContinentComponent[],
  filtersAreEmpty: boolean,
  payloadType: PayloadType,
  payload: Payload,
  countries?: ApiProductsCacheResponseCountry[],
  travelTypes?: ApiProductsCacheResponseTravelTypes[],
  products?: ApiProductsCacheData[]
): ApiContinentComponent[] => {
  if (
    (payloadType !== 'continent' &&
      meetsTravelTypeConditions(travelTypeItems, travelTypes) &&
      meetsAirportConditions(airportItems, products) &&
      meetsDateConditions(dateItems, products)) ||
    filtersAreEmpty
  ) {
    for (let country of countries || []) {
      const continentIndex = apiContinents.findIndex(
        apiContinent => apiContinent.id === country.continentId
      );
      if (continentIndex < 0) {
        apiContinents = [
          ...apiContinents,
          {
            id: country.continentId || '',
            name: country.continentName || '',
            products: 1,
            countries: [
              {
                id: country.id || '',
                name: country.name || '',
                products: 1,
                continent: country.continentId || ''
              }
            ]
          }
        ];
      } else {
        const countryIndex = (apiContinents[continentIndex]?.countries || []).findIndex(
          apiCountry => apiCountry.id === country.id
        );
        if (countryIndex < 0) {
          apiContinents[continentIndex] = {
            ...apiContinents[continentIndex],
            countries: [
              ...(apiContinents[continentIndex]?.countries || []),
              {
                id: country.id || '',
                name: country.name || '',
                products: 1,
                continent: country.continentId || ''
              }
            ]
          };
        } else {
          const foundCountry = {
            ...apiContinents[continentIndex].countries[countryIndex],
            products: apiContinents[continentIndex].countries[countryIndex].products + 1
          };
          apiContinents[continentIndex].countries.splice(countryIndex, 1, foundCountry);
        }
      }
    }
  }
  if (
    payloadType === 'continent' &&
    !continentItems.find(
      ([continentItemId, continentItem]) =>
        continentItemId === payload.id && !!continentItem.countries?.[payload.items?.[0]?.id || '']
    )
  ) {
    const apiContinentIndex = apiContinents.findIndex(
      apiContinent => apiContinent.id === payload.id
    );
    if (apiContinentIndex >= 0) {
      const apiCountryIndex = apiContinents[apiContinentIndex]?.countries?.findIndex(
        apiCountry => apiCountry.id === payload.items?.[0].id && apiCountry.products === 0
      );
      if (apiCountryIndex !== undefined && apiCountryIndex >= 0) {
        apiContinents[apiContinentIndex].countries.splice(apiCountryIndex, 1);
        if (apiContinents[apiContinentIndex].countries.length === 0) {
          apiContinents.splice(apiContinentIndex, 1);
        }
      }
    }
  }

  return apiContinents;
};
