import React, { PropsWithChildren } from 'react';
import { GlobalWindowContext } from '@ibe/components';
import isClient from './globals';

const GlobalWindowContextProvider = (props: PropsWithChildren<{}>): JSX.Element => {
  const { children } = props;

  return (
    <GlobalWindowContext.Provider value={isClient() ? window : null}>
      {children}
    </GlobalWindowContext.Provider>
  );
};

export default GlobalWindowContextProvider;
