'use client';

import React, { Fragment, ReactElement } from 'react';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import sanitize from '@/Util/sanitize';

type Spacings = {
  spacingBottomDesktop: string;
  spacingBottomMobile: string;
  spacingBottomTablet: string;
  spacingLeftDesktop: string;
  spacingLeftMobile: string;
  spacingLeftTablet: string;
  spacingRightDesktop: string;
  spacingRightMobile: string;
  spacingRightTablet: string;
  spacingTopDesktop: string;
  spacingTopMobile: string;
  spacingTopTablet: string;
};

type PropType = 'fixedColArea';
type Property = `${PropType}${number}`;
type Props = { [key in Property]: Spacings } & {
  layout: string;
  inlineStyles: string;
  inlineStylesheet: string;
  columnsWrapBreakpoint?: string;
};

const getStyleString = (props: Spacings, key: number): string => {
  return `
    .fixed-column-layout-column-${key} {
      padding-top: ${props.spacingTopMobile || '0'}rem;
      padding-right: ${props.spacingRightMobile || '0'}rem;
      padding-bottom: ${props.spacingBottomMobile || '0'}rem;
      padding-left: ${props.spacingLeftMobile || '0'}rem;
    }
    
    @media only screen and (min-width: 600px) {
        .fixed-column-layout-column-${key} {
          padding-top: ${props.spacingTopTablet || '0'}rem;
          padding-right: ${props.spacingRightTablet || '0'}rem;
          padding-bottom: ${props.spacingBottomTablet || '0'}rem;
          padding-left: ${props.spacingLeftTablet || '0'}rem;
        }
      }
      
    @media only screen and (min-width: 992px) {
      .fixed-column-layout-column-${key} {
        padding-top: ${props.spacingTopDesktop || '0'}rem;
        padding-right: ${props.spacingRightDesktop || '0'}rem;
        padding-bottom: ${props.spacingBottomDesktop || '0'}rem;
        padding-left: ${props.spacingLeftDesktop || '0'}rem;
      }
    }
  `;
};

const FixedColumnLayout = (props: Props): ReactElement => {
  const { layout, inlineStyles, inlineStylesheet, columnsWrapBreakpoint } = props;
  const colWidths = layout.split('x');
  const colCount = colWidths.length;

  return (
    <div>
      <div className={`grid${!!inlineStyles ? ` ${inlineStyles}` : ''}`}>
        {!!inlineStylesheet ? (
          <style dangerouslySetInnerHTML={{ __html: sanitize(inlineStylesheet, true) }} />
        ) : (
          <></>
        )}
        {[...Array(colCount).keys()].map(key => {
          const prop = props[`fixedColArea${key + 1}` as keyof Props];
          return !!prop ? (
            <Fragment key={key}>
              <style>{getStyleString(prop as Spacings, key)}</style>
              <div
                className={`fixed-column-layout-column-${key} grid-col-12 grid-col-${
                  columnsWrapBreakpoint || 'xm'
                }-${parseInt(colWidths[key], 10)}`}
              >
                <EditableArea content={prop as Record<string, any>} />
              </div>
            </Fragment>
          ) : (
            <Fragment key={key} />
          );
        })}
      </div>
    </div>
  );
};

export default FixedColumnLayout;
