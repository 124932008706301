'use client';

import React, { useMemo } from 'react';
import TravelInformationAccordion, {
  TravelInformationAccordionData,
  TravelInformationSectionData
} from '@/components/travelInformation/TravelInformationAccordion';
import FactsMobile from '@/components/travelInformation/FactsMobile';
import FactsDesktop from '@/components/travelInformation/FactsDesktop';
import GridContainer from '@/Layouts/GridContainer';
import { ApiFactSheet, ApiInformationGroup, ApiTravelType } from '../../../api/model';
import Keys from '@/Translations/generated/da/product.json.keys';
import { useTranslation } from '@/app/i18n/client';

interface Props {
  factSheet?: ApiFactSheet;
  informationGroup?: ApiInformationGroup[];
  visum?: boolean;
  vaccination?: boolean;
  currency?: string;
  theme?: string;
  travelType?: ApiTravelType[];
}

const TravelInformation = (props: Props) => {
  const { t } = useTranslation('product');
  const { factSheet, informationGroup, visum, vaccination, currency, theme, travelType } = props;

  const mappedInformationGroups: Array<TravelInformationAccordionData> = useMemo(() => {
    const mappedInformationGroupsTemp: Array<TravelInformationAccordionData> = [];
    informationGroup?.forEach(informationGroup => {
      const mappedGroup: TravelInformationAccordionData = {
        section: [],
        groupHeader: informationGroup?.groupName
      };
      informationGroup?.infoSection?.forEach(infoSection => {
        const mappedSection: TravelInformationSectionData = {
          sectionText: infoSection.description,
          sectionHeader: infoSection?.headline || ''
        };
        mappedGroup.section?.push(mappedSection);
      });
      mappedInformationGroupsTemp.push(mappedGroup);
    });
    return mappedInformationGroupsTemp;
  }, [informationGroup]);

  const factsData = {
    travelType: travelType?.at(0)?.name,
    language: factSheet?.language,
    visum: visum,
    vaccination: vaccination,
    currency: currency
  };

  return (
    <GridContainer className={`travel-information ${theme}`}>
      <h2 className="travel-information__header">{t(Keys.travelInformationHeadline)}</h2>
      <h4 className="travel-information__subHeader">{t(Keys.travelInformationText)}</h4>
      <FactsMobile {...factsData} />
      <FactsDesktop {...factsData} />
      <TravelInformationAccordion groups={mappedInformationGroups} showGeneralForHold />
    </GridContainer>
  );
};

export default TravelInformation;
