'use client';

import React, { FC, useEffect, useState } from 'react';
import { ApiProductsCacheData } from '@ibe/api';
import { CHECKOUT_CUSTOM_EVENT } from '@/templates/checkout/useCheckoutPageHelpers';
import PacificBaseInfos from '@/components/checkout/cart/PacificBaseInfos';

const HeroPacificProductInfos: FC<{ pacificProduct: ApiProductsCacheData }> = ({
  pacificProduct: pacificProductFromServer
}): JSX.Element => {
  const [pacificProduct, setPacificProduct] = useState<ApiProductsCacheData>(
    pacificProductFromServer
  );

  useEffect(() => {
    document.addEventListener(CHECKOUT_CUSTOM_EVENT, ((
      evt: CustomEvent<{ pacificProduct: ApiProductsCacheData }>
    ) => {
      const { pacificProduct: localPacificProduct } = evt.detail;
      if (!!localPacificProduct) {
        setPacificProduct(localPacificProduct);
      }
    }) as EventListener);
  }, []);

  return <PacificBaseInfos pacificProduct={pacificProduct} className="checkout__infos__product" />;
};

export default HeroPacificProductInfos;
