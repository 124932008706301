import { Dispatch, useEffect, useReducer } from 'react';
import { ApiProductsCacheResponse } from '@ibe/api';
import { Action, ReducerAction, ReducerState } from '@/components/Search/searchReducer/types';
import { getFilteredProductsAndCheckboxes } from '@/components/Search/searchReducer/getFilteredProductsAndCheckboxes';
import { usePathname, useSearchParams } from 'next/navigation';
import { useWindow } from '@ibe/components';
import { updateUrl } from '@/components/Search/searchReducer/helpers';
import { forceCheck } from 'react-lazyload';

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  const { type, payload } = action;
  const newState: ReducerState = { ...state };
  switch (type) {
    case Action.ADD_OR_REMOVE_SINGLE:
      if (payload.type === 'continent' && !!payload.items) {
        let localState = { ...newState };
        payload.items.forEach(({ id, name }) => {
          if (localState.items[payload.id]?.countries?.[id]) {
            delete localState.items[payload.id]?.countries?.[id];
            if (
              !!localState.items[payload.id]?.countries &&
              Object.keys(localState.items[payload.id].countries || {}).length === 0
            ) {
              delete localState.items[payload.id];
            }
          } else {
            localState = {
              ...localState,
              items: {
                ...localState.items,
                [payload.id]: {
                  ...(localState.items[payload.id] || {}),
                  type: payload.type,
                  name: payload.name,
                  countries: {
                    ...(localState.items[payload.id]?.countries || {}),
                    [id]: name
                  }
                }
              }
            };
          }
        });
        return getFilteredProductsAndCheckboxes(localState, payload);
      } else if (
        payload.type === 'travelType' ||
        payload.type === 'date' ||
        payload.type === 'airport'
      ) {
        if (!!newState.items[payload.id]) {
          delete newState.items[payload.id];
          return getFilteredProductsAndCheckboxes(newState, payload);
        } else {
          return getFilteredProductsAndCheckboxes(
            {
              ...newState,
              items: {
                ...newState.items,
                [payload.id]: {
                  type: payload.type,
                  name: payload.name,
                  differentAirportNames: payload.differentAirportNames
                }
              }
            },
            payload
          );
        }
      } else {
        return { ...newState };
      }
    case Action.ADD_OR_REMOVE_ALL_PER_TYPE:
      if (payload.type === 'continent' && !!payload.items) {
        const countries = newState.items[payload.id]?.countries;
        const countriesList = !!countries ? Object.keys(countries) : [];
        if (payload.items.length > 0 && countriesList.length === payload.items.length) {
          delete newState.items[payload.id];
          return getFilteredProductsAndCheckboxes(newState, payload);
        } else {
          return getFilteredProductsAndCheckboxes(
            {
              ...newState,
              items: {
                ...newState.items,
                [payload.id]: {
                  ...(newState.items[payload.id] || {}),
                  type: payload.type,
                  countries: Object.fromEntries(payload.items.map(({ id, name }) => [id, name]))
                }
              }
            },
            payload
          );
        }
      } else if ((payload.type === 'travelType' || payload.type === 'airport') && !!payload.items) {
        const itemsList = Object.entries(newState.items)
          .filter(([, item]) => item.type === payload.type)
          .map(([key]) => key);
        if (payload.items.length > 0 && itemsList.length === payload.items.length) {
          itemsList.forEach(key => {
            if (!!newState.items[key]) {
              delete newState.items[key];
            }
          });
          return getFilteredProductsAndCheckboxes(newState, payload);
        } else {
          let localState = { ...newState };
          payload.items.forEach(({ id, name, differentAirportNames }) => {
            localState = {
              ...localState,
              items: {
                ...localState.items,
                [id]: {
                  type: payload.type,
                  name,
                  differentAirportNames
                }
              }
            };
          });
          return getFilteredProductsAndCheckboxes(localState, payload);
        }
      } else {
        return { ...newState };
      }
    case Action.ADD_OR_REMOVE_ALL:
      return getFilteredProductsAndCheckboxes(
        {
          ...newState,
          items: {}
        },
        payload
      );
    case Action.REPLACE_STATE:
      if (!!payload.checkboxState) {
        return getFilteredProductsAndCheckboxes({ ...payload.checkboxState }, payload);
      } else {
        return { ...newState };
      }
    default:
      return { ...newState };
  }
};

export const useCheckboxStateReducer = (
  productPackagesTotal: ApiProductsCacheResponse[]
): [ReducerState, Dispatch<ReducerAction>] => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const window = useWindow();
  const [state, dispatch] = useReducer(
    reducer,
    {
      productPackagesTotal: [],
      productPackagesFiltered: [],
      items: {},
      continents: [],
      travelTypes: [],
      dates: [],
      airports: [],
      totalProductsForCountry: 0,
      totalProductsForTravelType: 0,
      totalProductsForAirport: 0,
      totalProductsOverall: 0
    },
    () =>
      getFilteredProductsAndCheckboxes(
        {
          productPackagesTotal,
          productPackagesFiltered: productPackagesTotal,
          items: {},
          continents: [],
          travelTypes: [],
          dates: [],
          airports: [],
          totalProductsForCountry: 0,
          totalProductsForTravelType: 0,
          totalProductsForAirport: 0,
          totalProductsOverall: 0
        },
        { id: '0', type: 'none', name: 'none' }
      )
  );
  const stateItems = state.items;
  const stateFilteredProducts = state.productPackagesFiltered;

  useEffect(() => {
    updateUrl(stateItems, searchParams, pathname, window);
  }, [state, stateItems, searchParams, pathname]);

  useEffect(() => {
    setTimeout((): void => {
      forceCheck();
    }, 1400);
  }, [state, stateItems, stateFilteredProducts]);

  return [state, dispatch];
};
