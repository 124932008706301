import { debounce } from 'lodash-es';
import { useEffect } from 'react';

const useResize = (
  callback: () => void,
  debounceDelay?: number,
  effectDependencyList?: any[]
): void => {
  useEffect(
    () => {
      const debouncedCallback = debounce(callback, debounceDelay || 0);

      window?.removeEventListener('resize', debouncedCallback);
      window?.addEventListener('resize', debouncedCallback);
      debouncedCallback();

      return (): void => {
        window?.removeEventListener('resize', debouncedCallback);
      };
    },
    !!effectDependencyList ? [...effectDependencyList] : []
  );
};

export default useResize;
