import React, { FC, useMemo } from 'react';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import Select from '@/components/Select/Select';
import { ApiAddItemRequestFromJSON, ApiTransfer } from '@ibe/api';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';

const StationSelection: FC<{
  bus: ApiTransfer;
  componentId: string;
  checkoutStore: CheckoutStore;
}> = observer(function StationSelection({ bus, componentId, checkoutStore }): JSX.Element {
  const { t } = useTranslation('Checkout');

  const stations = useMemo(() => {
    return {
      pickup:
        bus.selectablePickupStations?.map(station => ({
          label: `${station.description}${
            !!bus.start && !!station.group
              ? ` - ${dayjs(`${bus.start}T${station.group}`).format('HH:mm')}`
              : ''
          }`,
          value: station.code
        })) || [],
      dropOff:
        bus.selectableDropoffStations?.map(station => ({
          label: `${station.description}${
            !!bus.end && !!station.group
              ? ` - ${dayjs(`${bus.end}T${station.group}`).format('HH:mm')}`
              : ''
          }`,
          value: station.code
        })) || []
    };
  }, [bus]);

  const setStation = async (stationCode: string, isPickup: boolean): Promise<void> => {
    const station = (isPickup ? bus.selectablePickupStations : bus.selectableDropoffStations)?.find(
      singleStation => singleStation.code === stationCode
    );
    if (!!station) {
      await checkoutStore.selectItemsInCart(componentId, [bus.units?.[0]?.id], {
        [bus.units?.[0]?.id]: ApiAddItemRequestFromJSON({
          count: 1,
          date: bus.start,
          pickupStation: isPickup ? station : bus.pickupStation,
          dropoffStation: isPickup ? bus.dropoffStation : station,
          specialRequests: [],
          notes: [],
          travelers: []
        })
      });
    }
  };

  return (
    <div className="station-selection seat-selection">
      <h5 className="seat-selection__headline h5-medium">{t(Keys.stationSelectHeadline)}</h5>
      <div className="station-selection__container">
        <div>
          <label>{t(Keys.stationSelectPickup)}</label>
          <Select
            value={bus.pickupStation.code || ''}
            onChange={value => setStation(value, true)}
            options={stations.pickup}
            placeholder={t(Keys.select)}
          />
        </div>
        <div>
          <label>{t(Keys.stationSelectDropOff)}</label>
          <Select
            value={bus.dropoffStation?.code || ''}
            onChange={value => setStation(value, false)}
            options={stations.dropOff}
            placeholder={t(Keys.select)}
          />
        </div>
      </div>
    </div>
  );
});

export default StationSelection;
