import { ReactNode } from 'react';
import localFont from '@next/font/local';

export const DMSansFont = localFont({
  src: [
    {
      path: './Styles/Fonts/DMSans-Thin.ttf',
      weight: '100',
      style: 'normal'
    },
    {
      path: './Styles/Fonts/DMSans-ExtraLight.ttf',
      weight: '200',
      style: 'normal'
    },
    {
      path: './Styles/Fonts/DMSans-Light.ttf',
      weight: '300',
      style: 'normal'
    },
    {
      path: './Styles/Fonts/DMSans-Regular.ttf',
      weight: '400',
      style: 'normal'
    },
    {
      path: './Styles/Fonts/DMSans-Medium.ttf',
      weight: '500',
      style: 'normal'
    },
    {
      path: './Styles/Fonts/DMSans-SemiBold.ttf',
      weight: '600',
      style: 'normal'
    },
    {
      path: './Styles/Fonts/DMSans-Bold.ttf',
      weight: '700',
      style: 'normal'
    },
    {
      path: './Styles/Fonts/DMSans-ExtraBold.ttf',
      weight: '800',
      style: 'normal'
    }
  ],
  preload: true,
  display: 'swap',
  style: 'normal',
  variable: '--dm-sans-font'
});

// injected in _document.tsx
export const AlbatrosFont = localFont({
  src: [
    {
      path: './Styles/Fonts/Albatros-Regular.ttf',
      weight: '400',
      style: 'normal'
    },
    {
      path: './Styles/Fonts/Albatros-Italic.ttf',
      weight: '400',
      style: 'italic'
    },
    {
      path: './Styles/Fonts/Albatros-Medium.ttf',
      weight: '500',
      style: 'normal'
    },
    {
      path: './Styles/Fonts/Albatros-MediumItalic.ttf',
      weight: '500',
      style: 'italic'
    },
    {
      path: './Styles/Fonts/Albatros-Bold.ttf',
      weight: '700',
      style: 'normal'
    },
    {
      path: './Styles/Fonts/Albatros-ExtraBold.ttf',
      weight: '800',
      style: 'normal'
    }
  ],
  preload: true,
  display: 'swap',
  style: 'normal',
  variable: '--albatros-font'
});

export const FontLoaderAndSetter = ({ children }: { children: ReactNode }) => {
  return (
    <main className={DMSansFont.className}>
      <div className={AlbatrosFont.variable}>{children}</div>
    </main>
  );
};
