import React, { FC, JSX } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import Transfers from '@/components/transferSelection/Transfers';
import { Props } from '@/types/cms/magnolia';

const TransferSelection: FC<{ checkoutStore: CheckoutStore; pageProps?: Props }> = observer(
  function TransferSelection({ checkoutStore, pageProps }): JSX.Element {
    return (
      <div className="transfer-selection">
        {checkoutStore.transfers.map(transferComponent => (
          <div key={transferComponent.id} className="transfer-selection__inner">
            <Transfers
              transferComponent={transferComponent}
              checkoutStore={checkoutStore}
              pageProps={pageProps}
            />
          </div>
        ))}
      </div>
    );
  }
);

export default TransferSelection;
