import { ConfigModel, ConfigService, defaultConfig } from '@ibe/services';
import { MagnoliaSite } from '@/types/cms/magnolia';
import AlbConfigModel from '@/Config/AlbConfigModel';
import { Language } from '@/Translations';
import { languageMapping } from '@/app/i18n/settings';

export const customConfig: Partial<ConfigModel> = {
  defaultLanguage: Language.DA,
  defaultCurrency: languageMapping.da.currency,
  configUrl: '/config.json',
  displayFormatDate: {
    da: 'DD/MM/YYYY',
    no: 'DD/MM/YYYY',
    fi: 'DD/MM/YYYY',
    sv: 'DD/MM/YYYY',
    pl: 'DD/MM/YYYY'
  },
  displayFormatDateTime: {
    da: 'DD/MM/YYYY HH:mm',
    no: 'DD/MM/YYYY HH:mm',
    fi: 'DD/MM/YYYY HH:mm',
    sv: 'DD/MM/YYYY HH:mm',
    pl: 'DD/MM/YYYY HH:mm'
  },
  displayFormatDateShort: {
    da: 'D. MMMM YYYY',
    no: 'D. MMMM YYYY',
    fi: 'D. MMMM YYYY',
    sv: 'D MMMM YYYY',
    pl: 'D. MMMM YYYY'
  },
  useConsentManager: true,
  googleTagManagerContainerId: 'GTM-TMBVKV6',
  datePickerMinDateOffset: 0,
  session: {
    expirationTimeNotificationOffset: 5
  },
  traveler: {
    maxChildAge: 14,
    maxInfantAge: 2,
    mockAvailable: false,
    genderValues: []
  },
  useIndexedGeoUnits: true
};

export function initConfig(albConfig: AlbConfigModel, siteConfig?: MagnoliaSite) {
  let externalConfig = {
    ...(siteConfig?.parameters || {}),
    tenant: siteConfig?.name
  } as Partial<ConfigModel>;
  configService.mergeIntoConfig(externalConfig);
  configService.mergeIntoConfig(albConfig);
}

export const configService = new ConfigService<AlbConfigModel>(AlbConfigModel, {
  ...defaultConfig,
  googleTagManagerId: { da: '', fi: '', no: '', pl: '', sv: '' }
});
