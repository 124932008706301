'use client';

import React, { ChangeEvent, Dispatch, FC, JSX, SetStateAction, useRef } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Header.json.keys';
import classNames from 'classnames';
import Select from '@/components/Select/Select';
import { Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { Results } from '@/components/websiteSearch/WebsiteSearchFullResults';

export const types = ['product', 'destination', 'otherPages', 'faq'] as const;
export type Type = typeof types[number];

const getTypes = (t: TFunction, results?: Results): { label: string; value: Type }[] => {
  return types.map(value => ({
    label: `${t((Keys as Record<string, string>)[`${value}Full`])}${
      !!results ? ` (${results[value].length})` : ''
    }`,
    value
  }));
};

const WebsiteSearchFullHead: FC<{
  selectedType: Type;
  setSelectedType: Dispatch<SetStateAction<Type>>;
  value: string;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isDesktop: boolean;
  results: Results;
}> = ({ selectedType, setSelectedType, value, onInputChange, isDesktop, results }): JSX.Element => {
  const { t } = useTranslation('Header');
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="website-search-full__search">
      <div className="website-search-full__type">
        {isDesktop ? (
          <div className="website-search-full__type--desktop">
            <div>
              {getTypes(t).map(({ label, value }) => (
                <div
                  key={value}
                  className={classNames('website-search-full__type--desktop', {
                    'website-search-full__type--desktop__selected': value === selectedType
                  })}
                  onClick={(): void => setSelectedType(value)}
                >{`${label} (${results[value].length})`}</div>
              ))}
            </div>
          </div>
        ) : (
          <div className="website-search-full__type--mobile">
            <Select
              value={selectedType}
              onChange={(value: Type) => setSelectedType(value)}
              options={getTypes(t, results)}
            />
          </div>
        )}
      </div>
      <div className="website-search-full__input">
        <div className="website-search-full__input__inner">
          <Label htmlFor="website-search-full">
            {t((Keys as Record<string, string>)[`${selectedType}All`])}
          </Label>
          <div>
            <Input
              id="website-search-full"
              innerRef={inputRef}
              value={value}
              onChange={onInputChange}
            />
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              onClick={(): void => {
                if (!!inputRef.current) {
                  inputRef.current.focus();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteSearchFullHead;
