import React, { FC, useRef, useState } from 'react';
import Keys from '@/Translations/generated/da/search.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { Collapse } from 'reactstrap';
import { useClickAway } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretDownThinSmall } from '@/Theme/SVG/Icons';
import classNames from 'classnames';

const SortDropdown: FC<{
  options: string[];
  onSelect: (idx: number) => void;
  selectedOption: number;
}> = ({ options, onSelect, selectedOption }): JSX.Element => {
  const { t } = useTranslation('search');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useClickAway(containerRef, (): void => {
    setIsOpen(false);
  });

  const handleSelect = (idx: number): void => {
    onSelect(idx);
    setIsOpen(false);
  };

  return (
    <div ref={containerRef} className="sort-dropdown">
      <div className="sort-dropdown__label" onClick={(): void => setIsOpen(value => !value)}>
        <div className="sort-dropdown__label__static">{`${t(Keys.sortBy)}`}</div>
        <div className="sort-dropdown__label__delimiter">-</div>
        <div className="sort-dropdown__label__option">{options[selectedOption]}</div>
        <FontAwesomeIcon className="sort-dropdown__label__icon" icon={facCaretDownThinSmall} />
      </div>
      <Collapse isOpen={isOpen} className="sort-dropdown__menu">
        {options.map((option: string, idx: number) => (
          <div
            key={option}
            className={classNames('sort-dropdown__option', {
              'sort-dropdown__option--active': idx === selectedOption
            })}
            onClick={(): void => handleSelect(idx)}
          >
            {option}
          </div>
        ))}
      </Collapse>
    </div>
  );
};

export default SortDropdown;
