import { ApiService } from '@ibe/services';
import { useApi as useLibApi } from '@ibe/components';
import {
  FetchParams,
  Middleware as IbeApiMiddleware,
  RequestContext as IbeApiRequestContext
} from '@ibe/api';
import { REVALIDATION_DELAY, REVALIDATION_TAG } from '@/Util/globals';

export const useApi = (isAuthor?: boolean): ApiService => {
  return useLibApi().withMiddleware(
    new (class implements IbeApiMiddleware {
      async pre(context: IbeApiRequestContext): Promise<void | FetchParams> {
        return {
          ...context,
          init: {
            ...context.init,
            // cache: (isAuthor ? 'no-store' : 'force-cache') as RequestCache,
            cache: undefined,
            next: { revalidate: isAuthor ? 0 : REVALIDATION_DELAY, tags: [REVALIDATION_TAG] }
          }
        };
      }
    })()
  );
};
