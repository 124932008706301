'use client';

import Keys from '@/Translations/generated/da/continent.json.keys';
import React, { MouseEvent } from 'react';
import { smoothScroll } from '@/Util/globals';
import Intro from '@/components/intro/Intro';
import { useTranslation } from '@/app/i18n/client';
import { ApiContinent } from '../../../api/model';
import sanitize from '@/Util/sanitize';

type Props = {
  continent: ApiContinent;
};

const ContinentIntro = (props: Props): JSX.Element => {
  const { continent } = props;
  const { t } = useTranslation('continent');

  return (
    <Intro
      disableBreadcrumb
      pathname=""
      theme={continent.themeIntro}
      headline={
        continent.introTitleGeneral
          ? continent.introTitleGeneral
          : `${t(Keys.continentIntroHeadline)} ${continent.name}`
      }
      leftSide={
        <div>
          <div className="mb-xs-4 mb-lg-0">
            {!!continent.introTextGeneral && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(continent.introTextGeneral, true)
                }}
              />
            )}
            <a
              className="continent-page__container__link"
              onClick={(e: MouseEvent<HTMLAnchorElement>): void => smoothScroll('contentArea', e)}
            >
              {t(Keys.moreInformation)} {' >'}
            </a>
          </div>
        </div>
      }
      rightSide={
        <div>
          <div className="d-sm-flex">
            {!!continent.citizens && (
              <div className="continent-page__container__infoblock">
                <div className="continent-page__container__infoheadline">{t(Keys.citizens)}</div>
                <div className="continent-page__container__infotext">{continent.citizens}</div>
              </div>
            )}
            {!!continent.area && (
              <div className="continent-page__container__infoblock">
                <div className="continent-page__container__infoheadline">{t(Keys.area)}</div>
                <div className="continent-page__container__infotext">{continent.area}</div>
              </div>
            )}
          </div>
          {continent.largestCountries && (
            <div>
              <div className="continent-page__container__infoheadline">{t(Keys.largestCities)}</div>
              <div
                className="continent-page__container__infotext"
                dangerouslySetInnerHTML={{
                  __html: sanitize(continent.largestCountries, true)
                }}
              />
            </div>
          )}
        </div>
      }
    />
  );
};

export default ContinentIntro;
