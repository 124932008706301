import React, { Fragment, ReactElement } from 'react';
import sanitize from '@/Util/sanitize';
import { SectionData } from '@/components/accordion/SimpleAccordion';

const AccordionSection = ({ group }: { group?: SectionData[] }): ReactElement => {
  return (
    <div className="accordion-element__section">
      {group?.map((section, index) => {
        return (
          <Fragment key={section.sectionHeader}>
            <div className="accordion-element__section__container" key={section.sectionHeader}>
              {section?.sectionHeader && (
                <div className="accordion-element__section__header">{section.sectionHeader}</div>
              )}
              {typeof section.sectionText === 'string' ? (
                <div
                  className="accordion-element__section__text grid-col-12 grid-col-lg-9"
                  dangerouslySetInnerHTML={{ __html: sanitize(section.sectionText, true) }}
                />
              ) : (
                <>{section.sectionText}</>
              )}
            </div>
            {index < group.length - 1 && (
              <div>
                <hr className="accordion-element__section__line" />
                <br className="accordion-element__section__break" />
              </div>
            )}
          </Fragment>
        );
      }) || <></>}
    </div>
  );
};

export default AccordionSection;
