import Analytics, { AnalyticsInstance } from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import packageJson from '../../package.json';
import AlbConfigModel from '@/Config/AlbConfigModel';
import { Language } from '@/Translations';

const analyticsOptions = {
  app: packageJson.name,
  version: packageJson.version,
  debug: false,
  plugins: []
};

export let tracking: AnalyticsInstance | undefined;

export const initTracking = async (config: AlbConfigModel, isDev: boolean, locale: string) => {
  if (!!tracking) {
    tracking = undefined;
    tracking = Analytics(analyticsOptions);
  }

  if (!!config.googleTagManagerId?.[locale as `${Language}`]) {
    analyticsOptions.debug = isDev;
    if (
      !analyticsOptions.plugins.some((item: { name: string }) => item.name === 'google-tag-manager')
    ) {
      analyticsOptions.plugins.push(
        googleTagManager({
          containerId: config.googleTagManagerId?.[locale as `${Language}`],
          enabled: true
        }) as never
      );
    }
  }
  tracking = Analytics(analyticsOptions);
};
