'use client';

import React, { FC, useEffect } from 'react';
import ItineraryAccordion from '@/components/itinerary/ItineraryAccordion';
import { facArrowDown } from '@/Theme/SVG/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GridContainer from '@/Layouts/GridContainer';
import { ApiItinerary, ApiProduct } from '../../../api/model';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/product.json.keys';
import { Props as PageProps } from '@/types/cms/magnolia';

type Props = {
  theme: string;
  itinerary: ApiItinerary[];
  product?: ApiProduct;
  pageProps?: PageProps;
};

let timer: ReturnType<typeof setTimeout> | null = null;

const Itinerary: FC<Props> = ({ theme, itinerary, product, pageProps }) => {
  const { t } = useTranslation('product');

  useEffect(() => {
    return (): void => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const onClick = () => {
    const scrollY = window.scrollY;
    scrollTo(0, 0);
    timer = setTimeout(function () {
      window.print();
    }, 1500);
    scrollTo(0, scrollY);
  };

  return (
    <GridContainer className={`itinerary ${theme}`}>
      <div className="itinerary__wrapper">
        <h2 className="itinerary__header">{t(Keys.dailyProgram)}</h2>
        <button className="btn btn-primary itinerary__btn" onClick={onClick}>
          <div>
            <span className="itinerary__btn__text">{t(Keys.downloadFullProgram)}</span>
            <FontAwesomeIcon icon={facArrowDown} />
          </div>
        </button>
      </div>
      {!!itinerary && itinerary.length > 0 && (
        <ItineraryAccordion groups={itinerary} product={product} />
      )}
    </GridContainer>
  );
};

export default Itinerary;
