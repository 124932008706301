'use client';

import React, { FC, JSX, useEffect, useState } from 'react';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { ApiProductsCacheData } from '@ibe/api';
import { ApiProduct } from '../../../api/model';
import { useTranslation } from '@/app/i18n/client';
import HeroPacificProductInfos from '@/components/checkout/HeroPacificProductInfos';
import { CHECKOUT_CUSTOM_EVENT } from '@/templates/checkout/useCheckoutPageHelpers';

const CheckoutPageHeroContent: FC<{
  selectedPacificProduct: ApiProductsCacheData;
  product?: ApiProduct;
  locale: string;
}> = ({ product, selectedPacificProduct, locale }): JSX.Element => {
  const { t } = useTranslation('Checkout');
  const [productName, setProductName] = useState<string>('');

  useEffect(() => {
    document.addEventListener(CHECKOUT_CUSTOM_EVENT, ((
      evt: CustomEvent<{ productName: string }>
    ) => {
      const { productName } = evt.detail;
      if (!!productName) {
        setProductName(productName || '');
      }
    }) as EventListener);
  }, []);

  return (
    <div className="checkout__infos">
      <h1>{productName || product?.productTitle || ''}</h1>
      <HeroPacificProductInfos pacificProduct={selectedPacificProduct} />
    </div>
  );
};

export default CheckoutPageHeroContent;
