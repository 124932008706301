import React, { JSX, ReactNode, useContext, useEffect, useMemo } from 'react';
import { TemplateAnnotations } from '@magnolia/template-annotations';
import {
  EditableComponent,
  EditorContext,
  EditorContextHelper,
  ComponentHelper
} from '@magnolia/react-editor';
import EditableComment from '../EditableComponent/EditableComment';
import { constants, EditorContextType } from '../componentHelper';

interface Props {
  content: Record<string, any>;
  parentTemplateId?: string;
  className?: string;
  elementType?: string;
  customView?: any;
  children?: ReactNode;
}

const EditableArea = ({
  content,
  parentTemplateId,
  className,
  elementType = 'div',
  customView: CustomView,
  children
}: Props): JSX.Element => {
  const context = useContext<EditorContextType>(EditorContext);
  const componentContents = content['@nodes'].map((nodeName: string) => content[nodeName]);
  const element = React.createElement(
    elementType || 'div',
    className ? { className: ComponentHelper.classnames(className) } : {}
  );

  useEffect(() => {
    if (!context) {
      throw new Error('EditableArea component must be wrapped inside EditablePage component.');
    }
  }, []);

  const childContent = useMemo(() => {
    if (!!CustomView) {
      return <CustomView content={content} />;
    } else if (!!children) {
      return React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { content } as Partial<unknown>);
        }
        return child;
      });
    } else {
      return componentContents.map((item: object, index: number) => (
        <EditableComponent key={ComponentHelper.buildKey(item)} content={item} index={index} />
      ));
    }
  }, []);

  const getParentTemplateId = (): string => {
    const { content: contextContent } = context;

    return parentTemplateId || contextContent[constants.TEMPLATE_ID_PROP];
  };

  const getAnnotation = (): string => {
    if (!content) {
      return '';
    }
    const pageTemplateId = getParentTemplateId();
    const { templateDefinitions, templateAnnotations } = context;
    if (templateDefinitions && templateDefinitions[pageTemplateId]) {
      return TemplateAnnotations.getAreaCommentString(content, templateDefinitions[pageTemplateId]);
    }
    if (templateAnnotations && templateAnnotations[content?.['@path']]) {
      return templateAnnotations[content?.['@path']];
    }
    return '';
  };

  const onReady = (): void => {
    EditorContextHelper.refresh();
  };

  if (!content) {
    return <></>;
  }

  return (
    <>
      <EditableComment annotation={getAnnotation()} callback={onReady} />
      <element.type key={ComponentHelper.buildKey(content)} {...element.props}>
        {childContent}
      </element.type>
      <EditableComment annotation={constants.CLOSED_AREA_COMMENT} />
    </>
  );
};

export default EditableArea;
