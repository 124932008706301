import React, {
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
  MouseEvent,
  Ref
} from 'react';
import { facButtonCaret } from '@/Theme/SVG/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as NextLink } from '@/Hooks/useReactTransitionProgress';
import LinkListLink, { SwitchableLinkProps } from '@/components/linkList/Link';

const Link = (
  props: PropsWithChildren<
    {
      switchableLink?: SwitchableLinkProps;
      menu?: boolean;
      showCaret?: boolean;
      disabled?: boolean;
      labelClassName?: string;
    } & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
        ref?: Ref<HTMLAnchorElement>;
      }
  >
): JSX.Element => {
  const { showCaret, labelClassName, href, switchableLink, ...rest } = props;
  const { menu, children, tabIndex, disabled, onClick, className } = rest;
  return !!switchableLink ? (
    <LinkListLink
      {...switchableLink}
      className={`alb-link${!!className ? ` ${className}` : ''}${menu ? ' alb-link__menu' : ''}${
        disabled ? ' alb-link--disabled' : ''
      }`}
      showCaret={showCaret}
    />
  ) : (
    <NextLink
      {...rest}
      href={href || '#'}
      prefetch={false}
      className={`alb-link${!!className ? ` ${className}` : ''}${menu ? ' alb-link__menu' : ''}${
        disabled ? ' alb-link--disabled' : ''
      }`}
      tabIndex={tabIndex || 0}
      onClick={
        !disabled
          ? onClick
          : (e: MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              return false;
            }
      }
    >
      <span className={labelClassName || ''}>{children}</span>
      {showCaret && <FontAwesomeIcon icon={facButtonCaret} />}
    </NextLink>
  );
};

export default Link;
