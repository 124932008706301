/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiInfoSection,
        ApiInfoSectionFromJSON,
        ApiInfoSectionFromJSONTyped,
        ApiInfoSectionToJSON,
    } from './';

/**
* 
* @export
* @interface ApiInformationGroup
*/
export interface ApiInformationGroup {
    /**
    * 
    * @type {string}
    * @memberof ApiInformationGroup
    */
    groupName: string;
    /**
    * 
    * @type {Array<ApiInfoSection>}
    * @memberof ApiInformationGroup
    */
    infoSection?: Array<ApiInfoSection>;
}

export function ApiInformationGroupFromJSON(json: any): ApiInformationGroup {
return ApiInformationGroupFromJSONTyped(json, false);
}

export function ApiInformationGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInformationGroup {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'groupName': json['groupName'],
                'infoSection': ((json['infoSection'] as Array<any>).map(ApiInfoSectionFromJSON)),
        };
    }

    export function ApiInformationGroupToJSON(value?: ApiInformationGroup | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'groupName': value.groupName,
                    'infoSection': ((value.infoSection as Array<any>).map(ApiInfoSectionToJSON)),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

