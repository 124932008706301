import { ApiProductsCacheData, ApiProductsCacheResponse } from '@ibe/api';
import dayjs from 'dayjs';

export const getCheapestProduct = (
  product: ApiProductsCacheResponse,
  matchingPackageCode?: string
): ApiProductsCacheData | undefined => {
  return product.products?.reduce((total, current) => {
    return ((!!current.startingPriceAmount &&
      !!total.startingPriceAmount &&
      current.startingPriceAmount < total.startingPriceAmount) ||
      !total.startingPriceAmount) &&
      (!matchingPackageCode ||
        (!!matchingPackageCode && matchingPackageCode === current.packageCode))
      ? current
      : total;
  }, product.products?.[0]);
};

export const getProductByOccupancy = (
  productPackage: ApiProductsCacheResponse
): ApiProductsCacheData | undefined => {
  // get product with occupancy of 2, otherwise the one with the lowest occupancy
  const doubleOccupancy = productPackage?.products?.find(product => product?.maxOccupancy === 2);
  return (
    doubleOccupancy ||
    productPackage?.products?.reduce((total, current) => {
      return !!current?.maxOccupancy && current.maxOccupancy <= (total?.maxOccupancy || 1)
        ? current
        : total;
    }, productPackage?.products?.[0])
  );
};

export const getEarliestProduct = (
  product: ApiProductsCacheResponse
): ApiProductsCacheData | undefined => {
  return product.products?.reduce((total, current) => {
    return (!!current.travelStartDate &&
      !!total.travelStartDate &&
      dayjs(current.travelStartDate).unix() < dayjs(total.travelStartDate).unix() &&
      (current.availability || 0) > 0) ||
      ((current.availability || 0) > 0 && (total.availability || 0) <= 0) ||
      !total.travelStartDate
      ? current
      : total;
  }, product.products?.[0]);
};

export const getShortestDurationProduct = (
  product: ApiProductsCacheResponse
): ApiProductsCacheData | undefined => {
  return product.products?.reduce((total, current) => {
    return (!!current.duration && !!total.duration && current.duration < total.duration) ||
      !total.duration
      ? current
      : total;
  }, product.products?.[0]);
};
