'use client';

import React, { JSX, PropsWithChildren } from 'react';
import ContextProvider, { ApplicationProps } from '@/components/contextProviders/ContextProviders';
import { useMount } from 'react-use';
import { lngCookieName } from '@/app/i18n/settings';
import { REVALIDATION_DELAY, REVALIDATION_TAG } from '@/Util/globals';

const MagnoliaPage = ({
  pageProps,
  isProduction,
  locale,
  children,
  albConfig,
  isLocal
}: PropsWithChildren<ApplicationProps>): JSX.Element => {
  const { isAuthor } = pageProps;
  useMount(async () => {
    // Set language cookie
    await fetch(
      `${process.env.NEXT_PUBLIC_ENDPOINT_COOKIE}?name=${lngCookieName}&value=${locale}`,
      {
        // cache: isAuthor ? 'no-store' : 'force-cache',
        cache: undefined,
        next: { revalidate: isAuthor ? 0 : REVALIDATION_DELAY, tags: [REVALIDATION_TAG] }
      }
    );
  });

  return (
    <ContextProvider
      isProduction={isProduction}
      pageProps={pageProps}
      locale={locale}
      albConfig={albConfig}
      isLocal={isLocal}
    >
      {children}
    </ContextProvider>
  );
};

export default MagnoliaPage;
