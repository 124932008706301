/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiContentArea,
        ApiContentAreaFromJSON,
        ApiContentAreaFromJSONTyped,
        ApiContentAreaToJSON,
        ApiFooterContent,
        ApiFooterContentFromJSON,
        ApiFooterContentFromJSONTyped,
        ApiFooterContentToJSON,
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
        ApiMetaInformation,
        ApiMetaInformationFromJSON,
        ApiMetaInformationFromJSONTyped,
        ApiMetaInformationToJSON,
        ApiVideo,
        ApiVideoFromJSON,
        ApiVideoFromJSONTyped,
        ApiVideoToJSON,
    } from './';

/**
* 
* @export
* @interface ApiShip
*/
export interface ApiShip {
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    id?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    name: string;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    introTitle?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    productTeasersHeadline?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    teaserShortDescription?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    introText?: string;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiShip
    */
    introImage?: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    contentAreaTitle?: string;
    /**
    * 
    * @type {Array<ApiContentArea>}
    * @memberof ApiShip
    */
    contentArea?: Array<ApiContentArea>;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    salesPriority?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    content?: string;
    /**
    * 
    * @type {ApiVideo}
    * @memberof ApiShip
    */
    heroBackgroundVideo?: ApiVideo;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiShip
    */
    heroBackgroundImage?: ApiImage;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiShip
    */
    teaserImage?: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    galleryHeadline?: string;
    /**
    * 
    * @type {Array<ApiImage>}
    * @memberof ApiShip
    */
    imageGallery?: Array<ApiImage>;
    /**
    * 
    * @type {ApiFooterContent}
    * @memberof ApiShip
    */
    footerTopLeft?: ApiFooterContent;
    /**
    * 
    * @type {ApiFooterContent}
    * @memberof ApiShip
    */
    footerTopRight?: ApiFooterContent;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    themeIntro?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    themeContentArea?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    themeImageGallery?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiShip
    */
    themeProductTeaser?: string;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiShip
    */
    metaInformation?: ApiMetaInformation;
}

export function ApiShipFromJSON(json: any): ApiShip {
return ApiShipFromJSONTyped(json, false);
}

export function ApiShipFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiShip {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'id': json['id'],
                    'name': json['name'],
                    'introTitle': json['introTitle'],
                    'productTeasersHeadline': json['productTeasersHeadline'],
                    'teaserShortDescription': json['teaserShortDescription'],
                    'introText': json['introText'],
                            'introImage': ApiImageFromJSON(json['introImage']),
                    'contentAreaTitle': json['contentAreaTitle'],
                'contentArea': ((json['contentArea'] as Array<any>).map(ApiContentAreaFromJSON)),
                    'salesPriority': json['salesPriority'],
                    'content': json['content'],
                            'heroBackgroundVideo': ApiVideoFromJSON(json['heroBackgroundVideo']),
                            'heroBackgroundImage': ApiImageFromJSON(json['heroBackgroundImage']),
                            'teaserImage': ApiImageFromJSON(json['teaserImage']),
                    'galleryHeadline': json['galleryHeadline'],
                'imageGallery': ((json['imageGallery'] as Array<any>).map(ApiImageFromJSON)),
                            'footerTopLeft': ApiFooterContentFromJSON(json['footerTopLeft']),
                            'footerTopRight': ApiFooterContentFromJSON(json['footerTopRight']),
                    'themeIntro': json['themeIntro'],
                    'themeContentArea': json['themeContentArea'],
                    'themeImageGallery': json['themeImageGallery'],
                    'themeProductTeaser': json['themeProductTeaser'],
                            'metaInformation': ApiMetaInformationFromJSON(json['metaInformation']),
        };
    }

    export function ApiShipToJSON(value?: ApiShip | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'id': value.id,
                'name': value.name,
                'introTitle': value.introTitle,
                'productTeasersHeadline': value.productTeasersHeadline,
                'teaserShortDescription': value.teaserShortDescription,
                'introText': value.introText,
                            'introImage': ApiImageToJSON(value.introImage),
                'contentAreaTitle': value.contentAreaTitle,
                    'contentArea': ((value.contentArea as Array<any>).map(ApiContentAreaToJSON)),
                'salesPriority': value.salesPriority,
                'content': value.content,
                            'heroBackgroundVideo': ApiVideoToJSON(value.heroBackgroundVideo),
                            'heroBackgroundImage': ApiImageToJSON(value.heroBackgroundImage),
                            'teaserImage': ApiImageToJSON(value.teaserImage),
                'galleryHeadline': value.galleryHeadline,
                    'imageGallery': ((value.imageGallery as Array<any>).map(ApiImageToJSON)),
                            'footerTopLeft': ApiFooterContentToJSON(value.footerTopLeft),
                            'footerTopRight': ApiFooterContentToJSON(value.footerTopRight),
                'themeIntro': value.themeIntro,
                'themeContentArea': value.themeContentArea,
                'themeImageGallery': value.themeImageGallery,
                'themeProductTeaser': value.themeProductTeaser,
                            'metaInformation': ApiMetaInformationToJSON(value.metaInformation),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

