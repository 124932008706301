import React from 'react';
import { Label } from 'reactstrap';
import { Controller, FieldValues } from 'react-hook-form';
import dayjs from 'dayjs';
import useConfig from '../../Hooks/useConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Datepicker from '@/components/Datepickers/Datepicker';
import { useFormContext, DatePickerProps } from '@ibe/components';

interface Props extends Omit<DatePickerProps, 'onChange'> {
  name: string;
  baseName?: string;
  label?: string;
  boldLabel?: boolean;
  errorsProp?: Record<string, string>;
  dataTestId: string;
  selectedDate?: (date: string) => Date;
  showErrors?: boolean;
  showErrorIcon?: boolean;
  placeholderText?: string;
  withKeyboardSupport?: boolean;
  showInModal?: boolean;
  displayFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  inputClassName?: string;
  useFormValidation?: boolean;
  inLine?: boolean;
  modalLabel?: string;
}

const YEARS_TO_DISPLAY = 100;

const getYears = (): number[] => {
  return [...Array(YEARS_TO_DISPLAY).keys()].map(index => dayjs().get('year') - index);
};

const FormDatePicker = (props: Props): JSX.Element | null => {
  const {
    errorsProp,
    label,
    name,
    baseName,
    showErrors,
    boldLabel,
    showErrorIcon,
    placeholderText
  } = props;
  const { register, errors, control } = useFormContext<FieldValues>();
  const config = useConfig();
  if (!register) return null;

  const formErrors = errorsProp || errors;

  const handleChange = (date: string, onFieldChange: (...event: unknown[]) => void): void => {
    onFieldChange(date ? dayjs(date).format(config.formatDate) : undefined);
  };

  return (
    <div
      className={`form__datepicker ${
        formErrors && showErrors && formErrors[baseName || name] ? 'input--error' : ''
      }`}
    >
      <Label
        className={`${
          formErrors && showErrors && formErrors[baseName || name] ? 'form__error__top' : ''
        }${boldLabel ? ' font-weight-bold' : ''}`}
      >
        {label || ''}
      </Label>
      <Controller
        name={name}
        control={control}
        render={({ field }): JSX.Element => {
          return (
            <>
              <Datepicker
                /* eslint-disable */
                {...props}
                {...field}
                /* eslint-enable */
                date={field.value}
                setDate={(date: string): void => handleChange(date, field.onChange)}
                placeholder={placeholderText}
                customYears={getYears()}
              />
            </>
          );
        }}
      />
      {formErrors && showErrors
        ? formErrors[baseName || name] && (
            <div className="form__error">{formErrors[baseName || name].message}</div>
          )
        : null}
      {showErrorIcon && formErrors && showErrors && formErrors[baseName || name] && (
        <FontAwesomeIcon icon={faExclamationTriangle} />
      )}
    </div>
  );
};

export default FormDatePicker;
