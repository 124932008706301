import AccordionElement from '@/components/accordion/AccordionElement';
import React, { useMemo, useState } from 'react';
import Keys from '@/Translations/generated/da/generalForHold.json.keys';
import AccordionSection from '@/components/accordion/AccordionSection';
import { SectionData } from '@/components/accordion/SimpleAccordion';
import { useTranslation } from '@/app/i18n/client';
import AccordionElementList from '@/components/accordion/AccordionElementList';

export interface TravelInformationAccordionData {
  groupHeader: string;
  section: Array<TravelInformationSectionData> | undefined;
}

export interface TravelInformationSectionData {
  sectionHeader: string;
  sectionText: string;
}

const TravelInformationAccordion = (props: {
  groups: Array<TravelInformationAccordionData>;
  showGeneralForHold?: boolean;
}) => {
  const { groups, showGeneralForHold } = props;
  const { t } = useTranslation('generalForHold');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const generalForHold = useMemo(
    () =>
      [
        {
          sectionHeader: t(Keys.sectionHeader1),
          sectionText: t(Keys.sectionBody1)
        },
        {
          sectionHeader: t(Keys.sectionHeader2),
          sectionText: t(Keys.sectionBody2)
        },
        {
          sectionHeader: t(Keys.sectionHeader3),
          sectionText: t(Keys.sectionBody3)
        },
        {
          sectionHeader: t(Keys.sectionHeader4),
          sectionText: t(Keys.sectionBody4)
        },
        {
          sectionHeader: t(Keys.sectionHeader5),
          sectionText: t(Keys.sectionBody5)
        },
        {
          sectionHeader: t(Keys.sectionHeader6),
          sectionText: t(Keys.sectionBody6)
        },
        {
          sectionHeader: t(Keys.sectionHeader7),
          sectionText: t(Keys.sectionBody7)
        },
        {
          sectionHeader: t(Keys.sectionHeader8),
          sectionText: t(Keys.sectionBody8)
        },
        {
          sectionHeader: t(Keys.sectionHeader9),
          sectionText: t(Keys.sectionBody9)
        },
        {
          sectionHeader: t(Keys.sectionHeader10),
          sectionText: t(Keys.sectionBody10)
        },
        {
          sectionHeader: t(Keys.sectionHeader11),
          sectionText: t(Keys.sectionBody11)
        },
        {
          sectionHeader: t(Keys.sectionHeader12),
          sectionText: t(Keys.sectionBody12)
        },
        {
          sectionHeader: t(Keys.sectionHeader13),
          sectionText: t(Keys.sectionBody13)
        }
      ] as SectionData[],
    [t]
  );

  return (
    <div className="accordion">
      <AccordionElementList
        items={groups}
        bodyCb={group => <AccordionSection group={group?.section} />}
        headerCb={group => (group?.groupHeader ? <h3> {group?.groupHeader} </h3> : <></>)}
      />
      {showGeneralForHold && (
        <AccordionElement
          isOpen={isOpen}
          openHandler={() => setIsOpen(!isOpen)}
          key="generalForHold"
          accordionBody={<AccordionSection group={generalForHold} />}
          accordionHeader={<h3>{t(Keys.generalForHoldHeadline)}</h3>}
        />
      )}
    </div>
  );
};
export default TravelInformationAccordion;
