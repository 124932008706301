'use client';

import React, { FC } from 'react';
import { SelectField } from '@/components/magnoliaForm/types';
import { FormGroup, Input, Label } from 'reactstrap';
import classNames from 'classnames';
import Checkbox from '@/components/Search/Checkbox';
import Radio from '@/components/Search/Radio';

export const CHECKBOX_GROUP_NAME_DELIMITER = '$$$$';

const Select: FC<SelectField & { requiredSymbol: string }> = ({
  type,
  labels,
  controlName,
  title,
  horizontal,
  mandatory,
  default: defaultValue,
  requiredSymbol
}): JSX.Element => {
  const options = labels
    ?.split('\n')
    .map(keyValue => ({ key: keyValue?.split(':')?.[0], value: keyValue?.split(':')?.[1] }));
  return (
    <div className="magnolia-form__selection">
      {!!title && <Label>{`${title}${mandatory ? requiredSymbol : ''}`}</Label>}
      {(type === 'checkbox' || type === 'radio') && (
        <div
          className={classNames('magnolia-form__checkbox-group', {
            'magnolia-form__checkbox-group--horizontal': horizontal
          })}
        >
          {type === 'checkbox' &&
            !!options &&
            options.map(({ key, value }) => (
              <FormGroup key={key} check>
                <Checkbox
                  name={`${controlName}${CHECKBOX_GROUP_NAME_DELIMITER}${key}`}
                  label={value}
                  manual
                />
              </FormGroup>
            ))}
          {type === 'radio' &&
            !!options &&
            options.map(({ key, value }) => (
              <FormGroup key={key} check>
                <Radio id={`${controlName}-${value}`} name={controlName} label={value} manual />
              </FormGroup>
            ))}
        </div>
      )}
      {type === 'select' && !!options && (
        <div className="magnolia-form__select">
          <Input type="select" name={controlName} defaultValue={defaultValue}>
            {options.map(({ key, value }) => (
              <option key={key} value={value}>
                {key}
              </option>
            ))}
          </Input>
        </div>
      )}
    </div>
  );
};

export default Select;
