import React, { FC, JSX } from 'react';
import { AllItems } from '@/components/checkout/cart/getAllItems';
import { observer } from 'mobx-react';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { getDefaultFormatPrice } from '@/Util/globals';

const Insurances: FC<{ allItems: AllItems }> = observer(function Insurances({
  allItems
}): JSX.Element {
  const language = useCurrentLanguage();
  const { t } = useTranslation('Checkout');
  const { insurances } = allItems;

  return Object.keys(insurances).length > 0 ? (
    <>
      <hr />
      <div className="cart__headline">{t(Keys.insurances)}</div>
      <div className="checkout__data">
        {Object.entries(insurances).map(([traveler, insuranceItems], idx) => (
          <div key={traveler} className="checkout__data__section">
            <FontAwesomeIcon icon={faPlus} />
            <div>
              <div className="font-weight-bold">
                {t(Keys.insuranceParticipant, { number: idx + 1 })}
              </div>
              {insuranceItems.length === 0 ? (
                <div className="checkout__data__price checkout__data__price--inner">
                  <div className="checkout__data__price__single-item">
                    {t(Keys.noInsuranceSelected)}
                  </div>
                </div>
              ) : (
                <>
                  {insuranceItems.map(insuranceItem => (
                    <div
                      key={insuranceItem.id}
                      className="checkout__data__price checkout__data__price--inner"
                    >
                      <div>{insuranceItem.name}</div>
                      <div
                        className={classNames({
                          checkout__data__included: insuranceItem?.price?.finalPrice === 0
                        })}
                      >
                        {insuranceItem?.price?.finalPrice === 0
                          ? t(Keys.includedAlt)
                          : getDefaultFormatPrice(insuranceItem?.price?.finalPrice || 0, language)}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  ) : (
    <></>
  );
});

export default Insurances;
