/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiContentArea,
        ApiContentAreaFromJSON,
        ApiContentAreaFromJSONTyped,
        ApiContentAreaToJSON,
        ApiCountry,
        ApiCountryFromJSON,
        ApiCountryFromJSONTyped,
        ApiCountryToJSON,
        ApiFooterContent,
        ApiFooterContentFromJSON,
        ApiFooterContentFromJSONTyped,
        ApiFooterContentToJSON,
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
        ApiMetaInformation,
        ApiMetaInformationFromJSON,
        ApiMetaInformationFromJSONTyped,
        ApiMetaInformationToJSON,
        ApiVideo,
        ApiVideoFromJSON,
        ApiVideoFromJSONTyped,
        ApiVideoToJSON,
        ApiVideoContent,
        ApiVideoContentFromJSON,
        ApiVideoContentFromJSONTyped,
        ApiVideoContentToJSON,
    } from './';

/**
* 
* @export
* @interface ApiContinent
*/
export interface ApiContinent {
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    id?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    name: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    countryListTitle?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    introTitleGeneral?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    introTextGeneral?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    citizens?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    area?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    largestCountries?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    headlineProductTeasers?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    contentAreaTitle?: string;
    /**
    * 
    * @type {Array<ApiContentArea>}
    * @memberof ApiContinent
    */
    contentArea?: Array<ApiContentArea>;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    content?: string;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiContinent
    */
    heroBackgroundImage?: ApiImage;
    /**
    * 
    * @type {ApiVideo}
    * @memberof ApiContinent
    */
    heroBackgroundVideo?: ApiVideo;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiContinent
    */
    mainImage: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    galleryHeadline?: string;
    /**
    * 
    * @type {Array<ApiImage>}
    * @memberof ApiContinent
    */
    imageGallery?: Array<ApiImage>;
    /**
    * 
    * @type {Array<ApiVideoContent>}
    * @memberof ApiContinent
    */
    continentVideos?: Array<ApiVideoContent>;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    themeCountryList?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    themeIntro?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    themeContentArea?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiContinent
    */
    themeImageGallery?: string;
    /**
    * 
    * @type {ApiFooterContent}
    * @memberof ApiContinent
    */
    footerTopLeft?: ApiFooterContent;
    /**
    * 
    * @type {ApiFooterContent}
    * @memberof ApiContinent
    */
    footerTopRight?: ApiFooterContent;
    /**
    * 
    * @type {ApiMetaInformation}
    * @memberof ApiContinent
    */
    metaInformation?: ApiMetaInformation;
    /**
    * 
    * @type {number}
    * @memberof ApiContinent
    */
    products?: number;
    /**
    * 
    * @type {Array<ApiCountry>}
    * @memberof ApiContinent
    */
    countries?: Array<ApiCountry>;
}

export function ApiContinentFromJSON(json: any): ApiContinent {
return ApiContinentFromJSONTyped(json, false);
}

export function ApiContinentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiContinent {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'id': json['id'],
                    'name': json['name'],
                    'countryListTitle': json['countryListTitle'],
                    'introTitleGeneral': json['introTitleGeneral'],
                    'introTextGeneral': json['introTextGeneral'],
                    'citizens': json['citizens'],
                    'area': json['area'],
                    'largestCountries': json['largestCountries'],
                    'headlineProductTeasers': json['headlineProductTeasers'],
                    'contentAreaTitle': json['contentAreaTitle'],
                'contentArea': ((json['contentArea'] as Array<any>).map(ApiContentAreaFromJSON)),
                    'content': json['content'],
                            'heroBackgroundImage': ApiImageFromJSON(json['heroBackgroundImage']),
                            'heroBackgroundVideo': ApiVideoFromJSON(json['heroBackgroundVideo']),
                            'mainImage': ApiImageFromJSON(json['mainImage']),
                    'galleryHeadline': json['galleryHeadline'],
                'imageGallery': ((json['imageGallery'] as Array<any>).map(ApiImageFromJSON)),
                'continentVideos': ((json['continentVideos'] as Array<any>).map(ApiVideoContentFromJSON)),
                    'themeCountryList': json['themeCountryList'],
                    'themeIntro': json['themeIntro'],
                    'themeContentArea': json['themeContentArea'],
                    'themeImageGallery': json['themeImageGallery'],
                            'footerTopLeft': ApiFooterContentFromJSON(json['footerTopLeft']),
                            'footerTopRight': ApiFooterContentFromJSON(json['footerTopRight']),
                            'metaInformation': ApiMetaInformationFromJSON(json['metaInformation']),
                    'products': json['products'],
                'countries': ((json['countries'] as Array<any>).map(ApiCountryFromJSON)),
        };
    }

    export function ApiContinentToJSON(value?: ApiContinent | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'id': value.id,
                'name': value.name,
                'countryListTitle': value.countryListTitle,
                'introTitleGeneral': value.introTitleGeneral,
                'introTextGeneral': value.introTextGeneral,
                'citizens': value.citizens,
                'area': value.area,
                'largestCountries': value.largestCountries,
                'headlineProductTeasers': value.headlineProductTeasers,
                'contentAreaTitle': value.contentAreaTitle,
                    'contentArea': ((value.contentArea as Array<any>).map(ApiContentAreaToJSON)),
                'content': value.content,
                            'heroBackgroundImage': ApiImageToJSON(value.heroBackgroundImage),
                            'heroBackgroundVideo': ApiVideoToJSON(value.heroBackgroundVideo),
                            'mainImage': ApiImageToJSON(value.mainImage),
                'galleryHeadline': value.galleryHeadline,
                    'imageGallery': ((value.imageGallery as Array<any>).map(ApiImageToJSON)),
                    'continentVideos': ((value.continentVideos as Array<any>).map(ApiVideoContentToJSON)),
                'themeCountryList': value.themeCountryList,
                'themeIntro': value.themeIntro,
                'themeContentArea': value.themeContentArea,
                'themeImageGallery': value.themeImageGallery,
                            'footerTopLeft': ApiFooterContentToJSON(value.footerTopLeft),
                            'footerTopRight': ApiFooterContentToJSON(value.footerTopRight),
                            'metaInformation': ApiMetaInformationToJSON(value.metaInformation),
                'products': value.products,
                    'countries': ((value.countries as Array<any>).map(ApiCountryToJSON)),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

