'use client';

import React, { FC, JSX, ReactElement, useCallback } from 'react';
import TeaserCarousel from '@/components/TeaserCarousel/TeaserCarousel';
import TravelTypeTeaser from '@/components/travelTypeTeaser/TravelTypeTeaser';
import { ApiTravelType } from '../../../api/model';
import { Props } from '@/types/cms/magnolia';

const TravelTypeTeaserCarousel: FC<{
  travelTypes: ApiTravelType[];
  pageProps?: Props;
}> = ({ travelTypes, pageProps }): JSX.Element => {
  const renderItem = useCallback((travelType: ApiTravelType): ReactElement => {
    return <TravelTypeTeaser travelType={travelType} pageProps={pageProps} />;
  }, []);
  return (
    <>
      <TeaserCarousel
        items={travelTypes}
        renderItem={renderItem}
        innerItemClass=".video-gallery__video"
      />
    </>
  );
};

export default TravelTypeTeaserCarousel;
