import React from 'react';
import { Link } from '@/Hooks/useReactTransitionProgress';
import { getNextLinkAttrs } from '../linkList/Link';
import NextImage from 'next/image';
import {
  MagnoliaComponentNode,
  MagnoliaDamNode,
  LinkSwitchableMixin,
  CustomImageSizeSwitchableMixin
} from '@/types/cms/magnolia';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

interface Props extends LinkSwitchableMixin, CustomImageSizeSwitchableMixin {
  image: MagnoliaDamNode;
  imageAltText?: string;
  imageCaption?: string;
  keepAspectRatio?: boolean;
  imageCredit?: string;
  imageTitle?: string;
  metadata: MagnoliaComponentNode;
  openNewBrowserWindow?: boolean;
}

const StaticImage = (
  props: Props & LinkSwitchableMixin & CustomImageSizeSwitchableMixin
): JSX.Element => {
  const {
    image,
    imageAltText,
    imageTitle,
    imageCaption,
    imageSize,
    keepAspectRatio,
    imageSizecustomImageWidth,
    imageSizecustomImageHeight,
    imageCredit,
    linkTypedownload,
    linkTypeexternal,
    linkTypepage
  } = props;
  const imageElement = (
    <NextImage
      className={imageSize}
      style={keepAspectRatio ? { objectFit: 'contain' } : {}}
      src={image['@link']}
      alt={imageAltText ?? (image.metadata?.caption || '')}
      title={imageTitle ?? image.metadata?.title}
      width={imageSize === 'custom' ? imageSizecustomImageWidth! : image.metadata?.width}
      height={imageSize === 'custom' ? imageSizecustomImageHeight! : image.metadata?.height}
    />
  );
  const linkAttrs = getNextLinkAttrs(props, useGlobalMGLProps());
  return (
    <figure className="image">
      {(linkAttrs?.href && (linkTypedownload || linkTypeexternal || linkTypepage) && (
        <Link {...linkAttrs} prefetch={false}>
          {imageElement}
        </Link>
      )) ||
        imageElement}
      {(imageCaption || imageCredit) && (
        <figcaption className="image__caption">
          <span>{imageCaption ?? ''}</span>
          {imageCredit && <span>{imageCredit}</span>}
        </figcaption>
      )}
    </figure>
  );
};

export default StaticImage;
