import React, { FC, JSX } from 'react';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { ApiExtraUnit, ApiHotel, ApiHotelRoom } from '@ibe/api';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { ApiRoom } from '../../../api/model';
import SelectionItem from '@/components/selectionItems/SelectionItem';
import SelectionItems from '@/components/selectionItems/SelectionItems';
import { observer } from 'mobx-react';
import { Props } from '@/types/cms/magnolia';

export type HotelRoomsProps = {
  allRooms: ApiHotelRoom[];
  allExtraUnits: ApiExtraUnit[];
  cmsRooms: (ApiRoom | undefined)[];
  selectedHotel?: ApiHotel;
  componentId: string;
  checkoutStore: CheckoutStore;
  hotel?: ApiHotel;
  selectExternalHotel?: (hotel: ApiHotel, roomUnitRateId?: string) => void;
  pageProps?: Props;
};

const HotelRooms: FC<HotelRoomsProps> = observer(function HotelRooms({
  allRooms,
  selectedHotel,
  componentId,
  checkoutStore,
  hotel,
  selectExternalHotel,
  pageProps,
  allExtraUnits,
  cmsRooms
}): JSX.Element {
  const { t } = useTranslation('Checkout');

  return (
    <SelectionItems
      numberOfItems={allRooms.length}
      numberOfRooms={allRooms.length}
      label={t(Keys.upgrade)}
    >
      {allRooms.map((singleRoom: ApiHotelRoom, idx: number) => (
        <SelectionItem
          key={singleRoom?.roomrates?.[0]?.id || idx}
          itemId={singleRoom?.id || idx.toString()}
          selectedItemId={selectedHotel?.rooms?.[0]?.id}
          cartSelectionId={singleRoom?.roomrates?.[0]?.id}
          isOnlyItem={allRooms.length === 1}
          componentId={componentId}
          checkoutStore={checkoutStore}
          name={singleRoom?.description || allExtraUnits?.[idx]?.name}
          price={singleRoom?.roomrates?.[0]?.totalPrice}
          description={cmsRooms[idx]?.description}
          image={cmsRooms[idx]?.image}
          showImages={cmsRooms.every(room => !!room?.image)}
          pageProps={pageProps}
          selectExternalItem={
            !!selectExternalHotel
              ? () => {
                  if (!!hotel) {
                    selectExternalHotel(hotel, allExtraUnits?.[idx]?.unitRates?.[0]?.id);
                  }
                }
              : undefined
          }
        />
      ))}
    </SelectionItems>
  );
});

export default HotelRooms;
