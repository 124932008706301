/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
* 
* @export
* @interface ApiInfoSectionFact
*/
export interface ApiInfoSectionFact {
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    timeDifference?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    priceLevel?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    currencyCreditCard?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    drinkingWaterAndHygiene?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    electricity?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    etiquette?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    phoneInternet?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    smoking?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    tips?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    transportation?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    freeTextTitle?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiInfoSectionFact
    */
    freeTextDescription?: string;
}

export function ApiInfoSectionFactFromJSON(json: any): ApiInfoSectionFact {
return ApiInfoSectionFactFromJSONTyped(json, false);
}

export function ApiInfoSectionFactFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInfoSectionFact {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'timeDifference': json['timeDifference'],
                    'priceLevel': json['priceLevel'],
                    'currencyCreditCard': json['currencyCreditCard'],
                    'drinkingWaterAndHygiene': json['drinkingWaterAndHygiene'],
                    'electricity': json['electricity'],
                    'etiquette': json['etiquette'],
                    'phoneInternet': json['phoneInternet'],
                    'smoking': json['smoking'],
                    'tips': json['tips'],
                    'transportation': json['transportation'],
                    'freeTextTitle': json['freeTextTitle'],
                    'freeTextDescription': json['freeTextDescription'],
        };
    }

    export function ApiInfoSectionFactToJSON(value?: ApiInfoSectionFact | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'timeDifference': value.timeDifference,
                'priceLevel': value.priceLevel,
                'currencyCreditCard': value.currencyCreditCard,
                'drinkingWaterAndHygiene': value.drinkingWaterAndHygiene,
                'electricity': value.electricity,
                'etiquette': value.etiquette,
                'phoneInternet': value.phoneInternet,
                'smoking': value.smoking,
                'tips': value.tips,
                'transportation': value.transportation,
                'freeTextTitle': value.freeTextTitle,
                'freeTextDescription': value.freeTextDescription,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

