/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
* 
* @export
* @interface ApiImage
*/
export interface ApiImage {
    /**
    * 
    * @type {string}
    * @memberof ApiImage
    */
    id?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiImage
    */
    assetId?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiImage
    */
    title?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiImage
    */
    caption?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiImage
    */
    imageLink?: string;
    /**
    * 
    * @type {number}
    * @memberof ApiImage
    */
    height?: number;
    /**
    * 
    * @type {number}
    * @memberof ApiImage
    */
    width?: number;
}

export function ApiImageFromJSON(json: any): ApiImage {
return ApiImageFromJSONTyped(json, false);
}

export function ApiImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiImage {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'id': json['id'],
                    'assetId': json['assetId'],
                    'title': json['title'],
                    'caption': json['caption'],
                    'imageLink': json['imageLink'],
                    'height': json['height'],
                    'width': json['width'],
        };
    }

    export function ApiImageToJSON(value?: ApiImage | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'id': value.id,
                'assetId': value.assetId,
                'title': value.title,
                'caption': value.caption,
                'imageLink': value.imageLink,
                'height': value.height,
                'width': value.width,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

